import React, { Fragment } from "react";
import { Grid, Typography } from "@material-ui/core";
import {
  FacebookShareButton,
  TwitterShareButton,
  LineShareButton,
  PinterestShareButton,
} from "react-share";
import ShareIcon from "@material-ui/icons/Share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import swal from "@sweetalert/with-react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  shareSection: {
    display: "flex",
    alignItems: "center",
    padding: "20px 0",
  },
  linkCopy: {
    color: "#555555",
    opacity: 0.65,
    overflow: "hidden",
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 1,
    width: 170,
  },
  socialIcon: {
    outline: "none",
  },
}));

const ShareSection = () => {
  const classes = useStyles();
  return (
    <Fragment>
      <div className={classes.shareSection}>
        <Grid container spacing={2} style={{ alignItems: "center" }}>
          <Grid item>
            <ShareIcon />
          </Grid>
          <Grid item>
            <Typography style={{ fontWeight: "bold" }}>Bagikan :</Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.linkCopy}>
              {window.location.href}
            </Typography>
          </Grid>
          <Grid item>
            <CopyToClipboard
              text={window.location.href}
              onCopy={() => swal("Success", "Link disalin!", "success")}
            >
              <Typography style={{ cursor: "pointer" }}>Salin</Typography>
            </CopyToClipboard>
          </Grid>
          <Grid item>
            <Typography>Atau bagikan di</Typography>
          </Grid>
          <Grid item>
            <div>
              <Grid container spacing={2}>
                <Grid item>
                  <FacebookShareButton
                    url={window.location.href}
                    quote={document.title}
                    className={classes.socialIcon}
                  >
                    <img
                      src={require("../../../../assets/icons/facebook.svg")}
                      alt=""
                    />
                  </FacebookShareButton>
                </Grid>
                <Grid item>
                  <TwitterShareButton
                    url={window.location.href}
                    quote={document.title}
                    className={classes.socialIcon}
                  >
                    <img
                      src={require("../../../../assets/icons/twitter.svg")}
                      alt=""
                    />
                  </TwitterShareButton>
                </Grid>
                <Grid item>
                  <LineShareButton
                    url={window.location.href}
                    quote={document.title}
                    className={classes.socialIcon}
                  >
                    <img
                      src={require("../../../../assets/icons/line.svg")}
                      alt=""
                    />
                  </LineShareButton>
                </Grid>
                <Grid item>
                  <PinterestShareButton
                    url={window.location.href}
                    quote={document.title}
                    className={classes.socialIcon}
                  >
                    <img
                      src={require("../../../../assets/icons/pinterest.svg")}
                      alt=""
                    />
                  </PinterestShareButton>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );
};

export default ShareSection;
