import React, { useEffect } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import {
  AppBar,
  Container,
  Typography,
  Input,
  Grid,
  Badge,
  Popover,
  Tooltip,
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Brandlogo from "../../assets/images/Brand.svg";
import Brandlogo2 from "../../assets/images/logo.svg";
import Facebook from "../../assets/icons/social/facebook.svg";
import Instagram from "../../assets/icons/social/instagram.svg";
import Twitter from "../../assets/icons/social/twitter.svg";
import Menu from "../../assets/icons/menu.svg";
// dummy photo profile
import Dummy from "../../assets/images/dumy_photo_profile.jpg";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import SideBar from "./components/sidebar";
import Cart from "./components/cart";
import { isLoggedIn } from "../../services/axios";
import { useAuthContext } from "../../contexts/AuthContextProvider";
import swal from "sweetalert";

const useStyles = makeStyles((theme) => ({
  logo: {
    padding: "20px 0",
    height: 85,
    cursor: "pointer",
  },
  logoMobile: {
    margin: "15px 0",
    height: 40,
  },
  Nav: {
    background: "#ffffff",
    alignItems: "center",
    display: "flex",
    color: "#000",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
  },
  container: {
    display: "flex",
    alignItems: "center",
  },

  // Navbar Primary
  stickyContainer: {
    width: "100%",
    background: theme.palette.primary.main,
  },
  sticky: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 0",
    color: "#555555",
  },
  stickyCenter: {
    display: "flex",
    alignItems: "center",
  },
  containerPrimary: {
    display: "flex",
    alignItems: "center",
  },
  category: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    marginLeft: 10,
  },
  containerSearch: {
    display: "flex",
    border: "1px solid #555555",
    borderRadius: 4,
    alignItems: "center",
    marginLeft: 30,
  },
  search: {
    paddingLeft: 20,
    width: 450,
    paddingRight: 20,
  },
  divSearchIcon: {
    background: "#bbbaba",
    borderRadius: "0px 4px 4px 0px",
    cursor: "pointer",
  },
  searchIcon: {
    width: 27,
    height: 27,
    paddingTop: 5,
    color: "#565656",
    cursor: "pointer",
  },
  verticalLine: {
    width: 1.2,
    minWidth: 1.2,
    height: 24,
    background: "#333333",
    margin: "0px 0px 0px 16px",
  },
  buttonLogin: {
    border: "1px solid #FBBB14",
    color: theme.palette.primary.main,
    boxSizing: "border-box",
    borderRadius: 8,
    background: "#ffffff",
    padding: "10px 20px",
    outline: "none",
    cursor: "pointer",
  },
  buttonSignup: {
    background: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 8,
    padding: "10px 20px",
    marginLeft: 20,
    outline: "none",
    cursor: "pointer",
  },
  photoProfile: {
    height: 45,
    width: 45,
    borderRadius: "50%",
    backgroundImage: `url(${Dummy})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  profile: {
    marginLeft: 30,
    display: "flex",
    alignItems: "center",
  },

  //Navbar Primary Mobile
  wrapperMobile: {
    background: "#FFFFFF",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
    color: "#555555",
    width: () => window.screen.availWidth,
  },
  containerPrimaryMobile: {
    display: "flex",
    alignItems: "center",
    margin: "12px 0",
    justifyContent: "space-between",
  },
  searchMobile: {
    display: "flex",
    border: "1px solid #555555",
    boxSizing: "border-box",
    borderRadius: 4,
    alignItems: "center",
    width: 215,
    marginLeft: 10,
  },

  // Navbar Normal
  containerNavNormal: {
    padding: 15,
    display: "flex",
    alignItems: "center",
  },
  boldText: {
    fontWeight: "bold",
  },
}));

const NavbarDefault = () => {
  const isMobile = useMediaQuery("(max-width:500px)");
  const AsCustomer = false;
  let history = useHistory();
  const classes = useStyles();
  return (
    <React.Fragment>
      <AppBar className={classes.Nav} style={{ textAlign: "center" }}>
        <div className={classes.container}>
          <img
            src={Brandlogo}
            onClick={() =>
              AsCustomer
                ? history.push(`/store/${sessionStorage.getItem("reseller")}`)
                : history.push("/")
            }
            alt=""
            className={isMobile ? classes.logoMobile : classes.logo}
          />
        </div>
      </AppBar>
      <div style={{ marginTop: 80 }}></div>
    </React.Fragment>
  );
};

const NavbarNormal = (props) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <AppBar className={classes.Nav}>
        <Grid container spacing={2} className={classes.containerNavNormal}>
          <Grid item xs={1}>
            <ArrowBackIcon onClick={props.onClick} />
          </Grid>
          <Grid item>
            <Typography className={classes.boldText}>{props.text}</Typography>
          </Grid>
        </Grid>
      </AppBar>
      <div style={{ marginTop: 80 }}></div>
    </React.Fragment>
  );
};

const NavbarPrimary = (props) => {
  const isMobile = useMediaQuery("(max-width:500px)");
  let history = useHistory();
  const classes = useStyles();
  const [openSidebar, setOpenSidebar] = React.useState(false);
  const [cart, setCart] = React.useState(localStorage.getItem("__cart"));
  const [inputSearch, setInputSearch] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState({
    cart: null,
    profile: null,
  });
  const { isConsumer } = useAuthContext()
  const [storeFrontName, setstoreFrontName] = React.useState()

  const user = JSON.parse(localStorage.getItem("USER_DATA"));

  const handlePopoverOpen = (name, event) => {
    setAnchorEl({
      ...anchorEl,
      [name]: event.currentTarget,
    });
  };

  const handlePopoverClose = (name) => {
    setAnchorEl({
      ...anchorEl,
      [name]: null,
    });
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    setCart(localStorage.getItem("__cart"));
  }, [localStorage.getItem("__cart")]);

  const handleInputSearch = (event) => {
    setInputSearch(event.target.value);
  };

  // get storefront name
  React.useEffect(() => {
    const getStoreFrontName = () => {
      const nameStoreFront = localStorage.getItem("STORE_FRONT")
      if (nameStoreFront !== null) {
        setstoreFrontName(nameStoreFront)
      } else {
        const nameStoreFrontFromUserData = JSON.parse(localStorage.getItem('USER_DATA'))
        setstoreFrontName(nameStoreFrontFromUserData?.storefront?.slug)
      }
    }

    if (isLoggedIn()) {
      getStoreFrontName()
    }
  }, [])

  const handleLogout = () => {
    if (localStorage.getItem("USER_DATA")) {
      swal({
        title: "Apakah anda yakin ingin keluar?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((willLogOut) => {
        if (willLogOut) {
          history.push("login");
          localStorage.clear();
        }
      });
    } else{
      history.push("login");
      localStorage.clear();
    }
  };

  return (
    <React.Fragment>
      {isMobile ? (
        <React.Fragment>
          <AppBar>
            <div className={classes.wrapperMobile}>
              <Container
                maxWidth="lg"
                className={classes.containerPrimaryMobile}
              >
                <img
                  src={Menu}
                  style={{ height: 35 }}
                  onClick={() => setOpenSidebar(true)}
                  alt="menu idejualan"
                />
                <SideBar
                  open={openSidebar}
                  onClose={() => setOpenSidebar(false)}
                />
                <img
                  src={Brandlogo2}
                  alt="idejualan logo"
                  style={{ height: 40, marginLeft: 10 }}
                  onClick={() => history.push("/")}
                />
                <div className={classes.searchMobile}>
                  <React.Fragment>
                    <div style={{ paddingLeft: 5 }}>
                      <Input
                        disableUnderline={true}
                        fullWidth={true}
                        placeholder="Cari Barang"
                        value={inputSearch}
                        onChange={handleInputSearch}
                        onKeyDown={(event) =>
                          event.key === "Enter" &&
                          inputSearch !== "" &&
                          history.push(`/search/${inputSearch}`)
                        }
                      />
                    </div>
                    <div onClick={() => history.push(`/search/${inputSearch}`)}>
                      <SearchIcon className={classes.searchIcon} />
                    </div>
                  </React.Fragment>
                </div>
                {cart ? (
                  <Badge
                    badgeContent={
                      JSON.parse(localStorage.getItem("__cart")).length || 0
                    }
                    color="primary"
                  >
                    <ShoppingCartIcon
                      onClick={() => history.push("/cart")}
                      style={{ marginLeft: 10, cursor: "pointer" }}
                    />
                  </Badge>
                ) : (
                  <ShoppingCartIcon
                    onClick={() => history.push("/cart")}
                    style={{ marginLeft: 10, cursor: "pointer" }}
                  />
                )}
              </Container>
            </div>
          </AppBar>
          <div style={{ marginTop: 64 }}></div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <AppBar className={classes.Nav}>
            <div className={classes.stickyContainer}>
              <Container maxWidth="md" className={classes.sticky}>
                <div className={classes.stickyCenter}>
                  <Typography>Ikuti kami di&nbsp;</Typography>
                  <img src={Facebook} alt="" style={{ marginLeft: 10 }} />
                  <img src={Instagram} alt="" style={{ marginLeft: 10 }} />
                  <img src={Twitter} alt="" style={{ marginLeft: 10 }} />
                </div>
                <div className={classes.stickyCenter}>
                  <Typography>Konfirmasi Pembayaran</Typography>
                  <Typography
                    style={{ marginLeft: 10, cursor: "pointer" }}
                    onClick={() => history.push("/cek-resi")}
                  >
                    Cek Resi
                  </Typography>
                </div>
              </Container>
            </div>
            <Container maxWidth="md" className={classes.containerPrimary}>
              <img
                src={Brandlogo}
                alt=""
                className={isMobile ? classes.logoMobile : classes.logo}
                onClick={() => history.push("/")}
              />

              <div
                className={classes.category}
                onClick={() =>
                  props.isCategory === "yes"
                    ? history.goBack()
                    : history.push("/category")
                }
              >
                <img src={Menu} alt="menu idejualan" height={20} width={20} />
                <Typography style={{ paddingLeft: 10 }}>Kategori</Typography>
              </div>

              <div className={classes.containerSearch}>
                <div className={classes.search}>
                  <Input
                    disableUnderline={true}
                    fullWidth={true}
                    placeholder="Cari Barang"
                    value={inputSearch}
                    onChange={handleInputSearch}
                    onKeyDown={(event) =>
                      event.key === "Enter" &&
                      history.push(`/search/${inputSearch}`) &&
                      setInputSearch("")
                    }
                  />
                </div>
                <div
                  className={classes.divSearchIcon}
                  onClick={() => {
                    history.push(`/search/${inputSearch}`);
                    setInputSearch("");
                  }}
                >
                  <SearchIcon className={classes.searchIcon} />
                </div>
              </div>
              <div>
                <Badge
                  badgeContent={
                    localStorage.getItem("__cart")
                      ? JSON.parse(localStorage.getItem("__cart")).length
                      : 0
                  }
                  color="primary"
                >
                  <Tooltip title="Keranjang" placement="bottom">
                    <ShoppingCartIcon
                      onClick={(e) => handlePopoverOpen("cart", e)}
                      style={{ marginLeft: 30, cursor: "pointer" }}
                    />
                  </Tooltip>
                </Badge>
                <Popover
                  id="mouse-over-popover"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl.cart)}
                  anchorEl={anchorEl.cart}
                  onClose={() => handlePopoverClose("cart")}
                >
                  <Cart />
                  <div 
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      cursor: 'pointer'
                    }}
                    onClick={
                      () => {history.push("/orders")}
                    }>
                    <p>Lihat Semua Pesanan {'>'}</p>
                  </div>
                </Popover>
              </div>
              {
                !isConsumer && (
                  <div style={{ display: "flex" }}>
                <Tooltip title="Profile" placement="bottom">
                  {user ? (
                    <Avatar
                      src={user.avatar}
                      alt={user.name}
                      style={{
                        marginLeft: 10,
                        cursor: "pointer",
                        width: 20,
                        height: 20,
                      }}
                      onClick={(e) => handlePopoverOpen("profile", e)}
                    />
                  ) : (
                    <AccountCircleIcon
                      style={{ marginLeft: 10, cursor: "pointer" }}
                      onClick={(e) => handlePopoverOpen("profile", e)}
                    />
                  )}
                </Tooltip>
                <Popover
                  id="profile-pilih"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl.profile)}
                  anchorEl={anchorEl.profile}
                  onClose={() => handlePopoverClose("profile")}
                >
                  <List>
                    <ListItem
                      button
                      onClick={() =>
                        history.push(
                          isLoggedIn() ? `/user/my-profile` : "/login"
                        )
                      }
                    >
                      <ListItemIcon>
                        <AccountCircleIcon />
                      </ListItemIcon>{" "}
                      <ListItemText primary="Akun Saya" />
                    </ListItem>{" "}
                    <ListItem
                      button
                      onClick={() =>
                        history.push(
                          isLoggedIn()
                            ? `/store/${storeFrontName}`
                            : "/login"
                        )
                      }
                    >
                      <ListItemIcon>
                        <AccountCircleIcon />
                      </ListItemIcon>{" "}
                      <ListItemText primary="Toko Saya" />
                    </ListItem>{" "}
                  </List>
                </Popover>
              </div>
                )
              }
              <div style={{ display: "flex" }}>
                <Tooltip
                  title={isLoggedIn() ? "Logout" : "Login"}
                  placement="bottom"
                >
                  <ExitToAppIcon
                    style={{ marginLeft: 10, cursor: "pointer" }}
                    onClick={handleLogout}
                  />
                </Tooltip>
              </div>
            </Container>
          </AppBar>
          <div style={{ marginTop: 135 }}></div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const Navbar = (props) => {
  return (
    <React.Fragment>
      {props.variant === "primary" ? (
        <NavbarPrimary isCategory={props.isCategory} />
      ) : props.variant === "default" ? (
        <NavbarDefault />
      ) : (
        <NavbarNormal text={props.text} onClick={props.onClick} />
      )}
    </React.Fragment>
  );
};

export default Navbar;
