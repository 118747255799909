import styled from 'styled-components'

export const WrapTransactionList = styled.div`
  position: relative;
  display: flex;
  font-family: 'DMSans-Regular';
  border-bottom: 1px solid #F3F3F3;
  padding-bottom: 8px;
  margin-bottom: 8px;
  .desc, .date {
      font-size: 13px;
      color: #A5A0A0;
    }
  .left {
    width: 50%;
    .title{
      font-size: 16px;
      color: #000;
    }
  }
  .right {
    text-align: right;
    width: 50%;
    .price {
      font-size: 16px;
      font-family: 'DMSans-Bold';
      letter-spacing: 0.02em;
    }
  }
`