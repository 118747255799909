import React, { Fragment, useState } from "react";
import { Container, Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import swal from "@sweetalert/with-react";
import { ReactComponent as CartIcon } from "../../../../assets/icons/cart_plus.svg";
import { ReactComponent as WhatsappIcon } from "../../../../assets/icons/dashicons_whatsapp.svg";
import {
  addToStorefront,
  deleteProductStorefront,
  isLoggedIn,
} from "../../../../services/axios";
import CustomPriceReseller from "../../../../components/CustomPriceReseller";

const useStyles = makeStyles((theme) => ({
  actionSession: {
    position: "fixed",
    bottom: 0,
    background: "#ffffff",
    padding: "15px 0",
    boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.08)",
    width: "100%",
  },
  buttonPrimary: {
    border: `1px solid ${theme.palette.primary.main}`,
    padding: "8px 15px",
    background: theme.palette.primary.main,
    fontSize: "10px",
    height: 44,
    textTransform: "none",
  },
  buttonPrimary2: {
    border: `1px solid '#a7a7a7'`,
    padding: "8px 20px",
    color: "#a7a7a7",
    fontSize: "10px",
    height: 44,
    textTransform: "none",
  },
  buttonSecondary: {
    padding: "5px 20px",
    color: 'white',
    border: `1px solid ${theme.palette.primary.main}`,
    fontSize: "10px",
    height: 44,
    textTransform: "none",
  },
}));

const BottomNav = (props) => {
  const classes = useStyles();
  const { setOpenVariant, data, isStoreFront, dataSupplier } = props;
  const [isAddedToStore, setIsAddedToStore] = useState(data.is_added);
  const [isOpenCustomPriceReseller, setisOpenCustomPriceReseller] = useState(false)
  const userData = JSON.parse(localStorage.getItem("USER_DATA"))
  const isPremiumMember = JSON.parse(localStorage.getItem("USER_DATA"))?.activated || false;

  const handleDeleteProductStorefront = () => {
    const storefront = userData.storefront
      .slug;
    deleteProductStorefront(storefront, data.slug);
    setIsAddedToStore(!isAddedToStore);
    swal("Success", "Berhasil dihapus dari Toko !", "success");
  };

  const handleAddToStore = async() => {
    if (!isLoggedIn()) return (window.location = "/login");
    setisOpenCustomPriceReseller(true)
  };

  const handleTanyaSeller = () => {
    window.open(
      `https://api.whatsapp.com/send?phone=${sessionStorage.getItem(
        "phone_reseller"
      )}&text=Halo, saya ingin bertanya mengenai produk ${data.name}`,
      "_blank"
    );
  }

  return (
    <Fragment>
      <div className={classes.actionSession}>
        <Container maxWidth="lg">
          <Grid container spacing={1} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                className={classes.buttonSecondary}
                onClick={() => setOpenVariant(true)}
              >
                <CartIcon fill="white" />
                {
                  isStoreFront &&
                  <div style={{marginLeft: 3}}>Tambah ke keranjang</div>
                }
              </Button>
            </Grid>
            {
              isStoreFront && 
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.buttonSecondary}
                  onClick={handleTanyaSeller}
                >
                  Tanya seller
                </Button>
              </Grid>
            }
            {
              !isStoreFront &&
              <Grid item >
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.buttonSecondary}
                  fullWidth={isStoreFront ? true : false}
                  onClick={() =>
                    window.open(
                      `https://wa.me/${dataSupplier?.owner?.phone}?text=Hai,%20ini%20idejualan`,
                      "_blank"
                    )
                  }
                >
                  Chat Via WA&nbsp;
                  <WhatsappIcon fill="#00A884" height={18} />
                </Button>
              </Grid>
            }
            {
              isLoggedIn() & !isStoreFront ?
              <Grid item>
                {isAddedToStore === true && isAddedToStore !== null ? (
                  <Button
                    variant="contained"
                    className={classes.buttonPrimary2}
                    onClick={handleDeleteProductStorefront}
                  >
                    Hapus dari Toko
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.buttonSecondary}
                    onClick={handleAddToStore}
                  >
                    Tambah Ke Toko
                  </Button>
                )}
              </Grid>
              :
              ''
            }
          </Grid>
        </Container>
      </div>

      {
        isLoggedIn() &&
        <CustomPriceReseller 
          open={isOpenCustomPriceReseller} 
          setIsOpen={setisOpenCustomPriceReseller} 
          product={data.slug} 
          storefrontName={userData?.storefront?.slug}
          isPremiumMember={isPremiumMember}
          setIsAddedToStore={setIsAddedToStore}
        />
      }
    </Fragment>
  );
};

export default BottomNav;
