import React, { Fragment, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import { Container, Typography, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Navbar from "../../../components/navbar";
import Footer from "../../../components/footer";
import { getStorefront } from "../../../services/axios";

const Desktop = withStyles(styles)((props) => {
  const history = useHistory()
  const { classes, dataOrder, store } = props;
  const [dataStoreFront, setdataStoreFront] = React.useState()
  const midtransKey = 'SB-Mid-client-xYSFi8v3uCnhGICn'

  React.useEffect(() => {
    const getDataStorefront = async() => {
      const response = await getStorefront(store)
      setdataStoreFront(response.data)
    }

    getDataStorefront()
  }, []);

  // snap midtrans
  const initSnap = (token) => {
    const { NODE_ENV: ENV } = process.env;

    // create element for script
    const snapScript = document.createElement("script");

    // checking environment mode
    snapScript.src =
        ENV === "production"
        ? 
            window.origin.includes('dev') ?
            "https://app.sandbox.midtrans.com/snap/snap.js"
            :
            "https://app.midtrans.com/snap/snap.js"
        : "https://app.sandbox.midtrans.com/snap/snap.js";

    snapScript.type = "text/javascript";
    snapScript.onload = () => {
    if ("snap" in window) {
        const { snap } = window;
        snap.pay(token)
    }
    };
    snapScript.dataset.clientKey = midtransKey;
    document.head.appendChild(snapScript);
  };

  useEffect(() => {
    if (dataOrder) {
      if (dataOrder.payment_type === 'vendorbank') {
        if (dataOrder.is_completed === false) {
          history.push(`/checkout/vendor-bank/${store}/${dataOrder.token}`)
        }
      }else{
        if (dataOrder.is_completed === false) {
          initSnap(dataOrder.token)
        }
      }
    }
  }, [dataOrder])

  const handleWhatsappReseller = () => {
    const generateOrder = () => {
      let resultItem = ''
      dataOrder.summary.carts[0].orders.forEach((res, index) => {
        res.items.forEach((resItem, indexItem) => {
          resultItem =
            resultItem +
            `%28%20_${
              index + 1
            }_%20%29-%28%20_${
              indexItem + 1
            }_%20%29%0D%0A%E2%96%AA%EF%B8%8F%20Nama%20Produk%20%3A%20${encodeURI(
              resItem.sku.product.name
            )}%0D%0A%E2%96%AA%EF%B8%8F%20Link%20Produk%20%3A%20${encodeURI(
              `https://app.idejualan.com/store/${store}/${resItem.sku.product.slug}`
            )}%0D%0A%E2%96%AA%EF%B8%8F%20Varian%20%3A%20${encodeURI(
              resItem.sku.properties.color + "," + resItem.sku.properties.size
            )}`;
        })
      });
      return resultItem;
    };

    const getTotalPrice = () => {
      let result = dataOrder.summary.items_amount
      return result;
    };

    window.open(
      `https://wa.me/${dataStoreFront.owner.phone}?text=Hai%20Kak%20Saya%20Sudah%20Order.%0D%0A%0D%0ANama%20%3A%20${encodeURI(
        dataOrder.summary.carts[0].customer.name
      )}%0D%0AAlamat%20%3A%20${encodeURI(
        dataOrder.summary.carts[0].customer.address
      )}%20%0D%0A%0D%0A%2AOrder%2A%20%3A%20%0D%0A${generateOrder()}%0D%0A%2ATotal%2A%20%2AHarga%2A%20%3A%20Rp.%20${getTotalPrice()}%0D%0ABelum%20termasuk%20ongkos%20kirim`,
      "_blank"
    );

  };

  return (
    <Fragment>
      <Navbar variant="primary" />
      <Container maxWidth="md" style={{textAlign: 'center'}} className={classes.container}>
        <div className={classes.card}>
          <Typography variant="h6" className={classes.titleCard}>
            <b>Pesananmu Berhasil dikonfirmasi</b>
          </Typography>

          <div className={classes.content}>
            <Typography>
              Terimakasih telah berbelanja di Idejualan! Pesananmu <>{
                dataOrder?.summary?.carts?.map((cart, indexCart) => 
                  cart?.orders.map((order, indexOrder) => 
                    order?.items.map((item, indexItem) => 
                      <b key={indexItem+'item'}>{item?.sku?.product?.name}{indexItem === order.items.length - 1 ? ' ' : ', '}</b>
                    )
                  )
                )
                }</> sudah berhasil kami terima pada{" "}
              <b>{dataOrder?.created_at?.split('T')[0]}</b> dengan metode pembayaran <b>Transfer Bank</b>
              . Setelah ini penjual akan segera
              memproses pesananmu. Mohon ditunggu ya!
            </Typography>
          </div>

          <div className={classes.action}>
            <Button 
              variant="contained" 
              color="primary" 
              style={{ width: 343 }}
              onClick={handleWhatsappReseller}
            >
              Whatsapp Penjual
            </Button>
          </div>
        </div>
      </Container>
      <Footer />
    </Fragment>
  );
});

export default Desktop;
