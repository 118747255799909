import React from "react";
import swal from "sweetalert";
import { useAuthContext } from "../../contexts/AuthContextProvider";
import {
  ADD_SINGLE_STOREFRONT_PRODUCT,
  DELETE_SINGLE_STOREFRONT_PRODUCT,
} from "../../services/storefront.service";
import ProductSliderComponent from "./ProductSlider.component";

const ProductSliderContainer = (props) => {
  const { dataWishlist, setIsRefetch, type } = props;
  const { user } = useAuthContext();

  const handleAddToStore = async (slug) => {
    await ADD_SINGLE_STOREFRONT_PRODUCT(user.storefront.slug, slug)
      .then((res) => {
        setIsRefetch(true);
        swal("Success", "Berhasil menambah product ke toko", "success");
      })
      .catch(() => {
        swal("Error", "Gagal menambah product ke toko", "error");
      });
  };
  const handleDeleteProductStorefront = async (slug) => {
    await DELETE_SINGLE_STOREFRONT_PRODUCT(user.storefront.slug, slug)
      .then((res) => {
        setIsRefetch(true);
        swal("Success", "Berhasil menghapus product dari toko", "success");
      })
      .catch(() => {
        swal("Error", "Gagal menghapus product dari toko", "error");
      });
  };

  return (
    <ProductSliderComponent
      handleAddToStore={handleAddToStore}
      handleDeleteProductStorefront={handleDeleteProductStorefront}
      dataWishlist={dataWishlist}
      type={type}
      {...props}
    />
  );
};

export default ProductSliderContainer;
