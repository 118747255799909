import {
  Container,
  Box,
  Chip,
  Grid,
  Button,
  Tooltip,
  Breadcrumbs,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useHistory } from "react-router-dom";
import styles from "./styles";
import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import { getAllVouchers } from "../../services/axios";
import InfiniteScroll from "react-infinite-scroll-component";
import { CLAIM_VOUCHER } from "../../services/voucher.service";
import iconIdejualan from "../../assets/icons/iconIdejualan.svg";
import { numberSeparator } from "../../utils/numberSeparator";
import moment from "moment";
import swal from "@sweetalert/with-react";
import iconVoucher from "../../assets/icons/icon_voucher_white.svg";

const VoucherPage = withStyles(styles)((props) => {
  const { classes } = props;
  const [filter, setfilter] = useState("");
  const [voucher, setVoucher] = useState([]);
  const [page, setPage] = useState(1);
  const isLogin = localStorage.getItem("TOKEN");
  const history = useHistory();

  useEffect(() => {
    const getData = async () => {
      const allData = await getAllVouchers(filter, page);
      setVoucher(allData.data);
    };

    getData();
  }, []);

  const handleFilter = (myFilter, myPage) => {
    setVoucher([]);
    setfilter(myFilter);
    setPage(myPage);
    const getData = async () => {
      const allData = await getAllVouchers(myFilter, myPage);
      setVoucher(allData.data);
    };

    getData();
  };

  const fetchMoreData = () => {
    const getData = async () => {
      const allData = await getAllVouchers(filter, page + 1);
      setVoucher([...voucher, ...allData.data]);
    };

    getData();
    setPage(page + 1);
  };

  const handleClaim = async (id) => {
    try {
      await CLAIM_VOUCHER(id);
      swal("Success!", "Voucher berhasil di klaim", "success");
    } catch (error) {
      if (error) {
        if (error.response) {
          if (error.response.data) {
            if (error.response.data.message === "Unauthenticated.") {
              return swal(
                "Error!",
                "Silahkan login untuk claim voucher",
                "error"
              );
            }
          }
        }
      }
      swal("Error!", "Voucher gagal di klaim", "error");
    }
  };

  React.useEffect(() => {
    if (isLogin === null) {
      swal(
        <div style={{ width: "375px", margin: "auto" }}>
          <h2>Login Diperlukan</h2>
          <p>Anda harus masuk untuk dapat mengakses fitur ini</p>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              style={{
                margin: "5px",
                width: "50%",
                border: "1px solid orange",
                backgroundColor: "white",
                borderRadius: "5px",
                cursor: "pointer",
                color: "orange",
                padding: "10px",
              }}
              onClick={() => (window.location.href = "/signup")}
            >
              Daftar
            </button>
            <button
              style={{
                margin: "5px",
                width: "50%",
                border: "1px solid orange",
                backgroundColor: "orange",
                borderRadius: "5px",
                cursor: "pointer",
                color: "white",
                padding: "10px",
              }}
              onClick={() => (window.location.href = "/login")}
            >
              Masuk
            </button>
          </div>
        </div>,
        {
          closeOnClickOutside: false,
          buttons: false,
        }
      );
    }
  }, [isLogin]);

  return (
    <Fragment>
      <Navbar variant="primary" />
      <Container maxWidth="md" className={classes.container}>
        <Box mb={2}>
          <Grid container spacing={2}>
            {/* Semua */}
            <Grid item>
              <Chip
                label="Semua"
                color={filter === "" ? "primary" : "default"}
                onClick={() => handleFilter("", 1)}
              />
            </Grid>

            {/* Gratis Ongkir */}
            <Grid item>
              <Chip
                label="Gratis Ongkir"
                color={filter === "ongkir" ? "primary" : "default"}
                onClick={() => handleFilter("ongkir", 1)}
              />
            </Grid>

            {/* Cashback */}
            <Grid item>
              <Chip
                label="Cashback"
                color={filter === "cashback" ? "primary" : "default"}
                onClick={() => handleFilter("cashback", 1)}
              />
            </Grid>

            {/* Discount */}
            <Grid item>
              <Chip
                label="Discount"
                color={filter === "discount" ? "primary" : "default"}
                onClick={() => handleFilter("discount", 1)}
              />
            </Grid>
          </Grid>

          <br />

          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            style={{ fontSize: 12 }}
          >
            <p
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                history.push(`/`);
              }}
            >
              Home
            </p>

            <p>Voucher</p>
          </Breadcrumbs>

          <br />

          <div className={classes.containerVoucher}>
            {voucher &&
              voucher.map((res, index) => (
                <div
                  key={index + "voucher"}
                  style={{
                    margin: "11px",
                    width: "410px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    backgroundColor: "white",
                    padding: "10px",
                    borderRadius: "10px",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  }}
                >
                  {res.supplier ? (
                    <img
                      onClick={() => history.push(`/voucher/${res.id}`)}
                      src={res.supplier.avatar_url}
                      alt={res.name}
                      style={{
                        width: "115px",
                        height: "115px",
                        borderRadius: "10px",
                        cursor: "pointer",
                      }}
                    />
                  ) : (
                    <img
                      onClick={() => history.push(`/voucher/${res.id}`)}
                      src={iconIdejualan}
                      alt={res.name}
                      style={{
                        width: "115px",
                        height: "115px",
                        borderRadius: "10px",
                        cursor: "pointer",
                      }}
                    />
                  )}
                  <div
                    onClick={() => history.push(`/voucher/${res.id}`)}
                    style={{ padding: "0 10px 0 10px", cursor: "pointer" }}
                  >
                    <p>{res.name}</p>
                    <p
                      style={{ color: "orange", margin: "0" }}
                      variant="caption"
                    >
                      Rp {numberSeparator(res.amount)}
                    </p>
                    <p style={{ color: "#EE4236", margin: "0" }}>
                      Min. Belanja Rp {numberSeparator(res.minimum_payments)}
                    </p>
                    <p style={{ color: "#52C1C7", margin: "0" }}>
                      Berlaku hingga {moment(res.end_date).format("MM D YY")}
                    </p>
                  </div>

                  <div>
                    <Button
                      onClick={() => handleClaim(res.id)}
                      variant="contained"
                      color="primary"
                      size="small"
                      disabled={res.is_claimed}
                      style={{
                        backgroundColor: !res.is_claimed && "#FBBB14",
                        color: "#000000",
                        textTransform: "none",
                      }}
                    >
                      Klaim
                    </Button>
                  </div>
                </div>
              ))}

            <InfiniteScroll
              dataLength={voucher.length}
              next={fetchMoreData}
              style={{
                overflow: "none",
              }}
              hasMore={true}
            ></InfiniteScroll>
          </div>
        </Box>
      </Container>
      <Footer />

      <div
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          cursor: "pointer",
          backgroundColor: "orange",
          padding: "10px",
          borderRadius: "10px",
          width: "64px",
          height: "54px",
        }}
        onClick={() => {
          history.push(`/my-voucher`);
        }}
      >
        <Tooltip title="My Voucher" placement="top">
          <img src={iconVoucher} alt="button to my voucher" />
        </Tooltip>
      </div>
    </Fragment>
  );
});

export default VoucherPage;
