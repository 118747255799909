import styled from 'styled-components'

export const WrapTitlePage = styled.div`
  position: relative;
  display: flex;
  svg {
    cursor: pointer;
  }
  h1 {
    font-size: 20px;
    font-family: 'DMSans-Bold';
    color: #000;
    margin: 0;
    padding: 0;
    margin-left: 24px;
    top: 2px;
    position: relative;
  }
  .left {
    display: flex;
    width: 80%;
  }
`