import React from 'react'
import Slider from "react-slick";
import { getBrandPopuler } from '../../services/axios';

const BrandPopuler = ({ variant }) => {
    const [brand, setBrand] =  React.useState([]);
    const [mySettings, setmySettings] = React.useState()
    const settings = {
        dots: true,
        infinite: true,
        speed: 2000,
        autoplaySpeed: 15000,
        autoplay: true,
        slidesToShow: 7,
        slidesToScroll: 7
    };

    const settingsMobile = {
        dots: true,
        infinite: true,
        speed: 2000,
        autoplaySpeed: 15000,
        autoplay: true,
        slidesToShow: 5,
        slidesToScroll: 5
    };

    React.useEffect(() => {
        async function fetch() {
            const allData = await getBrandPopuler(1);
            setBrand(allData.data)
        }
        const myuseSettings = () => {
            if (variant === 'mobile') {
                setmySettings(settingsMobile)
            }else{
                setmySettings(settings)
            }
        }

        myuseSettings();
        fetch();
    }, []);

    const imgStyle = {
        nonMobile: {
            borderRadius: '48%', 
            width: '80%', 
            padding: '15px', 
            backgroundColor: 
            '#f4f4f4', 
            cursor: 'pointer' 
        },
        mobile: {
            borderRadius: '48%',
            width: '80%',
            cursor: 'pointer'
        }
    }

    return (
        <Slider {...mySettings} style={variant === 'mobile' ? { paddingBottom: '35px' } : { paddingBottom: '50px' }}>
            {
                brand.map((res, index) => 
                <div key={index} >
                    <a href={`/profile/${res.slug}`} style={{ textDecoration: 'none', textAlign: 'center'}}>
                        <img src={res.avatar_url} alt={res.name} style={variant === 'mobile' ? imgStyle.mobile : imgStyle.nonMobile}/>
                        <p style={{ color: 'black', marginRight: '15px'}}>{res.name}</p>
                    </a>
                </div>
                )
            }
        </Slider>
    )
}

export default BrandPopuler