import React from 'react'

// icon
import riwayat from '../../../../assets/icons/mobile/icon_history_2.svg'
import hadiah from '../../../../assets/icons/mobile/icon_arrow_gift.svg'
import info from '../../../../assets/icons/mobile/icon_info.svg'
import chart from '../../../../assets/icons/mobile/icon_chart_small.svg'


const containerInfoPanel = {
    backgroundColor: 'white',
    width: '90%',
    margin: 'auto',
    position: 'relative',
    top: '-40px',
    padding: 10,
    borderRadius: 10,
    border: '3px solid #fbbb14',
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px'
}

const innerContainer = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
}

const infoAccount = {
    width: '50%'
}

const myButton = { 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    flexDirection: 'column', 
    cursor: 'pointer' 
}

const InfoPanel = () => {
    const buttonPanel = [
        {
            name: 'Riwayat',
            icon: riwayat
        },
        {
            name: 'Hadiah',
            icon: hadiah
        },
        {
            name: 'Info',
            icon: info
        },
    ]

    return (
        <div style={containerInfoPanel}>
            <div style={innerContainer}>
                <div style={infoAccount}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{ backgroundColor: '#fbbb14', marginRight: 5, padding: 3, borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img style={{ width: 15, height: 15 }} src={chart} alt='chart' />
                        </div>
                        <div style={{ fontSize: '.8rem', color: '#BBBABA' }}>Next Level 2</div>
                    </div>
                    <div style={{ fontSize: '.6rem', color: '#BBBABA', marginTop: 5 }}>Omset saat ini</div>
                    <div style={{ fontSize: '.7rem', color: 'gray', fontWeight: 500, marginTop: 5 }}>Rp.0<span style={{ fontSize: '.6rem', color: '#fbbb14' }}>/Rp. 3,500,000</span></div>
                    <div style={{ border: '1px solid gray', width: '100%', height: 10, marginTop: 5 , borderRadius: 2}}></div>
                </div>

                <div style={{ display: 'flex', width: '50%', justifyContent: 'end', borderLeft: '1px solid black', marginLeft: 5, flexWrap:'wrap' }}>
                    {
                        buttonPanel.map((res, index) => 
                            <div key={index + 'buttonPanle'} style={myButton}>
                                <div style={{ backgroundColor: '#fbbb14', padding: 8, borderRadius: 5, margin: 5, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <img style={{ width: 15, height: 15 }} src={res.icon} alt={res.name} />
                                </div>
                                <div style={{ fontSize: '.5rem', color: '#BBBABA'}}>{res.name}</div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default InfoPanel