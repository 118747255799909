const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: window.screen.height - 64,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.primary.main}`,
    width: "25%",
  },
  contentWrapper: {
    width: "75%",
  },
  card: {
    padding: 10,
    textAlign: "center",
    height: "100%",
  },
});

export default styles;
