const style = (theme) => ({
  sidebar: {
    background: "#FFFFFF",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.08)",
    borderRadius: 8,
  },
  kategori: {
    padding: 10,
  },
  lokasi: {
    padding: 10,
  },
  buttonWrapper: {
    padding: 20,
  },
});

export default style;
