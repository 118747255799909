import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import Navbar from "../../../components/navbar";
import Footer from "../../../components/footer";
import style from "./style";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Grid from "@material-ui/core/Grid";
import Carousel from "../../../components/carousel";
import CircularProgress from "@material-ui/core/CircularProgress";

import Sidebar from "./components/sidebar";
import Content from "./components/content";

const SearchPage = withStyles(style)((props) => {
  const {
    classes,
    dataSearched,
    handleFilterCategory,
    handleChangePage,
    handleFilterLocation,
    filterLocation,
    filterCategory,
    handleFilterSort,
    handleSearch,
    setIndexTab,
    indexTab,
    sliderProduct,
    vendorSearched,
    handleFilter,
  } = props;

  return (
    <Fragment>
      <Navbar variant="primary" handleSearch={handleSearch} />

      <Container maxWidth="md">
        {dataSearched === null ? (
          <div className={classes.loading}>
            <div style={{ textAlign: "center" }}>
              <CircularProgress />
              <Typography>Mencari...</Typography>
            </div>
          </div>
        ) : (
          <div className={classes.container}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
              style={{ paddingBottom: 20 }}
            >
              <Link color="inherit" href="/">
                Home
              </Link>
              <Typography color="textPrimary">{filterCategory.name}</Typography>
            </Breadcrumbs>

            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Sidebar
                  handleFilterCategory={handleFilterCategory}
                  handleFilterLocation={handleFilterLocation}
                  filterLocation={filterLocation}
                  filterCategory={filterCategory}
                  indexTab={indexTab}
                  handleFilter={handleFilter}
                />
              </Grid>
              <Grid item xs={8}>
                {dataSearched !== null && (
                  <Content
                    dataSearched={dataSearched}
                    vendorSearched={vendorSearched}
                    handleChangePage={handleChangePage}
                    handleFilterSort={handleFilterSort}
                    setIndexTab={setIndexTab}
                  />
                )}
              </Grid>
            </Grid>
          </div>
        )}
      </Container>

      <Container maxWidth="md" style={{ paddingBottom: 40 }}>
        {dataSearched !== null && sliderProduct && (
          <div style={{ margin: "20px 0" }}>
            <Typography style={{ paddingBottom: 20, fontWeight: "bold" }}>
              Rekomendasi untuk kamu
            </Typography>
            <Carousel data={sliderProduct} />
          </div>
        )}
      </Container>

      <Footer />
    </Fragment>
  );
});

export default SearchPage;
