import { axiosInstance } from "../config/fetchAxios";

export const getDetailCampaign = async (slug) => {
    const response = axiosInstance.get(`/api/v2/campaigns/${slug}`);
    return response;
};

// submit campaign
export const submitCampaign = async (slug, data) => {
    const response = axiosInstance.post(`/api/v2/campaigns/${slug}/submit`, data);
    return response;
};

export const getCampaignData = async (uuid) => {
    const response = axiosInstance.get(`/api/v2/campaigns/submissions/${uuid}`);
    return response;
};