import { axiosInstance } from "../config/fetchAxios";

export const POST_REQUEST_WITHDRAW = async (amount) => {
  const url = `/api/v2/withdraw/request`
  const body = {
    amount: amount
  }
  const data = axiosInstance.post(url, body)
  return data
}

export const POST_VERIFY_WITHDRAW = async (otp) => {
  const url = `/api/v2/withdraw/verify`
  const body = {
    otp: otp
  }
  const data = axiosInstance.post(url, body)
  return data
}