import React, { useContext, useEffect, useState } from 'react';

export const AuthContext = React.createContext()
export const useAuthContext= () => useContext(AuthContext)

export const AuthContextProvider = (props) => {
  const { children } = props
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [user, setUser] = useState({})
  const [isConsumer, setIsConsumer] = useState(false)

  const [globalState, setGlobalState] = useState({})

  const globalStateUpdate = (name, value) => {
    setGlobalState({
      ...globalState,
      [name] : value
    })
  }

  const logout = () => {
    setUser({})
    setIsAuthenticated(false)
  }

  useEffect(() => {
    const getLocalStorageUser = JSON.parse(localStorage.getItem('USER_DATA'))
    if(getLocalStorageUser) {
      setUser(getLocalStorageUser)
      setIsAuthenticated(true)
    }

    if (!localStorage.getItem("TOKEN") && window.location.pathname !== "/login") {
      setIsAuthenticated(false)
      setIsConsumer(true)
    }
    
  }, [])
  
  return (
   <AuthContext.Provider
    value={{
      isAuthenticated,
      user,
      setUser,
      setIsAuthenticated,
      logout,
      isConsumer,
      setIsConsumer,
      globalStateUpdate
    }}
   >
     {children}
   </AuthContext.Provider>
  );
};