import React, { useEffect, useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useHistory } from "react-router-dom";
import { getProductData, getSupplier } from "../../services/axios";
import product_recomendation from "../../utils/productsRecomendation";

import Mobile from "./mobile";
import Desktop from "./desktop";

const SearchPage = (props) => {
  const history = useHistory();
  const keyword = decodeURI(props.match.params.keyword);
  const [filterCategory, setFilterCategory] = useState([]);
  const [filterLocation, setFilterLocation] = useState([]);
  const [filterSort, setFilterSort] = useState({ text: "", value: "" });
  const [dataSearched, setDataSearched] = useState(null);
  const [vendorSearched, setVendorSearched] = useState(null);
  const [indexTab, setIndexTab] = useState(0);
  const [sliderProduct, setSliderProduct] = useState(null);

  useEffect(() => {
    const product = async () => {
      setDataSearched(null);
      const response = await getProductData(`?filter[name]=${keyword}`);
      await setDataSearched(response);
    };
    const supplier = async () => {
      const response = await getSupplier(`?filter[name]=${keyword}`);
      setVendorSearched(response);
    };
    const recomendationProduct = async () => {
      const id = await product_recomendation();
      const response = await getProductData(`?filter[category.id]=${id}`);
      await setSliderProduct(response.data);
    };
    recomendationProduct();
    product();
    supplier();
  }, [props.match.params.keyword]);

  const handleSearch = async () => {
    const product = await getProductData(`?filter[name]=${keyword}`);
    const supplier = await getSupplier(`?filter[name]=${keyword}`);
    if (indexTab === 0) {
      setDataSearched(product);
    } else if (indexTab === 1) {
      setDataSearched(supplier);
    }
  };

  const handleChangePage = async (page) => {
    const product = await getProductData(
      `?filter[name]=${keyword}&sort=${
        filterSort.value
      }&filter[category.id]=${filterCategory.join(
        ","
      )}&filter[supplier.province_id]=${filterLocation.join(",")}&page=${page}`
    );
    const supplier = await getSupplier(
      `?filter[name]=${keyword}&sort=${
        filterSort.value
      }&filter[location.province.id]=${filterLocation.join(",")}&page=${page}`
    );
    if (indexTab === 0) {
      setDataSearched(product);
    } else if (indexTab === 1) {
      setDataSearched(supplier);
    }
  };

  const handleFilterCategory = async (id) => {
    if (filterCategory[filterCategory.indexOf(id)] === id) {
      setFilterCategory(filterCategory.filter((value) => value !== id));
    } else {
      setFilterCategory([...filterCategory, id]);
    }
  };

  const handleFilterLocation = async (id) => {
    if (filterLocation[filterLocation.indexOf(id)] === id) {
      setFilterLocation(filterLocation.filter((value) => value !== id));
    } else {
      setFilterLocation([...filterLocation, id]);
    }
  };

  const handleFilter = async () => {
    const response = await getProductData(
      `?filter[name]=${keyword}&sort=${
        filterSort.value
      }&filter[category.id]=${filterCategory.join(
        ","
      )}&filter[supplier.province_id]=${filterLocation.join(",")}`
    );
    setDataSearched(response);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleResetFilter = async () => {
    setFilterLocation([]);
    setFilterCategory([]);
    const response = await getProductData(
      `?filter[name]=${keyword}&sort=${
        filterSort.value
      }&filter[category.id]=${""}&filter[supplier.province_id]=${""}`
    );
    setDataSearched(response);
  };

  const handleFilterSort = async (sort) => {
    // value of Sort ["Termurah", "Termahal", "Terbaru", "Terlaris"]
    switch (sort) {
      case "Termurah":
        const responseTermurah = await getProductData(
          `?filter[name]=${keyword}&sort=-ref_price&filter[category.id]=${filterCategory.join(
            ","
          )}&filter[supplier.province_id]=${filterLocation.join(",")}`
        );
        setDataSearched(responseTermurah);
        setFilterSort({ text: sort, value: "-ref_price" });
        break;
      case "Termahal":
        const responseTermahal = await getProductData(
          `?filter[name]=${keyword}&sort=ref_price&filter[category.id]=${filterCategory.join(
            ","
          )}&filter[supplier.province_id]=${filterLocation.join(",")}`
        );
        setDataSearched(responseTermahal);
        setFilterSort({ text: sort, value: "ref_price" });
        break;
      case "Terbaru":
        const responseTerbaru = await getProductData(
          `?filter[name]=${keyword}&filter[from_newest]=1&filter[category.id]=${filterCategory.join(
            ","
          )}&filter[supplier.province_id]=${filterLocation.join(",")}`
        );
        setDataSearched(responseTerbaru);
        setFilterSort("");
        break;
      default:
        setFilterSort("");
    }
  };

  const isMobile = useMediaQuery("(max-width:500px)");
  return (
    <React.Fragment>
      {isMobile ? (
        <Mobile
          history={history}
          dataSearched={dataSearched}
          handleFilterCategory={handleFilterCategory}
          handleChangePage={handleChangePage}
          handleFilterLocation={handleFilterLocation}
          filterLocation={filterLocation}
          filterCategory={filterCategory}
          handleFilter={handleFilter}
          handleResetFilter={handleResetFilter}
          setFilterCategory={setFilterCategory}
          setFilterLocation={setFilterLocation}
          handleFilterSort={handleFilterSort}
          handleSearch={handleSearch}
          sliderProduct={sliderProduct}
          vendorSearched={vendorSearched}
        />
      ) : (
        <Desktop
          history={history}
          dataSearched={dataSearched}
          handleFilterCategory={handleFilterCategory}
          handleChangePage={handleChangePage}
          handleFilterLocation={handleFilterLocation}
          filterLocation={filterLocation}
          filterCategory={filterCategory}
          handleFilterSort={handleFilterSort}
          handleSearch={handleSearch}
          indexTab={indexTab}
          setIndexTab={setIndexTab}
          sliderProduct={sliderProduct}
          vendorSearched={vendorSearched}
          handleFilter={handleFilter}
        />
      )}
    </React.Fragment>
  );
};

export default SearchPage;
