import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Mobile from "./mobile";
import Desktop from "./desktop";

const ProfileSupplier = (props) => {
  const isMobile = useMediaQuery("(max-width:500px)");
  return (
    <React.Fragment>
      {isMobile ? <Mobile getProps={props} /> : <Desktop getProps={props} />}
    </React.Fragment>
  );
};

export default ProfileSupplier;
