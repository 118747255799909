import React from "react";

import {
  Grid,
  Typography,
  Dialog,
  DialogContent,
  Box,
  Button,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";

import { GET_ALL_VOUCHERS } from "../../../../services/voucher.service";

const ApplyVoucher = withStyles()((props) => {
  const { slug, choosedVoucher, handleChooseVoucher } = props;

  const [claimedVoucher, setClaimedVouhcer] = React.useState([]);
  const [openVoucher, setOpenVoucher] = React.useState(false);

  React.useEffect(() => {
    const fetch = async () => {
      const res = await GET_ALL_VOUCHERS({
        "filter[claimed]": true,
        include: "supplier",
        paginate: "100",
      });
      setClaimedVouhcer(res.data);
    };
    fetch();
  }, []);

  const handleVoucher = (voucher) => {
    handleChooseVoucher(
      choosedVoucher && choosedVoucher.id === voucher.id ? null : voucher
    );
    setOpenVoucher(false);
  };

  return (
    <>
      <Box bgcolor="rgba(253, 205, 15, 0.15)" padding="25px 35px">
        <Grid container alignItems="center">
          <Grid item xs={2}>
            <Typography>Voucher</Typography>
          </Grid>
          <Grid item xs={8}>
            <Box mb={1}>
              <Typography color="primary">{choosedVoucher?.name} </Typography>
            </Box>
            <Button
              onClick={() => setOpenVoucher(true)}
              color="secondary"
              variant="contained"
              disabled={!claimedVoucher.length}
            >
              {!claimedVoucher.length
                ? "Voucher tidak tersedia"
                : choosedVoucher
                ? "Ganti voucher"
                : "Pilih voucher"}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Dialog
        onClose={() => setOpenVoucher(false)}
        open={openVoucher}
        fullWidth
        maxWidth="xs"
      >
        <Box
          p={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h5">Pilih voucher</Typography>
          <IconButton onClick={() => setOpenVoucher(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent>
          <Box mt={1} mb={2}>
            {claimedVoucher.map((voucher) => {
              if (voucher.supplier && voucher.supplier.slug !== slug) {
                return null;
              } else {
                return (
                  <Grid
                    container
                    spacing={2}
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item>{voucher.name}</Grid>
                    <Grid item>
                      <Button
                        color="primary"
                        size="small"
                        variant="contained"
                        onClick={() => handleVoucher(voucher)}
                      >
                        {choosedVoucher && choosedVoucher.id === voucher.id
                          ? "Batalkan"
                          : "Pilih"}
                      </Button>
                    </Grid>
                  </Grid>
                );
              }
            })}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
});

export default ApplyVoucher;
