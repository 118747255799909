import React from 'react'
import {ReactComponent as IconBack } from '../../assets/icons/arrow-left.svg'
import { WrapTitlePage } from './style'

const TitlePageComponent = (props) => {
  const { children = null, onBack = null, contentRight = null} = props
  return (
    <WrapTitlePage>
      <div className="left">
        <IconBack onClick={onBack} />
        <h1>{children}</h1>
      </div>
      <div className="right">
        {contentRight}
      </div>
    </WrapTitlePage>
  )
}

export default TitlePageComponent