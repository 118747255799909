import { axiosInstance } from "../config/fetchAxios";

export const GET_WALLET_INFO = async () => {
  const url = `/api/v2/wallet`
  const data = axiosInstance.get(url)
  return data
}

export const GET_WALLET_TRANSACTION = (filters = '') => {
  const url = `/api/v2/wallet/transactions${filters}`
  const data = axiosInstance.get(url)
  return data
}

export const POST_TOPUP_WALLET = (value) => {
  const url = `/api/v2/wallet/topup`
  const data = axiosInstance.post(url, {
    amount: value
  })
  return data
}