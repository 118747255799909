import { getCookie } from "./handleCookie";

const getData = JSON.parse(getCookie("recomendation_products")) || [2, 2];
// [1,1,2,3]

const recomendation = async () => {
  var counts = {};
  var compare = -1;
  var mostFrequent;
  for (var i = 0, len = getData.length; i < len; i++) {
    var word = getData[i];

    if (counts[word] === undefined) {
      counts[word] = 1;
    } else {
      counts[word] = counts[word] + 1;
    }
    if (counts[word] > compare) {
      compare = counts[word];
      mostFrequent = getData[i];
    }
  }
  return mostFrequent;
};

export default recomendation;
