import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import CustomerForm from "../../../../components/CustomerForm";

const ChangeAddress = (props) => {
  const { open, handleClose, setUpdate, update, selectedCustomer } = props;

  const handleChangeAddress = (data) => {
    const dataCart = [...JSON.parse(localStorage.getItem("SELECTED_CART"))];
    let newCart = [];
    dataCart.map((res) => {
      if (res.customer.id === data.id) {
        newCart = [...newCart, { ...res, customer: data }];
      } else {
        newCart = [...newCart, res];
      }
    });
    localStorage.setItem("SELECTED_CART", JSON.stringify(newCart));
    setUpdate(!update);
    handleClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6">Ubah Alamat Customer</Typography>
          <CloseIcon
            style={{ height: 32, width: "auto", cursor: "pointer" }}
            onClick={handleClose}
          />
        </div>
      </DialogTitle>
      <DialogContent>
        <Typography>Atas Nama {selectedCustomer.name}</Typography>
        <CustomerForm
          defaultCustomer={selectedCustomer}
          submitCallback={handleChangeAddress}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ChangeAddress;
