import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Box from "@material-ui/core/Box";
import CssBaseline from "@material-ui/core/CssBaseline";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { SearchContext } from "./components/contexts/searchContext";
import handleRoute from "./config/handleRoute";
import Kollektif from "./assets/fonts/Kollektif.woff2";
import ListRoute from "./config/listRoute";
import withPageView from "./config/google-analytics/withPageView";
import DMSansRegular from "./assets/fonts/DM-Sans/DMSans-Regular.ttf";
import DMSansMedium from "./assets/fonts/DM-Sans/DMSans-Medium.ttf";
import DMSansBold from "./assets/fonts/DM-Sans/DMSans-Bold.ttf";

import Home from "./pages/home-page";
import Login from "./pages/login-page/login-page";
import Signup from "./pages/signup-page/signup-page";
import ResetPassword from "./pages/reset-password-page/reset-password-page";
import CategoryPage from "./pages/category-page";
import NotFound404 from "./pages/not-found-page";
import RegisterPage from "./pages/register-page";
import { AuthContextProvider } from "../src/contexts/AuthContextProvider";

import GetOnGooglePlayImage from "../src/assets/images/get-on-google-play.png";
import GetOnAppStoreImage from "../src/assets/images/get-on-appstore.png";
import RegisterBankPage from "./pages/register-bank-page";

function App(props) {
  const isMobile = useMediaQuery("(max-width:500px)");
  const pathname = window.location.pathname;

  // const kollektif = {
  //   fontFamily: "kollektif",
  //   fontStyle: "normal",
  //   fontDisplay: "swap",
  //   fontWeight: 400,
  //   src: `
	// 	local('Kollektif'),
	// 	local('Kollektif-Regular'),
	// 	url(${Kollektif}) format('woff2')
	// 	`,
  // };

  const theme = createMuiTheme({
    palette: {
      primary: { 500: "#FBBB14" },
      secondary: { main: "#FF6564" },
      yellow: {
        primary: "#FBBD1C",
      },
    },
    typography: {
      fontFamily: [
        "dmsans",
        "kollektif",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          html: {
            overflowX: isMobile && "hidden",
          },
          body: {
            overflow: isMobile && "hidden",
          },
          "@font-face": [
            {
              fontFamily: "dmsans-bold",
              src: `url(${DMSansBold})`,
            },
            {
              fontFamily: "dmsans-regular",
              src: `url(${DMSansRegular})`,
            },
            {
              fontFamily: "dmsans-medium",
              src: `url(${DMSansMedium})`,
            },
          ],
        },
      },
      MuiButton: {
        root: {
          textTransform: "capitalize",
        },
      },
      MuiTab: {
        root: {
          textTransform: "capitalize",
        },
        wrapper: {
          flexDirection: "initial",
        },
      },
    },
  });

  handleRoute();

  return (
    <AuthContextProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <SearchContext>
            <Switch>
              <Route exact path="/" component={withPageView(Home)} />
              <Route exact path="/login" component={withPageView(Login)} />
              <Route exact path="/signup" component={withPageView(Signup)} />
              <Route
                exact
                path="/reset-password"
                component={withPageView(ResetPassword)}
              />
              <Route
                exact
                path="/category"
                component={withPageView(CategoryPage)}
              />
              <Route
                exact
                path="/register"
                component={withPageView(RegisterPage)}
              />
              <Route
                exact
                path="/register/bank-transfer"
                component={withPageView(RegisterBankPage)}
              />
              {ListRoute.map((data) => (
                <Route
                  key={data.name}
                  path={data.path}
                  component={withPageView(data.component)}
                  exact={Boolean(data.exact)}
                />
              ))}
              <Route component={NotFound404} />
            </Switch>
          </SearchContext>
        </Router>
      </ThemeProvider>
    </AuthContextProvider>
  );
}

export default App;
