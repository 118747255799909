import React, { Fragment } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { makeStyles } from "@material-ui/core/styles";
import CardProduct from "./CardProduct";
import { Typography, Paper, Box } from "@material-ui/core";
import { numberSeparator } from "../utils/numberSeparator";
import iconIdejualan from "../assets/icons/iconIdejualan.svg";
import { CLAIM_VOUCHER } from "../services/voucher.service";
import swal from "@sweetalert/with-react";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import NeedLoginSwal from "../components/needLoginSwal";

const useStyles = makeStyles((theme) => ({
  slidePrev: {
    height: 50,
    width: 50,
    background: "#29c0c7",
    borderRadius: 4,
    marginRight: -40,
    zIndex: 9,
    color: "#ffffff",
    transition: "0.5s",
    "&:hover": {
      background: "#f2f4f8",
      transition: "0.5s",
      color: "#555555",
    },
  },
  slideNext: {
    height: 50,
    width: 50,
    marginLeft: -40,
    zIndex: 9,
    color: "#ffffff",
    background: "#29c0c7",
    borderRadius: 4,
    transition: "0.5s",
    "&:hover": {
      background: "#f2f4f8",
      transition: "0.5s",
      color: "#555555",
    },
  },
  slider: {
    margin: "auto",
    outline: "none",
    width: "140px !important",
  },
  voucherSlider: {
    margin: "auto",
    outline: "none",
    width: "180px !important",
  },
  ticket: {
    position: 'relative!important',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px;',
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'row',
    borderRadius: 10,
    background: 'white',
    margin: 5,
    padding: '7px 3px',
    '&:before': {
      content: "''",
      top: 7,
      width: 11,
      left: '-6px',
      height: 82,
      position: 'absolute',
      background: 'radial-gradient(circle, transparent, transparent 50%, #fff 50%, #fff 100% ) -7px -14px / 15px 21px repeat-y',
    }
  },
  ticket2: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 80,
  }
}));

function NextArrow(props) {
  const classes = useStyles();
  const { onClick, isSmall } = props;
  return (
    <div
      onClick={onClick}
      style={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        height: isSmall ? 75 : 120,
      }}
    >
      <NavigateNextIcon className={classes.slideNext} />
    </div>
  );
}

function PrevArrow(props) {
  const classes = useStyles();
  const { onClick, isSmall } = props;
  return (
    <div
      onClick={onClick}
      style={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        height: isSmall ? 75 : 120,
      }}
    >
      <NavigateBeforeIcon className={classes.slidePrev} />
    </div>
  );
}

const Customer = (props) => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:500px)");
  const consumAPI = props.data;
  return (
    <Fragment>
      <Slider
        infinite={isMobile ? true : false}
        slidesToShow={isMobile ? 2 : 6}
        centerMode={isMobile ? true : false}
        slidesToScroll={1}
        adaptiveHeight={true}
        swipeToSlide={isMobile ? true : false}
        nextArrow={!isMobile && <NextArrow />}
        prevArrow={!isMobile && <PrevArrow />}
      >
        {consumAPI &&
          consumAPI.map((res, index) => (
            <div key={index} className={classes.slider}>
              <CardProduct variant="customer" key={index} data={res} />
            </div>
          ))}
      </Slider>
    </Fragment>
  );
};

const Reseller = (props) => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:500px)");
  const consumAPI = props.data;

  return (
    <Fragment>
      <Slider
        infinite={isMobile ? true : false}
        slidesToShow={isMobile ? 2 : 6}
        centerMode={isMobile ? true : false}
        slidesToScroll={6}
        adaptiveHeight={true}
        swipeToSlide={isMobile ? true : false}
        nextArrow={!isMobile && <NextArrow />}
        prevArrow={!isMobile && <PrevArrow />}
      >
        {consumAPI &&
          consumAPI.map((res, index) => (
            <div key={index} className={classes.slider}>
              <CardProduct variant="reseller" key={index} data={res} />
            </div>
          ))}
      </Slider>
    </Fragment>
  );
};

const Vouchers = (props) => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:500px)");
  const history = useHistory();

  const handleClaimVoucher = async (id) => {
    try {
      await CLAIM_VOUCHER(id);
      swal("Success!", "Voucher berhasil di klaim", "success");
    } catch (error) {
      swal(
        <div style={{width: '375px', margin: 'auto'}}>
            <h2>Login Diperlukan</h2>
            <p>Anda harus masuk untuk dapat mengakses fitur ini</p>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <button 
                    style={{
                        margin: '5px', 
                        width: '50%', 
                        border: '1px solid orange', 
                        backgroundColor: 'white', 
                        borderRadius:'5px', 
                        cursor: 'pointer', 
                        color: 'orange', 
                        padding: '10px'
                    }}
                    onClick={() =>
                        window.location.href = '/signup'
                    }
                >
                    Daftar
                </button>
                <button 
                    style={{
                        margin: '5px', 
                        width: '50%', 
                        border: '1px solid orange', 
                        backgroundColor: 'orange', 
                        borderRadius:'5px', 
                        cursor: 'pointer', 
                        color: 'white', 
                        padding: '10px'
                    }}
                    onClick={() =>
                        window.location.href = '/login'
                    }
                >
                    Masuk
                </button>
            </div>
        </div>
      ,{
          buttons: false
      })
    }
  };

  const handleVoucherPage = () => {
    if (localStorage.getItem("TOKEN") === null) {
      swal(
        <NeedLoginSwal />
        , {
          buttons: false
        })
    } else {
      history.push('/voucher')
    }
  }

  return (
    <Fragment>
      <Slider
        infinite
        slidesToShow={isMobile ? 2 : 5}
        centerMode={false}
        slidesToScroll={isMobile ? 1 : 2}
        adaptiveHeight={true}
        swipeToSlide={isMobile ? true : false}
        nextArrow={!isMobile && <NextArrow isSmall />}
        prevArrow={!isMobile && <PrevArrow isSmall />}
      >
        {(props.data || []).map((res, index) => (
          <div key={index + 'voucher'}>
            <div onClick={handleVoucherPage} className={classes.ticket} >
              <div className={classes.ticket2}>
                <div>
                  <img src={res.supplier ? res.supplier.avatar_url : iconIdejualan} alt='logo' style={{ width: 40, height: 40, marginRight: 5 }} />
                </div>
                <div>
                  <div style={{ fontSize: '.7rem', fontWeight: "bold", color: 'gray', width: 90, height: 36, overflow: 'hidden', textOverflow: 'ellipsis' }}>{res.name}</div>
                  <div style={{ fontSize: '.8rem', color: '#FBBA14' }} >Rp {numberSeparator(res.amount)}</div>
                  {/* <div onClick={() => handleClaimVoucher(res.id)} style={res.is_claimed ? { background: 'gray', width: 50, textAlign: 'center', color: 'white', borderRadius: 5, marginTop: 10, padding: 5, cursor: 'not-alowed' } : {background: 'orange', width: 50, textAlign: 'center', color: 'white', borderRadius: 5, marginTop: 10, padding: 5}} >Claim</div> */}
                </div>

                <div style={{backgroundColor: 'white', fontSize: '.7rem', position: 'absolute', padding: '0 4px 0 4px', borderTopRightRadius: 10, borderBottomLeftRadius: 10, border: '1px solid #32f4ff', color: '#32f4ff', top: 0, right: 0, textTransform: 'uppercase' }}>
                  {res.type}
                </div>
                {
                  res.dompetjualan &&
                  <div style={{ fontSize: '.6rem', position: 'absolute', padding: '2px 4px 2px 4px', borderTopLeftRadius: 10, borderBottomRightRadius: 10, color: 'white', top: 0, left: 0, backgroundColor: '#f28282' }}>
                    Dompet Jualan
                  </div>
                }
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </Fragment>
  );
};

const Mobile = (props) => {
  const classes = useStyles();
  const isMobileSmall = useMediaQuery("(max-width:380px)");
  const consumAPI = props.data;

  return (
    <Fragment>
      <Slider
        infinite={true}
        slidesToShow={isMobileSmall ? 2 : 3}
        centerMode={false}
        slidesToScroll={1}
        adaptiveHeight={true}
        swipeToSlide={true}
      >
        {consumAPI &&
          consumAPI.map((res, index) => (
            <div key={index} className={classes.slider}>
              <CardProduct variant="mobile" key={index} data={res} />
            </div>
          ))}
      </Slider>
    </Fragment>
  )
}

const Carousel = (props) => {
  const { variant, data } = props;
  switch (variant) {
    case "customer":
      return <Customer data={data} />;
    case "voucher":
      return <Vouchers data={data} />;
    case "mobile":
      return <Mobile data={data} />;
    default:
      return <Reseller data={data} />;
  }
};

export default Carousel;
