import ResetPasswordForm from "../pages/reset-password-page/form-reset-password";
import ProductPage from "../pages/product-page";
import LoginWithSocialMedia from "../components/login-with-sm";
import CekOngkir from "../pages/product-page/mobile/components/cek-ongkir";
import SearchPage from "../pages/search-page";
import ProfileSupplier from "../pages/profile-page/supplier";
import ProfileReseller from "../pages/profile-page/reseller";
import CartPage from "../pages/cart-page";
import CartPageCustomer from "../pages/cart-page-customer";
import CekResi from "../pages/resi-page";
import CheckoutPage from "../pages/checkout-page";
import ConfirmPage from "../pages/confirm-page";
import DetailCategory from "../pages/detail-category-page";
import PrivacyPolicy from "../pages/privacy-policy";
import ConfirmPageAccount from "../pages/confirm-activation-account-page";
import MyProfile from "../pages/profile-page/my-profile";
import HistoryPage from "../pages/history-page";
import EditRekeningPage from "../pages/edit-rekening";
import WishlistPage from "../pages/wishlist-page";
import MyOrderPage from "../pages/my-order-page";
import TermsOfService from "../pages/terms-of-service";
import PromoPage from "../pages/promo-page";
import BrandPage from "../pages/brand-populer";
import VendorBankCheckoutSummary from "../pages/vendor-bank-checkout-summary";
import VoucherPage from "../pages/voucher-page";
import MyVoucherPage from "../pages/my-voucher-page";
import MyVoucherDetailPage from "../pages/voucher-detail-page";
import DailyLogin from "../pages/daily-login";
import UpgradeReseller from "../pages/upgrade-reseller";
import VerifPhone from "../pages/verif-phone";
import NotificationPage from "../pages/notification-page";
import BisnisPage from "../pages/bisnis-page";
import CampaignPage from "../pages/campaign";
import PaymentSuccessCampaign from "../pages/campaign/payment-success";
import SubmissionsCampaign from "../pages/campaign/submissions";
import ConfirmationCodPage from "../pages/checkout-page/confirmation-cod-page";
import CheckoutPageCustomer from "../pages/checkout-page-customer";
import ThanksPageCustomer from "../pages/thanks-page-customer";
import VendorBankCheckoutCustomer from "../pages/vendorbank-checkout-customer";
import EditProfilePage from "../pages/edit-profile";
import EditTokoPage from "../pages/edit-toko";

const list = [
  {
    path: "/search/:keyword",
    name: "SEARCH PAGE",
    component: SearchPage,
  },
  {
    path: "/form-reset-password",
    name: "FORM RESET PASSWORD",
    component: ResetPasswordForm,
  },
  {
    path: "/product/:slug",
    name: "DETAIL PRODUCT RESELLER",
    component: ProductPage,
  },
  {
    path: "/store/:reseller/payment/:id",
    name: "THANKS PAGE CUSTOMER",
    component: ThanksPageCustomer,
  },
  {
    path: "/store/:reseller/:slug",
    name: "DETAIL PRODUCT CUSTOMER",
    component: ProductPage,
  },
  {
    path: "/product-cek-ongkir",
    name: "CHECK SHIPPING COST",
    component: CekOngkir,
  },
  {
    path: "/login/get-account",
    name: "LOGIN WITH SOCIAL MEDIA",
    component: LoginWithSocialMedia,
  },
  {
    path: "/profile/:supplier",
    name: "PROFILE SUPPLIER",
    component: ProfileSupplier,
  },
  {
    path: "/store/:reseller",
    name: "STOREFRONT RESELLER",
    component: ProfileReseller,
  },
  {
    path: "/cart",
    name: "CART",
    component: CartPage,
  },
  {
    path: "/cek-resi",
    name: "CHECK PACKAGE TRACKING",
    component: CekResi,
  },
  {
    path: "/finish",
    name: "CONFIRM PAGE",
    component: ConfirmPage,
  },
  {
    path: "/checkout",
    name: "CHECKOUT",
    component: CheckoutPage,
    exact: true,
  },
  {
    path: "/category/:category",
    name: "CATEGORY",
    component: DetailCategory,
  },
  {
    path: "/PrivacyPolicy",
    name: "PRIVACYPOLICY",
    component: PrivacyPolicy,
  },
  {
    path: "/terms-of-service",
    name: "Terms of service",
    component: TermsOfService,
  },
  {
    path: "/activateemail/534543",
    name: "CONFIRM ACTIVATION ACCOUNT",
    component: ConfirmPageAccount,
  },
  {
    path: "/user/my-profile/edit-rekening",
    name: "EDIT-REKENING",
    component: EditRekeningPage,
  },
  {
    path: "/user/my-profile/edit-profile",
    name: "EDIT-PROFILE",
    component: EditProfilePage,
  },
  {
    path: "/user/my-profile/edit-toko",
    name: "EDIT-TOKO",
    component: EditTokoPage,
  },
  {
    path: "/user/my-profile",
    name: "MY PROFILE",
    component: MyProfile,
  },
  {
    path: "/history",
    name: "RIWAYAT",
    component: HistoryPage,
  },
  {
    path: "/wishlist",
    name: "WISHLIST",
    component: WishlistPage,
  },
  {
    path: "/wishlist",
    name: "WISHLIST",
    component: WishlistPage,
  },
  {
    path: "/orders",
    name: "MY ORDER",
    component: MyOrderPage,
  },
  {
    path: "/promo",
    name: "PROMO",
    component: PromoPage,
  },
  {
    path: "/brand-page",
    name: "BRANDPAGE",
    component: BrandPage,
  },
  {
    path: "/checkout/vendor-bank/:reseller/:paymentId",
    name: "VENDOR BANK CHECKOUT CUSTOMER",
    component: VendorBankCheckoutCustomer,
  },
  {
    path: "/checkout/vendor-bank/:paymentId",
    name: "VENDOR BANK CHECKOUT SUMMARY",
    component: VendorBankCheckoutSummary,
  },
  {
    path: "/voucher",
    name: "VOUCHER",
    component: VoucherPage,
    exact: true
  },
  {
    path: "/my-voucher",
    name: "MY VOUCHER",
    component: MyVoucherPage,
    exact: true
  },
  {
    path: "/voucher/:voucherId",
    name: "VOUCHER DETAIL",
    component: MyVoucherDetailPage,
    exact: true
  },
  {
    path: "/daily-login",
    name: "DAILY LOGIN",
    component: DailyLogin,
  },
  {
    path: "/upgrade-reseller",
    name: "UPGRADE RESELLER",
    component: UpgradeReseller,
  },
  {
    path: "/verif-phone",
    name: "Verif Phone",
    component: VerifPhone,
  },
  {
    path: "/notification",
    name: "NOTIFICATION",
    component: NotificationPage,
  },
  {
    path: "/bisnis",
    name: "BISNIS",
    component: BisnisPage,
  },
  {
    path: "/payment-campaign",
    name: "CAMPAIGN PAYMENT SUCCESS PAGE",
    component: PaymentSuccessCampaign,
  },
  {
    path: "/submissions/:uuid",
    name: "CAMPAIGN SUBMISSIONS PAGE",
    component: SubmissionsCampaign,
  },
  {
    path: "/campaign/:slug",
    name: "CAMPAIGN PAGE",
    component: CampaignPage,
  },
  {
    path: "/cod/:id",
    name: "CONFORMATION COD PAGE",
    component: ConfirmationCodPage,
  },
  {
    path: "/cart-customer",
    name: "CHART CUSTOMER",
    component: CartPageCustomer,
  },
  {
    path: "/checkout-customer",
    name: "CHECKOUT CUSTOMER",
    component: CheckoutPageCustomer,
  },
];

export default list;
