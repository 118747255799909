import React, { Fragment, useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import { useHistory } from "react-router-dom";
import Navbar from "../../../components/navbar";
import DividerMobile from "../../../components/divider-mobile";
import storeIcon from "../../../assets/icons/shop.svg";
import shippmentIcon from "../../../assets/icons/mdi_truck-fast.svg";
import {
  Typography,
  Divider,
  Container,
  TextField,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  InputBase,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {
  checkShippingCostV2,
  checkoutCustomer,
} from "../../../services/axios";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { numberSeparator } from "../../../utils/numberSeparator";
import OpsiPengiriman from "./components/OpsiPengiriman";
import ReactMidtrans from "../../../config/midtrans";
import Loading from "../../../components/loading";
import swal from "sweetalert";
import OpsiPembayaran from "./components/OpsiPembayaran";
import Toggle from "react-toggle";

const Mobile = withStyles(styles)((props) => {
  const { classes } = props;
  const history = useHistory();
  const data = JSON.parse(localStorage.getItem("SELECTED_CART"));
  const [dataCheckout, setDataCheckout] = useState(null);
  const [openOpsiPengiriman, setOpenOpsiPengiriman] = useState({
    status: false,
    data: null,
    customer: null,
  });
  const [openOpsiPembayaran, setopenOpsiPembayaran] = useState(false)
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [openChangeAddress, setOpenChangeAddress] = useState(false);
  const [update, setUpdate] = useState(false);
  const [midtrans, setMidtrans] = useState({
    clientKey: "SB-Mid-client-xYSFi8v3uCnhGICn",
    token: "",
  });
  const [paymentMethod, setPaymentMethod] = useState("midtrans");
  const [isIncludeCod, setisIncludeCod] = useState(false)
  const [isOpenDialogCOD, setisOpenDialogCOD] = useState(false)
  const [isButtonDisabled, setisButtonDisabled] = useState(true)
  const [isSetujuCod, setisSetujuCod] = useState(false)
  const [isLoading, setisLoading] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      if (isOpenDialogCOD === true) {
        setisButtonDisabled(false)
      }
    }, 5000);
  }, [isOpenDialogCOD])
  

  const handleCheckShippingCost = async (
    supplierId,
    destinationPostalCode,
    destinationSubDistrictName,
    couriers,
    weight
  ) => {
    const formData = new FormData();
    formData.append("supplier_id", supplierId);
    formData.append("destination_postal_code", destinationPostalCode);
    formData.append(
      "destination_sub_district_name",
      destinationSubDistrictName
    );
    formData.append("shipment_type", "DROP");
    formData.append("package_type_id", "1");
    formData.append("weight", Math.ceil(weight / 1000));
    formData.append("is_cod", "0");
    for (let i = 0; i < couriers.length; i++) {
      formData.append("couriers[]", couriers[i]);
    }
    const response = await checkShippingCostV2(formData);
    return response;
  };

  const filteredFullCustomer = (data) => {
    // console.log(data);
    let returnData = [];
    let returnDataTrue = [];
    data.map((res) => {
      if (returnData[returnData.indexOf(res.customer.name)] !== res.customer.name) {
        returnData = [...returnData, res.customer.name];
        returnDataTrue = [...returnDataTrue, res.customer];
      }
    });
    return returnDataTrue;
  };

  const filteredFullSupplier = (data) => {
    let returnData = [];
    let returnDataTrue = [];
    data.map((res) => {
      if (returnData[returnData.indexOf(res.supplier)] !== res.supplier) {
        returnData = [...returnData, res.supplier];
        returnDataTrue = [...returnDataTrue, res.data_supplier];
      }
    });
    return returnDataTrue;
  };

  const filteredCourierCode = (data) => {
    let returnData = [];
    data.forEach((res) => {
      if (returnData[returnData.indexOf(res.code)] !== res.code) {
        returnData = [...returnData, res.code];
      }
    });
    return returnData;
  };

  const getTotalWeight = (data) => {
    let returnData = 0;
    data.forEach((res) => {
      returnData = returnData + res.weight_gram;
    });
    return returnData;
  };

  const getOngkir = async (supplier, customer) => {
    const response = await handleCheckShippingCost(
      supplier.id,
      customer.village?.kodepos || customer.postal_code,
      customer.village?.name,
      filteredCourierCode(supplier.supported_couriers),
      getTotalWeight(
        data
          .filter((value) => value.customer.name === customer.name)
          .filter((item) => item.supplier === supplier.name)
      )
    );
    return response;
  };

  const getDataCheckout = async () => {
    let dataResult = [];
    const listCustomer = filteredFullCustomer(data);
    for (let ic = 0; ic < listCustomer.length; ic++) {
      let listSupplier = filteredFullSupplier(
        data.filter((value) => value.customer.name === listCustomer[ic].name)
      );

      for (let is = 0; is < listSupplier.length; is++) {
        const resListOngkir = await getOngkir(
          listSupplier[is],
          listCustomer[ic]
        );

        let selectedOngkir = null;

        for (const key in resListOngkir) {
          if (Object.hasOwnProperty.call(resListOngkir, key)) {
            if (resListOngkir[key] && resListOngkir[key].length > 0) {
              selectedOngkir = resListOngkir[key][0];
              break;
            }
          }
        }
        dataResult = [
          ...dataResult,
          {
            id: ic,
            customer: listCustomer[ic],
            data_supplier: [
              {
                id: is,
                supplier: listSupplier[is],
                list_ongkir: resListOngkir,
                selected_ongkir: selectedOngkir,
                catatan: "",
                products: data
                  .filter(
                    (value) => value.customer.name === listCustomer[ic].name
                  )
                  .filter((item) => item.supplier === listSupplier[is].name),
              },
            ],
            data_voucher: null,
          },
        ];
      }
    }
    setDataCheckout(dataResult);
    setCurrentCustomer(dataResult[0].customer);
  };

  useEffect(() => {
    getDataCheckout();
  }, [update]);

  const getNameCustomer = (data) => {
    let resultData = [];
    data.forEach((res) => {
      if (res.name !== undefined) {
        resultData = [...resultData, res.name];
      } else {
        resultData = [...resultData, "undefined"];
      }
    });
    return resultData;
  };

  const getAddressCustomer = (data) => {
    let resultData = [];
    data.forEach((res) => {
      if (res.name !== undefined) {
        resultData = [...resultData, res.address];
      }
    });
    return resultData;
  };

  const handleChangeShippment = (costs, supplier, customer, myIndex) => {
    setOpenOpsiPengiriman({ ...openOpsiPengiriman, status: false });
    let newDataCheckout = JSON.parse(JSON.stringify(dataCheckout))
    newDataCheckout[myIndex].selected_ongkir = costs
    newDataCheckout[myIndex].data_supplier[0].selected_ongkir = costs

    setDataCheckout(newDataCheckout)
  };

  const handleCatatan = (value, supplier, customer) => {
    setDataCheckout([
      ...dataCheckout.filter((value) => value.customer.name !== customer.name),
      {
        ...dataCheckout.filter(
          (value) => value.customer.name === customer.name
        )[0],
        data_supplier: [
          ...dataCheckout
            .filter((value) => value.customer.name === customer.name)[0]
            .data_supplier.filter(
              (value) => value.supplier.name !== supplier.name
            ),
          {
            ...dataCheckout
              .filter((value) => value.customer.name === customer.name)[0]
              .data_supplier.filter(
                (value) => value.supplier.name === supplier.name
              )[0],
            catatan: value,
          },
        ],
      },
    ]);
  };

  const getTotalOngkir = (response) => {
    let returnData = [];
    response.data_supplier.forEach(
      (res) => (returnData = [...returnData, res?.selected_ongkir?.price])
    );

    if (returnData.length === 1) {
      return returnData;
    } else {
      return returnData.reduce((a, b) => a + b, 0);
    }
  };

  const filteredCart = (data) => {
    let returnData = [];
    const selectedCart = JSON.parse(localStorage.getItem("SELECTED_CART"));
    
    data.map((res) => {
      if (
        selectedCart[selectedCart.findIndex((value) => value.id === `${res.id}-${value.customer.name}`)] ===
        undefined
      ) {
        returnData = [...returnData, res];
      }
    });
    return returnData;
  };

  // non cod price
  const filteredPrice = (data) => {
    let returnData = [];
    data.map((res) => {
      returnData = [...returnData, res.price * res.quantity];
    });
    return returnData;
  };

  const getTotalPrice = (res) => {
    let returnData = [];
    res.data_supplier.forEach(
      (res) => (returnData = [...returnData, filteredPrice(res.products)])
    );

    if (returnData.length === 1) {
      if (returnData[0].length === 1) {
        return returnData
      }else{
        let finalData = 0
        for (let i = 0; i < returnData[0].length; i++) {
          finalData =  finalData + returnData[0][i]
        }
        return finalData
      }
    } else {
      return returnData.reduce((a, b) => a + b, 0);
    }
  };

  const getAllTotalPrice = () => {
    let returnData = [];

    dataCheckout
    .filter((data) => data.data_supplier[0].is_cod !== true)
    .forEach(
      (res) =>
        (returnData = [
          ...returnData,
          Number(getTotalPrice(res)),
          Number(getTotalOngkir(res)),
          -Number(res.data_voucher ? res.data_voucher.amount : 0),
        ])
    );

    if (returnData.length === 1) {
      return returnData[0];
    } else {
      return returnData.reduce((a, b) => a + b, 0);
    }
  };
  // end non cod price

  //  ~~~~~ payload non cod ~~~~~~~
  const getproductsOrder = (data) => {
    let resultData = [];
    data.forEach((res) => {
      resultData = [
        ...resultData,
        { sku_code: res.selectedSkus.code, qty: res.quantity },
      ];
    });
    return resultData;
  };

  const getOrders = (data) => {
    let resultData = {
      supplier_id: data[0].supplier.id,
      courier: {
        code: data[0].selected_ongkir?.courier_code,
        type: data[0].selected_ongkir?.rate_code,
        min_duration: data[0].selected_ongkir?.min_duration,
        max_duration: data[0].selected_ongkir?.max_duration,
        shipping_cost: data[0].selected_ongkir?.price
      },
      products: getproductsOrder(data[0].products),
      customer_note: data[0].catatan
    }

    return resultData;
  };

  const payloadOrder = () => {
    let orders = []
    let resultData = {
      customer: {
        name: dataCheckout[0].customer.name,
        address: dataCheckout[0].customer.address,
        phone: dataCheckout[0].customer.phone,
        postal_code: dataCheckout[0].customer.postal_code,
        village_id: dataCheckout[0].customer.village.id,
      },
    }

    dataCheckout.forEach((res) => {
      orders = [
        ...orders,
        getOrders(res.data_supplier)
      ]
    })

    resultData.orders = orders

    return resultData;
  };
  //  ~~~~~ end payload non cod ~~~~~~~

  // pembayaran
  const getNewToken = async () => {
    const getCart = JSON.parse(localStorage.getItem("__cart"));
    const payload = payloadOrder();
    payload.payment_method = 'midtrans';

    // filter cart
    await localStorage.setItem("__cart", JSON.stringify(filteredCart(getCart)));

    const response = await checkoutCustomer(payload);
    setMidtrans({ ...midtrans, token: response.data.token });
    initSnap(response.data.token)
  };

  const initSnap = (token) => {
    const { NODE_ENV: ENV } = process.env;

    // create element for script
    const snapScript = document.createElement("script");

    // checking environment mode
    snapScript.src =
        ENV === "production"
        ? 
            window.origin.includes('dev') ?
            "https://app.sandbox.midtrans.com/snap/snap.js"
            :
            "https://app.midtrans.com/snap/snap.js"
        : "https://app.sandbox.midtrans.com/snap/snap.js";

    snapScript.type = "text/javascript";
    snapScript.onload = () => {
    if ("snap" in window) {
        const { snap } = window;
        snap.pay(token)
    }
    };
    snapScript.dataset.clientKey = midtrans.clientKey;
    document.head.appendChild(snapScript);
  };

  const handlePayVendorBank = async () => {
    const getCart = JSON.parse(localStorage.getItem("__cart"));
    const payload = payloadOrder();
    payload.payment_method = 'vendorbank';

    // filter cart
    await localStorage.setItem("__cart", JSON.stringify(filteredCart(getCart)));

    const response = await checkoutCustomer(payload);
    if (response) {
      history.push(`/checkout/vendor-bank/${sessionStorage.getItem('reseller')}/${response.data.payment.token}`);
    }
  };

  return (
    <Fragment>
      {!dataCheckout ? (
        <Loading />
      ) : (
        <Fragment>
          {openOpsiPengiriman.status ? (
            <Fragment>
              <OpsiPengiriman
                handleClose={() =>
                  setOpenOpsiPengiriman({
                    ...openOpsiPengiriman,
                    status: false,
                  })
                }
                open={openOpsiPengiriman.status}
                data={openOpsiPengiriman.data}
                customer={openOpsiPengiriman.customer}
                myIndex={openOpsiPengiriman.myIndex}
                handleChangeShippment={handleChangeShippment}
              />
            </Fragment>
          ) : 
          openOpsiPembayaran ? 
            (
              <>
                <OpsiPembayaran
                  handleClose={setopenOpsiPembayaran}
                  paymentMethod={paymentMethod}
                  setPaymentMethod={setPaymentMethod}
                />
              </>
            )
          : (
            <Fragment>
              <Navbar text="Checkout" onClick={() => history.goBack()} />
              <div style={{ marginBottom: 70 }}>
                {dataCheckout && (
                  <Fragment>
                    {dataCheckout
                      .sort(function (a, b) {
                        return a.customer.name > b.customer.name
                          ? 1
                          : b.customer.name > a.customer.name
                          ? -1
                          : 0;
                      })
                      .map((checkout, indexDataCheckout) => {
                        return (
                          <Fragment key={indexDataCheckout}>
                            <Container maxWidth="lg">
                              <Typography
                                color="primary"
                                style={{ paddingBottom: 10 }}
                              >
                                <b>{`Atas Nama ${checkout.customer.name}`}</b>
                              </Typography>
                            </Container>
                            {checkout.data_supplier.map((supplier, indexSupplier) => {
                                return (
                                  <Fragment key={indexSupplier}>
                                    <Divider />
                                    {supplier.products.map((res, indexProduct) => (
                                        <Fragment key={indexProduct}>
                                          <Container
                                            maxWidth="lg"
                                            style={{ paddingBottom: 10 }}
                                          >
                                            <div
                                              className={classes.itemWrapper}
                                            >
                                              <img
                                                src={res.img}
                                                height={80}
                                                width={80}
                                                alt="icon gambar"
                                                className={classes.image}
                                              />
                                              <div style={{ paddingLeft: 10 }}>
                                                <Typography
                                                  className={classes.itemTitle}
                                                >
                                                  {res.name}
                                                </Typography>
                                                <div>
                                                  <Typography variant="caption">
                                                    <b>Variasi : </b>
                                                  </Typography>
                                                  <Typography variant="caption">
                                                    {`${res.size}, ${res.varian}`}
                                                  </Typography>
                                                  <div
                                                    className={
                                                      classes.flexBetween
                                                    }
                                                    style={{ paddingTop: 20 }}
                                                  >
                                                    <Typography
                                                      variant="body2"
                                                      color="secondary"
                                                    >
                                                      {res.quantity}x @Rp{" "}
                                                      {numberSeparator(
                                                        res.price
                                                      )}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                      <b>
                                                        Rp
                                                        {numberSeparator(
                                                          res.quantity *
                                                            res.price
                                                        )}
                                                      </b>
                                                    </Typography>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Container>
                                        </Fragment>
                                      ))}
                                    <Container maxWidth="lg">
                                      <div
                                        className={classes.flexBetween}
                                        style={{ padding: "10px 0" }}
                                      >
                                        <div className={classes.flexCenter}>
                                          <img src={shippmentIcon} alt="" />
                                          <Typography
                                            style={{ paddingLeft: 10 }}
                                          >
                                            Pengiriman
                                          </Typography>
                                        </div>
                                        <div className={classes.flexCenter}>
                                          <Typography
                                            style={{
                                              cursor: "pointer",
                                              paddingRight: 10,
                                            }}
                                            onClick={() =>
                                              setOpenOpsiPengiriman({
                                                status: true,
                                                customer: checkout.customer,
                                                data: supplier,
                                                myIndex: indexDataCheckout
                                              })
                                            }
                                          >
                                            Ubah
                                          </Typography>
                                          <ArrowForwardIcon />
                                        </div>
                                      </div>

                                      <div>
                                        <div className={classes.flexBetween}>
                                          <div>
                                            <Typography>
                                              <b>{`${
                                                supplier.selected_ongkir
                                                .logistic_name
                                              } `}</b>
                                            </Typography>

                                            <Typography>
                                              {supplier.selected_ongkir.name}
                                            </Typography>
                                          </div>

                                          <Typography>
                                            <b>
                                              Rp{" "}
                                              {numberSeparator(
                                                supplier.selected_ongkir.price
                                              )}
                                            </b>
                                          </Typography>
                                        </div>
                                        <Typography
                                          variant="caption"
                                          style={{ color: "#555555" }}
                                        >
                                          Perkiraan Pengiriman{" "}
                                          {
                                            supplier.selected_ongkir.min_duration
                                            +
                                            '-'
                                            +
                                            supplier.selected_ongkir.max_duration
                                          } hari
                                        </Typography>
                                      </div>

                                      <div
                                        style={{
                                          display: "flex",
                                          paddingTop: 10,
                                          paddingBottom: 10,
                                        }}
                                      >
                                        <Typography>Catatan</Typography>
                                        <TextField
                                          placeholder="(Optional) Tinggalkan pesan ke penjual"
                                          variant="outlined"
                                          style={{
                                            width: "100%",
                                            marginLeft: 10,
                                          }}
                                          onChange={(e) =>
                                            handleCatatan(
                                              e.target.value,
                                              supplier.supplier,
                                              checkout.customer,
                                              indexDataCheckout
                                            )
                                          }
                                        />
                                      </div>
                                      {dataCheckout &&
                                        indexDataCheckout !== dataCheckout.length && (
                                          <DividerMobile />
                                        )}
                                    </Container>
                                  </Fragment>
                                );
                              })}
                          </Fragment>
                        );
                      })}
                  </Fragment>
                )}
              </div>

              <Container
                maxWidth="lg"
                className={classes.containerActionButton}
              >
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                  <div>
                    Metode Pembayaran
                  </div>
                  {
                    getAllTotalPrice() !== 0 ?
                    <div style={{cursor: 'pointer'}} onClick={() => {setopenOpsiPembayaran(true)}}>
                      {paymentMethod === 'midtrans' ? 'Pembayaran Otomatis' : 'Transfer Bank'} {`>`}
                    </div>
                    :
                    <div>
                      {paymentMethod === 'midtrans' ? 'Pembayaran Otomatis' : 'Transfer Bank'} {`>`}
                    </div>
                  }
                </div>
                <hr/>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                  <div>
                    <Typography
                      variant="caption"
                    >
                      Total Belanja
                    </Typography>
                    <Typography >
                      Rp. 
                      {numberSeparator(getAllTotalPrice())}
                    </Typography>
                  </div>
                  <Grid item xs={6}>
                    {/* Button Pembayaran */}
                    {
                      paymentMethod === 'midtrans' ?
                        <>
                          <ReactMidtrans
                            clientKey={midtrans.clientKey}
                          >
                              <div></div>
                          </ReactMidtrans>
                          <Button variant="contained" onClick={getNewToken} color="primary" fullWidth={true}>
                            Checkout
                          </Button>
                        </>
                        :
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth={true}
                          onClick={handlePayVendorBank}
                        >
                          Checkout
                        </Button>
                    }
                  </Grid>
                </div>
              </Container>
            </Fragment>
          )
          }
        </Fragment>
      )}
    </Fragment>
  );
});

export default Mobile;
