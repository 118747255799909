import React, { Fragment, useEffect, useState } from "react";
import StackGrid from "react-stack-grid";
import Navbar from "../../../components/navbar";
import Footer from "../../../components/footer";
import {
  Container,
  Typography,
  Card,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import Banner from "../../../assets/images/banner-kategori.svg";
import { getCategories } from "../../../services/axios";
import Loading from "../../../components/loading";
import { withRouter } from "react-router-dom";

const Desktop = withStyles(styles)((props) => {
  const { classes, history } = props;
  const [listCategory, setListCategory] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const response = await getCategories();
      setListCategory(response.data);
    };
    getData();
  }, []);

  return (
    <Fragment>
      <Navbar variant="primary" isCategory="yes" />
      <Container maxWidth="md" className={classes.container}>
        <div>
          <img src={Banner} alt="banner idejualan" className={classes.banner} />
        </div>

        {!listCategory ? (
          <Loading />
        ) : (
          <StackGrid columnWidth="50%" gutterWidth={50}>
            {listCategory.map((resParentCategory) => {
              console.log(resParentCategory.name)
              return (
                <div key={resParentCategory.id}>
                  <div className={classes.card}>
                    <Typography className={classes.category}>
                      {resParentCategory.name}
                    </Typography>
                    <Card>
                      <List>
                        {resParentCategory.children.map((resChildrenCategory, index) => (
                          <Fragment key={index}>
                            <ListItem
                              button
                              onClick={() =>
                                history.push(
                                  `/category/${resChildrenCategory.name.split(' ').join('_').split('/').join('&')}-cat.${resChildrenCategory.id}`
                                )
                              }
                            >
                              {resChildrenCategory.icon_url !== null && (
                                <ListItemIcon>
                                  <img
                                    src={resChildrenCategory.icon_url}
                                    alt={resChildrenCategory.category}
                                    className={classes.icon}
                                  />
                                </ListItemIcon>
                              )}
                              <ListItemText primary={resChildrenCategory.name} />
                            </ListItem>
                          </Fragment>
                        ))}
                      </List>
                    </Card>
                  </div>
                </div>
              );
            })}
          </StackGrid>
        )}
      </Container>

      <Footer />
    </Fragment>
  );
});

export default withRouter(Desktop);
