import React from "react";
import { useState } from "react";
import swal from "sweetalert";
import { POST_TOPUP_WALLET } from "../../services/wallet.service";
import ModalTopupComponent from "./ModalTopup.component";

const ModalTopupContainer = (props) => {
  const { isOpen = false, handleClose = null } = props
  const [valueSaldo, setValueSaldo] = useState("");
  const handleChangeSaldo = (e) => {
    setValueSaldo(e.target.value);
  };

  const handleSubmit = async () => {
    let token = ''
    await POST_TOPUP_WALLET(valueSaldo).then(res => {
      token = res.token
    }).catch(() => {
      swal('Error!', 'Silahkan periksa kembali koneksi anda', 'error')
    })
    return token
  }
  return (
    <ModalTopupComponent
      open={isOpen}
      handleClose={handleClose}
      onChange={handleChangeSaldo}
      onSubmit={handleSubmit}
      saldo={valueSaldo}
    />
  );
};

export default ModalTopupContainer;
