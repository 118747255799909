const stylesContent = (theme) => ({
  containerDefault: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cardbox: {
    background: "#ffffff",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.16)",
    borderRadius: 16,
    padding: "30px 0",
    textAlign: "center",
    cursor: "pointer",
    transition: "0.5s",
    "&:hover": {
      transform: "scale(1.1)",
      transition: "0.5s",
    },
  },
  cardimg: {
    height: 130,
  },
  icon: {
    width: 30,
  },
  titleItem: {
    paddingTop: 20,
    fontWeight: "bold",
  },
  titleCarousel: {
    display: "flex",
    alignItems: "center",
    paddingBottom: 30,
  },
  textTitleCarousel: {
    fontWeight: "bold",
    paddingLeft: 20,
  },
  bgTestimonial: {
    background: "#ffeac2",
    height: 300,
    width: 500,
    position: "absolute",
    zIndex: -1,
    borderRadius: "0px 8px 8px 0",
  },
  imgTestimonial: {
    backgroundSize: "cover !important",
    height: 300,
    width: 500,
    marginTop: 70,
    marginLeft: 175,
    borderRadius: 8,
  },
  arrow: {
    height: 40,
    width: 80,
    color: "#999999",
    cursor: "pointer",
    transition: "0.5s",
    "&:hover": {
      height: 50,
      width: 90,
      color: "#000000",
      transition: "0.5s",
    },
  },
  divider: {
    height: 2,
    width: 100,
    backgroundColor: "#000000",
    borderRadius: 50,
  },
  imgBannerMiddle: {
    width: "100%",
  },
  quote: {
    height: "45px !important",
    marginLeft: -260,
    marginTop: 20,
  },
  textBannerMiddle: {
    fontWeight: "bold",
    paddingBottom: 20,
  },
  // Kategori Populer
  containerKategoriPopuler: {
    marginBottom: 50,
    padding: 0,
  },
  kategoriPopuler: {
    fontWeight: "bold",
    paddingBottom: 20,
  },
  notFirstItemKategoriPopuler: {
    // marginLeft: 30,
  },
  cardKategoriPopuler: {
    padding: 15,
    width: 125,
    textAlign: "center",
  },
  containerImgKategoriPopuler: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  imgKategoriPopuler: {
    height: 40,
  },
  textItemKategoriPopuler: {
    paddingTop: 10,
    fontSize: "0.8rem",
  },
  buttonKategoriPopuler: {
    padding: 0,
    textTransform: "none",
  },
  cardWrapper: {
    margin: "0 10px",
  },
  loadingDiv: {
    height: "100vh",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  mainMenu: {
    display:'flex',
    justifyContent: 'space-around'
  },
  containerIcon: {
    textAlign: 'center',
    
  },
  iconMenu: {
    backgroundColor: '#f4f4f4',
    padding: '15px',
    borderRadius: '48%'
  }
});

export { stylesContent };
