import React from "react";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import swal from "@sweetalert/with-react";

import {
  Container,
  Box,
  Typography,
  Paper,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Grid,
  Divider,
  Button,
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { getPaymentsCustomer } from "../../services/order.service";
import { GET_VENDOR_BANKS } from "../../services/bank.service";

import { formatRupiah } from "../../utils/currencyFormat";
import { getStorefront } from "../../services/axios";

const VendorBankCheckoutCustomer = () => {
  const history = useHistory()
  const { reseller, paymentId } = useParams();
  const [paymentData, setPaymentData] = React.useState({});
  const [vendorBanks, setVendorBanks] = React.useState([]);
  const [isOpenDialog, setIsOpenDialog] = React.useState(false);
  const [dataStoreFront, setdataStoreFront] = React.useState()

  React.useEffect(() => {
    const fetch = async () => {
      const res = await getPaymentsCustomer(reseller, paymentId);
      if (res.data) {
        setPaymentData(res.data);
      }
    };

    const getDataStorefront = async() => {
      const response = await getStorefront(reseller)
      setdataStoreFront(response.data)
    }

    getDataStorefront()
    fetch();
  }, []);

  React.useEffect(() => {
    const fetch = async () => {
      const res = await GET_VENDOR_BANKS();
      if (res.data) {
        setVendorBanks(res.data);
      }
    };
    fetch();
  }, []);

  const handleWhatsappReseller = () => {
    const generateOrder = () => {
      let resultItem = ''
      paymentData.summary.carts[0].orders.forEach((res, index) => {
        res.items.forEach((resItem, indexItem) => {
          resultItem =
            resultItem +
            `%28%20_${
              index + 1
            }_%20%29-%28%20_${
              indexItem + 1
            }_%20%29%0D%0A%E2%96%AA%EF%B8%8F%20Nama%20Produk%20%3A%20${encodeURI(
              resItem.sku.product.name
            )}%0D%0A%E2%96%AA%EF%B8%8F%20Link%20Produk%20%3A%20${encodeURI(
              `https://app.idejualan.com/store/${reseller}/${resItem.sku.product.slug}`
            )}%0D%0A%E2%96%AA%EF%B8%8F%20Varian%20%3A%20${encodeURI(
              resItem.sku.properties.color + "," + resItem.sku.properties.size
            )}`;
        })
      });
      return resultItem;
    };

    const getTotalPrice = () => {
      let result = paymentData.summary.items_amount
      return result;
    };

    window.open(
      `https://wa.me/${dataStoreFront.owner.phone}?text=Hai%20Kak%20Saya%20Sudah%20Order.%0D%0A%0D%0ANama%20%3A%20${encodeURI(
        paymentData.summary.carts[0].customer.name
      )}%0D%0AAlamat%20%3A%20${encodeURI(
        paymentData.summary.carts[0].customer.address
      )}%20%0D%0A%0D%0A%2AOrder%2A%20%3A%20%0D%0A${generateOrder()}%0D%0A%2ATotal%2A%20%2AHarga%2A%20%3A%20Rp.%20${getTotalPrice()}%0D%0ABelum%20termasuk%20ongkos%20kirim`,
      "_blank"
    );

  };

  return (
    <>
      <Container maxWidth="sm">
        <Box py={10}>
          {paymentData && (
            <Paper elevation={0}>
              <Box textAlign={"center"} px={2} py={4}>
                <Typography variant="subtitle1">
                  Harap melakukan pembayaran sebelum{" "}
                  <b>
                    {moment(paymentData.token_expire_at).format(
                      "DD MMM YYYY hh:mm"
                    )}
                  </b>
                  , jika melebihi waktu yang ditentukan, maka pembayaran
                  otomatis dibatalkan
                </Typography>
              </Box>
            </Paper>
          )}
          <Box
            mt={2}
            p={3}
            borderRadius="8px 8px 0 0"
            bgcolor="#3fbfc5"
            color="#fff"
          >
            <Typography component="span">
              <b>Metode Transfer</b>
            </Typography>{" "}
            <Typography variant="subtitle2" component="span">
              (Pengecekan 10-30 menit)
            </Typography>
          </Box>
          <Box>
            {vendorBanks.map((res) => (
              <ExpansionPanel>
                <ExpansionPanelSummary>
                  <Grid container spacing={2}>
                    <Grid item>
                      <img src={res.bank.icon} style={{ width: 48 }} />
                    </Grid>
                    <Grid item>{res.bank.name}</Grid>
                  </Grid>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ display: "block" }}>
                  <Box>
                    <Typography variant="caption">{res.bank.name}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="subtitle2">
                      {res.account_name}
                    </Typography>
                  </Box>
                  <Box
                    my={2}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    bgcolor={"#f6f6f6"}
                    py={2}
                    px={1}
                  >
                    <Typography>
                      <b>{res.account_number}</b>
                    </Typography>
                  </Box>
                  <Divider />
                  {paymentData && (
                    <>
                      <Box
                        my={2}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography variant="caption" component="div">
                          Subtotal
                        </Typography>
                        <Typography variant="caption" component="div">
                          <b>
                            Rp
                            {formatRupiah(
                              paymentData?.summary?.items_amount || 0
                            )}
                          </b>
                        </Typography>
                      </Box>
                      <Box
                        my={2}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography variant="caption" component="div">
                          Admin fee
                        </Typography>
                        <Typography variant="caption" component="div">
                          <b>
                            Rp
                            {formatRupiah(paymentData?.summary?.admin_fee || 0)}
                          </b>
                        </Typography>
                      </Box>
                      <Box
                        my={2}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography variant="caption" component="div">
                          Ongkir
                        </Typography>
                        <Typography variant="caption" component="div">
                          <b>
                            Rp
                            {formatRupiah(
                              paymentData?.summary?.shipping_cost || 0
                            )}
                          </b>
                        </Typography>
                      </Box>
                      <Box
                        my={2}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography variant="caption" component="div">
                          Kode unik
                        </Typography>
                        <Typography variant="caption" component="div">
                          <b>
                            Rp
                            {formatRupiah(
                              paymentData?.summary?.code_unique || 0
                            )}
                          </b>
                        </Typography>
                      </Box>
                      <Typography variant="caption" component="div">
                        <b>Total Nominal Transfer</b>
                      </Typography>
                      <Box
                        mb={1}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        bgcolor={"#f6f6f6"}
                        py={2}
                        px={1}
                      >
                        <Typography>
                          <b>
                            Rp{formatRupiah(paymentData?.summary?.amount || 0)}
                          </b>
                        </Typography>
                      </Box>
                      <Typography variant="caption" component="div">
                        <b>Catatan</b> Harap transfer sesuai nominal yang
                        tertera beserta kode unik, pemesanan tidak akan diproses
                        tanpa kode unik
                      </Typography>
                      <br />
                      <Divider />
                      <Typography variant="subtitle2">
                        <b>PAYMENT ID #{paymentData?.id}</b>
                      </Typography>
                    </>
                  )}
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ))}
          </Box>
          <Box mt={2}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              style={{ color: "#fff" }}
              onClick={() => setIsOpenDialog(true)}
            >
              Kirim bukti transfer
            </Button>
          </Box>
          <Box mt={2}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              style={{ color: "#fff" }}
              onClick={() => history.push('/')}
            >
              Kembali ke Home
            </Button>
          </Box>
        </Box>
      </Container>
      <Dialog open={isOpenDialog} fullWidth maxWidth="sm">
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => {
              setIsOpenDialog(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box textAlign="center">
            <Typography variant="subtitle2">
              Bukti transfer dapat berupa informasi transfer dari m-Banking,
              e-Banking, struk atm, dan lainnya yang bersifat informatif
            </Typography>
          </Box>
          <br/><br/>
          <Box>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              style={{ color: "#fff" }}
              onClick={handleWhatsappReseller}
            >
              Whastapp Penjual
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default VendorBankCheckoutCustomer;
