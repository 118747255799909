import React, { Fragment } from "react";
import { Avatar, Typography, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    display: "flex",
    alignItems: "center",
    paddingTop: 20,
  },
  notFound: {
    height: window.screen.height - 220,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const Vendor = (props) => {
  const classes = useStyles();
  const { vendorSearched, history } = props;

  console.log("vendorSearched => ", vendorSearched);
  return (
    <Fragment>
      {vendorSearched.data.length === 0 ? (
        <div className={classes.notFound}>
          <div style={{ textAlign: "center" }}>
            <FindInPageIcon />
            <Typography>Data tidak ditemukan.</Typography>
          </div>
        </div>
      ) : (
        <Container maxWidth="lg">
          {vendorSearched.data.map((res, index) => (
            <div key={index} className={classes.cardWrapper}>
              <Avatar
                alt={res.name}
                src={res.avatar_url}
                style={{ width: 75, height: 75 }}
                onClick={() => history.push(`/profile/${res.slug}`)}
              />
              <div style={{ paddingLeft: 20 }}>
                <Typography
                  style={{ fontWeight: "bold" }}
                  onClick={() => history.push(`/profile/${res.slug}`)}
                >
                  {res.name}
                </Typography>
                <div>
                  <Typography variant="caption">
                    <b style={{ paddingRight: 5 }}>
                      {res.stats.products_count}
                    </b>
                    Produk
                  </Typography>
                  <Typography variant="caption" style={{ paddingLeft: 20 }}>
                    <b style={{ paddingRight: 5 }}>
                      {res.stats.followers_count}
                    </b>
                    Pengikut
                  </Typography>
                </div>
                <Typography variant="caption">
                  {res?.location?.village?.name}
                </Typography>
              </div>
            </div>
          ))}
        </Container>
      )}
    </Fragment>
  );
};

export default withRouter(Vendor);
