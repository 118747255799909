import { axiosInstance } from "../config/fetchAxios";

export const GET_ALL_VOUCHERS = (params) => {
  const url = `/api/v2/vouchers`;
  const response = axiosInstance.get(url, { params });
  return response;
};

export const POST_VOUCHER = (body) => {
  const url = `/api/v2/my-vouchers`;
  const response = axiosInstance.post(url, body);
  return response;
};

export const CLAIM_VOUCHER = (id) => {
  const url = `/api/v2/vouchers/${id}/claim`;
  const response = axiosInstance.post(url, {});
  return response;
};
