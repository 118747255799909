import { Breadcrumbs, Container, Link, Typography } from '@material-ui/core'
import React, { Fragment, useEffect, useState } from 'react'
import Footer from '../../components/footer'
import Navbar from '../../components/navbar'
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import InfiniteScroll from 'react-infinite-scroll-component'
import { getBrandPopuler } from '../../services/axios';
import { useHistory } from "react-router-dom";

const BrandPage = () => {
    const [brand, setbrand] = useState([])
    const [page, setPage] = useState(1)
    const history = useHistory();

    useEffect(() => {
      const getData = async() => {
        const allData = await getBrandPopuler()
        setbrand(allData.data);
      }

      getData()
    }, [])
    
    const fetchMoreData = () => {
        const getData = async() => {
            const allData = await getBrandPopuler(page+1)
            setbrand([...brand, ...allData.data]);
        }
    
            getData()
            setPage(page+1)
    }
    

    return (
        <>
        <Navbar variant="primary"/>
            <Container
            maxWidth="md"
            style={{ paddingTop: 40, paddingBottom: 40 }}
            >

                <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
                style={{ fontSize: 12 }}
                >
                    <Link color="inherit" href="/">
                        Home
                    </Link>
                    <Typography color="textPrimary" style={{ fontSize: 16 }}>
                        Brand Populer
                    </Typography>
                </Breadcrumbs>

                <br/>

                <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start'}}>
                    {
                        brand.map((res, index) =>
                            <div key={index} style={{marginBottom: '10px', marginRight: '20px', maxWidth: '100px'}}>
                                <a href={`/profile/${res.slug}`} style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', textDecoration: 'none', textAlign: 'center'}}>
                                    <img src={res.avatar_url} alt={res.name} style={{ borderRadius: '48%', width: '100px', padding: '10px', backgroundColor: '#f4f4f4', cursor: 'pointer', margin: '10px' }}/>
                                    <p style={{ color: 'black'}}>{res.name}</p>
                                </a>
                            </div>
                        )
                    }

                        <InfiniteScroll
                            dataLength={brand.length}
                            next={fetchMoreData}
                            style={{
                                overflow: 'none',
                            }}
                            hasMore={true}
                        >

                        </InfiniteScroll>
                </div>

            </Container>
        <Footer/>
        </>
    )
}

export default BrandPage