const style = (theme) => ({
  avatarCover: {
    width: '100%',
    height: 300,
    objectFit: 'cover',
    filter: 'blur(3px)'
  },
  containerStore: {
    position: 'relative'
  },
  containerNameStore: {
    top: 100,
    position: 'absolute',
    width: '100%',
    textAlign: 'center',
    "& :nth-child(1)": {
      color: 'white',
      fontSize: '2.5rem',
      fontWeight: 'bold'
    },
    "& :nth-child(2)": {
      color: 'white',
      fontSize: '1rem',
      width: 150,
      height: 100,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      margin: 'auto',
    },
  },
  containerProdut: {
    position: 'absolute',
    marginTop: '-20px',
    backgroundColor: 'white',
    padding: 15,
    width: '100%',
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
  }
  // card: {
  //   backgroundColor: "#f5f5f5",
  //   padding: "15px 10px",
  //   width: "100%",
  //   display: "flex",
  //   alignItems: "center",
  // },
  // photoProfile: {
  //   height: 75,
  //   width: 75,
  //   borderRadius: "50%",
  //   border: `2px solid ${theme.palette.yellow.primary}`
  // },
  // formControl: {
  //   marginLeft: 16,
  //   width: 200,
  // },
  // title: {
  //   fontWeight: 600,
  //   overflow: "hidden",
  //   display: "-webkit-box",
  //   boxOrient: "vertical",
  //   lineClamp: 2,
  //   paddingTop: 10,
  //   height: 55,
  // },
  // priceDiscount: {
  //   fontSize: "0.9rem",
  //   color: "#555555",
  //   paddingTop: 10,
  //   display: "flex",
  //   alignItems: "center",
  // },
  // pagination: {
  //   padding: 16,
  //   display: "flex",
  //   justifyContent: "center",
  // },
  // shareSection: {
  //   display: "flex",
  //   alignItems: "center",
  //   padding: "20px 0",
  // },
  // linkCopy: {
  //   color: "#555555",
  //   opacity: 0.65,
  //   overflow: "hidden",
  //   display: "-webkit-box",
  //   boxOrient: "vertical",
  //   lineClamp: 1,
  //   width: "fit-content",
  //   paddingTop: 5,
  //   paddingLeft: 10,
  //   paddingRight: 10,
  // },
  // socialIcon: {
  //   outline: "none",
  // },
  // salinWrapper: {
  //   display: "flex",
  // },
  // salin: {
  //   cursor: "pointer",
  //   backgroundColor: "#e5e5e5",
  //   padding: "5px 20px",
  //   borderRadius: "0 5px 5px 0",
  // },
  // customerCart: {
  //   display: "flex",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   borderRadius: 15,
  //   backgroundColor: theme.palette.primary.main,
  //   position: "fixed",
  //   height: 60,
  //   width: 60,
  //   bottom: 0,
  //   right: 0,
  //   marginBottom: 10,
  //   marginRight: 10,
  //   cursor: "pointer",
  // },
  // nameStore: {
  //   fontFamily: "dmsans-bold",
  //   fontSize: '20px'
  // }
});

export default style;
