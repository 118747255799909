import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const LoginSocialMedia = (props) => {
  const history = useHistory();
  const testResponse = new RegExp("error");
  const res = testResponse.test(props.location.search);
  const getResponse = () => {
    if (res) {
      return props.location.search.slice(7);
    } else {
      return props.location.search.slice(10);
    }
  };
  const extract = JSON.parse(atob(getResponse()));
  if (res === false) {
    window.localStorage.setItem("USER_DATA", JSON.stringify(extract.data));
    window.localStorage.setItem("TOKEN", extract.access_token);
  } else {
    window.localStorage.setItem(
      "LOGIN_WITH_SOCIAL_MEDIA",
      JSON.stringify(extract)
    );
  }
  if (
    window.localStorage.getItem("USER_DATA") ||
    window.localStorage.getItem("LOGIN_WITH_SOCIAL_MEDIA")
  )
    history.push("/login");

  const handleClose = () => {
    history.push("/login");
  };

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress color="primary" />
      </div>
      <Dialog
        open={res && extract.code !== "unactivated"}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Idejualan"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {extract.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default LoginSocialMedia;
