import React from "react";

import {
  Grid,
  Typography,
  Card,
  Chip,
  Avatar,
  Box,
  Button,
} from "@material-ui/core";
import swal from "@sweetalert/with-react";

import { numberSeparator } from "../../../utils/numberSeparator";
import ReactMidtrans from "../../../config/midtrans";

import { getPayments, cancelOrder } from "../../../services/order.service";
import { checkout } from "../../../services/axios";

const WaitingPayment = (props) => {
  const { order, refetch } = props;
  const [items, setItems] = React.useState([]);
  const [midtrans, setMidtrans] = React.useState({
    clientKey: "SB-Mid-client-xYSFi8v3uCnhGICn",
    token: "",
  });

  React.useEffect(() => {
    handlePayments();
  }, []);

  const handlePayments = async () => {
    const res = await getPayments({
      ["filter[id]"]: order.payment.id,
      ["filter[amount]"]: order.payment.amount,
      ["filter[reseller.name]"]: order.reseller.name,
      ["filter[reseller.email]"]: order.reseller.email,
      ["filter[reseller.phone]"]: order.reseller.phone,
      ["filter[status]"]: order.payment.status,
      ["filter[payment_type]"]: order.payment.payment_type,
    });
    setItems(res.data);
  };

  const handleCancelOrder = async (id) => {
    try {
      swal({
        title: "Apakah anda yakin?",
        text: "Untuk membatalkan pesanan ini",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          swal({
            text: "Masukkan catatan pembatalan",
            icon: "warning",
            content: {
              element: "input",
              attributes: {
                placeholder: "catatan pembatalan",
                type: "text",
              },
            },
            buttons: {
              cancel: "lewati",
              confirm: "Ya",
            },
            dangerMode: true,
          }).then(async (note) => {
            const params = {
              data: {
                cancel_note: note ?? "",
              }
            };
            const res = await cancelOrder(id, params);
            swal("Pesanan berhasil dibatalkan", {
              icon: "success",
            }).then(() => refetch());
          });
        }
      });
    } catch (error) {
      console.log(error);
      swal("Error", "Pesanan gagal dibatalkan", "error");
    }
  };

  const getNewToken = async () => {
    const data = [];

    if (items) {
      const payload = {
        customer: {},
        orders: [],
      };
      items.forEach((item) => {
        if (item.summary && item.summary.carts) {
          item.summary.carts.forEach((cart) => {
            payload.customer = {
              name: cart.customer.name,
              address: cart.customer.address,
              postal_code: cart.customer.postal_code,
              village_id:
                cart.customer.village_id || cart.customer.subdistrict_id.id,
            };

            if (cart.orders) {
              cart.orders.forEach((order) => {
                if (order.items) {
                  const products = [];

                  payload.orders.push({
                    supplier_id: order.supplier.id,
                    courier: order.shipping.courier.code,
                    courier_type: order.shipping.courier_type,
                    courier_estimated: order.shipping.estimated,
                    shipping_cost: order.shipping.cost,
                    customer_note: order.customer_note,
                    products,
                  });
                  order.items.map((item) => {
                    if (item.sku) {
                      products.push({
                        sku_code: item.sku.code,
                        qty: item.qty || 1,
                      });
                    }
                  });
                }
              });
            }
          });
        }
      });
      data.push(payload);
    }

    const response = await checkout({ data: data });
    setMidtrans({ ...midtrans, token: response.data.token });
    return response.data.token;
  };

  return (
    <Card key={order.id} style={{ marginBottom: 16 }} variant="outlined">
      <Box
        bgcolor="#f9bb16"
        display="flex"
        alignItems="center"
        justifyContent="center"
        color="#fff"
        fontWeight={600}
        p={1}
      >
        Payment ID: #{order.payment.id}
      </Box>
      <Box
        bgcolor="#3fbfc5"
        width="fit-content"
        color="#fff"
        fontWeight={600}
        p={1}
        px={2}
      >
        Order ID: #{order.id}
      </Box>
      <Box p={2}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center" gridGap={6}>
            <Avatar
              src={order.supplier.avatar_url}
              style={{ width: 36, height: 36 }}
            />
            <Typography>{order.supplier.name}</Typography>
          </Box>
          <Typography>
            Status: {order.histories[order.histories.length - 1].message}
          </Typography>
        </Box>

        <Box height={2} mt={1} mb={2} bgcolor="#F7F7F7" />
        {items.map(({ summary }) => {
          return summary.carts.map(({ orders }) => {
            return orders.map((order) => {
              return order.items.map(
                ({ id, sku, bundling, reseller_price_idr, qty }) => {
                  if (sku) {
                    return (
                      <Box mb={2} key={id}>
                        <Grid container spacing={2}>
                          <Grid item xs={8}>
                            <Typography>{sku.product.name}</Typography>
                            {sku.properties && (
                              <Box mt={1} mb={2}>
                                <Chip
                                  variant="outlined"
                                  label={sku.properties.color}
                                  disabled
                                  size="small"
                                />{" "}
                                <Chip
                                  variant="outlined"
                                  label={sku.properties.material}
                                  disabled
                                  size="small"
                                />{" "}
                                <Chip
                                  variant="outlined"
                                  label={sku.properties.size}
                                  disabled
                                  size="small"
                                />
                              </Box>
                            )}
                            <Typography
                              color="primary"
                              component="span"
                              variant="h6"
                            >
                              Rp.{numberSeparator(reseller_price_idr || 0)}
                            </Typography>
                            {"   x   "}
                            <Typography
                              component="span"
                              color="textSecondary"
                              variant="h6"
                            >
                              {qty}
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Avatar
                              src={sku.image_url}
                              style={{ height: 64 }}
                              variant="square"
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    );
                  }
                  if (bundling) {
                    return bundling.items.map(({ name, sku }) => {
                      return (
                        <Box mb={2} key={id}>
                          <Grid container spacing={2}>
                            <Grid item xs={8}>
                              <Typography>{name}</Typography>
                              {sku.properties && (
                                <Box mt={1} mb={2}>
                                  <Chip
                                    variant="outlined"
                                    label={sku.properties.color}
                                    disabled
                                    size="small"
                                  />{" "}
                                  <Chip
                                    variant="outlined"
                                    label={sku.properties.material}
                                    disabled
                                    size="small"
                                  />{" "}
                                  <Chip
                                    variant="outlined"
                                    label={sku.properties.size}
                                    disabled
                                    size="small"
                                  />
                                </Box>
                              )}
                              <Typography
                                color="primary"
                                component="span"
                                variant="h6"
                              >
                                Rp.{numberSeparator(reseller_price_idr || 0)}
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Avatar
                                src={sku.image}
                                style={{ height: 64 }}
                                variant="square"
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      );
                    });
                  }
                }
              );
            });
          });
        })}
      </Box>
      <Box mb={2} height={2} bgcolor="#F7F7F7" />
      <Box display="flex" alignItems="flex-end" flexDirection="column" px={2}>
        <Typography variant="caption">Jumlah harus dibayar</Typography>
        <Typography variant="h6" style={{ color: "red" }}>
          Rp.{numberSeparator(order.payment.amount || 0)}
        </Typography>
      </Box>
      <Grid container>
        <Grid item xs={6}>
          <ReactMidtrans getToken={getNewToken} clientKey={midtrans.clientKey}>
            <Button variant="contained" fullWidth color="primary">
              Bayar
            </Button>
          </ReactMidtrans>
        </Grid>
        <Grid item xs={6}>
          <Button
            onClick={() => handleCancelOrder(order.payment.id)}
            variant="contained"
            fullWidth
            color="secondary"
          >
            Batalkan
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
};

export default WaitingPayment;
