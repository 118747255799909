import { Button, Grid } from '@material-ui/core'
import React, {useState, useEffect} from 'react'
import { getBestSellerProduct, getNewProduct } from '../../services/axios'
import Carousel from '../carousel'

const TerbaruTerlaris = ({variant}) => {
    const [terbaru, setterbaru] = useState([])
    const [terlaris, setterlaris] = useState([])
    const [isTerbaru, setisTerbaru] = useState(true)
    const myStyle = {
        borderBottom: '2px solid orange',
        borderRadius: 0,
        width: '50%'
    }

    useEffect(() => {
        const getData = async() => {
            const dataTerbaru = await getNewProduct()
            const dataTerlaris = await getBestSellerProduct()
            setterbaru(dataTerbaru.data);
            setterlaris(dataTerlaris.data);
        }

        getData()
    }, [])
    
    const handleFilter = (data) => {
        if (data === true) {
            setisTerbaru(true);
        }else{
            setisTerbaru(false)
        }
    }

  return (
    <div style={{ backgroundColor: '#fff', padding: '20px 30px 0 30px', borderRadius: '10px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '20px' }}>
            <Button color={isTerbaru ? 'primary' : undefined} style={isTerbaru ? myStyle : {width: '50%'}} onClick={ () => handleFilter(true) }>Terbaru</Button>
            <Button color={isTerbaru ? undefined : 'primary'} style={isTerbaru ? {width: '50%'} : myStyle} onClick={ () => handleFilter(false) }>Terlaris</Button>
        </div>
        <Grid
            container
            spacing={5}
            style={{ padding: "20px 0 70px 0" }}
            >
            {
                isTerbaru ? <Carousel variant={variant} data={terbaru} /> : <Carousel variant={variant} data={terlaris} />
            }
        </Grid>
    </div>
  )
}

export default TerbaruTerlaris