import { Container, Box, Chip, Grid, Breadcrumbs, Link } from '@material-ui/core'
import React, { Fragment, useEffect, useState } from 'react'
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { withStyles } from "@material-ui/core/styles"
import { useHistory } from "react-router-dom";
import styles from "./styles"
import Footer from '../../components/footer'
import Navbar from '../../components/navbar'
import { getAllMyVouchers } from '../../services/axios'
import InfiniteScroll from 'react-infinite-scroll-component'
import iconIdejualan from "../../assets/icons/iconIdejualan.svg";
import { numberSeparator } from "../../utils/numberSeparator";
import moment from 'moment'
import swal from "@sweetalert/with-react";
import NeedLoginSwal from '../../components/needLoginSwal';

const MyVoucherPage = withStyles(styles)((props) => {
    const { classes } = props;
    let history = useHistory();
    const [filter, setfilter] = useState("")
    const [voucher, setVoucher] = useState([])
    const [page, setPage] = useState(1)
    const isLogin = localStorage.getItem("TOKEN");

    useEffect(() => {
        const getData = async () => {
            const allData = await getAllMyVouchers(filter, page);
            setVoucher(allData.data);
        }

        getData()
    }, []);

    const handleFilter = (myFilter, myPage) => {
        setVoucher([])
        setfilter(myFilter)
        setPage(myPage)
        const getData = async () => {
            const allData = await getAllMyVouchers(myFilter, myPage);
            setVoucher(allData.data);
        }

        getData()
    }

    const fetchMoreData = () => {
        const getData = async () => {
            const allData = await getAllMyVouchers(filter, page + 1);
            setVoucher([...voucher, ...allData.data]);
        }

        getData()
        setPage(page + 1)
    }

    const handleDetailVoucher = (data) => {
        swal(
            <div>
                <div style={{ backgroundColor: 'orange' }}>
                    <h2 style={{ color: 'white', paddingBottom: '60px' }}>Detail Kupon</h2>
                </div>
                <div>
                    <div
                        style={{
                            margin: '11px',
                            width: '410px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                            backgroundColor: 'white',
                            padding: '10px',
                            borderRadius: '10px',
                            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                            position: 'relative',
                            top: '-62px'
                        }}
                    >
                        {
                            data.supplier ?
                                <img src={data.supplier.avatar_url} alt={data.name} style={{ width: '70px', height: '70px', borderRadius: '10px' }} />
                                :
                                <img src={iconIdejualan} alt={data.name} style={{ width: '70px', height: '70px', borderRadius: '10px' }} />
                        }
                        <div style={{ padding: '0 10px 0 10px' }}>
                            <p>
                                {data.name}
                            </p>
                            <p style={{ color: '#52C1C7' }}>
                                {
                                    data.supplier ?
                                        data.supplier.name :
                                        <>Admin Ide Jualan</>
                                }
                            </p>
                        </div>
                        <div style={{ color: 'orange' }}>
                            {data.type}
                        </div>
                    </div>
                    <div style={{
                        position: 'relative',
                        top: '-49px',
                        textAlign: 'start'
                    }}>
                        <h5>Masa Berlaku</h5>
                        <p>{moment(data.start_date).format("MMM Do YY")} - {moment(data.end_date).format("MMM Do YY")}</p>

                        <h5>Jumlah Potongan</h5>
                        <p>Rp {numberSeparator(data.amount)}</p>

                        <h5>Minimum Pembelian</h5>
                        <p>Rp {numberSeparator(data.minimum_payments)}</p>

                        <h5>Berlaku Di</h5>
                        <p>{
                            data.dompetjualan ?
                                <>Dompet Jualan</> :
                                <>Semua Metode Pembayaran</>
                        }</p>

                        <h5>Kupon Berbayar</h5>
                        <p>{
                            data.is_purchasable ?
                                <>Berbayar</> :
                                <>Tidak</>
                        }</p>
                    </div>
                </div>
            </div>
            ,
            {
                buttons: false
            }
        )
    }

    React.useEffect(() => {
        if (isLogin === null) {
            swal(
                <NeedLoginSwal />
                , {
                    closeOnClickOutside: false,
                    buttons: false
                })
        }
    }, [isLogin])

    return (
        <Fragment>
            <Navbar variant="primary" />
            <Container maxWidth="md" className={classes.container}>
                <Box mb={2}>
                    <Grid container spacing={2}>
                        {/* Semua */}
                        <Grid item>
                            <Chip
                                label="Semua"
                                color={filter === '' ? 'primary' : "default"}
                                onClick={() => handleFilter("", 1)}
                            />
                        </Grid>

                        {/* Gratis Ongkir */}
                        <Grid item>
                            <Chip
                                label="Gratis Ongkir"
                                color={filter === 'ongkir' ? 'primary' : "default"}
                                onClick={() => handleFilter("ongkir", 1)}
                            />
                        </Grid>

                        {/* Cashback */}
                        <Grid item>
                            <Chip
                                label="Cashback"
                                color={filter === 'cashback' ? 'primary' : "default"}
                                onClick={() => handleFilter("cashback", 1)}
                            />
                        </Grid>

                        {/* Discount */}
                        <Grid item>
                            <Chip
                                label="Discount"
                                color={filter === 'discount' ? 'primary' : "default"}
                                onClick={() => handleFilter("discount", 1)}
                            />
                        </Grid>
                    </Grid>

                    <br />

                    <Breadcrumbs
                        separator={<NavigateNextIcon fontSize="small" />}
                        aria-label="breadcrumb"
                        style={{ fontSize: 12 }}
                    >
                        <p
                            style={{
                                cursor: 'pointer'
                            }}
                            onClick={() => {
                                history.push(`/`);
                            }}
                        >
                            Home
                        </p>

                        <p
                            style={{
                                cursor: 'pointer'
                            }}
                            onClick={() => {
                                history.push(`/voucher`);
                            }}
                        >
                            Voucher
                        </p>

                        <p>
                            My Voucher
                        </p>

                    </Breadcrumbs>

                    <br />

                    <div
                        className={classes.containerMyVoucher}
                    >
                        {
                            voucher &&
                            voucher.map((res, index) => (
                                <div
                                    key={index + 'voucher'}
                                    style={{ margin: '11px', width: '410px', display: 'flex', alignItems: 'center', justifyContent: 'start', backgroundColor: 'white', padding: '10px', borderRadius: '10px', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}
                                    onClick={
                                        () => handleDetailVoucher(res)
                                    }
                                >
                                    {
                                        res.supplier ?
                                            <img src={res.supplier.avatar_url} alt={res.name} style={{ width: '115px', height: '115px', borderRadius: '10px' }} />
                                            :
                                            <img src={iconIdejualan} alt={res.name} style={{ width: '115px', height: '115px', borderRadius: '10px' }} />
                                    }
                                    <div style={{ padding: '0 10px 0 10px' }}>
                                        <p>
                                            {res.name}
                                        </p>
                                        <p style={{ color: 'orange', margin: '0' }} variant="caption">
                                            Rp {numberSeparator(res.amount)}
                                        </p>
                                        <p style={{ color: '#EE4236', margin: '0' }}>
                                            Min. Belanja Rp {numberSeparator(res.minimum_payments)}
                                        </p>
                                        <p style={{ color: '#52C1C7', margin: '0' }}>
                                            Berlaku hingga {moment(res.end_date).format("MM D YY")}
                                        </p>
                                    </div>
                                </div>
                            ))
                        }

                        <InfiniteScroll
                            dataLength={voucher.length}
                            next={fetchMoreData}
                            style={{
                                overflow: 'none',
                            }}
                            hasMore={true}
                        >

                        </InfiniteScroll>
                    </div>
                </Box>
            </Container>
            <Footer />
        </Fragment>
    )
})

export default MyVoucherPage