import styled from 'styled-components'

export const WrapBodyModal = styled.div`
  .paper {
    position: absolute;
    width: 90%;
    margin: 0 auto;
    background-color: #FFF;
    padding: 16px;
    border-radius: 10px;
    outline: none;
    top: 5%;
    left: 0;
    right: 0;
  }
  .bodyModal {
    border: none;
  }
  .headModal {
    text-align: center;
  }
  button {
    outline: 0;
    border: 0;
    background: #FBBD1C;
    border-radius: 5px;
    font-size: 16px;
    color: #FFF;
    font-family: 'DMSans-Bold';
    letter-spacing: 0.02em;
    padding: 17px;
    text-align: center;
    width: 100%;
    :disabled {
      background: #f9db8c;
    }
  }
`