const styles = (theme) => ({
    container: {
      paddingTop: 10,
      paddingBottom: 60,
    },
    containerVoucher: {
      display: 'flex', 
      flexWrap: 'wrap', 
      justifyContent: 'center',
    }
    
  });
  
  export default styles;