const style = (theme) => ({
  icon: {
    height: 25,
    width: 25,
    backgroundColor: "grey",
  },
  iconClicked: {
    height: 25,
    width: 25,
    backgroundColor: theme.palette.primary.main,
  },
  iconTab: {
    paddingRight: 5,
    marginBottom: "0 !important",
  },
  input: {
    padding: 10,
  },
});

export default style;
