const stylesContent = (theme) => ({
  textField: {
    width: "100%",
  },
  inputGroup: {
    width: (props) => (props.desktop ? 445 : "100%"),
  },
  title: {
    marginBottom: 20,
    fontSize: 18,
  },
  textChekbox: {
    fontSize: (props) => (props.desktop ? 15 : 11),
    padding: "0 12px",
  },
  link: {
    color: "#FF97B0",
    cursor: "pointer",
  },
  signupButton: {
    margin: "10px 0 15px",
    fontSize: "1rem",
    color: "#fff",
    backgroundColor: "#F4BC2B",
    width: "100%",
    border: "1px solid #F4BC2B",
    padding: "6px 16px",
    borderRadius: 5,
    cursor: "pointer",
    outline: "none",
  },
  icon: {
    height: 20,
  },
  buttonGoogle: {
    width: "100%",
    border: "1px solid #FF97B0",
    padding: "3px 8px",
    borderRadius: 5,
    background: "#fff",
    cursor: "pointer",
    outline: "none",
  },
  buttonFacebook: {
    width: "100%",
    padding: "3px 8px",
    borderRadius: 5,
    background: "#6095FB",
    border: "1px solid #6095FB",
    marginTop: 10,
    color: "#fff",
    cursor: "pointer",
    outline: "none",
  },
  haveAccount: {
    textAlign: "center",
    color: "#999999",
    marginBottom: 15,
  },
  grid: {
    display: "flex",
    justifyContent: "center",
  },
  hr: {
    border: "1px solid #999999",
  },
  or: {
    display: "flex",
    justifyContent: "center",
    color: "#999999",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  buttonModal: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: 30,
  },
});

const stylesDesktop = (theme) => ({
  container: {
    height: "100vh",
  },
  containerImg: {
    height: "100vh",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    background:
      "linear-gradient(270deg, rgba(13,106,255,0) 0%, rgba(241,91,204,0) 51%, rgba(247,210,221,1) 58%)",
  },
  containerContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  vector: {
    height: 500,
    position: "absolute",
    marginRight: 130,
  },
  logo: {
    position: "absolute",
    marginLeft: 55,
    marginTop: 22,
  },
});

const stylesMobile = (theme) => ({
  imageVector: {
    textAlign: "center",
    marginTop: -120,
    marginBottom: 30,
  },
  logo: {
    position: "absolute",
    width: "100%",
    textAlign: "center",
  },
});

export { stylesContent, stylesDesktop, stylesMobile };
