const style = (theme) => ({
    searchWrapper: {
        display: "flex",
        alignItems: "center",
        borderRadius: "10px",
        backgroundColor: '#e0e0e0',
        width: "fit-content",
        padding: "0 10px",
    },
    SearchIcon: {
        fill: "#bbbbbb",
    },
});

export default style;
