const style = (theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20
    },
    containerDescript: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        '& img': {
            width: 100,
            borderRadius: 10,
            marginRight: 3
        }
    }
});

export default style;
