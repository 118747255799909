import React,{useState, useEffect} from 'react'

// icon
import IconLogo from '../../../assets/icons/iconIdejualan.svg'
import CloseIcon from "@material-ui/icons/Close";

const PopupDeeplink = ({url}) => {
    const [isClose, setisClose] = useState(true)
    const UA = navigator.userAgent.toLowerCase();
    
    const handlDeeplink = () => {
        if (url === '/') {
            setTimeout(function() {
                if (document.hasFocus()) {
                    window.location = 'https://play.google.com/store/apps/details?id=com.idejualan.appsreseller';
                }
            }, 2000);
            
            window.location = 'idejualan://home';
        }else if(url.split('/')[1] === 'product'){
            setTimeout(function() {
                if (document.hasFocus()) {
                    window.location = 'https://play.google.com/store/apps/details?id=com.idejualan.appsreseller';
                }
            }, 2000);
            
            window.location = `idejualan://product?slug=${url.split('/')[2]}`;
        }else if (url.split('/')[1] === 'profile') {
            setTimeout(function() {
                if (document.hasFocus()) {
                    window.location = 'https://play.google.com/store/apps/details?id=com.idejualan.appsreseller';
                }
            }, 2000);
            
            window.location = `idejualan://supplier?slug=${url.split('/')[2]}`;
        }else if (url.split('/')[1] === 'voucher') {
            setTimeout(function() {
                if (document.hasFocus()) {
                    window.location = 'https://play.google.com/store/apps/details?id=com.idejualan.appsreseller';
                }
            }, 2000);
            
            window.location = `idejualan://voucher?id=${url.split('/')[2]}`;
        }else if(url.split('/')[1] === 'daily-login'){
            setTimeout(function() {
                if (document.hasFocus()) {
                    window.location = 'https://play.google.com/store/apps/details?id=com.idejualan.appsreseller';
                }
            }, 2000);
            
            window.location = `idejualan://daily-login`;
        }else if(url.split('/')[1] === 'upgrade-reseller'){
            setTimeout(function() {
                if (document.hasFocus()) {
                    window.location = 'https://play.google.com/store/apps/details?id=com.idejualan.appsreseller';
                }
            }, 2000);
            
            window.location = `idejualan://upgrade-reseller`;
        }
    }

    useEffect(() => {
        if (UA.indexOf("android") > -1) {
            setisClose(false)
        }

        if ((document.referrer && !document.referrer.includes(window.location.origin))) {
            handlDeeplink()
        }
    }, [])
    

  return (
    <div style={isClose ? {display: 'none'} : {width: '100%', backgroundColor: 'black', position: 'fixed', top: 0, right: 0, color: 'white', zIndex: 1101, display:'flex', justifyContent:'center', alignItems: 'center', padding: 3}}>
        <div style={{display: 'flex', justifyContent: 'space-around', alignItems: 'center', backgroundColor: '#212121', borderRadius: 12, width: '80%', position:'relative'}}>
            <div onClick={() => setisClose(!isClose)} style={{backgroundColor: '#a5a5a5', padding: 5, borderRadius: '50%', display: 'flex', position: 'absolute', top: '-2px', left: '-13px'}}>
                <CloseIcon/>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{padding: 4, borderRadius: 12, backgroundColor: 'orange', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 5}}>
                    <img src={IconLogo} style={{width: 30, height: 30}} alt='logo'/>
                </div>
                <div>
                    <div>IDEJUALAN - Reseller Dropship</div>
                    <div style={{color: '#6F6F6F', fontSize: '.9rem'}}>Buka di App Idejualan</div>
                </div>
            </div>
            <div>
                <div onClick={handlDeeplink} style={{backgroundColor: 'blue', padding: 5, borderRadius: 12}}>Buka</div>
            </div>
        </div>
    </div>
  )
}

export default PopupDeeplink