import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Navbar from "../../../../components/navbar";
import { Box, FormControlLabel, Radio, RadioGroup, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        justifyContent: "space-between",
    },
}));

const OpsiPembayaran = (props) => {
    const classes = useStyles();
    const { paymentMethod, setPaymentMethod, handleClose } = props;

    return (
        <Fragment>
            <Navbar text="Pilih Opsi Pembayaran" onClick={() => handleClose(false)} />
            <Box className={classes.nested} mb={2}>
                <RadioGroup
                    value={paymentMethod}
                    onChange={async(e) => {
                        await setPaymentMethod(e.target.value)
                        handleClose(false)
                    }}
                >
                <FormControlLabel
                    value="midtrans"
                    control={<Radio />}
                    label={
                    <Typography variant="caption">MidTrans</Typography>
                    }
                />
                <FormControlLabel
                    value="vendorBank"
                    control={<Radio />}
                    label={
                    <Typography variant="caption">
                        Transfer Bank (dicek otomatis)
                    </Typography>
                    }
                />
                </RadioGroup>
            </Box>
        </Fragment>
    );
};

export default OpsiPembayaran;
