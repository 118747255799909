import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { Typography, List, ListItem, ListItemText } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    width: window.screen.width,
  },
  navbar: {
    background: "#FFFFFF",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
    display: "flex",
    justifyContent: "center",
  },
  navTitle: {
    padding: "20px 0",
    fontWeight: "bold",
  },
  closeIcon: {
    display: "flex",
    position: "absolute",
    right: 10,
    top: 20,
  },
}));

const Menu = ["Termurah", "Termahal", "Terbaru"];

const Sort = (props) => {
  const classes = useStyles();
  const { onClose, handleFilterSort } = props;

  const handleFilter = (response) => {
    handleFilterSort(response);
    onClose();
  };
  return (
    <Fragment>
      <div className={classes.container}>
        <div className={classes.navbar}>
          <Typography className={classes.navTitle}>Filter</Typography>
          <CloseIcon className={classes.closeIcon} onClick={onClose} />
        </div>
      </div>

      <List dense className={classes.root}>
        {Menu.map((response, index) => {
          const labelId = `checkbox-list-secondary-label-${response}`;
          return (
            <Fragment key={index}>
              <ListItem button>
                <ListItemText
                  id={labelId}
                  primary={response}
                  onClick={() => handleFilter(response)}
                />
              </ListItem>
              <hr />
            </Fragment>
          );
        })}
      </List>
    </Fragment>
  );
};

export default Sort;
