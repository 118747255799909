import React, { Fragment } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import ReceiptIcon from "@material-ui/icons/Receipt";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { isLoggedIn } from "../../../services/axios";
import { useAuthContext } from "../../../contexts/AuthContextProvider";

const useStylesMobile = makeStyles((theme) => ({
  header: {
    padding: 10,
    background: theme.palette.primary.main,
  },
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    marginLeft: theme.spacing(4),
  },
  hideOverflowX: {
    overflowX: "hidden",
  },
  logo: {
    height: 40,
  },
  icon: {
    height: 30,
    width: 30,
  },
}));

const SideBar = (props) => {
  const classes = useStylesMobile();
  const { history, onClose } = props;
  const { isConsumer } = useAuthContext()

  const handleLogout = () => {
    history.push("/login");
    localStorage.clear();
  };

  return (
    <Fragment>
      <Drawer anchor="left" open={props.open} onClose={onClose}>
        <div className={classes.hideOverflowX}>
          <div className={classes.header}>
            <img
              src={require("../../../assets/images/Brand.svg")}
              alt=""
              className={classes.logo}
            />{" "}
          </div>{" "}
          <List className={classes.root}>
           {
             !isConsumer && (
              <>
              <ListItem
              button
              onClick={() =>
                history.push(isLoggedIn() ? `/user/my-profile` : '/login')
              }
            >
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary="Akun Saya" />
            </ListItem>
            <ListItem
              button
              onClick={() =>
                history.push(isLoggedIn() ? `/store/${localStorage.getItem("STORE_FRONT")}` : '/login')
              }
            >
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary="Toko Saya" />
            </ListItem>
              </>
             )
           }
           
            <ListItem button onClick={() => history.push(`/category`)}>
              <ListItemIcon>
                <MenuIcon />
              </ListItemIcon>{" "}
              <ListItemText primary="Kategori" /> {}{" "}
            </ListItem>{" "}
            {}{" "}
            <ListItem button>
              <ListItemIcon>
                <ReceiptIcon />
              </ListItemIcon>{" "}
              <ListItemText primary="Konfirmasi Pembayaran" />
            </ListItem>{" "}
            <ListItem button onClick={() => history.push(`/cek-resi`)}>
              <ListItemIcon>
                <LocalShippingIcon />
              </ListItemIcon>{" "}
              <ListItemText primary="Cek Resi" />
            </ListItem>{" "}
            <ListItem button onClick={handleLogout}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>{" "}
              <ListItemText primary={ isLoggedIn() ? 'Logout' : 'Login' } />
            </ListItem>{" "}
          </List>{" "}
        </div>{" "}
      </Drawer>{" "}
    </Fragment>
  );
};

export default withRouter(SideBar);
