import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import Navbar from "../../../../components/navbar";
import { Container, Typography, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Footer from "../../../../components/footer";

const Mobile = withStyles(styles)((props) => {
  const history = useHistory()
  const { classes, dataOrder } = props;

  return (
    <Fragment>
      <Navbar variant="primary" />
      <Container maxWidth="md" style={{textAlign: 'center'}} className={classes.container}>
        <div className={classes.card}>
          <Typography variant="h6" className={classes.titleCard}>
            <b>COD Berhasil dikonfirmasi</b>
          </Typography>

          <div className={classes.content}>
            <Typography>
              Terimakasih telah berbelanja di Idejualan! Pesananmu <>{
                dataOrder?.summary?.carts?.map((cart, indexCart) => 
                  cart?.orders.map((order, indexOrder) => 
                    order?.items.map((item, indexItem) => 
                      <b key={indexItem+'item'}>{item?.sku?.product?.name}{indexItem === order.items.length - 1 ? ' ' : ', '}</b>
                    )
                  )
                )
                }</> sudah berhasil kami terima pada{" "}
              <b>{dataOrder?.created_at?.split('T')[0]}</b> dengan metode pembayaran <b>COD</b>
              . Setelah ini penjual akan segera
              memproses pesananmu. Mohon ditunggu ya!
            </Typography>
          </div>

          <div className={classes.action}>
            <Button 
              variant="contained" 
              color="primary" 
              style={{ width: 343 }}
              onClick={() => {
                history.push('/')
              }}
            >
              Kembali ke Home
            </Button>
          </div>
        </div>
      </Container>
      <Footer />
    </Fragment>
  );
});

export default Mobile;
