const styles = (theme) => ({
  container: {
    width: 130,
    margin: "auto",
  },
  imageProduct: {
    height: 130,
    width: 130,
    backgroundSize: "cover !important",
    borderRadius: "8px 8px 0 0",
    cursor: "pointer",
  },
  wrapperTop: {
    display: "flex",
    justifyContent: "space-between",
    color: "#ff6465",
    textAlign:'center',
  },
  wrapperBottom: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: '70px'
  },
  iconDropshop: {
    width: '60px !important',
    borderRadius: '4px 0 0 0',
    height: '19px !important'
  },
  iconBottom: {
    width: '40px !important',
  },
  komisi: {
    fontSize: "0.6rem",
    fontWeight: '500',
    padding: '2px 2px 9px 10px',
    borderRadius: "0 4px 0 40px",
    backgroundColor: '#f4bb16',
    color: 'white'
  },
  button1: {
    border: `1px solid ${theme.palette.primary.main}`,
    width: "100%",
    margin: "10px 0",
  },
  button2: {
    // background: theme.palette.primary.main,
    // border: `1px solid ${theme.palette.primary.main}`,
    color: "#000000",
    borderRadius: 4,
    padding: "5px 0",
    width: "100%",
    textTransform: "none",
  },
  title: {
    fontWeight: 600,
    overflow: "hidden",
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 2,
    paddingTop: 10,
    height: 55,
  },
  priceDiscount: {
    fontSize: "0.9rem",
    color: "#555555",
    paddingTop: 10,
    display: "flex",
    alignItems: "center",
  },
  pricePersen: {
    color: "#000000",
    fontWeight: "bold",
    background: theme.palette.primary.main,
    borderRadius: 4,
    padding: "5px 6px",
    // marginLeft: 5,
  },
  withoutDisc: {
    color: "#d9d9d9",
    fontSize: '0.7rem',
    textDecoration: 'line-through',
  },
  disc: {
    color: "#ff7a7a",
    fontWeight: 500,
    fontSize: '0.8rem',
  },
});

export default styles;
