import React, { Fragment, useEffect, useState } from "react";
import Pagination from "@material-ui/lab/Pagination";
import swal from "@sweetalert/with-react";
import Skeleton from "@material-ui/lab/Skeleton";
import { withStyles } from "@material-ui/core/styles";
import { style, VoucherWrap } from "./style";
import {
  Container,
  Grid,
  Tabs,
  Tab,
  Typography,
  Button,
} from "@material-ui/core";
import {
  getSupplier,
  getProductData,
  addToStorefront,
  getSupplierCategories,
  followSupplier,
  unfollowSupplier,
} from "../../../../services/axios";
import { GET_ALL_VOUCHERS } from "../../../../services/voucher.service";
import whatsappIcon from "../../../../assets/icons/dashicons_whatsapp.svg";
import ProductIcon from "../../../../assets/icons/gridiconsproduct.svg";
import FollowerIcon from "../../../../assets/icons/clarity_users-solid.svg";
import JoinedIcon from "../../../../assets/icons/bx_bxs-user-check.svg";
import iconKupon from '../../../../assets/icons/menu/icon_kupon.svg'
import { generateDate } from "../../../../utils/generateDate";
import CardProduct from "../../../../components/CardProduct";
import Navbar from "../../../../components/navbar";
import Footer from "../../../../components/footer";
import Carousel from "../../../../components/carousel";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Desktop = withStyles(style)((props) => {
  const { classes } = props;
  const getProps = props.getProps;
  const [value, setValue] = useState(0);
  const [dataSupplier, setDataSupplier] = useState(null);
  const [listProduct, setListProduct] = useState(null);
  const [listCategories, setListCategories] = useState(null);
  const [isFollowed, setIsFollowed] = React.useState(null);
  const [setIsUnfollowed] = React.useState(null);
  const [pengikut, setPengikut] = React.useState(0);
  const [vouchers, setVouchers] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      const response = await getSupplier(getProps.match.params.supplier);
      const getAllProduct = await getProductData(
        `?filter[supplier.slug]=${response.data.slug}`
      );
      const getCategories = await getSupplierCategories(response.data.slug);
      setDataSupplier(response.data);
      setPengikut(response.data.stats.followers_count);
      setListProduct(getAllProduct);
      setListCategories(getCategories);
      setIsFollowed(response.data.followed);
      console.log("data supplier => ", response.data);
      console.log("data product => ", getAllProduct);

      if(response.data.slug) {
        const resVoucher = await GET_ALL_VOUCHERS({
          "filter[supplier]" : response.data.slug,
          "paginate": 20,
          "include": "supplier"
        });
        if (resVoucher.data) {
          setVouchers(resVoucher.data);
        }
      }
    };
    fetch();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleAddToStorefront = async (slug_id) => {
    const storefront = window.localStorage.getItem("STORE_FRONT");
    const response = await addToStorefront(storefront, slug_id);
    alert(response.message);
  };

  const handleChangePage = async (page) => {
    const response = await getProductData(
      `?filter[supplier.slug]=${dataSupplier.slug}&page=${page}`
    );
    setListProduct(response);
  };

  const handleChangeTab = async (id) => {
    const getAllProduct = await getProductData(
      `?filter[supplier.slug]=${dataSupplier.slug}&filter[category.id]=${id}`
    );
    setListProduct(getAllProduct);
  };

  const handleFollow = () => {
    followSupplier(dataSupplier.slug);
    setIsFollowed(true);
    setIsUnfollowed(false);
    setPengikut(pengikut + 1);
    swal("Success!", "Supplier difollow!", "success");
  };

  const handleUnfollow = () => {
    unfollowSupplier(dataSupplier.slug);
    setIsFollowed(false);
    setIsUnfollowed(true);
    setPengikut(pengikut - 1);
    swal("Success!", "Supplier diunfollow!", "success");
  };

  return (
    <Fragment>
      <Navbar variant="primary" />

      <Container maxWidth="md" style={{ paddingTop: 60, paddingBottom: 80 }}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <div className={classes.card}>
              {dataSupplier === null ? (
                <Skeleton width={120} height={120} variant="rect" />
              ) : (
                <img
                  src={dataSupplier.avatar_url}
                  alt={`profile of ${dataSupplier.name}`}
                  className={classes.photoProfile}
                />
              )}
              <div style={{ margin: "auto 0 auto 20px", alignItems: "center" }}>
                <div>
                  {dataSupplier === null ? (
                    <Skeleton variant="text" width={88} height={24} />
                  ) : (
                    <Typography>
                      <b>{dataSupplier.name}</b>
                    </Typography>
                  )}
                  {dataSupplier === null ? (
                    <Skeleton
                      variant="text"
                      width={89}
                      height={24}
                      style={{ marginLeft: 10 }}
                    />
                  ) : (
                    <Typography variant="caption" style={{ marginLeft: 10 }}>
                      {dataSupplier.location.address} - {dataSupplier.location.postal_code}
                    </Typography>
                  )}
                </div>
                <div style={{ marginTop: 10, display: "flex" }}>
                  {dataSupplier === null ? (
                    <Skeleton variant="rect" width={64} height={36} />
                  ) : isFollowed === true && isFollowed !== null ? (
                    <Button
                      variant="contained"
                      style={{ color: "#a7a7a7", fontSize: 10 }}
                      onClick={handleUnfollow}
                    >
                      Batal Ikuti
                    </Button>
                  ) : (
                    <Button
                      onClick={handleFollow}
                      style={{ fontSize: 10 }}
                      variant="contained"
                      color="primary"
                    >
                      Ikuti
                    </Button>
                  )}

                  {dataSupplier === null ? (
                    <Skeleton
                      variant="rect"
                      style={{ marginLeft: 8 }}
                      width={143}
                      height={36}
                    />
                  ) : (
                    <Button
                      variant="outlined"
                      style={{ fontSize: 10, marginLeft: 8 }}
                      onClick={() =>
                        window.open(
                          `http://api.idejualan.com/whatsapp?text=Hai,%20ini%20idejualan`,
                          "_blank"
                        )
                      }
                      color="primary"
                      endIcon={
                        <img
                          src={whatsappIcon}
                          height={32}
                          width={32}
                          alt={"Whatsapp"}
                        />
                      }
                    >
                      Chat via WA
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.card} style={{ textAlign: "center" }}>
              {dataSupplier === null ? (
                <Fragment>
                  {[1, 2, 3].map((res, index) => {
                    return (
                      <Grid
                        item
                        xs={4}
                        key={index}
                        className={classes.cardGrid}
                      >
                        <div>
                          <Skeleton
                            variant="rect"
                            height={64}
                            width={64}
                            style={{ margin: "auto" }}
                          />
                          <div style={{ display: "flex", marginTop: 5 }}>
                            <Skeleton variant="text" height={14} width={40} />
                            <Skeleton
                              style={{ marginLeft: 5 }}
                              variant="text"
                              height={14}
                              width={40}
                            />
                          </div>
                        </div>
                      </Grid>
                    );
                  })}
                </Fragment>
              ) : (
                <Fragment>
                  <Grid item xs={3} className={classes.cardGrid}>
                    <div>
                      <img src={ProductIcon} alt={"icon"} />
                      <Typography variant="caption">Produk</Typography>
                      <Typography
                        style={{ paddingLeft: 5 }}
                        variant="caption"
                        color="primary"
                      >
                        <b>{dataSupplier.stats.products_count}</b>
                      </Typography>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    className={classes.cardGrid}
                    style={{ marginLeft: 20 }}
                  >
                    <div>
                      <img src={FollowerIcon} alt={"icon"} />
                      <Typography variant="caption">Pengikut</Typography>
                      <Typography
                        style={{ paddingLeft: 5 }}
                        variant="caption"
                        color="primary"
                      >
                        <b>{pengikut}</b>
                      </Typography>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    className={classes.cardGrid}
                    style={{ marginLeft: 20 }}
                  >
                    <div>
                      <img
                        src={JoinedIcon}
                        style={{ display: "block", margin: "auto" }}
                        alt={"icon"}
                      />
                      <Typography variant="caption">Bergabung</Typography>
                      <Typography
                        style={{ paddingLeft: 5 }}
                        variant="caption"
                        color="primary"
                      >
                        <b>{generateDate(dataSupplier.joined_at)}</b>
                      </Typography>
                    </div>
                  </Grid>
                </Fragment>
              )}
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.card} style={{ display: "block" }}>
              {dataSupplier === null ? (
                <Fragment>
                  <Skeleton variant="text" width={100} />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </Fragment>
              ) : (
                <Fragment>
                  <Typography>
                    <b>Deskripsi Toko</b>
                  </Typography>
                  <Typography>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: dataSupplier.description,
                      }}
                    />
                  </Typography>
                </Fragment>
              )}
            </div>
          </Grid>
            {vouchers && (
              <Grid item xs={12}>
                <div className={classes.card} style={{ display: "block" }}>
                  <VoucherWrap>
                      <div className={classes.vouchers}>
                      <div>
                        <div style={{ display: 'flex', marginBottom: 5, alignItems:'center', justifyContent: 'start',}} >
                          <img style={{ width: 25, marginLeft: 3}} src={iconKupon} alt='icon kupon'/>
                          <div style={{ fontFamily: 'DMSans-Regular', color: '#FBBA14', marginLeft: 3, fontSize: 16 }}><span style={{ fontWeight: 'bold' }}>KUPON </span><span>Di Toko ini</span></div>
                        </div>
                        <Grid
                          container
                          spacing={5}
                          style={{ padding: 20 }}
                      >
                         <Carousel data={vouchers} variant="voucher"  />
                      </Grid>
                    </div>
                  </div>
                </VoucherWrap>
                </div>
              </Grid>
                )}
        </Grid>

        <div>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            variant="scrollable"
            textColor="primary"
          >
            <Tab label="Semua Produk" {...a11yProps(0)} />
            {listCategories !== null &&
              listCategories.data.map((res, index) => (
                <Tab
                  label={res.name}
                  key={index}
                  onClick={() => handleChangeTab(res.id)}
                  icon={
                    <img
                      src={res.icon_url}
                      height={20}
                      style={{ marginRight: 10 }}
                      alt=""
                    />
                  }
                  {...a11yProps(index + 1)}
                />
              ))}
          </Tabs>
        </div>
        <TabPanel value={value} index={0}>
          <Grid container spacing={2} style={{ paddingTop: 30 }}>
            {listProduct !== null &&
              listProduct.data.map((res, index) => (
                <Grid item xs={2} key={index}>
                  <CardProduct data={res} variant="reseller" />
                </Grid>
              ))}
          </Grid>

          <div className={classes.pagination}>
            {listProduct !== null && (
              <Pagination
                count={listProduct.meta.last_page}
                siblingCount={listProduct.meta.current_page}
                variant="outlined"
                shape="rounded"
                color="primary"
                onChange={(event, index) => handleChangePage(index)}
              />
            )}
          </div>
        </TabPanel>
        {listCategories !== null &&
          listCategories.data.map((res, index) => {
            return (
              <TabPanel value={value} index={index + 1}>
                <Grid container spacing={2} style={{ paddingTop: 30 }}>
                  {listProduct !== null &&
                    listProduct.data.map((res, index) => (
                      <Grid item xs={2} key={index}>
                        <CardProduct data={res} variant="reseller" />
                      </Grid>
                    ))}
                </Grid>

                <div className={classes.pagination}>
                  {listProduct !== null && (
                    <Pagination
                      count={listProduct.meta.last_page}
                      siblingCount={listProduct.meta.current_page}
                      variant="outlined"
                      shape="rounded"
                      color="primary"
                      onChange={(event, index) => handleChangePage(index)}
                    />
                  )}
                </div>
              </TabPanel>
            );
          })}
      </Container>

      <Footer />
    </Fragment>
  );
});

export default Desktop;
