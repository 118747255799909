import React from 'react';
import { WrapMenu } from './style';
import iconNext from '../../assets/icons/next.svg'

const MenuMobileComponent = (props) => {
  const {title, desc, style, onClick = null} = props
  return (
    <WrapMenu style={style} onClick={onClick}>
      <div className="left">
        <p>{title}</p>
        <p>{desc}</p>
      </div>
      <div className="right">
        <img src={iconNext} alt="icon-next" />
      </div>
    </WrapMenu>
  );
};

export default MenuMobileComponent;