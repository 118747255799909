import React from "react";
import { InputAdornment, TextField } from "@material-ui/core";
import { SearchCustomWrap } from "./style";
import iconSearch from "../../assets/icons/ic_search.svg";
import iconIdejualan from "../../assets/icons/iconIdejualan.svg"

const SearchCustomComponent = ({ onChange = null, onSearch = null, withIcon = true, withLogo = false, placeholder = '' }) => {
  return (
    <SearchCustomWrap>
      <TextField
        id="search"
        variant="outlined"
        fullWidth
        onChange={onChange}
        placeholder={placeholder}
        InputProps={
          withIcon && {
            endAdornment: (
              <InputAdornment position="end">
                <img
                  src={iconSearch}
                  alt="search"
                  className="search"
                  onClick={onSearch}
                />
              </InputAdornment>
            ),
            startAdornment: withLogo && (
              <InputAdornment position="start">
                <img
                  src={iconIdejualan}
                  alt="logo-search"
                  className="logo"
                />
              </InputAdornment>
            )
          }
        }
      />
    </SearchCustomWrap>
  );
};

export default SearchCustomComponent