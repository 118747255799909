import { axiosInstance } from "../config/fetchAxios";

const userData = JSON.parse(localStorage.getItem("USER_DATA"));

export const addCustomer = async (data) => {
  const response = axiosInstance.post(
    `/api/v1/resellers/${userData.id}/customers`,
    data
  );
  return response;
};

export const getCustomers = async () => {
  const response = axiosInstance.get(
    `/api/v1/resellers/${userData.id}/customers`
  );
  return response;
};

export const updateCustomer = async (data, customerId) => {
  const response = axiosInstance.post(
    `/api/v1/resellers/${userData.id}/customers/${customerId}`,
    data
  );
  return response;
};
