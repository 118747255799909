import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@material-ui/core'
import { ToggleButton } from '@material-ui/lab';
import CheckIcon from '@material-ui/icons/Check';
import { editPriceByReseller } from '../../services/axios';
import React from 'react'
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom';

const CustomPriceReseller = ({ open, setIsOpen, product, storefrontName, isPremiumMember, setIsAddedToStore }) => {
    const [selectedRange, setselectedRange] = React.useState(false)
    const history = useHistory()

    const [form, setForm] = React.useState({
        product: product,
        is_range: selectedRange,
        price_idr: 0,
        price_start_idr: 0,
        price_end_idr: 0,
    });
    
    React.useEffect(() => {
        setForm({
                product: product,
                is_range: selectedRange,
                price_idr: 0,
                price_start_idr: 0,
                price_end_idr: 0,
            })
    }, [selectedRange])
    

    const changeForm = (key, value) => {
        setForm({ ...form, [key]: value === '' ? 0 : value });
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const handleSubmitData = async() => {
        if (!selectedRange) {
            if (form.price_idr === 0) {
                swal('Mohon isi harga terlebih dahulu')
            }else{
                const response = await editPriceByReseller(storefrontName, form)
                setIsOpen(false);
                if (response.code === 'success') {
                    swal("Success!", response.message, "success");
                    setIsAddedToStore(true)
                } else {
                    swal("Error!", response.message, "error");
                }
            }
        }else{
            if (form.price_start_idr === 0 || form.price_end_idr === 0) {
                swal('Mohon isi harga terlebih dahulu')
            } else {
                const response = await editPriceByReseller(storefrontName, form)
                setIsOpen(false);
                if (response.code === 'success') {
                    swal("Success!", response.message, "success");
                    setIsAddedToStore(true)
                } else {
                    swal("Error!", response.message, "error");
                }
            }
        }
    }

    return (
        <>
            <Dialog
                open={isPremiumMember ? open : false}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Custom Harga</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Silahkan mengisi data harga yang ingin anda tampilkan ke pembeli.
                    </DialogContentText>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{marginRight: 5}}>Range harga product</div>
                        <ToggleButton
                            value="check"
                            selected={selectedRange}
                            size="small"
                            onChange={() => {
                                setselectedRange(!selectedRange);
                            }}
                        >
                            <CheckIcon style={ selectedRange === true ? {color: 'rgb(0 225 2 / 87%)'} : {} } />
                        </ToggleButton>
                    </div>
                    <div style={{fontSize: '.8rem', color: 'rgb(7 7 7 / 54%)'}} >note: Berikan centang jika harga pada produkmu lebih dari satu</div>

                    {
                        !selectedRange &&
                        <TextField
                            autoFocus
                            margin="dense"
                            id="price_idr"
                            onChange={(e) => changeForm(e.target.id, e.target.value)}
                            label="Harga Produk"
                            type="number"
                            fullWidth
                        />
                    }

                    {
                        selectedRange &&
                        <>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="price_start_idr"
                                onChange={(e) => changeForm(e.target.id, e.target.value)}
                                label="Harga terendah"
                                type="number"
                                fullWidth
                            />

                            <TextField
                                autoFocus
                                margin="dense"
                                id="price_end_idr"
                                onChange={(e) => changeForm(e.target.id, e.target.value)}
                                label="Harga tertinggi"
                                type="number"
                                fullWidth
                            />
                        </>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmitData} color="primary">
                        Tambahkan ke Toko
                    </Button>
                </DialogActions>
            </Dialog>

            {/* dialog when user not premium member */}
            <Dialog
                open={!isPremiumMember ? open : false}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Upgrade Akun Sekarang</DialogTitle>
                <DialogContent>
                    Anda harus menjadi Super Seller untuk menikmati fitur ini
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Nanti
                    </Button>
                    <Button onClick={() => {history.push('/upgrade-reseller')}} color="primary">
                        Upgrade
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CustomPriceReseller