import React, { Fragment, useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import Navbar from "../../../components/navbar";
import {
  Container,
  Typography,
  Divider,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Drawer,
} from "@material-ui/core";
import { getAllCourier, checkResi } from "../../../services/axios";
import { getDateWithDayName } from "../../../utils/generateDate";
import swal from "sweetalert";
import { CopyToClipboard } from "react-copy-to-clipboard";

const Mobile = withStyles(styles)((props) => {
  const { history, classes } = props;
  const [listCourier, setListCourier] = useState(null);
  const [currentCourier, setCurrentCourier] = useState(null);
  const [openStatusPengiriman, setOpenStatusPengiriman] = useState(false);
  const [resi, setResi] = useState("");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      const response = await getAllCourier();
      setListCourier(response.data);
    };
    fetch();
  }, []);

  const handleChangeCourier = (event) => {
    setCurrentCourier(event.target.value);
  };

  const handleCekResi = async () => {
    setLoading(true);
    if (resi.length !== 0 && currentCourier !== null) {
      const body = {
        waybill: resi,
        courier: currentCourier.code,
      };
      const response = await checkResi(body);
      await setData(response);
      console.log("resi result => ", response);
      setLoading(false);
      setOpenStatusPengiriman(true);
    } else {
      swal("Masukan nomor resi dan pilih kurir!");
    }
  };

  return (
    <Fragment>
      <Navbar text="Cek Resi" onClick={() => history.goBack()} />
      <Container maxWidth="lg">
        <div className={classes.card}>
          <Typography variant="h6">
            <b>Lacak status dan posisi paket</b>
          </Typography>

          <div className={classes.formCekResi}>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              style={{ width: "100%" }}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Pilih Kurir
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={currentCourier}
                style={{ width: "100%", display: "flex" }}
                label="Pilih Kurir"
                onChange={handleChangeCourier}
              >
                {listCourier !== null &&
                  listCourier.map((res, index) => {
                    return (
                      <MenuItem value={res} key={index}>
                        <div style={{ width: "100%", display: "flex" }}>
                          <Typography style={{ width: "80%" }}>
                            {res.name}
                          </Typography>
                          <img src={res.icon_url} alt="" />
                        </div>
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </div>

          <div className={classes.formCekResi}>
            <TextField
              fullWidth={true}
              placeholder="No. Resi"
              variant="outlined"
              label="No. Resi"
              onChange={(event) => setResi(event.target.value)}
            />
          </div>

          <div className={classes.formCekResi}>
            <Button
              variant="contained"
              color="primary"
              fullWidth={true}
              onClick={handleCekResi}
            >
              {loading ? (
                <CircularProgress color="inherit" size={24} />
              ) : (
                "Cek Resi"
              )}
            </Button>
          </div>
        </div>
      </Container>

      <Drawer
        anchor="right"
        open={openStatusPengiriman}
        onClose={() => setOpenStatusPengiriman(false)}
      >
        <div style={{ width: window.screen.width }}>
          <Navbar
            text="Status Pengiriman"
            onClick={() => setOpenStatusPengiriman(false)}
          />
          {data !== null && (
            <Container maxWidth="lg" className={classes.container}>
              <div className={classes.card2}>
                <div className={classes.resiNumber}>
                  <div>
                    <Typography>{data.summary.courier_name}</Typography>
                    <Typography>
                      No. Resi: {data.summary.waybill_number}
                    </Typography>
                  </div>
                  <div>
                    <CopyToClipboard
                      text={data.summary.waybill_number}
                      onCopy={() => {
                        swal("resi disalin!");
                      }}
                    >
                      <Typography color="primary" style={{ cursor: "pointer" }}>
                        <b>Salin</b>
                      </Typography>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
              <Divider />
              <div className={classes.card2}>
                <div>
                  <Typography variant="caption">Pengirim</Typography>
                  <Typography>
                    <b>
                      {data.summary.shipper_name} - {data.summary.origin}
                    </b>
                  </Typography>
                </div>
                <div className={classes.formCekResi}>
                  <Typography variant="caption">Penerima</Typography>
                  <Typography>
                    <b>
                      {data.summary.receiver_name} - {data.summary.destination}
                    </b>
                  </Typography>
                </div>
              </div>
              <Divider />
              <div
                className={classes.card2}
                style={{ paddingRight: 0, paddingBottom: 0 }}
              >
                {[...data.manifest].reverse().map((label, index) => (
                  <div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          height: 20,
                          width: 20,
                          background: index === 0 ? "#fbbb14" : "#555555",
                          borderRadius: "50%",
                        }}
                      />
                      <Typography variant="caption" style={{ paddingLeft: 10 }}>
                        {getDateWithDayName(label.manifest_date)},{" "}
                        {label.manifest_time}
                      </Typography>
                    </div>
                    <Typography
                      style={{
                        borderLeft: "2px solid #555555",
                        marginLeft: 10,
                        padding: "10px 20px",
                        paddingTop: 0,
                      }}
                    >
                      {label.manifest_description}
                      {index !== data.manifest.length - 1 && <Divider />}
                    </Typography>
                  </div>
                ))}
              </div>
            </Container>
          )}
        </div>
      </Drawer>
    </Fragment>
  );
});

export default Mobile;
