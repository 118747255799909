const stylesContent = (theme) => ({
    outerContainer: {
        backgroundColor: 'white',
        padding: 20,
    },
    innerContainer: {
        backgroundColor: 'orange',
        color: 'white',
        borderRadius: '10px 10px 0 0',
        padding: '30px 30px 150px 30px'
    },
    coinContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 20px 0 20px'
    },
    mainContainer: {
        backgroundColor: 'white',
        padding: 20,
        margin: 20,
        borderRadius: 10,
        marginTop: '-170px',
        boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px'
    },
    innerMainContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap'
    },
    coinCard: {
        backgroundColor: '#fafafa',
        width: 85,
        margin: 5,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 10,
        boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px'
    },
    titleCard: {
        backgroundColor: 'orange',
        width: '100%',
        textAlign: 'center',
        color: 'white',
        borderRadius: '10px 10px 0 0',
        marginBottom: 10
    },
    imgCard: {
        width: 50,
        height: 50
    }
});

export { stylesContent };
