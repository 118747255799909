import React, { Fragment, useEffect, useState } from "react";
import Navbar from "../../../../components/navbar";
import Footer from "../../../../components/footer";
import {
  Container,
  Grid,
  Tabs,
  Tab,
  Typography,
  Button,
  Badge,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import {
  getStorefrontProductCategories,
  getStorefrontProducts,
  getStorefront,
  isLoggedIn,
} from "../../../../services/axios";
import Skeleton from "@material-ui/lab/Skeleton";
import whatsappIcon from "../../../../assets/icons/white_whatsapp.svg";
import ProductIcon from "../../../../assets/icons/gridiconsproduct.svg";
import JoinedIcon from "../../../../assets/icons/bx_bxs-user-check.svg";
import { generateDate } from "../../../../utils/generateDate";
import { withRouter } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import swal from "@sweetalert/with-react";
import ShareIcon from "@material-ui/icons/Share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  FacebookShareButton,
  TwitterShareButton,
  LineShareButton,
  PinterestShareButton,
} from "react-share";
import CardProduct from "../../../../components/CardProduct";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import placeholder from "../../../../assets/images/placeholder.jpg";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Desktop = withStyles(style)((props) => {
  const { classes, history, match } = props;
  const isStoreFront = match.path.indexOf("/store/:reseller") !== -1;
  const [value, setValue] = useState(0);
  const [listCategories, setListCategories] = useState(null);
  const [listProduct, setListProduct] = useState(null);
  const [dataStorefront, setDataStorefront] = useState(null);
  const [categoryId, setCategoryId] = useState("");
  const [cart] = useState(
    JSON.parse(localStorage.getItem("__cart"))
      ? JSON.parse(localStorage.getItem("__cart")).length
      : 0
  );
  const isPremiumMember = JSON.parse(localStorage.getItem("USER_DATA"))?.activated || false;
  const [isOpenDialogPremiumMember, setisOpenDialogPremiumMember] = useState(isPremiumMember ? false : true)

  useEffect(() => {
    const fetch = async () => {
      const response = await getStorefront(props.match.params.reseller);
      const getCategories = await getStorefrontProductCategories(
        response.data.slug
      );
      const getProducts = await getStorefrontProducts(response.data.slug);
      setListProduct(getProducts);
      setDataStorefront(response.data);
      setListCategories(getCategories);
    };
    fetch();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangePage = async (page) => {
    const response = await getStorefrontProducts(
      dataStorefront.slug,
      `?filter[category.id]=${categoryId}&page=${page}`
    );
    setListProduct(response);
  };

  const handleChangeTab = async (id) => {
    const getAllProduct = await getStorefrontProducts(
      dataStorefront.slug,
      `?filter[category.id]=${id}`
    );
    setListProduct(getAllProduct);
    setCategoryId(id);
  };

  const handleChangeTabAllProduct = async () => {
    const getProducts = await getStorefrontProducts(dataStorefront.slug);
    setListProduct(getProducts);
    setCategoryId("");
  };

  const handleCloseDialogPremiumMember = () => {
    setisOpenDialogPremiumMember(false)
    history.push('/')
  }

  return (
    <Fragment>
      <Navbar variant={isStoreFront ? "default" : "primary"} />

      {isStoreFront && (
        <div
          className={classes.customerCart}
          onClick={() => history.push("/cart")}
        >
          <Badge badgeContent={cart} color="secondary">
            <ShoppingCartIcon />
          </Badge>
        </div>
      )}

      <Container maxWidth="md" style={{ paddingTop: 60, paddingBottom: 80 }}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <div className={classes.card}>
              {dataStorefront === null ? (
                <Skeleton width={120} height={120} variant="rect" />
              ) : (
                <img
                  src={dataStorefront.avatar_url || placeholder}
                  alt={`profile of ${dataStorefront.name}`}
                  className={classes.photoProfile}
                />
              )}
              <div style={{ margin: "auto 0 auto 20px", alignItems: "center" }}>
                <div>
                  {dataStorefront === null ? (
                    <Skeleton variant="text" width={120} height={24} />
                  ) : (
                    <Typography>
                      <b>{dataStorefront.name}</b>
                    </Typography>
                  )}
                  {dataStorefront === null ? (
                    <Skeleton variant="text" width={89} height={24} />
                  ) : (
                    <Typography variant="caption" style={{ marginLeft: 10 }}>
                      - {dataStorefront.owner.location.city.name}
                    </Typography>
                  )}
                </div>
                {/* <div style={{ marginTop: 10, display: "flex" }}>
                  {dataStorefront === null ? (
                    <Skeleton variant="rect" width={143} height={36} />
                  ) : (
                    <Button
                      variant="contained"
                      onClick={() =>
                        window.open(
                          `https://wa.me/`+dataStorefront.owner.phone,
                          "_blank"
                        )
                      }
                      color="primary"
                      style={{ fontSize: 10, color: "#ffffff" }}
                      endIcon={
                        <img src={whatsappIcon} height={32} width={32} alt="" />
                      }
                    >
                      Chat via WA
                    </Button>
                  )}
                </div> */}
              </div>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.card} style={{ textAlign: "center" }}>
              {dataStorefront === null ? (
                <Fragment>
                  {[1, 2].map((res, index) => {
                    return (
                      <Grid
                        item
                        xs={6}
                        className={classes.cardGrid}
                        key={index}
                      >
                        <div>
                          <Skeleton
                            variant="rect"
                            height={64}
                            width={64}
                            style={{ margin: "auto" }}
                          />
                          <div style={{ display: "flex", marginTop: 5 }}>
                            <Skeleton variant="text" height={14} width={40} />
                            <Skeleton
                              style={{ marginLeft: 5 }}
                              variant="text"
                              height={14}
                              width={40}
                            />
                          </div>
                        </div>
                      </Grid>
                    );
                  })}
                </Fragment>
              ) : (
                <Fragment>
                  <Grid item xs={6} className={classes.cardGrid}>
                    <div>
                      <img
                        src={ProductIcon}
                        style={{ display: "block", margin: "auto" }}
                        alt=""
                      />
                      <Typography variant="caption">Produk</Typography>
                      <Typography
                        style={{ paddingLeft: 5 }}
                        variant="caption"
                        color="primary"
                      >
                        <b>{dataStorefront.stats.total_product}</b>
                      </Typography>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    className={classes.cardGrid}
                    style={{ marginLeft: 20 }}
                  >
                    <div>
                      <img
                        src={JoinedIcon}
                        style={{ display: "block", margin: "auto" }}
                        alt=""
                      />
                      <Typography variant="caption">Bergabung</Typography>
                      <Typography
                        style={{ paddingLeft: 5 }}
                        variant="caption"
                        color="primary"
                      >
                        <b>{generateDate(dataStorefront.owner.joined_at)}</b>
                      </Typography>
                    </div>
                  </Grid>
                </Fragment>
              )}
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.card} style={{ display: "block" }}>
              {dataStorefront === null ? (
                <Fragment>
                  <Skeleton variant="text" width={100} />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </Fragment>
              ) : (
                <Fragment>
                  <Typography>
                    <b>Deskripsi Toko</b>
                  </Typography>
                  <Typography>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: dataStorefront.description,
                      }}
                    />
                  </Typography>
                </Fragment>
              )}
            </div>
          </Grid>
        </Grid>

        <div className={classes.shareSection}>
          <Grid container spacing={2} style={{ alignItems: "center" }}>
            <Grid item>
              <ShareIcon />
            </Grid>
            <Grid item>
              <Typography style={{ fontWeight: "bold" }}>Bagikan :</Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.linkCopy}>
                {window.location.href}
              </Typography>
            </Grid>
            <Grid item>
              <CopyToClipboard
                text={window.location.href}
                onCopy={() => {
                  swal("Link disalin !");
                }}
              >
                <Typography style={{ cursor: "pointer" }}>Salin</Typography>
              </CopyToClipboard>
            </Grid>
            <Grid item>
              <Typography>Atau bagikan di</Typography>
            </Grid>
            <Grid item>
              <div>
                <Grid container spacing={2}>
                  <Grid item>
                    <FacebookShareButton
                      url={window.location.href}
                      quote={document.title}
                      className={classes.socialIcon}
                    >
                      <img
                        src={require("../../../../assets/icons/facebook.svg")}
                        alt=""
                      />
                    </FacebookShareButton>
                  </Grid>
                  <Grid item>
                    <TwitterShareButton
                      url={window.location.href}
                      quote={document.title}
                      className={classes.socialIcon}
                    >
                      <img
                        src={require("../../../../assets/icons/twitter.svg")}
                        alt=""
                      />
                    </TwitterShareButton>
                  </Grid>
                  <Grid item>
                    <LineShareButton
                      url={window.location.href}
                      quote={document.title}
                      className={classes.socialIcon}
                    >
                      <img
                        src={require("../../../../assets/icons/line.svg")}
                        alt=""
                      />
                    </LineShareButton>
                  </Grid>
                  <Grid item>
                    <PinterestShareButton
                      url={window.location.href}
                      quote={document.title}
                      className={classes.socialIcon}
                    >
                      <img
                        src={require("../../../../assets/icons/pinterest.svg")}
                        alt=""
                      />
                    </PinterestShareButton>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>

        <div>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            variant="scrollable"
            textColor="primary"
          >
            <Tab
              label="Semua Produk"
              {...a11yProps(0)}
              onClick={handleChangeTabAllProduct}
            />
            {listCategories !== null &&
              listCategories.data.map((res, index) => (
                <Tab
                  label={res.name}
                  key={index}
                  onClick={() => handleChangeTab(res.id)}
                  icon={
                    <img
                      src={res.icon_url}
                      height={20}
                      width={40}
                      style={{ marginRight: 10 }}
                      alt=""
                    />
                  }
                  {...a11yProps(index + 1)}
                />
              ))}
          </Tabs>
        </div>
        <TabPanel value={value} index={0}>
          <Grid container spacing={2} style={{ paddingTop: 30 }}>
            {listProduct !== null &&
              listProduct.data.map((res, index) => (
                <Grid item xs={2}>
                  <div key={index}>
                    <CardProduct
                      data={res}
                      variant={isStoreFront ? "customer" : "reseller"}
                      isStoreFront={isStoreFront}
                    />
                  </div>
                </Grid>
              ))}
          </Grid>

          <div className={classes.pagination}>
            {listProduct !== null && (
              <Pagination
                count={listProduct?.meta?.last_page}
                siblingCount={listProduct?.meta?.current_page}
                variant="outlined"
                shape="rounded"
                color="primary"
                onChange={(event, index) => handleChangePage(index)}
              />
            )}
          </div>
        </TabPanel>
        {listCategories !== null &&
          listCategories.data.map((res, index) => {
            return (
              <TabPanel value={value} index={index + 1}>
                <Grid container spacing={2} style={{ paddingTop: 30 }}>
                  {listProduct !== null &&
                    listProduct.data.map((res, index) => (
                      <Grid item xs={2}>
                        <div key={index}>
                          <CardProduct
                            data={res}
                            variant={isStoreFront ? "customer" : "reseller"}
                            isStoreFront={isStoreFront}
                          />
                        </div>
                      </Grid>
                    ))}
                </Grid>

                <div className={classes.pagination}>
                  {listProduct !== null && (
                    <Pagination
                      count={listProduct?.meta?.last_page}
                      siblingCount={listProduct?.meta?.current_page}
                      variant="outlined"
                      shape="rounded"
                      color="primary"
                      onChange={(event, index) => handleChangePage(index)}
                    />
                  )}
                </div>
              </TabPanel>
            );
          })}
      </Container>

      <Footer />

      {/* dialog when user not premium member */}
      {
        isLoggedIn() &&
        <Dialog
          open={isOpenDialogPremiumMember}
          onClose={handleCloseDialogPremiumMember}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Upgrade Akun Sekarang</DialogTitle>
          <DialogContent>
              Anda harus menjadi Super Seller untuk menikmati fitur ini
          </DialogContent>
          <DialogActions>
              <Button onClick={handleCloseDialogPremiumMember} color="primary">
                  Nanti
              </Button>
              <Button onClick={() => {history.push('/upgrade-reseller')}} color="primary">
                  Upgrade
              </Button>
          </DialogActions>
        </Dialog>
      }
    </Fragment>
  );
});

export default withRouter(Desktop);
