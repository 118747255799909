import React from 'react'
import CloseIcon from "@material-ui/icons/Close";
import { getPicContact } from '../services/axios';

// icon
import IconPic from '../assets/icons/icon_pic.png'

const ChatPic = () => {
    const [open, setOpen] = React.useState(true)

    const handleClose = () => {
        setOpen(false)
    }

    const handleClick = async () => {
        const allDataPic = await getPicContact();
        const contact = allDataPic.data.phone

        window.open(`https://api.whatsapp.com/send/?phone=${contact}&text=Halo+kak%2C+saya+ingin+bertanya+sesuatu.&app_absent=0`, '_blank')
    }

    return (
        <React.Fragment>
            <div style={open ? style.container : style.closePic}>
                <div style={style.btnContactPic} onClick={handleClick}>
                    <img src={IconPic} style={style.icon} alt='icon chat pic' />
                </div>
                <div style={style.closeAreaPic} onClick={handleClose}>
                    <div style={style.closeBtn}>
                        <CloseIcon />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}


// style
const style = {
    closePic: {
        display: 'none'
    },
    container: {
        position: 'fixed',
        bottom: 70,
        right: 15,
        zIndex: 10,
    },
    btnContactPic: {
        cursor: 'pointer',
        backgroundColor: '#28BFC6',
        borderRadius: '50%'
    },
    icon: {
        width: 55,
        height: 55,
        padding: 10
    },
    closeAreaPic: {
        position: 'absolute',
        display: 'block',
        top: '-10px',
        right: 10,
        width: '15%',
        height: '19%',
        cursor: 'pointer',
    },
    closeBtn: {
        height: 30,
        width: 30,
        top: -10,
        right: -10,
        borderRadius: 20,
        backgroundColor: 'white',
        border: '3px solid #fff',
        color: 'red'
    }
}

export default ChatPic