const stylesContent = (theme) => ({
    containerSearch: {
        position: 'fixed',
        zIndex: 1100,
        width: '100%',
        paddingTop: 8,
        paddingBottom: 8
    },
    innerContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative'
    },
});

export { stylesContent };
