import React from 'react'

const NeedLoginSwal = () => {
    return (
        <div style={{margin: 'auto' }}>
            <h2>Login Diperlukan</h2>
            <p>Anda harus masuk untuk dapat mengakses fitur ini</p>
            <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                <button
                    style={{
                        margin: '5px',
                        width: '30%',
                        border: '1px solid orange',
                        backgroundColor: 'white',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        color: 'orange',
                        padding: '10px'
                    }}
                    onClick={() =>
                        window.location.href = '/signup'
                    }
                >
                    Daftar
                </button>
                <button
                    style={{
                        margin: '5px',
                        width: '30%',
                        border: '1px solid orange',
                        backgroundColor: 'orange',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        color: 'white',
                        padding: '10px'
                    }}
                    onClick={() =>
                        window.location.href = '/login'
                    }
                >
                    Masuk
                </button>
            </div>
        </div>
    )
}

export default NeedLoginSwal