import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Button } from "@material-ui/core";
import whatsappIcon from "../../../../../assets/icons/white_whatsapp.svg";
import { generateDate } from "../../../../../utils/generateDate";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  show: {
    height: 41,
    display: "flex",
    alignItems: "center",
  },
  profileImage: {
    height: "100%",
    width: 130,
    borderRadius: "8px 0px 0px 8px",
    objectFit: "cover",
  },
  bg: {
    background: "#FFFFFF",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.04)",
    borderRadius: 8,
    height: 136,
    marginTop: 20,
  },
  notFound: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 50,
  },
}));

const Vendor = (props) => {
  const { vendorSearched, history, match } = props;
  const keyword = decodeURI(match.params.keyword);
  const classes = useStyles();

  console.log(vendorSearched.data);

  return (
    <Fragment>
      {vendorSearched.data.length === 0 ? (
        <Fragment>
          <div className={classes.notFound}>
            <Typography>
              Pencarian untuk Vendor "{keyword}" tidak ditemukan.
            </Typography>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div className={classes.show}>
            <Typography>
              Menampilkan {vendorSearched.data.length} vendor untuk{" "}
              <b>"{keyword}"</b>(<b>1-5</b> dari{" "}
              <b>{vendorSearched.meta.total}</b>)
            </Typography>
          </div>

          {vendorSearched.data.map((res) => (
            <Fragment>
              <Grid container className={classes.bg}>
                <Grid item xs={3}>
                  <img
                    src={res.avatar_url}
                    alt={`idejualan profile vendor ${res.name}`}
                    className={classes.profileImage}
                  />
                </Grid>
                <Grid
                  item
                  xs={9}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div>
                    <div style={{ display: "flex" }}>
                      <Typography>
                        <b>{res.name}</b>
                      </Typography>
                      <Typography style={{ paddingLeft: 20 }}>
                        {res?.location?.village?.name}
                      </Typography>
                    </div>

                    <div style={{ display: "flex", paddingTop: 10 }}>
                      <div>
                        <Typography variant="caption">Produk</Typography>
                        <Typography
                          variant="caption"
                          color="primary"
                          style={{ paddingLeft: 10 }}
                        >
                          {res.stats.products_count}
                        </Typography>
                      </div>
                      <div style={{ paddingLeft: 20 }}>
                        <Typography variant="caption">Pengikut</Typography>
                        <Typography
                          variant="caption"
                          color="primary"
                          style={{ paddingLeft: 10 }}
                        >
                          {res.stats.followers_count}
                        </Typography>
                      </div>
                      <div style={{ paddingLeft: 20 }}>
                        <Typography variant="caption">Bergabung</Typography>
                        <Typography
                          variant="caption"
                          color="primary"
                          style={{ paddingLeft: 10 }}
                        >
                          {generateDate(res.joined_at)}
                        </Typography>
                      </div>
                    </div>

                    <div style={{ paddingTop: 20, display: "flex" }}>
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => history.push(`/profile/${res.slug}`)}
                      >
                        Kunjungi Toko
                      </Button>
                      <Button
                        color="primary"
                        variant="contained"
                        style={{
                          color: "#fff",
                          display: "flex",
                          alignItems: "center",
                          marginLeft: 20,
                        }}
                        onClick={() =>
                          window.open(
                            `http://api.idejualan.com/whatsapp?text=Hai,%20ini%20idejualan`,
                            "_blank"
                          )
                        }
                      >
                        <Typography>Chat via WA </Typography>
                        <img src={whatsappIcon} alt="" height={20} width={20} />
                      </Button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Fragment>
          ))}
        </Fragment>
      )}
    </Fragment>
  );
};

export default withRouter(Vendor);
