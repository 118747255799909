import React, { useEffect } from "react";
import {
  Container,
  Box,
  OutlinedInput,
  Checkbox,
  Typography,
  Button,
  useMediaQuery,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ArrowForward, Check } from "@material-ui/icons";
import swal from "@sweetalert/with-react";

import satisfactionIcon from "../../assets/images/satisfaction.png";
import secureTransaction from "../../assets/images/secureTransaction.png";
import membershipPackage from "../../assets/images/idejualanMembershipPackage.jpeg";
import vipPlusPackage from "../../assets/images/idejualanVipPlusPackage.jpeg";
import ely from "../../assets/images/ely.png";
import ditya from "../../assets/images/ditya.png";
import anih from "../../assets/images/anih.png";
import romilah from "../../assets/images/romilah.png";

import { RegReseller, upgradeAccount } from "../../services/axios";

import midtransClientKey from "../../config/midtransClientKey";
import ReactMidtrans from "../../config/midtrans";
import { getUserDetails } from "../../services/Reseller/reseller.service";
import { useHistory } from "react-router-dom";

const BENEFITS_DATA = [
  "Membership Idejualan SELAMANYA Senilai Rp. 500.000",
  "12 Video Materi WhatsApp Marketing Senilai Rp. 297.000",
  "- Social Media Branding Kit Senilai Rp. 199.000",
  "- 101 Pro Music Track Pack Senilai Rp. 178.000",
  "- 1500 Stock Images Blowout Senilai Rp. 189.000",
  "- Wow Presentation 2 Senilai Rp. 245.000",
  "- eCover Smart Templates Senilai Rp. 199.000",
  "- Sales Presentation Graphics Pack Senilai Rp 199.000",
  "- Time Management Graphics Pack Senilai Rp 199.000",
  "-  Public Speaking Graphics Pack Senilai Rp 199.000",
  "- Web Security Graphics Senilai Rp 199.000",
  "- Money Graphics Pack Senilai Rp 199.000",
  "-Master Email Marketing Senilai Rp 199.000",
  "-LANDING PAGE VIDEO Senilai Rp 199.000",
  "TOTAL SENILAI Rp. 3.000.0000",
];

const REVIEW_DATA = [
  {
    name: "Ely",
    image: ely,
    review:
      "Mudah benget hanya dari HP udah bisa dapat tambahan penghasilan. Produk-produk bagus untuk customer saya",
  },
  {
    name: "Ditya",
    image: ditya,
    review:
      "Ini aplikasi sangat membantu untuk menjual barang dengan mudah. Sangat Praktis dg produk-produk yang sudah pasti alris",
  },
  {
    name: "Anih",
    image: anih,
    review:
      "Alhamdulillah sangat bersyukur dengan adanya aplikasi idejulan dari keuntungan jadi reseller aku bisa membahagiakan pasangan",
  },
  {
    name: "Romilah",
    image: romilah,
    review:
      "Seneng banget gabung dengan idejualan, dibimbing sama mentor mentor handal dan asik , sampai bisa jualan online. Supportnya juga sangat memuaskan",
  },
];

const useStyles = makeStyles((theme) => ({
  wrapper: {
    boxShadow: "0 0 0.25em rgb(0 0 0 / 30%)",
    padding: "32px 64px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.down("501")]: {
      padding: "32px 10px",
    },
  },
  title: {
    textAlign: "center",
    color: "#252525",
  },
  formContainer: {
    "& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-fullWidth": {
      marginBottom: "16px",
    },
  },
  submitButton: {
    color: "#fff",
    backgroundColor: "#3bb877",
  },
}));

const RegisterPage = () => {
  const classes = useStyles();
  const history = useHistory()

  const isMobile = useMediaQuery("(max-width:500px)");

  const query = new URLSearchParams(window.location.search);
  const ref = query.get("ref") ?? localStorage.getItem("ref") ?? "";

  const [formData, setFormData] = React.useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    storefront_name: "",
    ref: "",
  });
  const [isVip, setIsVip] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [snap, setSnap] = React.useState(null);
  const [metodePembayaran, setmetodePembayaran] = React.useState('other')

  const data = localStorage.getItem("USER_DATA");

  useEffect(() => {
    if (data) {
      const userData = JSON.parse(data);
      if (userData && userData.level && userData.level.id === 1) {
        initSnap();
      }
    }
  }, []);

  useEffect(() => {
    const upgrade = async () => {
      if (snap) {
        const parsedData = JSON.parse(data);
        try {
          const upgradeRes = await upgradeAccount(parsedData.id);
          snap.pay(upgradeRes.token);
        } catch (error) {
          if (error) {
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.message) {
                  swal("Error!", error.response.data.message, "error");
                  return;
                }
              }
            }
          }
          swal("Error!", "Terjadi kesalahan silakan coba lagi", "error");
          return;
        }
      }
    };
    upgrade();
  }, [snap]);

  useEffect(() => {
    if (ref) {
      setFormData((prevState) => ({
        ...prevState,
        ref: ref,
      }));

      // save ref to local storage
      localStorage.setItem("ref", ref);
    }
  }, []);

  const initSnap = () => {
    const { NODE_ENV: ENV } = process.env;

    // create element for script
    const snapScript = document.createElement("script");

    // checking environment mode
    snapScript.src =
      ENV === "production"
        ? "https://app.midtrans.com/snap/snap.js"
        : "https://app.sandbox.midtrans.com/snap/snap.js";

    snapScript.type = "text/javascript";
    snapScript.onload = () => {
      if ("snap" in window) {
        const { snap } = window;
        setSnap(snap);
      }
    };
    snapScript.dataset.clientKey = midtransClientKey;
    document.head.appendChild(snapScript);
  };

  const handleChangeFormData = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    const { name, email, phone, password, storefront_name } = formData;

    if (name === "" || email === "" || phone === "" || password === "") {
      swal("Oops", "Lengkapi data terlebih dahulu", "error");
      return;
    } else if (password.length < 6) {
      swal("Oops", "Password minimal 6 karakter", "error");
      return;
    }

    try {
      setIsLoading(true);
      const payload = {
        name,
        email,
        phone,
        password,
        storefront_name,
      };

      if (formData.ref) {
        payload.ref = formData.ref;
      }

      const regsiterRes = await RegReseller(payload);

      localStorage.setItem("TOKEN", regsiterRes.access_token);
      localStorage.setItem("REGISTER_USER_ID", regsiterRes.user.id);

      const upgradeRes = await upgradeAccount(regsiterRes.user.id, {
        vip: isVip ? 1 : 0,
      });

      return upgradeRes.token;
    } catch (error) {
      if (error) {
        if (error.response) {
          if (error.response.data) {
            if (error.response.data.message) {
              swal("Error!", error.response.data.message, "error");
              return;
            }
          }
        }
      }
      swal("Error!", "Terjadi kesalahan silakan coba lagi", "error");
      return;
    } finally {
      setIsLoading(false);
    }
  };

  const handleMetodePembayaran = (e) => {
    setmetodePembayaran(e.target.value)
  }

  const handleToBankTransfer = async() => {
    const { name, email, phone, password, storefront_name } = formData;

    if (name === "" || email === "" || phone === "" || password === "") {
      swal("Oops", "Lengkapi data terlebih dahulu", "error");
      return;
    } else if (password.length < 6) {
      swal("Oops", "Password minimal 6 karakter", "error");
      return;
    }

    try {
      setIsLoading(true);
      const payload = {
        name,
        email,
        phone,
        password,
        storefront_name,
      };

      if (formData.ref) {
        payload.ref = formData.ref;
      }

      const regsiterRes = await RegReseller(payload);

      localStorage.setItem("TOKEN", regsiterRes.access_token);
      localStorage.setItem("REGISTER_USER_ID", regsiterRes.user.id);

      const upgradeRes = await upgradeAccount(regsiterRes.user.id, {
        vip: isVip ? 1 : 0,
        pay_with_vendorbank: 1
      });

      localStorage.setItem('nominalTf', `${upgradeRes.data.amount}`);

      return upgradeRes.token;
    } catch (error) {
      if (error) {
        if (error.response) {
          if (error.response.data) {
            if (error.response.data.message) {
              swal("Error!", error.response.data.message, "error");
              return;
            }
          }
        }
      }
      swal("Error!", "Terjadi kesalahan silakan coba lagi", "error");
      return;
    } finally {
      setIsLoading(false);
      history.push('/register/bank-transfer')
    }
  }

  return (
    <Box>
      <Container maxWidth="sm">
        <h1 className={classes.title}>Pendaftaran Reseller Idejualan</h1>
        <div className={classes.wrapper}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <img
              src={secureTransaction}
              alt="secureTransaction"
              width={isMobile ? "50%" : "100%"}
            />
            <img
              src={satisfactionIcon}
              alt="satisfaction"
              width={isMobile ? "50%" : "100%"}
            />
          </Box>
          <Box my={3}>
            <img src={membershipPackage} alt="membership" width="100%" />
          </Box>
          <h3>
            Jika kamu Transfer Sekarang ini Kamu Dapat Bonus Senilai
            Rp.3.000.000 :
          </h3>
          <Box mb={2}>
            {BENEFITS_DATA.map((benefit, index) => (
              <Box display="flex" alignItems="center" key={index}>
                <Check style={{ color: "green" }} />
                <h4>{benefit}</h4>
              </Box>
            ))}
          </Box>
          <h3>Data Anda :</h3>
          <Box className={classes.formContainer}>
            <OutlinedInput
              placeholder="Nama anda"
              fullWidth
              value={formData.name}
              onChange={handleChangeFormData}
              name="name"
            />
            <OutlinedInput
              placeholder="No whatsapp anda"
              fullWidth
              value={formData.phone}
              onChange={handleChangeFormData}
              name="phone"
            />
            <OutlinedInput
              placeholder="Nama toko"
              fullWidth
              value={formData.storefront_name}
              onChange={handleChangeFormData}
              name="storefront_name"
            />
            <OutlinedInput
              placeholder="Email"
              fullWidth
              value={formData.email}
              onChange={handleChangeFormData}
              name="email"
            />
            <OutlinedInput
              placeholder="Password untuk aplikasi idejualan anda"
              fullWidth
              value={formData.password}
              onChange={handleChangeFormData}
              name="password"
              type="password"
            />
            <OutlinedInput
              placeholder="Kode referral"
              fullWidth
              value={formData.ref}
              onChange={handleChangeFormData}
              name="ref"
            />
          </Box>
          <h3>Metode Pembayaran :</h3>
          <Box className={classes.formContainer}>
            <RadioGroup aria-label="gender" name="gender1" value={metodePembayaran} onChange={handleMetodePembayaran}>
              <FormControlLabel value="bank" control={<Radio />} label="Bank Transfer" />
              <FormControlLabel value="other" control={<Radio />} label="Pembayaran Otomatis" />
            </RadioGroup>
          </Box>
          <br/>
          <Box border="1px dashed red" bgcolor="#fefbe1" p={2}>
            <Box
              my={1}
              p={1}
              bgcolor="#f5d764"
              display="flex"
              alignItems="center"
            >
              <Checkbox
                checked={isVip}
                onChange={(e) => setIsVip(e.target.checked)}
              />
              <h3>Centang untuk jadi VIP Member</h3>
            </Box>
            <Typography
              style={{
                color: "red",
                textDecoration: "underline",
                marginBottom: 8,
                fontWeight: 600,
              }}
            >
              VIP Member Hanya + Rp. 100.000,-
            </Typography>
            <img src={vipPlusPackage} alt="vipPlusPackage" width="100%" />
            <Box mt={1}>
              <Typography variant="subtitle2">
                Apa yang di dapat oleh VIP Member idejualan ?
              </Typography>
              <br />
              <Typography variant="subtitle2">
                1. Kelas Jualan <br />- 7 Kelas Jualan dengan masing-masing
                berisi lebih dari 20 video materi yang isinya Daging banget.
              </Typography>
              <br />
              <Typography variant="subtitle2">
                2. Video Endorse Produk dari 20++ Selebgram
              </Typography>
              <br />
              <Typography variant="subtitle2">
                3. Bonus Tambahan
                <br />- Grup VIP Member
                <br />- Diajarin cara jualan dengan mentor handal
                <br />- Webinar atau live setiap minggu dengan mentor handal
                <br />- Dapat Video hasil Live atau Webinar
              </Typography>
            </Box>
          </Box>
          <Box my={2} bgcolor="#fcfcfe" border="1px solid #41c3cf" p={1}>
            <Box mb={1}>
              <Typography
                variant="caption"
                style={{ textDecoration: "underline" }}
              >
                RINCIAN PESANAN:
              </Typography>
            </Box>
            <Box mb={1} display="flex" justifyContent="space-between">
              <Typography variant="caption">Pendaftaran reseller</Typography>
              <Typography variant="caption">
                Rp 99.000{" "}
                <span style={{ color: "red", textDecoration: "line-through" }}>
                  Rp 500.000
                </span>
              </Typography>
            </Box>
            {isVip && (
              <Box mb={1} display="flex" justifyContent="space-between">
                <Typography variant="caption">
                  VIP Member <br />
                  Idejualan
                </Typography>

                <Typography variant="caption">Rp 100.000</Typography>
              </Box>
            )}
            <Box mb={1} border="0.8px solid #eaeaea" />
            <Box display="flex" justifyContent="space-between">
              <Typography variant="caption" style={{ fontWeight: 700 }}>
                Total
              </Typography>

              <Typography variant="caption" style={{ fontWeight: 700 }}>
                Rp {isVip ? "199.000" : "99.000"}
              </Typography>
            </Box>
          </Box>
          {
            metodePembayaran === 'other' ? 
            <ReactMidtrans
              getToken={() => handleSubmit()}
              clientKey={midtransClientKey}
            >
              <Button
                className={classes.submitButton}
                variant="contained"
                fullWidth
                size="large"
                endIcon={<ArrowForward />}
                disabled={isLoading}
              >
                {isLoading ? "Mohon tunggu...." : "DAFTAR SEKARANG KLIK"}
              </Button>
            </ReactMidtrans>
            :
            <Button
              className={classes.submitButton}
              variant="contained"
              fullWidth
              size="large"
              endIcon={<ArrowForward />}
              disabled={isLoading}
              onClick={() => {handleToBankTransfer()}}
            >
              {isLoading ? "Mohon tunggu...." : "DAFTAR SEKARANG KLIK"}
            </Button>
          }
        </div>
      </Container>
      <Container maxWidth="sm">
        <Box bgcolor="#69cbd4" padding={`32px ${isMobile ? "16px" : "64px"}`}>
          {REVIEW_DATA.map((review, i) => (
            <Box display="flex" alignItems="center" mb={3}>
              <img src={review.image} alt="review" width={86} height={86} />
              <Box ml={2} color="#fff">
                <Typography variant="h6">{review.name}</Typography>
                <Typography variant="caption">"{review.review}"</Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default RegisterPage;
