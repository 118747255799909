import React, { Fragment } from "react";
import CardProduct from "../../../../../components/CardProduct";
import { Grid, Typography } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

const Content = (props) => {
  const { category, data, handleChangePage } = props;
  const category_name = category
    .split('_').join(' ')
    .slice(0, category.indexOf("-cat."));

  return (
    <Fragment>
      <h1 style={{ textAlign: "center", textTransform: 'capitalize' }}>{category_name}</h1>
      {data.data.length === 0 ? (
        <Typography style={{ textAlign: "center", paddingTop: 30 }}>
          Data tidak ditemukan.
        </Typography>
      ) : (
        <Fragment>
          <Grid container spacing={2} style={{ paddingTop: 30 }}>
            {data.data.map((res, index) => (
              <Grid item xs={3} key={index}>
                <CardProduct variant="reseller" data={res} />
              </Grid>
            ))}
          </Grid>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: 30,
            }}
          >
            <Pagination
              count={data.meta.last_page}
              siblingCount={data.meta.current_page}
              variant="outlined"
              shape="rounded"
              color="primary"
              onChange={(event, index) => handleChangePage(index)}
            />
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Content;
