import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import { numberSeparator } from "../../../../utils/numberSeparator";
import Navbar from "../../../../components/navbar";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    justifyContent: "space-between",
  },
}));

const OpsiPengiriman = (props) => {
  const classes = useStyles();
  const { data, handleChangeShippment, customer, handleClose, myIndex } = props;
  const [openExpand, setOpenExpand] = useState("");

  const handleExpand = (code) => {
    if (openExpand === code) {
      setOpenExpand("");
    } else {
      setOpenExpand(code);
    }
  };

  return (
    <Fragment>
      <Navbar text="Pilih Opsi Pengiriman" onClick={() => handleClose()} />

      <div style={{fontSize: '1.1rem', fontWeight: 'bold', marginLeft: 10}}>Cargo</div>
      <List>
        {data &&
          data.list_ongkir.cargo.map((res, index) => {
            return (
              <Fragment>
                {res.length !== 0 && (
                  <ListItem
                    key={index}
                    button
                    onClick={() => handleExpand(res.courier_code)}
                  >
                    <ListItemText primary={res.name} />
                    {openExpand === res.courier_code ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                )}
                <Collapse
                  in={openExpand === res.courier_code}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    <ListItem
                      button
                      className={classes.nested}
                      onClick={() => {
                        handleChangeShippment(
                          res,
                          data.supplier,
                          customer,
                          myIndex
                        );
                        setOpenExpand("");
                      }}
                    >
                      <div style={{ display: "block" }}>
                        <Typography>
                          {
                            res.logistic_name
                          }
                        </Typography>

                        <Typography variant="caption">
                          Perkiraan Pengiriman{" "}
                          {
                            res.min_duration 
                            +
                            '-'
                            +
                            res.max_duration
                          } hari
                        </Typography>
                      </div>
                      <Typography color="primary">
                        Rp {numberSeparator(res.price)}
                      </Typography>
                    </ListItem>
                  </List>
                </Collapse>
              </Fragment>
            );
          })}
      </List>

      <div style={{fontSize: '1.1rem', fontWeight: 'bold', marginLeft: 10}}>Express</div>
      <List>
        {data &&
          data.list_ongkir.express.map((res, index) => {
            return (
              <Fragment>
                {res.length !== 0 && (
                  <ListItem
                    key={index}
                    button
                    onClick={() => handleExpand(res.courier_code)}
                  >
                    <ListItemText primary={res.name} />
                    {openExpand === res.courier_code ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                )}
                <Collapse
                  in={openExpand === res.courier_code}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    <ListItem
                      button
                      className={classes.nested}
                      onClick={() => {
                        handleChangeShippment(
                          res,
                          data.supplier,
                          customer,
                          myIndex
                        );
                        setOpenExpand("");
                      }}
                    >
                      <div style={{ display: "block" }}>
                        <Typography>
                          {
                            res.logistic_name
                          }
                        </Typography>

                        <Typography variant="caption">
                          Perkiraan Pengiriman{" "}
                          {
                            res.min_duration
                            +
                            '-'
                            +
                            res.max_duration
                          } hari
                        </Typography>
                      </div>
                      <Typography color="primary">
                        Rp {numberSeparator(res.price)}
                      </Typography>
                    </ListItem>
                  </List>
                </Collapse>
              </Fragment>
            );
          })}
      </List>

      <div style={{fontSize: '1.1rem', fontWeight: 'bold', marginLeft: 10}}>Instant</div>
      <List>
        {data &&
          data.list_ongkir.instant.map((res, index) => {
            return (
              <Fragment>
                {res.length !== 0 && (
                  <ListItem
                    key={index}
                    button
                    onClick={() => handleExpand(res.courier_code)}
                  >
                    <ListItemText primary={res.name} />
                    {openExpand === res.courier_code ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                )}
                <Collapse
                  in={openExpand === res.courier_code}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    <ListItem
                      button
                      className={classes.nested}
                      onClick={() => {
                        handleChangeShippment(
                          res,
                          data.supplier,
                          customer,
                          myIndex
                        );
                        setOpenExpand("");
                      }}
                    >
                      <div style={{ display: "block" }}>
                        <Typography>
                          {
                            res.logistic_name
                          }
                        </Typography>

                        <Typography variant="caption">
                          Perkiraan Pengiriman{" "}
                          {
                            res.min_duration
                            +
                            '-'
                            +
                            res.max_duration
                          } hari
                        </Typography>
                      </div>
                      <Typography color="primary">
                        Rp {numberSeparator(res.price)}
                      </Typography>
                    </ListItem>
                  </List>
                </Collapse>
              </Fragment>
            );
          })}
      </List>

      <div style={{fontSize: '1.1rem', fontWeight: 'bold', marginLeft: 10}}>Regular</div>
      <List>
        {data &&
          data.list_ongkir.regular.map((res, index) => {
            return (
              <Fragment>
                {res.length !== 0 && (
                  <ListItem
                    key={index}
                    button
                    onClick={() => handleExpand(res.courier_code)}
                  >
                    <ListItemText primary={res.name} />
                    {openExpand === res.courier_code ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                )}
                <Collapse
                  in={openExpand === res.courier_code}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    <ListItem
                      button
                      className={classes.nested}
                      onClick={() => {
                        handleChangeShippment(
                          res,
                          data.supplier,
                          customer,
                          myIndex
                        );
                        setOpenExpand("");
                      }}
                    >
                      <div style={{ display: "block" }}>
                        <Typography>
                          {
                            res.logistic_name
                          }
                        </Typography>

                        <Typography variant="caption">
                          Perkiraan Pengiriman{" "}
                          {
                            res.min_duration
                            +
                            '-'
                            +
                            res.max_duration
                          } hari
                        </Typography>
                      </div>
                      <Typography color="primary">
                        Rp {numberSeparator(res.price)}
                      </Typography>
                    </ListItem>
                  </List>
                </Collapse>
              </Fragment>
            );
          })}
      </List>

      <div style={{fontSize: '1.1rem', fontWeight: 'bold', marginLeft: 10}}>Same Day</div>
      <List>
        {data &&
          data.list_ongkir.same_day.map((res, index) => {
            return (
              <Fragment>
                {res.length !== 0 && (
                  <ListItem
                    key={index}
                    button
                    onClick={() => handleExpand(res.courier_code)}
                  >
                    <ListItemText primary={res.name} />
                    {openExpand === res.courier_code ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                )}
                <Collapse
                  in={openExpand === res.courier_code}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    <ListItem
                      button
                      className={classes.nested}
                      onClick={() => {
                        handleChangeShippment(
                          res,
                          data.supplier,
                          customer,
                          myIndex
                        );
                        setOpenExpand("");
                      }}
                    >
                      <div style={{ display: "block" }}>
                        <Typography>
                          {
                            res.logistic_name
                          }
                        </Typography>

                        <Typography variant="caption">
                          Perkiraan Pengiriman{" "}
                          {
                            res.min_duration
                            +
                            '-'
                            +
                            res.max_duration
                          } hari
                        </Typography>
                      </div>
                      <Typography color="primary">
                        Rp {numberSeparator(res.price)}
                      </Typography>
                    </ListItem>
                  </List>
                </Collapse>
              </Fragment>
            );
          })}
      </List>
    </Fragment>
  );
};

export default OpsiPengiriman;
