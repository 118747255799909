import { useMediaQuery } from '@material-ui/core'
import React,{useState, useEffect} from 'react'
import { getPaymentsCod } from '../../../services/order.service'
import Desktop from './desktop'
import Mobile from './mobile'

const ConfirmationCodPage = ({match}) => {
    const [dataOrder, setdataOrder] = useState()

    useEffect(() => {
        const getAllDataOrder = async() => {
            const response = await getPaymentsCod({
                [`filter[id]`]: match.params.id
            })
            setdataOrder(response.data[0])
        }

        getAllDataOrder()
    }, [])

    const isMobile = useMediaQuery("(max-width:500px)");
    return <React.Fragment>{isMobile ? <Mobile dataOrder={dataOrder} /> : <Desktop dataOrder={dataOrder} />}</React.Fragment>;
}

export default ConfirmationCodPage