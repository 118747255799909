import React, { Fragment, useState, useEffect } from "react";
import Navbar from "../../../components/navbar";
import Footer from "../../../components/footer";
import {
  Container,
  Grid,
  Typography,
  Checkbox,
  Divider,
  Button,
} from "@material-ui/core";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import { numberSeparator } from "../../../utils/numberSeparator";
import { quantity, remove, update } from "cart-localstorage";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import { withRouter } from "react-router-dom";
import Checkout from "./components/Checkout";

const Desktop = withStyles(styles)((props) => {
  const { classes, history } = props;
  const [cart, setCart] = useState(JSON.parse(localStorage.getItem("__cart")));
  const [selectedCart, setSelectedCart] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [listPrice, setListPrice] = useState([]);

  const getListPrice = async () => {
    let list = [];
    await selectedCart.forEach((res) => {
      list = [...list, res.price * res.quantity];
    });
    setListPrice(list);
  };

  useEffect(() => {
    getListPrice();
  }, [selectedCart]);

  useEffect(() => {
    const updateCart = async () => {
      const getCart = JSON.parse(localStorage.getItem("__cart"));
      await setCart(getCart);
    };
    updateCart();
    getListPrice();
  }, [isUpdated]);

  const handleRemoveItem = async (id) => {
    await remove(id);

    // fungsi untuk menghapus barang yang sudah di checklist
    if (selectedCart.length !== 0) {
      setSelectedCart(selectedCart.filter((value) => value.id !== id));
    }
    setIsUpdated(!isUpdated);
  };

  const handlePlus = async (id) => {
    await quantity(id, 1);
    if (selectedCart.length !== 0) {
      setSelectedCart([
        ...selectedCart.filter((value) => value.id !== id),
        {
          ...selectedCart.filter((value) => value.id === id)[0],
          quantity:
            selectedCart.filter((value) => value.id === id)[0].quantity + 1,
        },
      ]);
    }
    setIsUpdated(!isUpdated);
  };

  const handleMin = async (id) => {
    await quantity(id, -1);
    if (selectedCart.length !== 0) {
      setSelectedCart([
        ...selectedCart.filter((value) => value.id !== id),
        {
          ...selectedCart.filter((value) => value.id === id)[0],
          quantity:
            selectedCart.filter((value) => value.id === id)[0].quantity - 1,
        },
      ]);
    }
    setIsUpdated(!isUpdated);
  };

  const handleInput = async (id, value) => {
    await update(id, "quantity", value);
    setIsUpdated(!isUpdated);
  };

  const handlePilihSemua = () => {
    if (cart.length === selectedCart.length) {
      setSelectedCart([]);
    } else {
      setSelectedCart(cart);
    }
  };

  const handleSelectedCart = async (data) => {
    const index = selectedCart.findIndex((value) => value.id === data.id);
    const currentCart = selectedCart.filter((item) => item.id === data.id)[0];
    if (Boolean(currentCart) === Boolean(data)) {
      if (index > -1 && index !== 0) {
        setSelectedCart(selectedCart.filter((item) => item.id !== data.id));
      } else if (index === 0 && selectedCart.length === 1) {
        setSelectedCart([]);
      } else {
        setSelectedCart(selectedCart.filter((item) => item.id !== data.id));
      }
    } else {
      setSelectedCart([...selectedCart, data]);
    }
  };

  return (
    <Fragment>
      <Navbar variant="default" />
      <Container maxWidth="md" className={classes.container}>
        {cart === null || cart.length === 0 ? (
          <Fragment>
            <div className={classes.center} style={{ marginTop: 60 }}>
              <div style={{ textAlign: "center", height: "18vh" }}>
                <ShoppingBasketIcon className={classes.iconCart} />
                <Typography>Tidak ada produk dikeranjang.</Typography>
                <Button
                  color="primary"
                  variant="contained"
                  style={{ marginTop: 20 }}
                  onClick={() =>
                    history.push(`/store/${sessionStorage.getItem("reseller")}`)
                  }
                >
                  Belanja sekarang
                </Button>
              </div>
            </div>
          </Fragment>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Fragment>
                <div className={classes.cardBackground}>
                  <div className={classes.flexCenter}>
                    <Checkbox
                      color="primary"
                      checked={cart.length === selectedCart.length}
                      onClick={handlePilihSemua}
                    />
                    <Typography>
                      <b>Pilih Semua</b>
                    </Typography>
                    <Typography variant="caption" style={{ paddingLeft: 10 }}>
                      {selectedCart.length !== 0 &&
                        `(${selectedCart.length} barang terpilih)`}
                    </Typography>
                  </div>
                  <Divider />
                  {/* Nama Barang/Produk */}
                  <div style={{ paddingTop: 20 }}>
                    {cart.map((res) => (
                      <Fragment>
                        <div style={{ display: "flex" }}>
                          <div>
                            <Checkbox
                              onClick={() => handleSelectedCart(res)}
                              color="primary"
                              checked={Boolean(
                                selectedCart[
                                  selectedCart.findIndex(
                                    (value) => value.id === res.id
                                  )
                                ]
                              )}
                            />
                          </div>
                          <img
                            src={res.img}
                            alt={res.name}
                            className={classes.productImage}
                          />
                          <div className={classes.contentWrapper}>
                            <Typography
                              className={classes.productName}
                              onClick={() =>
                                history.push(`/product/${res.slug}`)
                              }
                            >
                              <b>{res.name}</b>
                            </Typography>
                            <div className={classes.contentText2}>
                              <div>
                                <Typography variant="caption">
                                  <b>Variasi : </b>
                                </Typography>
                                <Typography variant="caption">
                                {res.selectedSkus.properties.color}, {res.selectedSkus.properties.size}
                                </Typography>
                              </div>
                              <Typography variant="caption" color="primary">
                                @RP {numberSeparator(res.price)}
                              </Typography>
                            </div>
                            <div className={classes.actionWrapper}>
                              <div className={classes.groupedButton}>
                                <Button
                                  className={classes.button}
                                  onClick={() => handleMin(res.id)}
                                >
                                  -
                                </Button>
                                <input
                                  type="number"
                                  className={classes.styledInput}
                                  value={res.quantity}
                                  onChange={(e) =>
                                    handleInput(res.id, e.target.value)
                                  }
                                  onKeyDown={(event) =>
                                    event.key === "ArrowUp"
                                      ? handlePlus(res.id)
                                      : event.key === "ArrowDown" &&
                                        handleMin(res.id)
                                  }
                                />
                                <Button
                                  className={classes.button}
                                  onClick={() => handlePlus(res.id)}
                                >
                                  +
                                </Button>
                              </div>

                              <Typography>
                                <b style={{ fontSize: 25 }}>
                                  Rp
                                  {numberSeparator(res.price * res.quantity)}
                                </b>
                              </Typography>
                            </div>
                            <Typography
                              className={classes.removeItem}
                              onClick={() => handleRemoveItem(res.id)}
                            >
                              Hapus barang
                            </Typography>
                          </div>
                        </div>
                      </Fragment>
                    ))}
                  </div>
                </div>
              </Fragment>
            </Grid>
            <Grid item xs={4} className={classes.flexEnd}>
              <Checkout
                listPrice={listPrice}
                setListPrice={setListPrice}
                selectedCart={selectedCart}
                isUpdated={isUpdated}
                setIsUpdated={setIsUpdated}
                cart={cart}
                setCart={setCart}
                setSelectedCart={setSelectedCart}
              />
            </Grid>
          </Grid>
        )}
      </Container>
      <Footer />
    </Fragment>
  );
});

export default withRouter(Desktop);
