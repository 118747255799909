import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Mobile from "./mobile";
import Desktop from "./desktop";

const CheckoutPage = () => {
  React.useEffect(() => {
    return () => {
      localStorage.removeItem("SELECTED_CART");
    };
  }, []);

  const isMobile = useMediaQuery("(max-width:500px)");
  return <React.Fragment>{isMobile ? <Mobile /> : <Desktop />}</React.Fragment>;
};

export default CheckoutPage;
