import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Mobile from "./mobile";
import Desktop from "./desktop";
import { useHistory } from "react-router-dom";

const ConfirmPage = () => {
  const history = useHistory()
  const url = new URL(window.location.href);
  const orderId = url.searchParams.get("order_id");
  const splitOrderId = orderId?.split("_") || [];
  const isMemberhood = splitOrderId[0] === "mb";
  if(isMemberhood) {
    const subdomain = splitOrderId[1];
    window.location.href = `https://${subdomain}.memberhood.id`;
  }
  
  React.useEffect(() => {
    if (window.location.href.includes('campaign')) {
      const dataCampaign = JSON.parse(localStorage.getItem('data-campaign'))
      history.push(`/payment-campaign?campaign_slug=${dataCampaign.campaign_slug}`)
    }
  }, [])
  

  const isMobile = useMediaQuery("(max-width:500px)");
  return <React.Fragment>{isMobile ? <Mobile /> : <Desktop />}</React.Fragment>;
};

export default ConfirmPage;
