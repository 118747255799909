import styled from 'styled-components'

export const WrapHome = styled.div`
  position: relative;

  .wrapSearch {
    position: relative;
    display: flex;
    .left {width: 85%;}
    .MuiOutlinedInput-notchedOutline {
      border:none;
    }
    .MuiOutlinedInput-root{
      background: #fff;
    }
  }
`

export const WrapCategory = styled.div`
  background: #FFFFFF;
  border-radius: 14px 14px 0px 0px;
  padding: 5px;
  position: relative;
  bottom: 16px;
`

export const CategoryIcon = styled.div`
cursor: pointer;
  .logo{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #F6F6F6;
    padding: 10px;
    display:flex;
    align-items: center;
    margin: auto;
    img {
      width: 100%
    }
  }
  .text {
      font-size: 10px;
      color: #666666;
      margin: 0; padding: 0;
      text-align: center;
      width: 60px;
    }
`

export const MyCart = styled.div`
  position: absolute;
  right: 0;
  cursor: pointer;
  top: 4px;

  .count {
    width: 18px;
    height: 18px;
    border-radius: 36px;
    font-size: 11px;
    font-family: "DMSans-Regular";
    color: #fff;
    background-color: #f54646;
    right: 0;
    text-align: center;
    padding: 2px;
    top: -4px;
    position: absolute;
    z-index: 1;
  }
  svg {
    position: relative;
    right: 8px;
  }
`;