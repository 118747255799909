import React from 'react';
import iconInfoCircle from "../../assets/icons/info-circle.svg"
import { WrapInformationMessage } from './style';

const index = ({text}) => {
  return (
    <WrapInformationMessage>
       <div className='btnIcon'>
       <div className="bodyBtnIcon">
          <div className="icon">
            <img src={iconInfoCircle} alt='withdraw' />
          </div>
          {text}
          </div>
       </div>
    </WrapInformationMessage>
  );
};

export default index;