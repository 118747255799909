import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextareaAutosize, TextField, useMediaQuery } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import { TitlePage } from '../../components';
import Navbar from '../../components/navbar';
import { editStorefront, getStorefront } from '../../services/axios';
import { getUserDetails } from '../../services/Reseller/reseller.service';
import styles from "./style.module.css";

const EditTokoPage = (props) => {
  const isMobile = useMediaQuery("(max-width:500px)");
  const [state, setstate] = useState({
    name: '',
    description: '',
    avatar: '',
    avatarUrl: '',
    slug: ''
  })

  // manage state
  useEffect(() => {
    const getAllDataStorefront = async(slug) => {
      const response = await getStorefront(slug)
      setstate({
        name: response.data.name,
        avatar: '',
        avatarUrl: response.data.avatar_url,
        description: response.data.description,
        slug: response.data.slug
      })
    }
    
    if (localStorage.getItem('USER_DATA')) {
      const userData = JSON.parse(localStorage.getItem('USER_DATA'))
      getAllDataStorefront(userData.storefront.slug)
    }
  }, [])

  const handleChangeInput = (event) => {
    setstate({...state, [event.target.name]: event.target.value})
  }

  // submit
  const submit = async(event) => {
    event.preventDefault()
    const dataToUpload = JSON.parse(JSON.stringify(state))

    // delete slug
    delete dataToUpload.slug

    // delete avatar url
    delete dataToUpload.avatarUrl

    // delete avatar when no update
    if (state.avatar === '') {
      delete dataToUpload.avatar
    }

    // convert to formdata
    const formData = new FormData();
    for (var key in dataToUpload) {
      if (key === 'avatar') {
        formData.append(key, state.avatar);
      }else{
        formData.append(key, dataToUpload[key]);
      }
    }

    // hit api
    try {
      const response = await editStorefront(state.slug, formData)
      if (response) {
        await localStorage.setItem('STORE_FRONT', JSON.stringify(response.data.slug))
        swal("Success!", "Your Storefront Updated!", "success").then(async() => {
          const responseReseller = await getUserDetails(response.data.owner.id)
          await localStorage.setItem('USER_DATA', JSON.stringify(responseReseller.data))
          window.location.reload()
        })
      }
    } catch (error) {
      swal("Error!", error, "error")
    }
  }

  return (
    <div style={!isMobile ? {maxWidth: 960, padding: 24, position: 'absolute', margin: '0 auto', left: 0, right:0} : {}}>
      <Navbar variant={"primary"} />
      <Box style={{backgroundColor: 'white'}}>
        <Grid item xs={12} style={{ padding: 16 }}>
          <TitlePage onBack={() => props.history.goBack()}>Edit Toko</TitlePage>
        </Grid>
        <Box style={{padding: 20, width: '100%'}}>
          <form onSubmit={(event) => {submit(event)} }>
            <Grid 
              container
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12}>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <div className={styles.photo}>
                    {state.avatarUrl !== '' ? (
                      <img src={state.avatarUrl} alt="Preview" />
                    ) : (
                      <>
                        <div>Image Avatar</div>
                        <Add />
                      </>
                    )}
                    <input
                      name="image"
                      accept="image/*"
                      type="file"
                      onChange={(event) => {
                        const file = event.target.files[0];
                        const url = URL.createObjectURL(file);
                        setstate({...state, avatar: file, avatarUrl: url})
                      }}
                    />
                  </div>
                </div>
              </Grid>

              {/* nama */}
              <Grid item xs={12}>
                <TextField
                  name='name'
                  label="Nama Toko" 
                  style={{width: '100%'}} 
                  variant="outlined"
                  value={state.name}
                  onChange={(event) => handleChangeInput(event)}
                  />
              </Grid>

              {/* description */}
              <Grid item xs={12}>
                <TextareaAutosize
                  name='description'
                  placeholder="Description"
                  style={{ 
                    width: "100%", 
                    padding: '18.5px 14px', 
                    height: 150, 
                    fontFamily: `"Roboto", "Helvetica", "Arial", 'sans-serif'`, 
                    fontSize: '1rem' 
                  }}
                  variant="outlined"
                  value={state.description}
                  onChange={(event) => handleChangeInput(event)}
                  />
              </Grid>

              <Grid item xs={12} style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button type='submit' variant="contained" color="primary">Simpan</Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </div>
  );
};

export default EditTokoPage;