import { Grid, useMediaQuery } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { CardProductWishlist, SearchCustom, TitlePage } from "../../components";
import Navbar from "../../components/navbar";
import { ReactComponent as IconCart } from "../../assets/icons/shop_cart.svg";
import { MyCart } from "./style";
import { GET_WISHLIST, REMOVE_WISHLIST } from "../../services/wishlist.service";
import { useAuthContext } from "../../contexts/AuthContextProvider";
import { ADD_SINGLE_STOREFRONT_PRODUCT, DELETE_SINGLE_STOREFRONT_PRODUCT } from "../../services/storefront.service";
import swal from "sweetalert";

const WishlistPage = (props) => {
  const isMobile = useMediaQuery("(max-width:500px)");
  const [dataWishlist, setDataWishlist] = useState([]);
  const { user } = useAuthContext();
  const [isRefetch, setIsRefetch] = useState(false);
  const [filters, setFilters] = useState({
    keyword: ''
  })

  const handleAddToStore = async (slug) => {
    await ADD_SINGLE_STOREFRONT_PRODUCT(user.storefront.slug, slug)
      .then((res) => {
        setIsRefetch(true);
        swal("Success", "Berhasil menambah product ke toko", "success");
      })
      .catch(() => {
        swal("Error", "Gagal menambah product ke toko", "error");
      });
  };

  const handleDeleteWishlist = async (slug) => {
    await REMOVE_WISHLIST(slug)
      .then((res) => {
        setIsRefetch(true);
        swal("Success", "Berhasil menghapus product dari wishlist", "success");
      })
      .catch(() => {
        swal("Error", "Gagal menghapus product dari wishlist", "error");
      });
  };

  const handleDeleteProductStorefront = async (slug) => {
    await DELETE_SINGLE_STOREFRONT_PRODUCT(user.storefront.slug, slug)
      .then((res) => {
        setIsRefetch(true);
        swal("Success", "Berhasil menghapus product dari toko", "success");
      })
      .catch(() => {
        swal("Error", "Gagal menghapus product dari toko", "error");
      });
  };

  const _myCart = () => {
    return (
      <MyCart>
        <div className="count">0</div>
        <IconCart />
      </MyCart>
    );
  };

  useEffect(() => {
    const fetch = async () => {
      const getWishlist = await GET_WISHLIST();
      setDataWishlist(getWishlist.data);
    };
    fetch();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const getWishlist = await GET_WISHLIST();
      setDataWishlist(getWishlist.data);
      setIsRefetch(false)
    };
    fetch();
  }, [isRefetch]);

  const _mappingCard = () => {
    let item = [];
    dataWishlist.map((wishlist) => {
 
      item.push(
        <CardProductWishlist
          type="full"
          product={wishlist.product}
          key={wishlist.product.slug}
          onAddProduct={() => handleAddToStore(wishlist.product.slug)}
          onDelete={() => handleDeleteWishlist(wishlist.product.slug)}
          onDeleteProduct={() => handleDeleteProductStorefront(wishlist.product.slug)}
          history={props.history}
        />
      );
      return "";
    });
    return item;
  };

  const handleOnChangeSearch = (e) => {
    setFilters({
      ...filters,
      keyword: e.target.value
    })
  }

  const handleOnSearch = async () => {
    const getWishlist = await GET_WISHLIST();
    if(filters.keyword === '') {
      setDataWishlist(getWishlist.data)
    } else {
      setDataWishlist(getWishlist.data.filter(wishlist => wishlist.product.name.toLowerCase().includes(filters.keyword)))
    }
    return ''
  }
  

  return (
    <div
      style={
        !isMobile
          ? {
              maxWidth: 960,
              padding: 24,
              position: "absolute",
              margin: "0 auto",
              left: 0,
              right: 0,
            }
          : {}
      }
    >
      <Navbar variant={"primary"} />
      <Grid item xs={12} style={{ padding: 16, background: "#FFF" }}>
        <TitlePage
          onBack={() => props.history.goBack()}
          contentRight={_myCart()}
        >
          Wishlist
        </TitlePage>
      </Grid>
      <Grid item xs={12} style={{ padding: 16, background: "#FFF" }}>
        <SearchCustom onChange={handleOnChangeSearch} onSearch={handleOnSearch} />
      </Grid>
      <Grid item xs={12} style={{ padding: 16, background: "#FFF" }}>
        {_mappingCard()}
      </Grid>
    </div>
  );
};

export default WishlistPage;
