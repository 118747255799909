import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Divider, Button } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import { remove } from "cart-localstorage";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "15px 10px",
    width: 400,
  },
  image: {
    height: 60,
    width: 60,
    borderRadius: 2,
    objectFit: "cover",
  },
  flexBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  hapus: {
    cursor: "pointer",
  },
  price: {
    paddingLeft: 10,
    display: "flex",
  },
  priceQty: {
    paddingLeft: 10,
    display: "flex",
    aligIitems: "flex-end",
  },
  wrapper: {
    display: "flex",
  },
  iconCart: {
    height: 40,
    width: "auto",
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 200,
  },
}));

const Cart = withRouter((props) => {
  const classes = useStyles();
  const { history } = props;

  const [data, setData] = React.useState(
    JSON.parse(localStorage.getItem("__cart"))
  );

  React.useEffect(() => {}, [data]);

  const handleRemove = async (id) => {
    await remove(id);
    setData(JSON.parse(localStorage.getItem("__cart")));
    if (JSON.parse(localStorage.getItem("__cart")).length === 0)
      localStorage.setItem("LIST_SUPPLIER", JSON.stringify([]));
  };

  return (
    <Fragment>
      {!Boolean(data) || data.length === 0 ? (
        <div className={classes.center}>
          <div className={classes.container} style={{ textAlign: "center" }}>
            <ShoppingBasketIcon className={classes.iconCart} />
            <Typography>Tidak ada produk dikeranjang.</Typography>
          </div>
        </div>
      ) : (
        <Fragment>
          <div className={classes.container}>
            <Typography>
              <b>Total: {data.length} barang</b>
            </Typography>
          </div>
          <Divider />
          <div className={classes.container}>
            {data
              .reverse()
              .slice(0, 5)
              .map((res) => (
                <div className={classes.wrapper}>
                  <img src={res.img} alt={res.name} className={classes.image} />
                  <div style={{ paddingLeft: 10, width: "100%" }}>
                    <div className={classes.flexBetween}>
                      <Typography>
                        <b>
                          {res.name.length < 21
                            ? res.name
                            : res.name.slice(0, 21) + "..."}
                        </b>
                      </Typography>
                      <div className={classes.price}>
                        <Typography color="primary">{res.price}</Typography>
                        <Typography
                          variant="caption"
                          className={classes.priceQty}
                        >
                          x {res.quantity}
                        </Typography>
                      </div>
                    </div>
                    <Typography variant="body2">Dari {res.supplier}</Typography>
                    <div className={classes.flexBetween}>
                      <Typography variant="caption">
                        {res.quantity} Barang
                      </Typography>
                      <Typography
                        variant="caption"
                        className={classes.hapus}
                        onClick={() => handleRemove(res.id)}
                      >
                        Hapus
                      </Typography>
                    </div>
                  </div>
                </div>
              ))}
            <div
              className={classes.flexBetween}
              style={{ alignItems: "center", paddingTop: 10 }}
            >
              <Typography>
                {data.length > 5 && data.slice(5).length + " Produk Lainnya"}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => history.push("/cart")}
              >
                Tampilkan Keranjang Belanja
              </Button>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
});

export default Cart;
