import React, { Fragment } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

const Loading = () => {
  const loadingDiv = {
    // height: "100vh",
    height: window.screen.height - 237,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const center = { textAlign: "center" };

  return (
    <Fragment>
      <div style={loadingDiv}>
        <div style={center}>
          <CircularProgress />
          <Typography>Memuat Halaman...</Typography>
        </div>
      </div>
    </Fragment>
  );
};

export default Loading;
