import React, { Fragment, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import style from "./style";
import { getCategories, getProvinces } from "../../../../../services/axios";

const Sidebar = withStyles(style)((props) => {
  const {
    classes,
    handleFilterCategory,
    handleFilterLocation,
    filterLocation,
    indexTab,
    handleFilter,
    filterCategory,
  } = props;
  const [openKategori, setOpenKategori] = React.useState(true);
  const [openSubkategori, setOpenSubkategori] = React.useState([]);
  const [openMoreKategori, setOpenMoreKategori] = React.useState(false);
  const [openLokasi, setOpenLokasi] = React.useState(true);
  const [openMoreLokasi, setOpenMoreLokasi] = React.useState(false);
  const [listCategory, setListCategory] = React.useState(null);
  const [listProvince, setListProvince] = React.useState(null);

  console.log("listCategory => ", listCategory);
  console.log("filterLocation => ", filterLocation);
  console.log("filterCategory => ", filterCategory);

  useEffect(() => {
    const fetch = async () => {
      const responseCategories = await getCategories();
      const responseProvince = await getProvinces();
      setListCategory(responseCategories.data);
      setListProvince(responseProvince.data);
    };
    fetch();
  }, []);

  const handleClickOpenKategori = () => {
    setOpenKategori(!openKategori);
  };

  const handleClickOpenMoreKategori = () => {
    setOpenMoreKategori(!openMoreKategori);
  };

  const handleClickOpenLokasi = () => {
    setOpenLokasi(!openLokasi);
  };

  const handleClickOpenMoreLokasi = () => {
    setOpenMoreLokasi(!openMoreLokasi);
  };

  const handleOpenSubKategori = (id) => {
    if (openSubkategori[openSubkategori.indexOf(id)] === id) {
      setOpenSubkategori(openSubkategori.filter((value) => value !== id));
    } else {
      setOpenSubkategori([...openSubkategori, id]);
    }
  };
  return (
    <Fragment>
      <div className={classes.sidebar}>
        {indexTab === 0 && (
          <Fragment>
            <div className={classes.kategori}>
              <List>
                <ListItem button onClick={handleClickOpenKategori}>
                  <ListItemText>
                    <Typography style={{ fontWeight: "bold" }}>
                      Kategori
                    </Typography>
                  </ListItemText>
                  {openKategori ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openKategori} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {listCategory !== null &&
                      listCategory.slice(0, 10).map((res) => (
                        <Fragment key={res.id}>
                          <ListItem
                            button
                            // onClick={() => handleFilterCategory(res.id)}
                            onClick={() => handleOpenSubKategori(res.id)}
                          >
                            <ListItemText
                              color="primary"
                              primary={`• ${res.name}`}
                            />
                            {openSubkategori[
                              openSubkategori.indexOf(res.id)
                            ] === res.id ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </ListItem>
                          <Collapse
                            in={
                              openSubkategori[
                                openSubkategori.indexOf(res.id)
                              ] === res.id
                            }
                            timeout="auto"
                            unmountOnExit
                          >
                            {res.children.map((child) => (
                              <Fragment>
                                <ListItem
                                  key={child.id}
                                  dense
                                  button
                                  onClick={() => handleFilterCategory(child.id)}
                                >
                                  <Checkbox
                                    edge="start"
                                    checked={
                                      filterCategory[
                                        filterCategory.indexOf(child.id)
                                      ] === child.id
                                    }
                                    tabIndex={-1}
                                    color="primary"
                                    disableRipple
                                  />
                                  <ListItemText primary={child.name} />
                                </ListItem>
                              </Fragment>
                            ))}
                          </Collapse>
                        </Fragment>
                      ))}
                    <Collapse
                      in={openMoreKategori}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        {listCategory !== null &&
                          listCategory.slice(10).map((res) => (
                            <Fragment key={res.id}>
                              <ListItem
                                button
                                onClick={() => handleFilterCategory(res)}
                              >
                                <ListItemText primary={`• ${res.name}`} />
                              </ListItem>
                            </Fragment>
                          ))}
                      </List>
                    </Collapse>
                    {listCategory && listCategory.length > 9 && (
                      <ListItem button onClick={handleClickOpenMoreKategori}>
                        <ListItemText>
                          <Typography
                            style={{ fontWeight: "bold", color: "#FBBB14" }}
                          >
                            {openMoreKategori
                              ? "Tampilkan Lebih Sedikit..."
                              : `+${
                                  listCategory !== null &&
                                  listCategory.slice(9).length
                                } Kategori Lainnya`}
                          </Typography>
                        </ListItemText>
                      </ListItem>
                    )}
                  </List>
                </Collapse>
              </List>
            </div>
            <Divider />
          </Fragment>
        )}
        <div className={classes.kategori}>
          <List>
            <ListItem button onClick={handleClickOpenLokasi}>
              <ListItemText>
                <Typography style={{ fontWeight: "bold" }}>Lokasi</Typography>
              </ListItemText>
              {openLokasi ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openLokasi} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {listProvince !== null &&
                  listProvince.slice(0, 10).map((res) => {
                    const labelId = `checkbox-list-label-${res.id}`;

                    return (
                      <ListItem
                        key={res.id}
                        dense
                        button
                        onClick={() => handleFilterLocation(res.id)}
                      >
                        <Checkbox
                          edge="start"
                          checked={
                            filterLocation[filterLocation.indexOf(res.id)] ===
                            res.id
                          }
                          tabIndex={-1}
                          color="primary"
                          disableRipple
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                        <ListItemText id={labelId} primary={res.name} />
                      </ListItem>
                    );
                  })}
                <Collapse in={openMoreLokasi} timeout="auto" unmountOnExit>
                  {listProvince !== null &&
                    listProvince.slice(10).map((res) => {
                      const labelId = `checkbox-list-label-${res.id}`;

                      return (
                        <ListItem
                          key={res.id}
                          role={undefined}
                          dense
                          button
                          onClick={() => handleFilterLocation(res)}
                        >
                          <Checkbox
                            edge="start"
                            checked={filterLocation.name === res.name}
                            tabIndex={-1}
                            color="primary"
                            disableRipple
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                          <ListItemText id={labelId} primary={res.name} />
                        </ListItem>
                      );
                    })}
                </Collapse>
                <ListItem button onClick={handleClickOpenMoreLokasi}>
                  <ListItemText>
                    <Typography
                      style={{ fontWeight: "bold", color: "#FBBB14" }}
                    >
                      {openMoreLokasi
                        ? "Tampilkan Lebih Sedikit..."
                        : "Lihat selengkapnya..."}
                    </Typography>
                  </ListItemText>
                </ListItem>
              </List>
            </Collapse>
          </List>

          <div className={classes.buttonWrapper}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              style={{ color: "#fff" }}
              onClick={handleFilter}
            >
              Terapkan Filter
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
});

export default Sidebar;
