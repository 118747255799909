import { axiosInstance } from "../config/fetchAxios";

export const GET_WISHLIST = async () => {
  const url = `/api/v2/wishlist`
  const data = axiosInstance.get(url)
  return data
}

export const REMOVE_WISHLIST = async (slug) => {
  const url = `/api/v2/wishlist/remove`
  const data = axiosInstance.post(url, {
    product_slug: slug
  })
  return data
}