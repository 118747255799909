import React, { Fragment, useState } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { stylesDesktop } from "../style";
import swal from "@sweetalert/with-react";
import { followSupplier, unfollowSupplier, isLoggedIn } from "../../../../services/axios";
import { withRouter } from "react-router-dom";
import { generateDate } from "../../../../utils/generateDate";

const StoreDetail = withStyles(stylesDesktop)((props) => {
  const { classes, dataSupplier, history, match } = props;
  const isStoreFront = match.path.indexOf("/store/:reseller") !== -1;
  const [isFollowed, setIsFollowed] = useState(dataSupplier.followed);
  const [pengikut, setPengikut] = useState(dataSupplier.stats.followers_count);

  const handleFollow = () => {
    if (!isLoggedIn()) return window.location = '/login';
    followSupplier(dataSupplier.slug);
    setIsFollowed(true);
    setPengikut(pengikut + 1);
    swal("Success", "Supplier berhasil diikuti!", "success");
  };

  const handleUnfollow = () => {
    unfollowSupplier(dataSupplier.slug);
    setIsFollowed(false);
    setPengikut(pengikut - 1);
    swal("Success", "Supplier batal diikuti!", "success");
  };

  return (
    <Fragment>
      <Grid container style={{ margin: "20px 0" }}>
        <Grid item xs={6} className={classes.storeDetail}>
          <div
            style={{
              background: `url(${
                dataSupplier?.avatar_url
              })`,
            }}
            className={classes.storeImage}
          ></div>
          <div style={{ marginLeft: 20 }}>
            <Typography>{dataSupplier.name}</Typography>
            <Typography className={classes.storeCity}>
              {dataSupplier?.location?.address}
            </Typography>
            <div>
              {!isStoreFront && (
                <Fragment>
                  {isFollowed === true && isFollowed !== null ? (
                    <Button
                      variant="contained"
                      className={classes.disabledButton}
                      onClick={handleUnfollow}
                    >
                      Unfollow Supplier
                    </Button>
                  ) : (
                    <Button
                      className={classes.followVendor}
                      onClick={handleFollow}
                    >
                      Follow Supplier
                    </Button>
                  )}
                </Fragment>
              )}
              <Button
                className={classes.visitProfile}
                onClick={() =>
                  history.push(
                    isStoreFront
                      ? `/store/${dataSupplier.slug}`
                      : `/profile/${dataSupplier.slug}`
                  )
                }
              >
                Kunjungi Profile
              </Button>
            </div>
          </div>
          {!isStoreFront && <div className={classes.verticalLine}></div>}
        </Grid>
        <Grid item xs={6} className={classes.storeDetail2}>
          <Grid container spacing={2}>
            <Grid item xs={!isStoreFront ? 4 : 6}>
              <Typography style={{ fontSize: 14, textAlign: "center" }}>
                Produk{" "}
                <Typography color="primary">
                  {isStoreFront
                    ? dataSupplier.stats.total_product
                    : dataSupplier.stats.products_count}
                </Typography>
              </Typography>
            </Grid>
            {!isStoreFront && (
              <Grid item xs={4}>
                <Typography style={{ fontSize: 14, textAlign: "center" }}>
                  Pengikut <Typography color="primary">{pengikut}</Typography>
                </Typography>
              </Grid>
            )}
            <Grid item xs={!isStoreFront ? 4 : 6}>
              <Typography style={{ fontSize: 14, textAlign: "center" }}>
                Bergabung{" "}
                <Typography color="primary">
                  {generateDate(
                    isStoreFront
                      ? dataSupplier.owner.joined_at
                      : dataSupplier.joined_at
                  )}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
});

export default withRouter(StoreDetail);
