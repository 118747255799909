import React, { Fragment } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Mobile from "./mobile";
import Desktop from "./desktop";
import { HomePageMobileV2 } from "./mobile-v2";

const HomePage = () => {
  const isMobile = useMediaQuery("(max-width:500px)");

  return (
    <Fragment>
      <Fragment>{isMobile ? <HomePageMobileV2 /> : <Desktop />}</Fragment>
    </Fragment>
  );
};

export default HomePage;
