import { Fade, Modal, TextField } from "@material-ui/core";
import React from "react";
import NumberFormat from "react-number-format";
import { InputSaldo } from "../ModalWithdraw/style";
import { WrapBodyModal } from "./style";
import ReactMidtrans from "../../config/midtrans";

const ModalTopupComponent = ({
  open,
  handleClose,
  onChange,
  onSubmit,
  saldo,
}) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <WrapBodyModal>
          <Fade in={open} className="paper">
            <div className="bodyModal">
              <div className="headModal">
                <h2 id="transition-modal-title">Isi Saldo Dompet</h2>
              </div>
              <InputSaldo>
                <NumberFormat
                  onValueChange={(values) => {
                    onChange({
                      target: {
                        name: "saldo",
                        value: values.value,
                      },
                    });
                  }}
                  thousandSeparator="."
                  decimalSeparator=","
                  isNumericString
                  value={saldo}
                  customInput={TextField}
                  label="Jumlah Pengisian Saldo"
                  name="saldo"
                  InputLabelProps={{ shrink: true }}
                  placeholder="Masukan Nominal"
                  InputProps={{
                    startAdornment: <div className="before">Rp</div>,
                  }}
                />
              </InputSaldo>
              <ReactMidtrans clientKey={'SB-Mid-client-xYSFi8v3uCnhGICn'} getToken={onSubmit}>
                <button>Lanjutkan Pembayaran</button>
              </ReactMidtrans>
              {/* <button onClick={onSubmit} disabled={saldo === '' ? true : false }>
                Lanjutkan Pembayaran
              </button> */}
            </div>
          </Fade>
        </WrapBodyModal>
      </Modal>
    </div>
  );
};

export default ModalTopupComponent;
