import React from "react";
import { Fade, TextField } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import { BankSection, InputSaldo, OTPWrap, WrapModal } from "./style";
import OtpInput from 'react-otp-input';
import { isEmpty } from 'lodash'
import { formatRupiah } from "../../utils/currencyFormat";
import NumberFormat from "react-number-format";
import Countdown from 'react-countdown';
import { InformationMessage } from "../../components";

const useStyles = makeStyles((theme) => ({
  paper: {
    top: "5%",
    position: "relative",
    width: '90%',
    backgroundColor: "#FFF",
    margin: "0 auto",
    boxShadow: theme.shadows[5],
    padding: 16,
    borderRadius: 10,
    outline: "none",
  },
  bodyModal: {
    border: "none",
  },
  headModal: {
    textAlign: "center",
    borderBottom: "2px solid #DFDFDF",
  },
  saldo: {
    fontSize: 20,
    fontFamily: "dmsans-bold",
    color: theme.palette.primary.main,
  },
}));

const ModalWithdrawComponent = ({
  open,
  handleClose,
  walletInfo,
  bankInfo,
  handleChangeSaldo,
  handleSubmitWithdraw,
  alert,
  isOpenOtp,
  handleCloseOtp,
  stateOtp,
  onChangeOtp,
  handleSubmitOtp,
  onClickEditRekening,
  valueSaldo,
  resendCode,
  countdown
}) => {
  const classes = useStyles();

  const _modalInputSaldo = () => (
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Fade in={open} className={classes.paper}>
          <div className={classes.bodyModal}>
            <div className={classes.headModal}>
              <h2 id="transition-modal-title">Pencairan Dompet</h2>
              <p id="transition-modal-description">Saldo DompetJualan</p>
              <p className={classes.saldo}>Rp. {formatRupiah(walletInfo?.amount ?? 0)}</p>
            </div>
            <InformationMessage
              text={
                <span style={{ color: "#666" }}>
                  Proses pencairan diproses{" "}
                  <span style={{ color: "#000" }}>maksimal 2x24 Jam.</span>{" "}
                </span>
              }
            />
            <InformationMessage
              text={
                <span style={{ color: "#666" }}>
                  Setiap withdraw akan diberikan potongan sebesar{" "}
                  <span style={{ color: "#000" }}>Rp 5.650</span>{" "}
                </span>
              }
            />
            <InputSaldo>
            <NumberFormat
                  onValueChange={(values) => {
                    handleChangeSaldo({
                      target: {
                        name: 'saldo',
                        value: values.value,
                      },
                    });
                  }}
                  thousandSeparator="."
                  decimalSeparator=","
                  isNumericString
                  value={valueSaldo}
                  customInput={TextField}
                  label="Jumlah Pengisian Saldo"
                  name="saldo"
                  InputLabelProps={{ shrink: true }}
                  placeholder="Masukan Nominal"
                  InputProps={{
                    startAdornment: <div className="before">Rp</div>,
                  }}
                />
            </InputSaldo>
            {alert}

            <BankSection>
              <div className="title">Pencairan ke</div>
              <div className="cardBank">
                {
                  isEmpty(bankInfo) ? (
                    <div className="editRekening" onClick={onClickEditRekening}>
                      Edit Rekening
                    </div>
                  ) : (
                    <div>
                      <div className="title">{bankInfo.alias}</div>
                      <div className="number">{bankInfo.account_number}</div>
                      <div className="cardHolder">{bankInfo.account_name}</div>
                    </div>
                  )
                }
              </div>
              <button onClick={handleSubmitWithdraw}>Proses Pencairan</button>
            </BankSection>
          </div>
        </Fade>
      </Modal>
  )

  const _buttonResend = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <div style={{color: 'fbbd1c', cursor: 'pointer', textDecoration: 'underline'}} onClick={()=>resendCode()}>Kirim ulang</div>;
    } else {
      // Render a countdown
      return <span>{hours}:{minutes}:{seconds}</span>;
    }
  };

  const _modalOtp = () => (
    <Modal
        open={isOpenOtp}
        onClose={handleCloseOtp}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Fade in={isOpenOtp} className={classes.paper}>
          <div className={classes.bodyModal}>
            <div className={classes.headModal}>
              <h2 id="transition-modal-title">Masukan OTP</h2>
              <p>Masukkan Kode OTP yang kami kirimkan melalui WhatsApp Anda.</p>
            </div>
            <OTPWrap>
              <OtpInput
                value={stateOtp.otp}
                onChange={onChangeOtp}
                numInputs={6}
                isInputNum={true}
                containerStyle="containerOtp"
                inputStyle="inputOtp"
              />
              <div style={{textAlign: 'center', marginBottom: 16}}>
                <p>Anda dapat mengirim ulang code verifikasi untuk withdraw</p>
                <Countdown
                  date={Date.now() + countdown}
                  renderer={_buttonResend}
                />
              </div>
              <button onClick={handleSubmitOtp}>Verifikasi OTP</button>
            </OTPWrap>
          </div>
        </Fade>
      </Modal>
  )
  return (
    <WrapModal>
      {_modalInputSaldo()}
      {_modalOtp()}
    </WrapModal>
  );
};

export default ModalWithdrawComponent;
