/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
    Container,
    Box,
    OutlinedInput,
    Checkbox,
    Typography,
    Button,
    useMediaQuery,
    RadioGroup,
    FormControlLabel,
    Radio,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ArrowForward } from "@material-ui/icons";
import satisfactionIcon from "../../assets/images/satisfaction.png";
import secureTransaction from "../../assets/images/secureTransaction.png";
import { getDetailCampaign, submitCampaign } from "../../services/campaign.services";
import { numberSeparator } from "../../utils/numberSeparator";
import { useHistory } from "react-router-dom";
import arrowGif from '../../assets/icons/arrow.gif'
import { getVedorBanks } from "../../services/axios";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        boxShadow: "0 0 0.25em rgb(0 0 0 / 30%)",
        padding: "32px 64px",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        [theme.breakpoints.down("501")]: {
        padding: "32px 10px",
        },
    },
    title: {
        textAlign: "center",
        color: "#252525",
    },
    formContainer: {
        "& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-fullWidth": {
        marginBottom: "16px",
        },
    },
    submitButton: {
        color: "#fff",
        backgroundColor: "#3bb877",
    },
}));

const CampaignPage = ({match}) => {
    const classes = useStyles();
    const isMobile = useMediaQuery("(max-width:500px)");
    const history = useHistory()
    const [allDataCampaign, setallDataCampaign] = useState()
    const [allDataBank, setallDataBank] = useState()
    const [metodePembayaran, setmetodePembayaran] = useState('vendorbank')
    const [selectedProduct, setselectedProduct] = useState({
        product: [],
        total_price: 0
    })
    const [dataFields, setdataFields] = useState({
        'field': []
    })
    const [productBump, setproductBump] = useState([])

    useEffect(() => {
        const getAllDataCampaign = async() => {
            const response = await getDetailCampaign(match.params.slug)
            setallDataCampaign(response.data)

            // penambahan data ke selected product
            let allDataSelectedProduct = selectedProduct.product
            let allSelectedPriceProduct = selectedProduct.total_price
            for (let i = 0; i < response.data.products.length; i++) {
                if (response.data.products[i].is_bump === false) {
                    allDataSelectedProduct.push(response.data.products[i])
                    allSelectedPriceProduct += response.data.products[i].price
                }
            }
            setselectedProduct({...selectedProduct, product: allDataSelectedProduct, total_price: allSelectedPriceProduct})
            setdataFields({...dataFields, field: Array(response.data.fields.length).fill(null).map(() => ({
                "campaign_field_id": '',
                "value": ''
            }))})
        }

        const getAllDataBank = async() => {
            const response = await getVedorBanks()
            setallDataBank(response.data)
        }

        getAllDataBank()
        getAllDataCampaign()
    }, [])

    const handleChangeDataFields = (index, id, value) => {
        const allDataFields = dataFields.field

        const dataValue = {
            "campaign_field_id": id,
            "value": value
        }
        allDataFields.splice(index, 1, dataValue)
        setdataFields({...dataFields, field: allDataFields})
    }

    const handleChangeSelectedProduct = (e, id) => {
        if (e.target.checked === true) {
            let allDataSelectedProduct = selectedProduct.product
            let allSelectedPriceProduct = selectedProduct.total_price

            // tambah produk bump
            let allProductBump = productBump
            allProductBump.push(id)

            for (let i = 0; i < allDataCampaign.products.length; i++) {
                if (allDataCampaign.products[i].id === id) {
                    allDataSelectedProduct.push(allDataCampaign.products[i])
                    allSelectedPriceProduct += allDataCampaign.products[i].price
                }
            }

            setproductBump(allProductBump)
            setselectedProduct({...selectedProduct, product: allDataSelectedProduct, total_price: allSelectedPriceProduct})
        }else{
            let allDataSelectedProduct = selectedProduct.product
            let allSelectedPriceProduct = selectedProduct.total_price

            // hapus produk bump
            let allProductBump = productBump
            let indexOfProduct = allProductBump.indexOf(id)
            allProductBump.splice(indexOfProduct, 1)

            for (let i = 0; i < selectedProduct.product.length; i++) {
                if (selectedProduct.product[i].id === id) {
                    allDataSelectedProduct.splice(i, 1)
                }
            }

            for (let i = 0; i < allDataCampaign.products.length; i++) {
                if (allDataCampaign.products[i].id === id) {
                    allSelectedPriceProduct -= allDataCampaign.products[i].price
                }
            }

            setproductBump(allProductBump)
            setselectedProduct({...selectedProduct, product: allDataSelectedProduct, total_price: allSelectedPriceProduct})
        }
    }

    const checkIsExistOnSelectedProduct = (id) => {
        return selectedProduct.product.some(function(data) {
            return data.id === id;
        });
    }

    const handleSubmit = async(event) => {
        event.preventDefault()
        
        const dataToUpload = {
            "forms": dataFields.field,
            "product_bumps": productBump,
            "payment_method": metodePembayaran
        }

        // inisialisasi local storage
        localStorage.setItem('data-campaign', JSON.stringify({
            campaign_slug: match.params.slug
        }))

        const response = await submitCampaign(match.params.slug, dataToUpload)
        if (response) {
            history.push(`/submissions/${response.data.submission.uuid}`)
        }
    }

    return (
        <Box>
            <form onSubmit={(e) => {handleSubmit(e)}}>
                <Container maxWidth="sm">
                    <h1 className={classes.title} style={{textTransform: 'capitalize'}}>{allDataCampaign?.name}</h1>
                    <div className={classes.wrapper}>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <img
                            src={secureTransaction}
                            alt="secureTransaction"
                            width={isMobile ? "50%" : "100%"}
                            />
                            <img
                            src={satisfactionIcon}
                            alt="satisfaction"
                            width={isMobile ? "50%" : "100%"}
                            />
                        </Box>
                        {
                            allDataCampaign?.image &&
                            <Box my={3}>
                                <img src={allDataCampaign?.image} alt="banner campaign" width="100%" />
                            </Box>
                        }

                        {/* keterangan campaign */}
                        <Box mb={2}>
                            <div>{allDataCampaign?.description}</div>
                        </Box>

                        {/* produk */}

                        {/* utama */}
                        {
                            allDataCampaign?.products
                            .filter((data) => data.is_bump === false)
                            .map((res, index) => 
                                <Box key={'product' + index} style={{marginBottom: 10}} border="1px dashed red" bgcolor={`${res.is_bump ? '#fefbe1' : ''}`} p={2}>
                                    {
                                        res.is_bump &&
                                        <Box
                                            my={1}
                                            p={1}
                                            bgcolor="#f5d764"
                                            display="flex"
                                            alignItems="center"
                                        >
                                            <Checkbox
                                                checked={''}
                                                onChange={() => {}}
                                            />
                                            <h3>Centang untuk membeli produk</h3>
                                        </Box>
                                    }
                                    {
                                        res.is_bump &&
                                        <Typography
                                            style={{
                                                color: "red",
                                                textDecoration: "underline",
                                                marginBottom: 8,
                                                fontWeight: 600,
                                            }}
                                        >
                                            Hanya + Rp. {numberSeparator(res.price)},-
                                        </Typography>
                                    }
                                    <img src={res.image} alt={`imageproduct` + index} width="100%" />

                                    {
                                        !res.is_bump &&
                                        <div style={{fontSize: '1.2rem', color: 'orange'}}>Rp. {numberSeparator(res.price)}</div>
                                    }

                                    <Box mt={1}>
                                        <Typography variant="subtitle2">
                                            Keterangan Produk
                                        </Typography>
                                        <br />
                                        <div>{res.description}</div>
                                    </Box>
                                </Box>
                            )
                        }

                        {/* bump */}
                        {
                            allDataCampaign?.products
                            .filter((data) => data.is_bump === true)
                            .map((res, index) => 
                                <Box key={'product' + index} style={{marginBottom: 10}} border="1px dashed red" bgcolor={`${res.is_bump ? '#fefbe1' : ''}`} p={2}>
                                    {
                                        res.is_bump &&
                                        <Box
                                            my={1}
                                            p={1}
                                            bgcolor="#f5d764"
                                            display="flex"
                                            alignItems="center"
                                        >
                                            <img alt="arrow" src={arrowGif} style={{width: 50}}/>
                                            <div style={{backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 19, height: 16, margin: '10px 5px'}}>
                                                <Checkbox
                                                    checked={checkIsExistOnSelectedProduct(res.id)}
                                                    onChange={(e) => {handleChangeSelectedProduct(e, res.id)}}
                                                />
                                            </div>
                                            <h3>Centang untuk membeli produk</h3>
                                        </Box>
                                    }
                                    {
                                        res.is_bump &&
                                        <Typography
                                            style={{
                                                color: "red",
                                                textDecoration: "underline",
                                                marginBottom: 8,
                                                fontWeight: 600,
                                            }}
                                        >
                                            Hanya + Rp. {numberSeparator(res.price)},-
                                        </Typography>
                                    }
                                    <img src={res.image} alt={`imageproduct` + index} width="100%" />

                                    {
                                        !res.is_bump &&
                                        <div style={{fontSize: '1.2rem', color: 'orange'}}>Rp. {numberSeparator(res.price)}</div>
                                    }

                                    <Box mt={1}>
                                        <Typography variant="subtitle2">
                                            Keterangan Produk
                                        </Typography>
                                        <br />
                                        <div>{res.description}</div>
                                    </Box>
                                </Box>
                            )
                        }

                        {/* input data */}
                        <h3>Data Anda :</h3>
                        <Box className={classes.formContainer}>
                            {
                                allDataCampaign?.fields.map((res, index) => 
                                    <OutlinedInput
                                        key={'inputData' + index}
                                        placeholder={`${res.name[0].toUpperCase() + res.name.substring(1)}`}
                                        fullWidth
                                        onChange={(e) => {handleChangeDataFields(index, res.id, e.target.value)}}
                                        required={res.is_required}
                                        type={`${res.type === 'phone' ? 'number' : res.type}`}
                                        name={`${res.name.toLowerCase().split(' ').join('_')}`}
                                    />
                                )
                            }
                        </Box>

                        {/* metode pembayaran */}
                        <h3>Metode Pembayaran :</h3>
                        <Box className={classes.formContainer}>
                            <RadioGroup aria-label="gender" name="gender1" value={metodePembayaran} onChange={(e) => {setmetodePembayaran(e.target.value)}}>

                            {/* default bank */}
                            {
                                allDataCampaign?.is_custom_bank === false &&
                                <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center', flexWrap: 'wrap'}}>
                                    <FormControlLabel value="vendorbank" control={<Radio />} label="Bank Transfer" />
                                    {
                                        allDataBank?.map((res, index) => 
                                            <div key={index+'iconBank'}>
                                                <img style={{width: 50, marginRight: 5}} alt="iconBANK" src={res.bank.icon}/>
                                            </div>
                                        )
                                    }
                                </div>
                            }

                            {/* CustomBank */}
                            {
                                allDataCampaign?.is_custom_bank === true &&
                                <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center', flexWrap: 'wrap'}}>
                                    <FormControlLabel value="vendorbank" control={<Radio />} label="Bank Transfer" />
                                    {
                                        allDataCampaign.banks?.map((res, index) => 
                                            <div key={index+'iconBank'}>
                                                <img style={{width: 50, marginRight: 5}} alt={res.bank.name} src={res.bank.icon}/>
                                            </div>
                                        )
                                    }
                                </div>
                            }

                            {/* midtrans */}
                            {
                                allDataCampaign?.is_midtrans_enabled &&
                                <FormControlLabel value="midtrans" control={<Radio />} label="Pembayaran Otomatis" />
                            }
                            </RadioGroup>
                        </Box>
                        <br/>

                        {/* rincian pesanan */}
                        <Box my={2} bgcolor="#fcfcfe" border="1px solid #41c3cf" p={1}>
                            <Box mb={1}>
                            <Typography
                                variant="caption"
                                style={{ textDecoration: "underline" }}
                            >
                                RINCIAN PESANAN:
                            </Typography>
                            </Box>
                                {
                                    selectedProduct?.product
                                    .map((res, index) => 
                                        <Box key={'selectedProduct'+index} mb={1} display="flex" justifyContent="space-between" >
                                            <div>{res.description}</div>
                                            <Typography variant="caption">
                                                Rp. {numberSeparator(res.price)}
                                            </Typography>
                                        </Box>
                                    )
                                }
                                {
                                    allDataCampaign?.tax !== 0 &&
                                    <Box mb={1} display="flex" justifyContent="space-between" >
                                        <div>PPN {allDataCampaign?.tax}%</div>
                                        <Typography variant="caption">
                                            Rp. {numberSeparator(selectedProduct?.total_price * allDataCampaign?.tax / 100)}
                                        </Typography>
                                    </Box>
                                }
                            <Box mb={1} border="0.8px solid #eaeaea" />
                            <Box display="flex" justifyContent="space-between">
                                <Typography variant="caption" style={{ fontWeight: 700 }}>
                                    Total
                                </Typography>

                                <Typography variant="caption" style={{ fontWeight: 700 }}>
                                    Rp. {numberSeparator(selectedProduct?.total_price + (selectedProduct?.total_price * allDataCampaign?.tax / 100))}
                                </Typography>
                            </Box>
                        </Box>
                        <Button
                            className={classes.submitButton}
                            variant="contained"
                            fullWidth
                            size="large"
                            endIcon={<ArrowForward />}
                            disabled={''}
                            type={'submit'}
                        >
                            {'' ? "Mohon tunggu...." : "DAFTAR SEKARANG KLIK"}
                        </Button>
                    </div>
                </Container>
            </form>

            {/* review */}
            <Container maxWidth="sm">
                <Box bgcolor="#69cbd4" padding={`32px ${isMobile ? "16px" : "64px"}`}>
                {allDataCampaign?.testimonials.map((review, i) => (
                    <Box display="flex" alignItems="center" mb={3}>
                        <img src={review.avatar} alt="review" style={{borderRadius: '50%', backgroundImage: 'cover'}} width={86} height={86} />
                    <Box ml={2} color="#fff">
                        <Typography variant="h6">{review.name}</Typography>
                        <Typography variant="caption">"{review.testimonial}"</Typography>
                    </Box>
                    </Box>
                ))}
                </Box>
            </Container>
        </Box>
    );
};

export default CampaignPage;
