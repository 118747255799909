import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Mobile from "./mobile";
import Desktop from "./desktop";
import { useHistory } from "react-router-dom";

const ResiPage = () => {
  const isMobile = useMediaQuery("(max-width:500px)");
  const history = useHistory();
  return (
    <React.Fragment>
      {isMobile ? <Mobile history={history} /> : <Desktop />}
    </React.Fragment>
  );
};

export default ResiPage;
