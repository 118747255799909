const styles = (theme) => ({
  formCekResi: {
    marginTop: 20,
  },
  resiNumber: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: 16,
  },
  card2: {
    padding: "10px 0",
  },
  container: {
    paddingRight: 0,
  },
});

export default styles;
