import styled from 'styled-components'

export const WrapLabel = styled.div`
  position: relative;
  .text{
    font-size: 15px;
    font-family: 'DMSans-Regular'
  }
  .count{
    position: absolute;
    top: -11px;
    right: -12px;
    font-size: 12px;
    width: 18px;
    height: 18px;
    color: #fff;
    background: red;
    border-radius: 24px;
    text-align: center;
  }
`