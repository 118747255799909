import React from "react";

const DividerMobile = () => {
  return (
    <div
      style={{
        background: "#f2f4f8",
        height: 8,
        width: "100%",
        margin: "16px 0",
      }}
    ></div>
  );
};

export default DividerMobile;
