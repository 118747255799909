import React, { useEffect } from "react";
import {
  Container,
  Box,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CopyToClipboard from "react-copy-to-clipboard";
import swal from "sweetalert";
import { numberSeparator } from "../../utils/numberSeparator";
import { useHistory } from "react-router-dom";
import { getVedorBanks } from "../../services/axios";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        boxShadow: "0 0 0.25em rgb(0 0 0 / 30%)",
        padding: "32px 64px",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        [theme.breakpoints.down("501")]: {
        padding: "32px 10px",
        },
    },
    title: {
        textAlign: "center",
        color: "#252525",
    },
    formContainer: {
        "& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-fullWidth": {
        marginBottom: "16px",
        },
    },
    submitButton: {
        color: "#fff",
        backgroundColor: "#3bb877",
    },
    wrapperBank: {
        width: '50%', 
        minWidth: 200, 
        border: '1px dashed #a9a9a9', 
        marginTop: '-1px', 
        marginLeft: '-1px',
        padding: 10
    }
}));

const RegisterBankPage = () => {
    const classes = useStyles();
    const [nominalTf, setnominalTf] = React.useState('99000')
    const [allDataBank, setallDataBank] = React.useState()
    const history = useHistory()

    useEffect(() => {
        const nominalFromLocal = localStorage.getItem('nominalTf')
        if (nominalFromLocal === null) {
            history.push('/register')
        }else{
            setnominalTf(nominalFromLocal)
        }
    }, [])
    
    useEffect(() => {
        const getAllDataBank = async() => {
            const response = await getVedorBanks()
            setallDataBank(response.data)
        }

        getAllDataBank()
    }, [])
    

    return (
        <Box>
            <Container maxWidth="sm">
                <div className={classes.wrapper} style={{marginTop: 10, textAlign: 'center'}}>
                    <h1 className={classes.title}>Pembayaran Transfer Bank</h1>
                    <div style={{fontSize: '1.1rem', fontWeight: '400', marginBottom: 20}}>Untuk menyelesaikan proses pendaftaran, silahkan transfer sejumlah</div>

                    {/* jumlah tf */}
                    <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
                        <div style={{fontSize: '1.8rem', fontWeight: 'bold', marginBottom: 20}}>Rp. {nominalTf && numberSeparator(nominalTf)}</div>
                        
                        <div style={{backgroundColor: '#FCC84D', padding: 5, borderRadius: 8, marginBottom: 20}}>
                            <div style={{fontWeight: 400}}><span style={{fontWeight: 'bold'}}>PENTING!</span> Mohon transfer sesuai sampai dengan 3 digit terakhir</div>
                        </div>

                        <CopyToClipboard
                            text={nominalTf}
                            onCopy={() => {
                                swal("Nominal disalin.", {
                                    icon: "success",
                                    button: false,
                                });
                            }}
                        >
                            <Button style={{maxWidth: 100, border: '1px solid black', marginBottom: 25}}>Salin Jumlah</Button>
                        </CopyToClipboard>

                        <div>ke salah satu bank berikut ini:</div>
                    </div>

                    <br/>

                    {/* bank */}
                    <div>
                        <div style={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap'}}>
                            {
                                allDataBank?.map((res, index) => 
                                    <div key={res.account_name + index} className={classes.wrapperBank}>
                                        <img src={res.bank.icon} alt={res.account_name} style={{width: 100, marginBottom: 20, marginTop: 10}}/>
                                        <div>No. Rek: <span style={{fontWeight: 'bold', color:'#555555'}}>{res.account_number}</span></div>
                                        <div>Atas Nama: <span style={{fontWeight: 'bold', color:'#555555'}}>{res.account_name}</span></div>
                                        
                                        <CopyToClipboard
                                            text={res.account_number}
                                            onCopy={() => {
                                                swal("Rekening disalin.", {
                                                    icon: "success",
                                                    button: false,
                                                });
                                            }}
                                        >
                                            <Button style={{maxWidth: 120, border: '1px solid black', marginTop: 25, fontSize: '.8rem'}}>Salin Rekening</Button>
                                        </CopyToClipboard>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>

                <br/>
            </Container>
        </Box>
    )
}

export default RegisterBankPage