import React from "react";

import { Grid, Typography, Card, Chip, Avatar, Box, Button } from "@material-ui/core";
import swal from "@sweetalert/with-react";
import { numberSeparator } from "../../../utils/numberSeparator";
import { cancelPaidOrder } from "../../../services/order.service";

const Forwarded = (props) => {
  const { order, refetch } = props;

  const handleCancelOrder = async (id) => {
    try {
      swal({
        title: "Apakah anda yakin?",
        text: "Untuk membatalkan pesanan ini",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          swal({
            text: "Masukkan catatan pembatalan",
            icon: "warning",
            content: {
              element: "input",
              attributes: {
                placeholder: "catatan pembatalan",
                type: "text",
              },
            },
            buttons: {
              cancel: "lewati",
              confirm: "Ya",
            },
            dangerMode: true,
          }).then(async (note) => {
            const params = {
              order_id: id,
              cancel_note: note ?? "",
            };
            const res = await cancelPaidOrder(params);
            swal("Pesanan berhasil dibatalkan", {
              icon: "success",
            }).then(() => refetch());
          });
        }
      });
    } catch (error) {
      console.log(error);
      swal("Error", "Pesanan gagal dibatalkan", "error");
    }
  };

  return (
    <Card key={order.id} style={{ marginBottom: 16 }} variant="outlined">
      <Box
        bgcolor="#3fbfc5"
        width="fit-content"
        color="#fff"
        fontWeight={600}
        p={1}
        px={2}
      >
        Order ID: #{order.id}
      </Box>
      <Box p={2}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center" gridGap={6}>
            <Avatar
              src={order.supplier.avatar_url}
              style={{ width: 36, height: 36 }}
            />
            <Typography>{order.supplier.name}</Typography>
          </Box>
          <Typography>
            Status: {order.histories[order.histories.length - 1].message}
          </Typography>
        </Box>

        <Box height={2} mt={1} mb={2} bgcolor="#F7F7F7" />
        {order.items.map(({ id, sku, reseller_price_idr, qty }) => {
          if (sku) {
            return (
              <Box mb={2} key={id}>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Typography>{sku.product.name}</Typography>
                    {sku.properties && (
                      <Box mt={1} mb={2}>
                        <Chip
                          variant="outlined"
                          label={sku.properties.color}
                          disabled
                          size="small"
                        />{" "}
                        <Chip
                          variant="outlined"
                          label={sku.properties.material}
                          disabled
                          size="small"
                        />{" "}
                        <Chip
                          variant="outlined"
                          label={sku.properties.size}
                          disabled
                          size="small"
                        />
                      </Box>
                    )}
                    <Typography color="primary" component="span" variant="h6">
                      Rp.{numberSeparator(reseller_price_idr || 0)}
                    </Typography>
                    {"   x   "}
                    <Typography
                      component="span"
                      color="textSecondary"
                      variant="h6"
                    >
                      {qty}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Avatar
                      src={sku.image_url}
                      style={{ height: 64 }}
                      variant="square"
                    />
                  </Grid>
                </Grid>
              </Box>
            );
          }
        })}
      </Box>
      <Box mb={2} height={2} bgcolor="#F7F7F7" />
      <Box display="flex" alignItems="center" justifyContent="space-between" px={2}>
        <Button
            onClick={() => handleCancelOrder(order.id)}
            variant="contained"
            color="secondary"
          >
            Batalkan
          </Button>
        <Box display="flex" alignItems="flex-end" flexDirection="column" px={2}>
          <Typography variant="caption">Jumlah harus dibayar</Typography>
          <Typography variant="h6" style={{ color: "red" }}>
            Rp.{numberSeparator(order.payment.amount || 0)}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};

export default Forwarded;
