import React from 'react'
import { WrapSeeAll } from './style'
import iconNext from '../../assets/icons/next.svg'

const SeeAllComponent = ({children = null, onClick = null, top = 36, style}) => {
  return (
    <WrapSeeAll style={style}>
       <div className="left">
        {children}
      </div>
      <div className="right" onClick={onClick} style={{top: top}}>
        <p>Lihat semua</p>
        <img src={iconNext} alt="icon-next" />
      </div>
    </WrapSeeAll>
  )
}

export default SeeAllComponent