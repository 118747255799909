import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, useMediaQuery } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import { TitlePage } from '../../components';
import Navbar from '../../components/navbar';
import { getCitiesByProvinceId, getProvinces } from '../../services/axios';
import { editProfileUser } from '../../services/Reseller/reseller.service';
import styles from "./style.module.css";

const EditProfilePage = (props) => {
  const isMobile = useMediaQuery("(max-width:500px)");
  const [state, setstate] = useState({
    id: '',
    name: '',
    email: '',
    address: '',
    postal_code: '',
    phone: '',
    city_id: '',
    avatar: '',
    gender: '',
    born_at: '',
    location: {
      province: {
        name: '',
        id: ''
      },
      city: {
        name: '',
        id: ''
      },
    },
  })
  const [listProvince, setListProvince] = useState([]);
  const [listCity, setListCity] = useState([]);

  // manage state
  useEffect(() => {
    if (localStorage.getItem('USER_DATA')) {
      const userData = JSON.parse(localStorage.getItem('USER_DATA'))
      setstate({
        id: userData.id,
        name: userData.name,
        email: userData.email,
        address: userData.location.address,
        postal_code: userData.location.postal_code,
        phone: userData.phone,
        city_id: userData.location.city.id,
        avatar: '',
        avatarUrl: userData.avatar,
        gender: userData.gender,
        born_at: userData.born_at.split(' ')[0],
        location: {
          province: userData.location.province,
          city: userData.location.city,
        },
      })
    }
  }, [])
  

  const handleChangeInput = (event) => {
    setstate({...state, [event.target.name]: event.target.value})
  }

  // location manage
  useEffect(() => {
    const fetch = async () => {
      // province
      const resProvince = await getProvinces();
      setListProvince(resProvince.data.sort((a, b) => a.name.localeCompare(b.name)));

      // city
      const resCity = await getCitiesByProvinceId(state.location.province.id);
      setListCity(resCity.data.sort((a, b) => a.name.localeCompare(b.name)));
    };
    state.location.province.name !== '' && fetch();
  }, [state.location]);

  const handleChangeProvince = async (event) => {
    const res = await getCitiesByProvinceId(event.id);
    setListCity(res.data.sort((a, b) => a.name.localeCompare(b.name)));
    setstate({ ...state, location: {...state.location, province: event, city: ''}})
  };

  const handleChangeCity = async (event) => {
    setstate({ ...state, location: {...state.location, city: event}, city_id: event.id})
  };

  // manajemen jenis kelamin
  const handleChangeGender = (event) => {
    setstate({...state, gender: event})
  }

  // submit
  const submit = async(event) => {
    event.preventDefault()
    const dataToUpload = JSON.parse(JSON.stringify(state))

    // delete location
    delete dataToUpload.location

    // delete avatar url
    delete dataToUpload.avatarUrl

    // delete avatar when no update
    if (state.avatar === '') {
      delete dataToUpload.avatar
    }

    // convert to formdata
    const formData = new FormData();
    for (var key in dataToUpload) {
      if (key === 'avatar') {
        formData.append(key, state.avatar);
      }else{
        formData.append(key, dataToUpload[key]);
      }
    }

    // hit api
    try {
      const response = await editProfileUser(state.id, formData)
      if (response) {
        await localStorage.setItem('USER_DATA', JSON.stringify(response.data))
        swal("Success!", "Your Profile Updated!", "success").then(() => {
          window.location.reload()
        })
      }
    } catch (error) {
      
    }
  }

  // console.log(state)

  return (
    <div style={!isMobile ? {maxWidth: 960, padding: 24, position: 'absolute', margin: '0 auto', left: 0, right:0} : {}}>
      <Navbar variant={"primary"} />
      <Box style={{backgroundColor: 'white'}}>
        <Grid item xs={12} style={{ padding: 16 }}>
          <TitlePage onBack={() => props.history.goBack()}>Edit Profile</TitlePage>
        </Grid>
        <Box style={{padding: 20, width: '100%'}}>
          <form onSubmit={(event) => {submit(event)} }>
            <Grid 
              container
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12}>
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div className={styles.photo}>
                  {state.avatarUrl !== '' ? (
                    <img src={state.avatarUrl} alt="Preview" />
                  ) : (
                    <>
                      <div>Image Avatar</div>
                      <Add />
                    </>
                  )}
                  <input
                    name="image"
                    accept="image/*"
                    type="file"
                    onChange={(event) => {
                      const file = event.target.files[0];
                      const url = URL.createObjectURL(file);
                      setstate({...state, avatar: file, avatarUrl: url})
                    }}
                  />
                </div>
              </div>
            </Grid>

              {/* nama */}
              <Grid item xs={12}>
                <TextField
                  name='name'
                  label="Nama" 
                  style={{width: '100%'}} 
                  variant="outlined"
                  value={state.name}
                  onChange={(event) => handleChangeInput(event)}
                  />
              </Grid>

              {/* email */}
              <Grid item xs={12}>
                <TextField 
                  name='email'
                  type='email'
                  label="Email" 
                  style={{width: '100%'}} 
                  variant="outlined" 
                  value={state.email}
                  onChange={(event) => handleChangeInput(event)}
                />
              </Grid>

              {/* alamat */}
              <Grid item xs={12}>
                <TextField 
                  name='address' 
                  label="Alamat" 
                  style={{width: '100%'}} 
                  variant="outlined" 
                  value={state.address}
                  onChange={(event) => handleChangeInput(event)}
                />
              </Grid>

              {/* no hp */}
              <Grid item xs={12}>
                <TextField 
                  name='phone'
                  label="No. Hp"
                  type='number'
                  style={{width: '100%'}} 
                  variant="outlined"
                  value={state.phone}
                  onChange={(event) => handleChangeInput(event)} 
                />
              </Grid>

              <>
                {
                  state.location.province.name &&
                  <Grid item xs={12}>
                    <FormControl variant="outlined" style={{ width: "100%" }}>
                      <InputLabel id="select-province">Provinsi</InputLabel>
                      <Select
                        labelId="select-province"
                        id="demo-simple-select-outlined"
                        label="Provinsi"
                        value={state.location.province.name}
                      >
                        {listProvince !== null &&
                          listProvince.map((res) => {
                            return (
                              <MenuItem
                                key={res.id}
                                value={res.name}
                                selected={state.location.province.id === res.id}
                                onClick={() => handleChangeProvince(res)}
                              >
                                {res.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                }
              </>

              <Grid item xs={12}>
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <InputLabel id="cities">Kota</InputLabel>
                  <Select
                    labelId="cities"
                    id="demo-simple-select-outlined"
                    label="Kota"
                    value={state.location.city.name ? state.location.city.name : ''}
                  >
                    {listCity !== null &&
                      listCity.map((res) => {
                        return (
                          <MenuItem
                            key={res.id}
                            value={res.name}
                            onClick={() => handleChangeCity(res)}
                          >
                            {res.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>

              {/* kode pos */}
              <Grid item xs={12}>
                <TextField 
                  name='postal_code'
                  label="Kode Pos" 
                  style={{width: '100%'}} 
                  variant="outlined" 
                  type='number'
                  value={state.postal_code}
                  onChange={(event) => handleChangeInput(event)} 
                  />
              </Grid>

              {/* tanggal lahir */}
              <Grid item xs={12}>
                <TextField 
                  name='born_at'
                  label="Tanggal Lahir" 
                  style={{width: '100%'}} 
                  variant="outlined" 
                  type='date'
                  value={state.born_at}
                  onChange={(event) => handleChangeInput(event)} 
                />
              </Grid>

              {/* jenis kelamin */}
              <Grid item xs={12}>
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <InputLabel id="jenkel">Jenis Kelamin</InputLabel>
                  <Select
                    labelId="jenkel"
                    id="jenkel-outlined"
                    label="Jenis Kelamin"
                    value={state.gender}
                  >
                    <MenuItem
                      value='m'
                      onClick={() => handleChangeGender('m')}
                    >
                      Laki - Laki
                    </MenuItem>
                    <MenuItem
                      value='f'
                      onClick={() => handleChangeGender('f')}
                    >
                      Perempuan
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button type='submit' variant="contained" color="primary">Simpan</Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </div>
  );
};

export default EditProfilePage;