import React, { useEffect } from "react";
import { getProductData } from "../../services/axios";
import { withRouter } from "react-router-dom";

let ContextType;
const { Provider, Consumer } = (ContextType = React.createContext());

const SearchContext = withRouter((props) => {
  const { history } = props;
  const [inputSearch, setInputSearch] = React.useState("");
  const [filterCategory, setFilterCategory] = React.useState("");
  const [filterLocation, setFilterLocation] = React.useState("");
  const [filterSort, setFilterSort] = React.useState({ text: "", value: "" });
  const [dataSearched, setDataSearched] = React.useState(null);

  const keyword = decodeURI(window.location.pathname.slice(8));

  const handleInputSearch = (event) => {
    setInputSearch(event.target.value);
  };

  const handleChangePage = async (page) => {
    const response = await getProductData(
      `?filter[name]=${inputSearch}&sort=${
        filterSort.value
      }&filter[category.id]=${
        filterCategory.id || ""
      }&filter[supplier.province_id]=${filterLocation.id || ""}&page=${page}`
    );
    setDataSearched(response);
  };

  const handleFilterCategory = async (res) => {
    const response = await getProductData(
      `?filter[name]=${inputSearch}&sort=${
        filterSort.value
      }&filter[category.id]=${res.id}&filter[supplier.province_id]=${
        filterLocation.id || ""
      }`
    );
    setDataSearched(response);
    setFilterCategory(res);
  };

  const handleFilterLocation = async (res) => {
    const response = await getProductData(
      `?filter[name]=${inputSearch}&sort=${
        filterSort.value
      }&filter[supplier.province_id]=${res.id}&filter[category.id]=${
        filterCategory.id || ""
      }`
    );
    setDataSearched(response);
    setFilterLocation(res);
  };

  const handleFilter = async () => {
    const response = await getProductData(
      `?filter[name]=${inputSearch}&sort=${
        filterSort.value
      }&filter[category.id]=${
        filterCategory.id || ""
      }&filter[supplier.province_id]=${filterLocation.id || ""}`
    );
    setDataSearched(response);
  };

  const handleResetFilter = async () => {
    setFilterLocation("");
    setFilterCategory("");
    const response = await getProductData(
      `?filter[name]=${inputSearch}&sort=${
        filterSort.value
      }&filter[category.id]=${""}&filter[supplier.province_id]=${""}`
    );
    setDataSearched(response);
  };

  const handleFilterSort = async (sort) => {
    // value of Sort ["Termurah", "Termahal", "Terbaru", "Terlaris"]
    switch (sort) {
      case "Termurah":
        const responseTermurah = await getProductData(
          `?filter[name]=${inputSearch}&sort=-ref_price&filter[category.id]=${
            filterCategory.id || ""
          }&filter[supplier.province_id]=${filterLocation.id || ""}`
        );
        setDataSearched(responseTermurah);
        setFilterSort({ text: sort, value: "-ref_price" });
        break;
      case "Termahal":
        const responseTermahal = await getProductData(
          `?filter[name]=${inputSearch}&sort=ref_price&filter[category.id]=${
            filterCategory.id || ""
          }&filter[supplier.province_id]=${filterLocation.id || ""}`
        );
        setDataSearched(responseTermahal);
        setFilterSort({ text: sort, value: "ref_price" });
        break;
      case "Terbaru":
        setFilterSort("");
        break;
      case "Terlaris":
        setFilterSort("");
        break;
      default:
        setFilterSort("");
    }
  };

  useEffect(() => {
    if (dataSearched !== null) history.push(`/search/${inputSearch}`);
  }, [dataSearched]);

  const value = {
    inputSearch: inputSearch,
    handleInputSearch: handleInputSearch,
    // handleSearch: handleSearch,
    dataSearched: dataSearched,
    handleFilter: handleFilter,
    filterCategory: filterCategory,
    handleFilterCategory: handleFilterCategory,
    filterLocation: filterLocation,
    handleFilterLocation: handleFilterLocation,
    setFilterCategory: setFilterCategory,
    setFilterLocation: setFilterLocation,
    handleChangePage: handleChangePage,
    handleResetFilter: handleResetFilter,
    handleFilterSort: handleFilterSort,
    filterSort: filterSort,
    keyword: keyword,
    setInputSearch: setInputSearch,
  };
  return <Provider value={value}>{props.children}</Provider>;
});

export { SearchContext, Consumer, ContextType };
