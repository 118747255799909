import React from 'react'
import { formatRupiah } from '../../utils/currencyFormat';
import { WrapTransactionList } from './style'
import moment from 'moment'

const TransactionListComponent = ({
  title = 'Title',
  date = '01-01-2021',
  price = 100000,
  type = 'IN'
}) => {
  return (
    <WrapTransactionList>
     <div className="left">
     <div className="title">{title}</div>
      {/* <div className="desc">{desc}</div> */}
      <div className="date">{moment(date).format('DD-MM-YYYY')}</div>
     </div>
     <div className="right">
       <div className="price">{type === 'IN' ? '' : '-'} Rp {formatRupiah(price)}</div>
       {/* <div className="desc">Diproses</div> */}
     </div>
    </WrapTransactionList>
  )
}

export default TransactionListComponent