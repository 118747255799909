const style = (theme) => ({
    container: {
        position: 'fixed',
        top: 0,
        right: 0,
        width: '100%',
        zIndex: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    containerBackButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgb(255 255 255 / 46%)',
        borderRadius: '50%',
        padding: 5,
        margin: 5
    },
    containerMenu: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: 'rgb(255 255 255 / 46%)',
        padding: '5px 10px 5px 10px',
        borderRadius: 30,
        margin: 5,
        width: 90
    },
    conatinerChart: {
        position: 'relative',
        display: 'flex'
    },
    countChart: {
        position: 'absolute',
        width: 18,
        height: 18,
        textAlign: 'center',
        color: 'white',
        backgroundColor: 'red',
        top: '-4px',
        right: '-9px',
        borderRadius: '50%',
        fontSize: 11,
        padding: 2,
    }
});

export default style;
