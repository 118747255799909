import React, { Fragment, useState, useEffect } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  Grid,
  Typography,
  TextField,
  Checkbox,
  Container,
  Backdrop,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { stylesContent, stylesDesktop, stylesMobile } from "./style";
import { withStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { LoginReseller, resendActivationEmail } from "../../services/axios";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Brandlogo from "../../assets/images/Brand.svg";
import IlustrationDesktop from "../../assets/images/ilustrasi.svg";
import BackgoundDesktop from "../../assets/images/background.svg";
import MobileVectorBg from "../../assets/images/Vector1.svg";
import MobileVector from "../../assets/images/login2.svg";
import GoogleIcon from "../../assets/icons/google.svg";
import FacebookIcon from "../../assets/icons/facebook.svg";
import { setCookie, getCookie, eraseCookie } from "../../utils/handleCookie";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const Content = withStyles(stylesContent)((props) => {
  const { classes } = props;
  let history = useHistory();
  const [input, setInput] = useState({
    email: getCookie("email") || "",
    password: getCookie("password") || "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [remember, setRemember] = useState(
    getCookie("remember") === "true" || false
  );
  const [showPassword, setShowPassword] = useState(false);
  const dataLoginSocialMedia = JSON.parse(
    window.localStorage.getItem("LOGIN_WITH_SOCIAL_MEDIA")
  );

  useEffect(() => {
    if (dataLoginSocialMedia) setOpenAlert(true);
  }, []);

  const handleChangeInput = (prop) => (event) => {
    setInput({ ...input, [prop]: event.target.value });
  };

  const handleLogin = async (event) => {
    event.preventDefault()
    setIsLoading(true);
    let loginData = {
      email: input.email.toLowerCase(),
      password: input.password,
    };
    if (input.email !== "" && input.password !== "") {
      const response = await LoginReseller(loginData);
      localStorage.setItem("USER_DATA", JSON.stringify(response.data));
      localStorage.setItem("TOKEN", response.access_token);
      localStorage.setItem("STORE_FRONT", response.data.storefront.slug);
      window.location = "/";
    }
    if (window.localStorage.USER_DATA) {
      setIsLoading(false);
    }
    if (remember) {
      setCookie("email", input.email, 30);
      setCookie("password", input.password, 30);
      setCookie("remember", true, 30);
    } else {
      eraseCookie("email");
      eraseCookie("password");
      eraseCookie("remember");
    }
  };

  const handleResendEmail = () => {
    resendActivationEmail({
      email: dataLoginSocialMedia.email !== null && dataLoginSocialMedia.email,
    });
    window.localStorage.removeItem("LOGIN_WITH_SOCIAL_MEDIA");
    setOpenAlert(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Fragment>
      <div className={classes.containerContent}>
        <div>
          <Typography>Silakan masuk ke dalam akun kamu</Typography>
          <form onSubmit={(event) => handleLogin(event)}>
            <div className={classes.inputGroup}>
              <TextField
                className={classes.textField}
                label="Email"
                value={input.email}
                type="email"
                variant="outlined"
                onChange={handleChangeInput("email")}
                required
              />
              <TextField
                className={classes.textFieldPassword}
                label="Password"
                value={input.password}
                type={showPassword ? "text" : "password"}
                variant="outlined"
                onChange={handleChangeInput("password")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                required
              />
            </div>
            <Grid container style={{ alignItems: "center" }}>
              <Grid item xs={6} className={classes.checkboxRememberMe}>
                <Checkbox
                  color="primary"
                  checked={remember}
                  onClick={() => setRemember(!remember)}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
                <Typography>Ingat saya</Typography>
              </Grid>
              <Grid item xs={6} className={classes.forgotPassword}>
                <Typography
                  style={{ color: "#F63B68", cursor: "pointer" }}
                  onClick={() => history.push("/reset-password")}
                >
                  Lupa password ?
                </Typography>
              </Grid>
            </Grid>

            <Button
              variant="contained"
              fullWidth={true}
              color="primary"
              className={classes.loginButton}
              type={'submit'}
            >
              Login
            </Button>
          </form>
          <Backdrop open={isLoading} className={classes.backdrop}>
            <CircularProgress color="primary" />
          </Backdrop>

          <Grid container spacing={0} className={classes.grid}>
            <Grid item xs={5}>
              <hr className={classes.hr} />
            </Grid>
            <Grid item xs={2} className={classes.or}>
              <Typography>Atau</Typography>
            </Grid>
            <Grid item xs={5}>
              <hr className={classes.hr} />
            </Grid>
          </Grid>

          <div style={{ margin: "10px 0 30px" }}>
            <button
              className={classes.buttonGoogle}
              onClick={() => {
                setIsLoading(true);
                window.location.href =
                  "https://api.idejualan.com/api/v1/auth/social/google";
              }}
            >
              <Grid container style={{ alignItems: "center" }}>
                <Grid item xs={1}>
                  <img src={GoogleIcon} alt="" className={classes.icon} />
                </Grid>
                <Grid item xs={11}>
                  <Typography style={{ color: "#555555" }}>
                    Login dengan Google
                  </Typography>
                </Grid>
              </Grid>
            </button>

            <button
              className={classes.buttonFacebook}
              onClick={() => {
                setIsLoading(true);
                window.location.href =
                  "https://api.idejualan.com/api/v1/auth/social/facebook";
              }}
            >
              <Grid container style={{ alignItems: "center" }}>
                <Grid item xs={1}>
                  <img src={FacebookIcon} alt="" className={classes.icon} />
                </Grid>
                <Grid item xs={11}>
                  <Typography>Login dengan Facebook</Typography>
                </Grid>
              </Grid>
            </button>
          </div>

          <Typography className={classes.haventAccount}>
            Belum punya akun?&nbsp;
            <span
              style={{ color: "#F63B68", cursor: "pointer" }}
              onClick={() => history.push("/signup")}
            >
              Daftar sekarang
            </span>
          </Typography>
        </div>
      </div>
      <Dialog
        open={openAlert}
        onClose={() =>
          window.localStorage.removeItem("LOGIN_WITH_SOCIAL_MEDIA")
        }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ textAlign: "center" }}
      >
        <DialogTitle id="alert-dialog-title">
          <Typography>
            <b>Verifikasi Email</b>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Kamu hampir selesai! Kami telah mengirim email ke{" "}
            <b>{dataLoginSocialMedia !== null && dataLoginSocialMedia.email}</b>
            <br />
            <br />
            Cek emailmu dan segera lakukan verifikasi dengan mengikuti instruksi
            pada email. Jika kamu tidak melihat email masuk, kamu mungkin harus
            mengecek <b>folder spam</b>
            <br />
            <br />
            Masih tidak melihat email dari kami?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.buttonModal}>
          <Button
            onClick={handleResendEmail}
            color="primary"
            variant="contained"
          >
            Kirim Ulang Email
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
});

const Mobile = withStyles(stylesMobile)((props) => {
  const { classes } = props;
  return (
    <Fragment>
      <div className={classes.logo}>
        <img
          src={Brandlogo}
          alt="idejualan"
          style={{ height: 40, marginTop: 10 }}
        />
      </div>
      <div>
        <img src={MobileVectorBg} alt="" style={{ width: "100%" }} />
        <div className={classes.imageVector}>
          <img src={MobileVector} alt="" />
        </div>
      </div>
      <Container maxWidth="xs">
        <Content />
      </Container>
    </Fragment>
  );
});

const Desktop = withStyles(stylesDesktop)((props) => {
  const { classes } = props;
  return (
    <div className={classes.container}>
      <img src={Brandlogo} alt="idejualan" className={classes.logo} />
      <Grid container>
        <Grid item xs={6}>
          <Content desktop />
        </Grid>
        <Grid item xs={6}>
          <div className={classes.containerImg}>
            <img
              src={BackgoundDesktop}
              alt="idejualan"
              style={{ height: "100%" }}
            />
            <img src={IlustrationDesktop} alt="" className={classes.vector} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
});

const Login = () => {
  const isMobile = useMediaQuery("(max-width:500px)");
  return <React.Fragment>{isMobile ? <Mobile /> : <Desktop />}</React.Fragment>;
};

export default Login;
