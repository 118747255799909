import React from "react";
import { CardFull, WrapCPWishlist } from "./style";
import { ReactComponent as IconAdd } from "../../assets/icons/icon_add.svg";
import { ReactComponent as IconMinus } from "../../assets/icons/icon_minus.svg";
import { ReactComponent as IconDelete } from "../../assets/icons/icon_delete.svg";
import { formatRupiah } from "../../utils/currencyFormat";
import { CommissionLabel } from "../global.style";

const CardProductWishlist = (props) => {
  const {
    product,
    onClick,
    buttonIcon,
    type = "card",
    onDelete = null,
    onAddProduct = null,
    onDeleteProduct = null
  } = props;

  const _card = () => {
    if (type === "card") {
      return (
        <WrapCPWishlist>
          <div
            onClick={() => props.history.push(`/product/${product.slug}`)}
            style={{ cursor: "pointer" }}
          >
            <div className="imageProduct">
              <img src={product.image_url} alt={product.slug} />
            </div>
            <h2>{product.name}</h2>
            <p className="price">
              Rp {formatRupiah(product.skus[0].price.reseller)}
            </p>
          </div>
          <button onClick={onClick}>
            {buttonIcon === "add" ? <IconAdd /> : <IconMinus />} <p>Tokoku</p>
          </button>
        </WrapCPWishlist>
      );
    } else if (type === "full") {
      return (
        <CardFull>
          <div
            className="flex"
            onClick={() => props.history.push(`/product/${product.slug}`)}
            style={{ cursor: "pointer" }}
          >
            <div className="imageProduct">
              <img src={product.image_url} alt={product.slug} />
            </div>
            <div className="info">
              <h2>{product.name}</h2>
              {product.commission.type === "PERCENT" && (
                <CommissionLabel>
                  {product.commission.value}% Komisi
                </CommissionLabel>
              )}

              <span className="price">
                Rp. {formatRupiah(product.skus[0].price.reseller)}
              </span>
            </div>
          </div>
          <div className="flex" style={{ marginTop: 16 }}>
            <button className="delete" onClick={onDelete}>
              <IconDelete />
            </button>
            <button className="add" onClick={product.is_added ? onDeleteProduct : onAddProduct}>
              {product.is_added ? 'Hapus dari tokoku' : 'Tambah ke Tokoku'}
            </button>
          </div>
        </CardFull>
      );
    }
  };
  return <>{_card()}</>;
};

export default CardProductWishlist;
