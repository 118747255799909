import { Container, Grid, MenuItem, Typography, CircularProgress } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Banner from "../../../components/banner";
import { CategoryIcon, WrapCategory, WrapHome } from "./style";
import { Title } from "../../../components";
import settings from "../settings";
import Carousel from "../../../components/carousel";
import ScrollMenu from "react-horizontal-scrolling-menu";
import Loading from "../../../components/loading";
import ChatPic from "../../../components/chatPIC";
import SearchbarMobile from "../../../components/Mobile/SearchbarMobile";
import InfoPanel from "./components/InfoPanel";
import ToolbarMobile from "../../../components/Mobile/ToolbarMobile";
import InfiniteScroll from 'react-infinite-scroll-component';
import swal from "@sweetalert/with-react";
import NeedLoginSwal from "../../../components/needLoginSwal";
import {
  getProductData,
  getProductRecomendations,
  getVouchers,
} from "../../../services/axios";

// img
import bannerSuperSaller from '../../../assets/images/mobile/banner_upgrade.jpg'
import iconKupon from '../../../assets/icons/menu/icon_kupon.svg'
import iconProdukPilihan from '../../../assets/icons/icon_premium.svg'
import iconShop from '../../../assets/icons/shop.svg'
import IconShopping from "../../../assets/icons/iconShopping.svg";
import IconVoucher from "../../../assets/icons/iconVoucher.svg";
import IconDiscount from "../../../assets/icons/iconDiscount.svg";
import IconKampusJualan from "../../../assets/icons/menu/kampusjualan_logo.png";
import IconDailyLogin from '../../../assets/icons/menu/icon_daily_login.svg'
import TerbaruTerlaris from "../../../components/TerbaruTerlaris";
import BrandPopuler from "../../../components/BrandPopuler";
import ModalPopUp from "../../../components/ModalPopUp";
import PopupDeeplink from "../../../components/Mobile/DeeplinkPopup";

const HomePageMobileComponent = (props) => {
  const { history, loading, sliderProduct, setSliderProduct, allCategories, setAllCategories,  classes, onChangeSearch,
    onSearch } = props;
  const [vouchers, setvouchers] = useState()
  const [recomenProduct, setRecomendProduct] = useState();
  const [pages, setPages] = React.useState(1)
  const [distancePages, setDistancePages] = React.useState(2)
  const [numberPages, setNumberPages] = React.useState(0)
  const [noMore, setNoMore] = React.useState(true)

  const handleVoucherPage = () => {
    if (localStorage.getItem("TOKEN") === null) {
      swal(
        <NeedLoginSwal />
        , {
          buttons: false
        })
    } else {
      history.push('/voucher')
    }
  }

  const handleDailyLoginPage = () => {
    if (localStorage.getItem("TOKEN") === null) {
      swal(
        <NeedLoginSwal />
        , {
          buttons: false
        })
    } else {
      history.push('/daily-login')
    }
  }

  const menuCategories = [
    {
      name: "Kategori",
      icon: IconShopping,
      onClick: () => history.push("/category"),
    },
    {
      name: "Promo",
      icon: IconDiscount,
      onClick: () => history.push("/promo"),
    },
    {
      name: "Kupon",
      icon: IconVoucher,
      onClick: handleVoucherPage,
    },
    {
      name: "Kampus Jualan",
      icon: IconKampusJualan,
      onClick: () => window.open("https://www.kampusjualan.com/", '_blank'),
    },
    {
      name: "Daily Login",
      icon: IconDailyLogin,
      onClick: handleDailyLoginPage,
    },
  ];

  useEffect(() => {
    const getDataVuchers = async() => {
      const response = await getVouchers()
      setvouchers(response.data)
    }

    const getDataRecom = async () => {
      const getProductRecomen = await getProductRecomendations();
      setRecomendProduct(getProductRecomen);
    };

    getDataRecom();
    getDataVuchers()
  }, [])

  const fetchData = () => {
    const getData = async (myDataPages, myPages, myDistancePages) => {
      const myData = allCategories.data[myDataPages]
      if (myData === undefined) {
        setNoMore(false)
      } else {
        const category = myData.children.slice(myPages, myDistancePages);
        let response1 = ''

        response1 = await getProductData(
          `?filter[category.id]=${category[0].id}`
        );

        let data = [];
        category.forEach((res, index) => {
          data = [
            ...data.filter((value) => value.id !== category[index].id),
            {
              ...category[index],
              products:
                response1.data
            },
          ];
          setSliderProduct([...sliderProduct, ...data]);
        });
      }
    };

    if (pages >= allCategories.data[numberPages].children.length) {
      if (allCategories.data[numberPages].children.length === 0) {
        getData(numberPages + 2, 0, 1);
        setNumberPages(numberPages + 2)

      } else if (allCategories.data[numberPages].children.length === 1) {
        getData(numberPages + 2, 0, 1);
        setNumberPages(numberPages + 2)

      } else {
        getData(numberPages + 1, 0, 1);
        setNumberPages(numberPages + 1)
        setPages(1)
        setDistancePages(2)
      }
    } else {
      getData(numberPages, pages, distancePages);
      setPages(pages + 1)
      setDistancePages(distancePages + 1)
    }
  }
  
  return (
    <WrapHome>
      <ModalPopUp/>
      <PopupDeeplink url={window.location.pathname}/>
      <SearchbarMobile/>
      <Banner />
      <WrapCategory>
        <InfoPanel/>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '-50px' }} >
          <img style={{ width: '80%', borderRadius: 10, boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }} src={bannerSuperSaller} alt='banner upgrade' />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', padding: 16, top: '-10px'}}>
          {menuCategories.map((category, index) => (
            <CategoryIcon onClick={category.onClick} key={'menu' + index}>
              <div className="logo">
                <img src={category.icon} alt="category" />
              </div>
              <p className="text">{category.name}</p>
            </CategoryIcon>
          ))}
        </div>
      </WrapCategory>
      {loading ? (
        <Loading />
      ) : (
        <div>
          {/* Kupon */}
          <div style={{ backgroundColor: 'white', padding: '10px 0 10px 0', marginTop: '-10px'}}>
            <div style={{display: 'flex',marginBottom: 5, alignItems:'center', justifyContent: 'start'}}>
              <img style={{ width: 25, marginLeft: 3}} src={iconKupon} alt='icon kupon'/>
                <div style={{ fontFamily: 'DMSans-Regular', color: '#FBBA14', marginLeft: 3, fontSize: 16 }}><span style={{ fontWeight: 'bold' }}>KUPON </span><span>UNTUK KAMU HARI INI</span></div>
            </div>
            <div>
              <Carousel data={vouchers} variant="voucher" />
            </div>
          </div>
          <br/>

          {/* Produk pilihan */}
          <div style={{ backgroundColor: 'white', padding: '10px 0 10px 0', marginTop: '-10px' }}>
            <div style={{display: 'flex',marginBottom: 5, alignItems: 'center', justifyContent: 'start' }}>
              <img style={{ width: 25, marginLeft: 3 }} src={iconProdukPilihan} alt='icon ProdukPilihan' />
              <div style={{ fontFamily: 'DMSans-Regular', color: '#FBBA14', marginLeft: 3, fontSize: 16 }}><span>PRODUK </span><span style={{ fontWeight: 'bold' }}>PILIHAN</span></div>
            </div>
            <div>
              <Carousel variant='mobile' data={recomenProduct.data} />
            </div>

          </div>

          <br/>

          {/* brand populer */}
          <div style={{ backgroundColor: 'white', padding: '10px 0 10px 0', marginTop: '-10px' }}>
            <div style={{display: 'flex',marginBottom: 8, alignItems: 'center', justifyContent: 'start' }}>
              <img style={{ width: 25, marginLeft: 3 }} src={iconShop} alt='iconShop' />
              <div style={{ fontFamily: 'DMSans-Regular', color: '#FBBA14', marginLeft: 3, fontSize: 16 }}><span style={{ fontWeight: 'bold' }}>BRAND </span><span>POPULER</span></div>
            </div>
            <BrandPopuler variant='mobile'/>
          </div>

          <br/>

          {/* terbaru terlaris */}
          <div style={{ backgroundColor: 'white', padding: '10px 0 10px 0', marginTop: '-10px' }} >
            <TerbaruTerlaris variant='mobile' />
          </div>

            <br /> <br />

          {/* e-comerce terbaru */}

          {/* rekomendasi untukmu */}

          {/* auto scroll */}
          <div style={{marginTop:'-20px'}}>
            <InfiniteScroll
              dataLength={sliderProduct.length} //This is important field to render the next data
              next={fetchData}
              style={{
                overflow: 'none',
              }}
              hasMore={noMore}
              loader={
                <CircularProgress
                  color="primary"
                  style={{ display: 'flex', margin: 'auto' }}
                />
              }
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>You have seen it all</b>
                </p>
              }
            >
              {
                sliderProduct.map((res, index) =>
                  <div key={index} style={{backgroundColor: 'white', marginBottom: 10, paddingTop: 10, paddingBottom: 10}}>
                    <div
                      className={classes.titleCarousel}
                      onClick={() =>
                        history.push(
                          `/category/${res.name.split(' ').join('_').split('/').join('&')}-cat.${res.id}`
                        )
                      }
                      style={{ cursor: 'pointer' }}
                    >
                      <img
                        src={res.icon_url}
                        alt={"slider"}
                        className={classes.icon}
                      />
                      <Typography
                        className={classes.textTitleCarousel}
                      >
                        {res.name}
                      </Typography>
                    </div>
                    <div>
                      <Carousel variant='mobile' data={res.products} />
                    </div>
                  </div>
                )
              }
            </InfiniteScroll>
          </div>

        </div>
      )}

      <ChatPic />
      <ToolbarMobile/>
    </WrapHome>
  );
};

export default HomePageMobileComponent;
