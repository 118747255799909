import React, { Fragment, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import { useHistory, withRouter } from "react-router-dom";
import formatCash from "../../utils/formatCash";
import { numberSeparator } from "../../utils/numberSeparator";
import { addToStorefront, isLoggedIn } from "../../services/axios";
import swal from "@sweetalert/with-react";
import { Typography, Button, Grid } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { setCookie, getCookie } from "../../utils/handleCookie";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { CopyToClipboard } from "react-copy-to-clipboard";

// icon
import iconDropship from "../../../src/assets/icons/icon_card/icon_label_marketplace.png";
import iconGratisOngkirMulti from "../../../src/assets/icons/icon_card/icon_label_ongkir_multi.svg";
import iconGratisOngkirSinggle from "../../../src/assets/icons/icon_card/icon_label_ongkir_single.svg";
import iconCod from "../../../src/assets/icons/icon_card/icon_label_cod.png";
import iconCashback from "../../../src/assets/icons/icon_card/icon_label_cashback.svg";
import iconLocation from '../../../src/assets/icons/location-pin-thin.svg'
import CustomPriceReseller from "../CustomPriceReseller";

const CardProduct = withStyles(styles)((props) => {
  const { classes, data, variant, match, handleClick } = props;
  const [isSingleOngkir, setisSingleOngkir] = useState(false);
  const isMobileSmall = useMediaQuery("(max-width:380px)")
  const isStoreFront = match.path.indexOf("/store/:reseller") !== -1;
  const urlPathName = window.location.pathname.split('/')
  let storefront = urlPathName[2]
  const [loading, setLoading] = useState(false);
  const [isOpenCustomPriceReseller, setisOpenCustomPriceReseller] = useState(false)
  const isPremiumMember = JSON.parse(localStorage.getItem("USER_DATA"))?.activated || false;

  if (isLoggedIn()) {
    if (!isStoreFront) {
      const userData = JSON.parse(localStorage.getItem('USER_DATA'))
      storefront = userData.storefront.slug
    }
  }

  const history = useHistory();

  const handleAddToStorefront = async (slug_id) => {
    if (!isLoggedIn()) return (window.location = "/login");
    setisOpenCustomPriceReseller(true)
    setLoading(false);
  };

  const handleClcikProduct = () => {
    const getData = JSON.parse(getCookie("recomendation_products"));
    if (Boolean(getCookie("recomendation_products")) && getData.length === 10) {
      setCookie(
        "recomendation_products",
        JSON.stringify([...getData, data.category.id].slice(1)),
        30
      );
    } else if (Boolean(getCookie("recomendation_products"))) {
      setCookie(
        "recomendation_products",
        JSON.stringify([...getData, data.category.id]),
        30
      );
    } else {
      setCookie(
        "recomendation_products",
        JSON.stringify([data.category.id]),
        30
      );
    }
    if (handleClick) handleClick();
    if (isStoreFront) {
      history.push(`/store/${storefront}/${data.slug}`);
    }else {
      history.push(`/product/${data.slug}`);
    }
  };

  const handleClcikProductCustomer = () => {
    const getData = JSON.parse(getCookie("recomendation_products"));
    if (Boolean(getCookie("recomendation_products")) && getData.length === 10) {
      setCookie(
        "recomendation_products",
        JSON.stringify([...getData, data.category.id].slice(1)),
        30
      );
    } else if (Boolean(getCookie("recomendation_products"))) {
      setCookie(
        "recomendation_products",
        JSON.stringify([...getData, data.category.id]),
        30
      );
    } else {
      setCookie(
        "recomendation_products",
        JSON.stringify([data.category.id]),
        30
      );
    }
    if (handleClick) handleClick();
    window.scrollTo({ top: 0, behavior: "smooth" });
    history.push(`/store/${storefront}/${data.slug}`);
  };

  React.useEffect(() => {
    if (
      (data.supplier.is_cod === false) & (data.vouchers.cashback === false) &&
      data.vouchers.ongkir === true
    ) {
      setisSingleOngkir(true);
    }
  }, []);

  const Customer = () => {
    const isMobile = useMediaQuery("(max-width:500px)");
    return (
      <Fragment>
        <div className={classes.container} style={{ width: isMobile && 120 }}>
          <div style={{ margin: "auto", width: isMobile ? 120 : 130 }}>
            <a
              style={{ textDecoration: "none" }}
              href={`/store/${storefront}/${data.slug}`}
            >
              <div
                style={{
                  background: `url(${data.image_url})`,
                  width: isMobile && 120,
                  height: isMobile && 120,
                }}
                className={classes.imageProduct}
                onClick={handleClcikProductCustomer}
              >
                <div className={classes.wrapperTop}>
                  {data.supplier.is_dropship ? (
                    <img
                      src={iconDropship}
                      alt="icon dropship"
                      className={classes.iconDropshop}
                    ></img>
                  ) : (
                    <div></div>
                  )}
                  <Typography className={classes.komisi}>
                    -{" "}
                    {data.commission.type === "FIXED"
                      ? formatCash(data.commission.value)
                      : data.commission.value + " %"}
                    <div>KOMISI</div>
                  </Typography>
                </div>

                <div className={classes.wrapperBottom}>
                  {data.supplier.is_cod && (
                    <img
                      src={iconCod}
                      alt="icon cod"
                      className={classes.iconBottom}
                    ></img>
                  )}
                  {isSingleOngkir ? (
                    <img
                      src={iconGratisOngkirSinggle}
                      alt="icon gratis ongkir"
                      className={classes.iconBottom}
                    ></img>
                  ) : (
                    data.vouchers.ongkir && (
                      <img
                        src={iconGratisOngkirMulti}
                        alt="icon gratis ongkir"
                      ></img>
                    )
                  )}
                  {data.vouchers.cashback && (
                    <img
                      src={iconCashback}
                      alt="icon cashbak"
                      className={classes.iconBottom}
                    ></img>
                  )}
                </div>
              </div>
            </a>
            <a
              style={{ textDecoration: "none", color: "black" }}
              href={`/store/${storefront}/${data.slug}`}
            >
              <Typography
                onClick={handleClcikProductCustomer}
                style={{ cursor: "pointer" }}
                className={classes.title}
              >
                {data.name}
              </Typography>
            </a>
            {
              isStoreFront ?
              <>
                <Typography className={classes.priceDiscount}>
                  Harga
                </Typography>

                <Typography color="primary">
                  <b>
                    Rp{" "}
                    {numberSeparator(
                      data.consumer_price_idr
                    )}
                  </b>
                </Typography>
              </>
              :
              <>
                <Typography className={classes.priceDiscount}>
                  Harga Reseller
                </Typography>

                {/* {discount} */}
                <Typography className={classes.withoutDisc}>
                  <b>
                    Rp{" "}
                    {numberSeparator(
                      data.skus[0].price.consumer +
                      data.skus[0].price.commission
                    )}
                  </b>
                </Typography>
                {/* end off discount */}

                <Typography
                  color="primary"
                >
                  <b>
                    Rp{" "}
                    {numberSeparator(
                      data && data.skus && data.skus.length > 0
                        ? data.skus[0].price.consumer
                        : 0
                    )}
                  </b>
                </Typography>
              </>
            }
            <Button
              className={classes.button2}
              color="primary"
              variant="contained"
              onClick={handleClcikProductCustomer}
            >
              <Typography style={{ fontSize: "0.8rem" }}>
                Beli Sekarang
              </Typography>
            </Button>
          </div>
        </div>
        {/* <div className={classes.container} style={{ width: isMobile && 120 }}>
          <div style={{ margin: "auto", width: isMobile ? 120 : 130 }}>
            <div
              style={{
                background: `url(${data.image_url})`,
                width: isMobile && 120,
                height: isMobile && 120,
              }}
              className={classes.imageProduct}
              onClick={handleClcikProductCustomer}
            ></div>
            <Typography className={classes.title}>{data.name}</Typography>
            <Typography
              className={classes.priceDiscount}
              style={{ justifyContent: "space-between" }}
              variant="caption"
            >
              <Grid container>
                <Grid item xs={12}>
                  <span style={{ textDecoration: "line-through" }}>
                    Rp{" "}
                    {numberSeparator(
                      data.skus[0].price.consumer +
                        data.skus[0].price.commission
                    )}
                  </span>
                </Grid>
                <Grid item>
                  <div className={classes.pricePersen}>
                    -{" "}
                    {data.commission.type === "FIXED"
                      ? formatCash(data.commission.value)
                      : data.commission.value + " %"}
                  </div>
                </Grid>
              </Grid>
            </Typography>
            <Typography color="primary" style={{ marginTop: 10 }}>
              <b>
                Rp{" "}
                {numberSeparator(
                  data && data.skus && data.skus.length > 0
                    ? data.skus[0].price.consumer
                    : 0
                )}
              </b>
            </Typography>
            <Button
              className={classes.button2}
              style={{ marginTop: 10, marginBottom: 10 }}
              onClick={handleClcikProductCustomer}
              color="primary"
              variant="contained"
            >
              <Typography style={{ fontSize: "0.8rem" }}>
                Beli Sekarang
              </Typography>
            </Button>
          </div>
        </div> */}
      </Fragment>
    );
  };

  const Reseller = () => {
    const isMobile = useMediaQuery("(max-width:500px)");

    return (
      <Fragment>
        <div className={classes.container} style={{ width: isMobile && 120 }}>
          <div style={{ margin: "auto", width: isMobile ? 120 : 130 }}>
            <a
              style={{ textDecoration: "none" }}
              href={`/product/${data.slug}`}
            >
              <div
                style={{
                  background: `url(${data.image_url})`,
                  width: isMobile && 120,
                  height: isMobile && 120,
                }}
                className={classes.imageProduct}
                onClick={handleClcikProduct}
              >
                <div className={classes.wrapperTop}>
                  {data.supplier.is_dropship ? (
                    <img
                      src={iconDropship}
                      alt="icon dropship"
                      className={classes.iconDropshop}
                    ></img>
                  ) : (
                    <div></div>
                  )}
                  <Typography className={classes.komisi}>
                    {(data.commission.type === "FIXED" ? "RP " : "") +
                      formatCash(data.commission.value) +
                      (data.commission.type === "PERCENT" ? " %" : "")}{" "}
                    <div>KOMISI</div>
                  </Typography>
                </div>

                <div className={classes.wrapperBottom}>
                  {data.supplier.is_cod && (
                    <img
                      src={iconCod}
                      alt="icon cod"
                      className={classes.iconBottom}
                    ></img>
                  )}
                  {isSingleOngkir ? (
                    <img
                      src={iconGratisOngkirSinggle}
                      alt="icon gratis ongkir"
                      className={classes.iconBottom}
                    ></img>
                  ) : (
                    data.vouchers.ongkir && (
                      <img
                        src={iconGratisOngkirMulti}
                        alt="icon gratis ongkir"
                      ></img>
                    )
                  )}
                  {data.vouchers.cashback && (
                    <img
                      src={iconCashback}
                      alt="icon cashbak"
                      className={classes.iconBottom}
                    ></img>
                  )}
                </div>
              </div>
            </a>
            <a
              style={{ textDecoration: "none", color: "black" }}
              href={`/product/${data.slug}`}
            >
              <Typography
                onClick={handleClcikProduct}
                style={{ cursor: "pointer" }}
                className={classes.title}
              >
                {data.name}
              </Typography>
            </a>
            <Typography className={classes.priceDiscount}>
              Harga Reseller
            </Typography>

            {/* {discount} */}
            <Typography className={classes.withoutDisc}>
              <b>
                {data.skus[0].price.reseller_without_disc !== undefined ? (
                  "Rp  " +
                  numberSeparator(data.skus[0].price.reseller_without_disc)
                ) : (
                  <br />
                )}
              </b>
            </Typography>
            {/* end off discount */}

            <Typography
              color="primary"
              className={
                data.skus[0].price.reseller_without_disc !== undefined
                  ? classes.disc
                  : ""
              }
            >
              <b>
                Rp{" "}
                {numberSeparator(
                  data && data.skus && data.skus.length > 0
                    ? data.skus[0].price.reseller
                    : 0
                )}
              </b>
            </Typography>
            {props.match.path.indexOf("/store/:reseller") !== -1 ? (
              <CopyToClipboard
                text={window.location.href + `/${data.slug}`}
                onCopy={() => {
                  swal("Link disalin !");
                }}
              >
                <Button className={classes.button1} color="primary">
                  <Typography style={{ fontSize: "0.8rem" }}>
                    Bagikan Produk
                  </Typography>
                </Button>
              </CopyToClipboard>
            ) : (
              <Button
                className={classes.button1}
                color="primary"
                onClick={() => handleAddToStorefront(data.slug)}
              >
                {loading ? (
                  <CircularProgress style={{ height: 19, width: 19 }} />
                ) : (
                  <Typography style={{ fontSize: "0.8rem" }}>
                    Tambah ke Toko
                  </Typography>
                )}
              </Button>
            )}
            <Button
              className={classes.button2}
              color="primary"
              variant="contained"
              onClick={handleClcikProduct}
            >
              <Typography style={{ fontSize: "0.8rem" }}>
                Beli Sekarang
              </Typography>
            </Button>
          </div>
        </div>
        <CustomPriceReseller 
          open={isOpenCustomPriceReseller} 
          setIsOpen={setisOpenCustomPriceReseller} 
          product={data.slug} 
          storefrontName={storefront}
          isPremiumMember={isPremiumMember}
        />
      </Fragment>
    );
  };

  const Mobile = () => {
    return (
      <Fragment>
        <div className={classes.container} style={isMobileSmall ? { width: 150, margin: 5, boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px', borderRadius: 4 } : { width: 120, margin: 5, boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px', borderRadius: 4 }}>
          <div style={isMobileSmall ? { margin: "auto", width: 150 } : { margin: "auto", width: 120 }}>
            <a
              style={{ textDecoration: "none" }}
              href={isStoreFront ? `/store/${storefront}/${data.slug}` : `/product/${data.slug}`}
            >
              <div
                style={isMobileSmall ? { background: `url(${data.image_url})`, width: 150, height: 120, } : { background: `url(${data.image_url})`, width: 120, height: 120, }}
                className={classes.imageProduct}
                onClick={handleClcikProduct}
              >
                <div className={classes.wrapperTop}>
                  {data.supplier.is_dropship ? (
                    <img
                      src={iconDropship}
                      alt="icon dropship"
                      className={classes.iconDropshop}
                    ></img>
                  ) : (
                    <div></div>
                  )}
                  <div className={classes.komisi}>
                    {(data.commission.type === "FIXED" ? "RP " : "") +
                      formatCash(data.commission.value) +
                      (data.commission.type === "PERCENT" ? " %" : "")}{" "}
                    <div>KOMISI</div>
                  </div>
                </div>

                <div className={classes.wrapperBottom}>
                  {data.supplier.is_cod && (
                    <img
                      src={iconCod}
                      alt="icon cod"
                      className={classes.iconBottom}
                    ></img>
                  )}
                  {isSingleOngkir ? (
                    <img
                      src={iconGratisOngkirSinggle}
                      alt="icon gratis ongkir"
                      className={classes.iconBottom}
                    ></img>
                  ) : (
                    data.vouchers.ongkir && (
                      <img
                        src={iconGratisOngkirMulti}
                        alt="icon gratis ongkir"
                      ></img>
                    )
                  )}
                  {data.vouchers.cashback && (
                    <img
                      src={iconCashback}
                      alt="icon cashbak"
                      className={classes.iconBottom}
                    ></img>
                  )}
                </div>
              </div>
            </a>
            <div style={{padding: 5}}>
              <a
                style={{ textDecoration: "none", color: "black" }}
                href={`/product/${data.slug}`}
              >
                <Typography
                  onClick={handleClcikProduct}
                  style={{ cursor: "pointer", fontSize: '.9rem', color: '#8b8b8b' }}
                  className={classes.title}
                >
                  {data.name}
                </Typography>
              </a>

              {/* {discount} */}
              <Typography className={classes.withoutDisc}>
                {data.skus[0].price.reseller_without_disc !== undefined ? (
                  "Rp  " +
                  numberSeparator(data.skus[0].price.reseller_without_disc)
                ) : (
                  <br />
                )}
              </Typography>
              {/* end off discount */}

              <Typography
                color="primary"
                style={{fontSize: '.8rem'}}
                className={
                  data.skus[0].price.reseller_without_disc !== undefined
                    ? classes.disc
                    : ""
                }
              >
                Rp{" "}
                {numberSeparator(
                  data && data.skus && data.skus.length > 0
                    ? data.skus[0].price.reseller
                    : 0
                )}
              </Typography>
              <div style={{ color: '#a5a3a3', fontSize: '.7rem' }} >{data.total_sold} Terjual</div>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <img src={iconLocation} alt='icon location' style={{width: 13, height: 13, marginRight: 2}} />
                <div style={{
                  color: '#bdbdbd', fontSize: '.8rem', width: 100, overflow: 'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap' }}>
                  {data.supplier.location.subdistrict !== null ? 'Kab. ' + data.supplier?.location?.village?.district?.city?.name : data.supplier.location.village.name}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }

  return (
    <Fragment>
      {
        variant === "reseller" ? 
          <Reseller /> 
          : 
          variant === 'mobile' ?
            <Mobile />
            :
            <Customer />
      }
    </Fragment>
  );
});

export default withRouter(CardProduct);
