const styles = (theme) => ({
  storeName: {
    display: "flex",
    paddingBottom: 10,
  },
  image: {
    objectFit: "cover",
    borderRadius: 4,
  },
  itemWrapper: {
    paddingTop: 10,
    display: "flex",
  },
  itemTitle: {
    overflow: "hidden",
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 1,
  },
  flexBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  textFlexEnd: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  containerActionButton: {
    position: "fixed",
    width: "100%",
    bottom: 0,
    paddingTop: 20,
    paddingBottom: 10,
    boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.08)",
    backgroundColor: "#ffffff",
  },
});

export default styles;
