import React, { Fragment, useEffect } from "react";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import { Container, Typography } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const NotFoundPage = () => {
  const isMobile = useMediaQuery("(max-width:500px)");

  useEffect(() => {
    document.title = "404 Page Not Found | Idejualan";
  }, []);

  const style = {
    textAlign: "center",
    height: !isMobile
      ? window.screen.height - 247.855
      : window.screen.height - 292,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  return (
    <Fragment>
      <Navbar variant="default" />
      <Container maxWidth="md" style={style}>
        <div>
          <Typography variant={isMobile ? "h2" : "h1"}>404</Typography>
          <Typography variant={isMobile ? "h5" : "h2"}>
            Halaman Tidak Ditemukan.
          </Typography>
        </div>
      </Container>
      <Footer />
    </Fragment>
  );
};

export default NotFoundPage;
