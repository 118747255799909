import styled from "styled-components";

export const MyCart = styled.div`
  position: absolute;
  right: 0;
  cursor: pointer;
  top: 4px;

  .count {
    width: 18px;
    height: 18px;
    border-radius: 36px;
    font-size: 11px;
    font-family: "DMSans-Regular";
    color: #fff;
    background-color: #f54646;
    right: 0;
    text-align: center;
    padding: 2px;
    top: -4px;
    position: absolute;
    z-index: 1;
  }
  svg {
    position: relative;
    right: 8px;
  }
`;
