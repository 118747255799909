import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Container, Typography } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

// Pembayaran Icons
import Alfamart from "../assets/icons/pembayaran/Alfamart.svg";
import BRI from "../assets/icons/pembayaran/bank_BRI.svg";
import Mandiri from "../assets/icons/pembayaran/Bank_Mandiri.svg";
import BCA from "../assets/icons/pembayaran/BCA.svg";
import BNI from "../assets/icons/pembayaran/BNI.svg";
import Indomaret from "../assets/icons/pembayaran/Indomaret.svg";

//Pengiriman Icons
import Gosend from "../assets/icons/pengiriman/gosend.svg";
import Grab from "../assets/icons/pengiriman/grabexpress.svg";
import JNT from "../assets/icons/pengiriman/j&t.svg";
import JNE from "../assets/icons/pengiriman/jne.svg";
import Ninja from "../assets/icons/pengiriman/ninja.svg";
import POS from "../assets/icons/pengiriman/pos.svg";
import Sicepat from "../assets/icons/pengiriman/sicepat.svg";

// social media
import Facebook from "../assets/icons/social/facebook.svg";
import Instagram from "../assets/icons/social/instagram.svg";
import Line from "../assets/icons/social/line.svg";
import Twitter from "../assets/icons/social/twitter.svg";

const useStyles = makeStyles({
  icon: {
    width: "100%",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  flex: {
    display: "flex",
    marginBottom: 10,
  },
  container: {
    paddingTop: 45,
    paddingBottom: 50,
  },
  title: {
    fontWeight: "bold",
    paddingBottom: 20,
  },
  gray: {
    color: "#555555",
  },
  footer: {
    paddingTop: 30,
    paddingBottom: 30,
    textAlign: "center",
    background: "#f5f5f5",
    marginTop: 30,
  },
  footerActions: {
    display: "block",
    paddingTop: 10,
  },
  textActionFooter: {
    borderBottomStyle: "dotted",
    borderWidth: 1,
    cursor: "pointer",
  },
});

const BaseIcon = (props) => {
  const classes = useStyles();
  return (
    <Grid item xs={4} className={classes.center}>
      <img src={props.logo} alt="" className={classes.icon} />
    </Grid>
  );
};

const Social = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.flex}>
      <img src={props.icon} alt="" />
      <Typography className={classes.gray} style={{ marginLeft: 5 }}>
        {props.text}
      </Typography>
    </div>
  );
};

const Pembayaran = [Alfamart, BRI, Mandiri, BCA, BNI, Indomaret];
const Pengiriman = [Gosend, Grab, JNT, JNE, Ninja, POS, Sicepat];
const SocialMedia = [
  {
    id: 1,
    icon: Facebook,
    text: "Facebook",
  },
  {
    id: 2,
    icon: Instagram,
    text: "Instagram",
  },
  {
    id: 3,
    icon: Twitter,
    text: "Twitter",
  },
  {
    id: 4,
    icon: Line,
    text: "Line",
  },
];

const Footer = (props) => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:500px)");
  return (
    <Fragment>
      {isMobile ? (
        <Fragment>
          <Container maxWidth="ls" className={classes.footer}>
            <Typography variant="caption">
              Copyright {new Date().getFullYear()} · idejualan · Mudah &
              Terpercaya
            </Typography>
            <Typography variant="caption" className={classes.footerActions}>
              <span className={classes.textActionFooter}>Peta Situs</span> ·{" "}
              <span
                className={classes.textActionFooter}
                onClick={() => (window.location.href = "/cek-resi")}
              >
                Cek Resi
              </span>{" "}
              · <span className={classes.textActionFooter}>Cek Konfirmasi</span>{" "}
              · <span className={classes.textActionFooter}>Testimonial</span>
            </Typography>
          </Container>
        </Fragment>
      ) : (
        <Fragment>
          <div style={{ background: "#F5F5F5" }}>
            <Container maxWidth="md">
              <Grid container spacing={3} className={classes.container}>
                <Grid item xs={3}>
                  <Typography className={classes.title}>
                    LAYANAN PELANGGAN
                  </Typography>
                  <div className={classes.gray}>
                    <Typography>Bantuan</Typography>
                    <Typography>Pembayaran</Typography>
                    <Typography>Lacak Pesanan</Typography>
                    <Typography>Gratis Ongkir</Typography>
                    <Typography>Pengembalian Barang & Dana</Typography>
                    <Typography>Garansi Idejualan</Typography>
                    <Typography>Hubungi Kami</Typography>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <Typography className={classes.title}>
                    JELAJAHI IDEJUALAN
                  </Typography>
                  <div className={classes.gray}>
                    <Typography>Tentang Kami</Typography>
                    <Typography>Karir</Typography>
                    <Typography>Kebijakan Idejualan</Typography>
                    <Typography>Kebijaka Privasi</Typography>
                    <Typography>Blog</Typography>
                    <Typography>Seller Center</Typography>
                    <Typography>Flash Sale</Typography>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <Typography className={classes.title}>PEMBAYARAN</Typography>

                  <Grid container spacing={2}>
                    {Pembayaran.map((res, index) => (
                      <BaseIcon logo={res} key={index} />
                    ))}
                  </Grid>
                  <Typography
                    className={classes.title}
                    style={{ paddingTop: 20 }}
                  >
                    PENGIRIMAN
                  </Typography>
                  <Grid container spacing={2}>
                    {Pengiriman.map((res, index) => (
                      <BaseIcon logo={res} key={index} />
                    ))}
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <Typography className={classes.title}>IKUTI KAMI</Typography>
                  {SocialMedia.map((res) => (
                    <Fragment key={res.id}>
                      <Social icon={res.icon} text={res.text} />
                    </Fragment>
                  ))}
                </Grid>
              </Grid>
            </Container>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Footer;
