import React, { Fragment, useState, useEffect } from "react";
import {
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { numberSeparator } from "../../../../utils/numberSeparator";
import { withRouter } from "react-router-dom";
import swal from "@sweetalert/with-react";
import { quantity, remove } from "cart-localstorage";
import { getCitiesByProvinceId, getDistrictsByCityId, getProvinces, getSubdistrictByDistrictId } from "../../../../services/location.service";
import { isEmpty } from "lodash";

const useStyles = makeStyles((theme) => ({
  cardBackground: {
    background: "#F5F5F5",
    borderRadius: 8,
    padding: 10,
    width: "100%",
  },
  flexCenterBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flexBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  ubahAlamat: {
    borderBottomStyle: "dotted",
    borderWidth: 1,
    cursor: "pointer",
    display: "flex",
    width: "fit-content",
    paddingTop: 20,
    marginBottom: 30,
  },
}));

const Checkout = (props) => {
  const { listPrice, setListPrice, isUpdated, setCart, selectedCart, history } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [listProvince, setListProvince] = useState([]);
  const [listCity, setListCity] = useState([]);
  const [listDistrict, setListDistrict] = useState([]);
  const [listSubdistrict, setListSubdistrict] = useState([]);
  const [dataCustomer, setDataCustomer] = useState({
    name: "",
    address: "",
    province: "",
    city: "",
    subdistrict: "",
    district: "",
    postal_code: "",
    phone: "",
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    if (selectedCart.length !== 0) {
      setOpen(true)
    } else {
      swal("Oops", "Cheklist barang yang akan di checkout", "warning");
    }
  };

  useEffect(() => {
    const fetch = async () => {
      const res = await getProvinces();
      setListProvince(res.data);
    };
    fetch();
  }, []);

  const handleChangeProvince = async (event) => {
    const res = await getCitiesByProvinceId(event.id);
    setListCity(res.data);
    setDataCustomer({ ...dataCustomer, province: event });
  };

  const handleChangeCity = async (event) => {
    const res = await getDistrictsByCityId(event.id);
    setListDistrict(res.data);
    setDataCustomer({ ...dataCustomer, city: event });
  };

  const handleChangeDistrict = async (event) => {
    const res = await getSubdistrictByDistrictId(event.id);
    setListSubdistrict(res.data);
    setDataCustomer({ ...dataCustomer, district: event });
  };

  const handleChangeSubdistrict = (event) => {
    setDataCustomer({
      ...dataCustomer,
      subdistrict: event,
      postal_code: event.kodepos || "",
    });
  };

  const getListPrice = async () => {
    let list = [];
    await selectedCart.forEach((res) => {
      list = [...list, res.price * res.quantity];
    });
    setListPrice(list);
  };

  useEffect(() => {
    getListPrice();
  }, [selectedCart]);

  useEffect(() => {
    const updateCart = async () => {
      const getCart = JSON.parse(localStorage.getItem("__cart"));
      await setCart(getCart);
    };
    updateCart();
    getListPrice();
  }, [isUpdated]);

  const handleCheckout = () => {
    const getDataCheckout = JSON.parse(localStorage.getItem("SELECTED_CART"));

    // check form
    if (isEmpty(dataCustomer.province)) {
      return swal("Error!", "Provinsi wajib diisi", "error");
    } else if (isEmpty(dataCustomer.city)) {
      return swal("Error!", "Kota wajib diisi", "error");
    } else if (isEmpty(dataCustomer.subdistrict)) {
      return swal("Error!", "Desa/Kelurahan wajib diisi", "error");
    } else if (isEmpty(dataCustomer.district)) {
      return swal("Error!", "Kecamatan wajib diisi", "error");
    } else if (isEmpty(dataCustomer.postal_code)) {
      return swal("Error!", "Kodepos wajib diisi", "error");
    } else if (isEmpty(dataCustomer.name)) {
      return swal("Error!", "Nama wajib diisi", "error");
    } else if (isEmpty(dataCustomer.address)) {
      return swal("Error!", "Alamat wajib diisi", "error");
    } else if (isEmpty(dataCustomer.phone)) {
      return swal("Error!", "No. Telephone wajib diisi", "error");
    }

    // add form to cart
    if (selectedCart.length !== 0) {
      for (let indexSelectedCart = 0; indexSelectedCart < selectedCart.length; indexSelectedCart++) {
        selectedCart[indexSelectedCart].id = `${selectedCart[indexSelectedCart].id}-${dataCustomer.name}`
        selectedCart[indexSelectedCart].customer = {
          name: dataCustomer.name,
          phone: dataCustomer.phone,
          postal_code: dataCustomer.postal_code,
          address: dataCustomer.address,
          village: {
            district: {
              city: {
                id: dataCustomer.city.id,
                name: dataCustomer.city.name,
                province_id: dataCustomer.province.id,
                province: {
                  id: dataCustomer.province.id,
                  name: dataCustomer.province.name,
                }
              },
              id: dataCustomer.district.id,
              name: dataCustomer.district.name
            },
            id: dataCustomer.subdistrict.id,
            kodepos: dataCustomer.subdistrict.kodepos,
            name: dataCustomer.subdistrict.name
          }
        }
      }

      // memasukkan data ke data checkout
      if (getDataCheckout !== null) {
        localStorage.setItem(
          "SELECTED_CART",
          JSON.stringify(getDataCheckout.concat(selectedCart))
        );
      } else {
        localStorage.setItem("SELECTED_CART", JSON.stringify(selectedCart));
      }
      history.push("/checkout-customer");
    } else {
      swal("Oops", "Cheklist barang yang akan di checkout", "warning");
    }
  };

  return (
    <Fragment>
      <Fragment>
        <div className={classes.cardBackground}>
          <div className={classes.flexCenterBetween}>
            <Typography>
              <b>Total Belanja</b>
            </Typography>
            <Typography>
              <b>Rp {numberSeparator(listPrice.reduce((a, b) => a + b, 0))}</b>
            </Typography>
          </div>

          <div
            className={classes.flexCenterBetween}
            style={{
              paddingTop: 20,
            }}
          >
            <Typography variant="caption">
              {selectedCart.length === 0
                ? ""
                : `${selectedCart.length} barang `}
            </Typography>
            <Typography variant="caption">belum ongkos kirim</Typography>
          </div>

          <Button
            variant="contained"
            color="primary"
            fullWidth={true}
            style={{ marginTop: 30 }}
            onClick={handleOpen}
          >
            <b>Checkout</b>
          </Button>
        </div>
      </Fragment>

      {/* dialog customer */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Form Pembeli</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Silahkan mengisi data diri untuk melanjutkan transaksi.
          </DialogContentText>
          <>
            <TextField
              id="outlined-basic"
              style={{ marginTop: 20 }}
              value={dataCustomer.name}
              label="Nama"
              variant="outlined"
              fullWidth={true}
              onChange={(e) =>
                setDataCustomer({ ...dataCustomer, name: e.target.value })
              }
            />
            <TextField
              id="outlined-basic"
              style={{ marginTop: 20 }}
              label="Alamat"
              value={dataCustomer.address}
              variant="outlined"
              fullWidth={true}
              onChange={(e) =>
                setDataCustomer({ ...dataCustomer, address: e.target.value })
              }
            />
            <TextField
              id="outlined-basic"
              style={{ marginTop: 20 }}
              type='number'
              label="No.Telpon"
              value={dataCustomer.phone}
              variant="outlined"
              fullWidth={true}
              onChange={(e) =>
                setDataCustomer({ ...dataCustomer, phone: e.target.value })
              }
            />
          </>

          <FormControl variant="outlined" style={{ width: "100%", marginTop: 20 }}>
            <InputLabel id="demo-simple-select-outlined-label">Provinsi</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={
                dataCustomer.province.length === 0 ? "" : dataCustomer.province.name
              }
              label="Provinsi"
            >
              {listProvince !== null &&
                listProvince.map((res) => {
                  return (
                    <MenuItem
                      key={res.id}
                      value={res.name}
                      onClick={() => handleChangeProvince(res)}
                    >
                      {res.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>

          <FormControl variant="outlined" style={{ width: "100%", marginTop: 20 }}>
            <InputLabel id="demo-simple-select-outlined-label">Kota</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={dataCustomer.city.length === 0 ? "" : dataCustomer.city.name}
              label="Kota"
            >
              {listCity !== null &&
                listCity.map((res) => {
                  return (
                    <MenuItem
                      key={res.id}
                      value={res.name}
                      onClick={() => handleChangeCity(res)}
                    >
                      {res.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>

          <FormControl variant="outlined" style={{ width: "100%", marginTop: 20 }}>
            <InputLabel id="demo-simple-select-outlined-label">
              Kecamatan
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={
                dataCustomer.district.length === 0 ? "" : dataCustomer.district.name
              }
              label="Kecamatan"
            >
              {listDistrict.map((res) => {
                return (
                  <MenuItem
                    key={res.id}
                    value={res.name}
                    onClick={() => handleChangeDistrict(res)}
                  >
                    {res.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl variant="outlined" style={{ width: "100%", marginTop: 20 }}>
            <InputLabel id="demo-simple-select-outlined-label">
              Desa/Kelurahan
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={
                dataCustomer.subdistrict.length === 0
                  ? ""
                  : dataCustomer.subdistrict.name
              }
              label="Desa/Kelurahan"
            >
              {listSubdistrict.map((res) => {
                return (
                  <MenuItem
                    key={res.id}
                    value={res.name}
                    onClick={() => handleChangeSubdistrict(res)}
                  >
                    {res.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl variant="outlined" style={{ width: "100%" }}>
            <TextField
              id="outlined-basic"
              style={{ marginTop: 20 }}
              value={dataCustomer.postal_code}
              label="Kode Pos"
              type="number"
              variant="outlined"
              fullWidth={true}
              onChange={(e) =>
                setDataCustomer({ ...dataCustomer, postal_code: e.target.value })
              }
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCheckout} color="primary">
            Lanjutkan 
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
export default withRouter(Checkout);
