import React, { Fragment, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, Typography, Button } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Slider from "react-slick";
import { ReactComponent as PremiumIcon } from "../assets/icons/icon_premium.svg";
import { ReactComponent as PremiumIconVector1 } from "../assets/icons/icon_premium/premium_1.svg";
import { ReactComponent as PremiumIconVector2 } from "../assets/icons/icon_premium/premium_2.svg";
import { ReactComponent as PremiumIconVector3 } from "../assets/icons/icon_premium/premium_3.svg";
import { ReactComponent as PremiumIconVector4 } from "../assets/icons/icon_premium/premium_4.svg";
import { ReactComponent as PremiumIconVector5 } from "../assets/icons/icon_premium/premium_5.svg";
import { ReactComponent as PremiumIconVector6 } from "../assets/icons/icon_premium/premium_6.svg";
import ReactMidtrans from "../config/midtrans";
// import ReactMidtrans from 'react-midtrans'
import midtransClientKey from "../config/midtransClientKey"
import { formatRupiah } from "../utils/currencyFormat";

const overview = [
  {
    title: "Bimbingan Jualan",
    desc:
      "Di Platform Kampusjualan.com hingga 50++ Materi Jualan",
    icon: <PremiumIconVector6 height="auto" width="100%" />,
  },
  {
    title: "Potongan Hingga 30%",
    desc:
      "Dengan menjadi Super Seller, Anda akan mendapatkan potongan harga sebesar 30%",
    icon: <PremiumIconVector1 height="auto" width="100%" />,
  },
  {
    title: "Unlimited Produk Tokoku",
    desc: "Anda bisa menambahkan produk ke tokoku tanpa batas limitasi!",
    icon: <PremiumIconVector2 height="auto" width="100%" />,
  },
  {
    title: "Bonus Penjualan",
    desc:
      "Berbagai bonus menanti Anda untuk membuat anda menjasi lebih nyaman dalam menjalankan bisnis anda",
    icon: <PremiumIconVector3 height="auto" width="100%" />,
  },
  {
    title: "Event Khusus Bulanan",
    desc:
      "Berbagai event menarik dengan hadiah mulai dari Smartphone hingga Emas Batangan!",
    icon: <PremiumIconVector4 height="auto" width="100%" />,
  },
  {
    title: "Member Get Member",
    desc:
      "Ajak temanmu untuk bergabung di Idejualan dan dapatkan bonus Rp100,000/member",
    icon: <PremiumIconVector5 height="auto" width="100%" />,
  },
];

const UpgradePremiumDialog = (props) => {
  const { open, onClose, token } = props;
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:500px)");

  const customeSlider = React.createRef();

  const next = () => {
    customeSlider.current.slickNext();
  };
  const previous = () => {
    customeSlider.current.slickPrev();
  };

  return (
    <Fragment>
      <Dialog open={open} onClose={onClose}>
        <div className={classes.wrapper}>
          <Slider
            infinite={true}
            speed={500}
            slidesToShow={1}
            slidesToScroll={1}
            arrows={false}
            ref={customeSlider}
          >
            {overview.map((res, index) => (
              <div key={index}>
                <div className={classes.icon}>{res.icon}</div>
                <div className={classes.overviewTextWrapper}>
                  <Typography variant="h6">
                    <b>{res.title}</b>
                  </Typography>
                  <Typography variant="body2" className={classes.desc}>
                    {res.desc}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: -80,
                    marginBottom: 20,
                    position: "relative",
                  }}
                >
                  <ArrowBackIcon
                    className={classes.arrowBefore}
                    onClick={() => previous()}
                  />
                  <ArrowForwardIcon
                    className={classes.arrowNext}
                    onClick={() => next()}
                  />
                </div>
              </div>
            ))}
          </Slider>

          <Typography
            variant="body2"
            style={{
              marginBottom: 10,
              marginTop: 10,
            }}
          >
            <b>Dapatkan Akses Premium Setahun</b>
          </Typography>
          <ReactMidtrans clienttKey={midtransClientKey} token={token}>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonUpgrade}
              startIcon={
                <PremiumIcon style={{ height: 20, fill: "#ffffff" }} />
              }
            >
              <div>
                  <Typography className={classes.boldText}>
                    Nikmati Premium
                  </Typography>
                  <Typography className={classes.boldText}>
                    Rp {formatRupiah(99000)}{" "}
                    <span className={classes.discPrice}>Rp500.000</span>
                  </Typography>
                  <Typography variant="caption" className={classes.boldText}>
                    Dapat Modal Jualan Rp30,000
                  </Typography>
                </div>
            </Button>
          </ReactMidtrans>
        </div>
      </Dialog>
    </Fragment>
  );
};

export default UpgradePremiumDialog;

const useStyles = makeStyles({
  wrapper: {
    padding: 20,
    textAlign: "center",
  },
  buttonUpgrade: {
    color: "#ffffff",
    fontWeight: "bold",
    textTransform: "uppercase",
    borderRadius: 30,
    paddingLeft: 30,
    paddingRight: 30,
  },
  discPrice: {
    fontWeight: "initial",
    textDecoration: "line-through",
  },
  boldText: {
    fontWeight: "bold",
  },
  overviewTextWrapper: {
    textAlign: "center",
  },
  arrowBefore: {
    position: "absolute",
  },
  arrowNext: {
    position: "absolute",
    right: 0,
  },
  desc: {
    width: "80%",
    margin: "auto",
    height: 80,
  },
  icon: {
    height: 256,
    width: 256,
    margin: 'auto'
  },
});
