const styles = (theme) => ({
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconCart: {
    height: 50,
    width: "auto",
  },
  title: {
    fontWeight: 600,
    overflow: "hidden",
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 2,
    paddingTop: 10,
    height: 55,
  },
  containerItemCart: {
    display: "flex",
    paddingTop: 20,
    paddingBottom: 20,
    paddingRight: 18,
  },
  imageitemCart: {
    objectFit: "cover",
    borderRadius: 4,
    height: 100,
    width: 100,
  },
  actionSectionCart: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 10,
  },
  inputGroup: {
    display: "flex",
    borderRadius: 4,
    width: "fit-content",
    border: "1px solid #9999",
  },
  buttonInput: {
    background: "#9999",
    borderRadius: 2,
    color: "#fff",
    fontSize: 40,
    height: 30,
    minWidth: 30,
  },
  input: {
    height: 30,
    width: 50,
    border: "none",
    outline: "none",
    textAlign: "center",
  },
  containerActionSection: {
    display: "flex",
    justifyContent: "space-between",
    paddingRight: 18,
    position: "fixed",
    width: "100%",
    bottom: 0,
    paddingTop: 20,
    paddingBottom: 10,
    boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.08)",
    background: "#ffffff",
  },
});

export default styles;
