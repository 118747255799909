import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import { numberSeparator } from "../../../../utils/numberSeparator";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    justifyContent: "space-between",
  },
}));

const OpsiPengiriman = (props) => {
  const classes = useStyles();
  const { handleClose, open, data, handleChangeShippment, customer, myIndex } = props;
  const [openExpand, setOpenExpand] = useState("");

  const handleExpand = (code) => {
    if (openExpand === code) {
      setOpenExpand("");
    } else {
      setOpenExpand(code);
    }
  };

  return (
    <Fragment>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={false}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          style={{ width: window.screen.width / 2 }}
        >
          Pilih Opsi Pengiriman
        </DialogTitle>
        <DialogContent dividers>
          <List>
            {data &&
              Object.keys(data.list_ongkir).map((key) =>
                data.list_ongkir[key].map((item, index) => (
                  <Fragment>
                    <ListItem
                      key={index}
                      button
                      onClick={() => handleExpand(item.courier_code + index)}
                    >
                      <ListItemText
                        primary={`${item.logistic_name} ${item.rate_name}`}
                      />
                      {openExpand === item.courier_code + index ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </ListItem>

                    <Collapse
                      in={openExpand === item.courier_code + index}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        <ListItem
                          button
                          className={classes.nested}
                          onClick={() => {
                            handleChangeShippment(
                              item,
                              data.supplier,
                              customer,
                              myIndex
                            );
                            setOpenExpand("");
                          }}
                        >
                          <div style={{ display: "block" }}>
                            <Typography>
                              {item.rate_name} ({item.rate_code})
                            </Typography>

                            <Typography variant="caption">
                              Perkiraan Pengiriman {item.min_duration} {"-"}
                              {item.max_duration} hari
                            </Typography>
                          </div>
                          <Typography color="primary">
                            Rp {numberSeparator(item.price)}
                          </Typography>
                        </ListItem>
                      </List>
                    </Collapse>
                  </Fragment>
                ))
              )}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>NANTI SAJA</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default OpsiPengiriman;
