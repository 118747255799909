import React from "react";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import swal from "@sweetalert/with-react";

import {
  Container,
  Box,
  Typography,
  Paper,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Grid,
  Divider,
  Button,
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { getPayments, confirmPayment } from "../../services/order.service";
import { GET_VENDOR_BANKS } from "../../services/bank.service";

import { formatRupiah } from "../../utils/currencyFormat";

const VendorBankCheckoutSummary = () => {
  const history = useHistory()
  const { paymentId } = useParams();
  const [paymentData, setPaymentData] = React.useState({});
  const [vendorBanks, setVendorBanks] = React.useState([]);
  const [isOpenDialog, setIsOpenDialog] = React.useState(false);
  const [transferProof, setTransferProof] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    const fetch = async () => {
      const res = await getPayments({
        "filter[id]": paymentId,
      });
      if (res.data) {
        setPaymentData(res.data[0]);
      }
    };
    fetch();
  }, []);

  React.useEffect(() => {
    const fetch = async () => {
      const res = await GET_VENDOR_BANKS();
      if (res.data) {
        setVendorBanks(res.data);
      }
    };
    fetch();
  }, []);

  const handleChangeImage = async (event) => {
    const validImage = ["image/jpeg", "image/png"];
    const file = event.target.files[0];
    if (validImage.includes(file.type)) {
      setTransferProof(file);
    } else alert("Gambar yang di upload tidak valid");
  };

  const handleUpload = () => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("image", transferProof);

      confirmPayment(formData, paymentId);
      setTransferProof(null);

      swal("Success!", "Bukti transfer berhasil dikirim", "success").then(() =>
        setIsOpenDialog(false)
      );
    } catch (error) {
      swal("Error!", "Terjadi kesalahan silahkan coba lagi", "error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Container maxWidth="sm">
        <Box py={10}>
          {paymentData && (
            <Paper elevation={0}>
              <Box textAlign={"center"} px={2} py={4}>
                <Typography variant="subtitle1">
                  Harap melakukan pembayaran sebelum{" "}
                  <b>
                    {moment(paymentData.token_expire_at).format(
                      "DD MMM YYYY hh:mm"
                    )}
                  </b>
                  , jika melebihi waktu yang ditentukan, maka pembayaran
                  otomatis dibatalkan
                </Typography>
              </Box>
            </Paper>
          )}
          <Box
            mt={2}
            p={3}
            borderRadius="8px 8px 0 0"
            bgcolor="#3fbfc5"
            color="#fff"
          >
            <Typography component="span">
              <b>Metode Transfer</b>
            </Typography>{" "}
            <Typography variant="subtitle2" component="span">
              (Pengecekan 10-30 menit)
            </Typography>
          </Box>
          <Box>
            {vendorBanks.map((res) => (
              <ExpansionPanel>
                <ExpansionPanelSummary>
                  <Grid container spacing={2}>
                    <Grid item>
                      <img src={res.bank.icon} style={{ width: 48 }} />
                    </Grid>
                    <Grid item>{res.bank.name}</Grid>
                  </Grid>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ display: "block" }}>
                  <Box>
                    <Typography variant="caption">{res.bank.name}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="subtitle2">
                      {res.account_name}
                    </Typography>
                  </Box>
                  <Box
                    my={2}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    bgcolor={"#f6f6f6"}
                    py={2}
                    px={1}
                  >
                    <Typography>
                      <b>{res.account_number}</b>
                    </Typography>
                  </Box>
                  <Divider />
                  {paymentData && (
                    <>
                      <Box
                        my={2}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography variant="caption" component="div">
                          Subtotal
                        </Typography>
                        <Typography variant="caption" component="div">
                          <b>
                            Rp
                            {formatRupiah(
                              paymentData?.summary?.items_amount || 0
                            )}
                          </b>
                        </Typography>
                      </Box>
                      <Box
                        my={2}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography variant="caption" component="div">
                          Admin fee
                        </Typography>
                        <Typography variant="caption" component="div">
                          <b>
                            Rp
                            {formatRupiah(paymentData?.summary?.admin_fee || 0)}
                          </b>
                        </Typography>
                      </Box>
                      <Box
                        my={2}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography variant="caption" component="div">
                          Ongkir
                        </Typography>
                        <Typography variant="caption" component="div">
                          <b>
                            Rp
                            {formatRupiah(
                              paymentData?.summary?.shipping_cost || 0
                            )}
                          </b>
                        </Typography>
                      </Box>
                      <Box
                        my={2}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography variant="caption" component="div">
                          Kode unik
                        </Typography>
                        <Typography variant="caption" component="div">
                          <b>
                            Rp
                            {formatRupiah(
                              paymentData?.summary?.code_unique || 0
                            )}
                          </b>
                        </Typography>
                      </Box>
                      <Typography variant="caption" component="div">
                        <b>Total Nominal Transfer</b>
                      </Typography>
                      <Box
                        mb={1}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        bgcolor={"#f6f6f6"}
                        py={2}
                        px={1}
                      >
                        <Typography>
                          <b>
                            Rp{formatRupiah(paymentData?.summary?.amount || 0)}
                          </b>
                        </Typography>
                      </Box>
                      <Typography variant="caption" component="div">
                        <b>Catatan</b> Harap transfer sesuai nominal yang
                        tertera beserta kode unik, pemesanan tidak akan diproses
                        tanpa kode unik
                      </Typography>
                      <br />
                      <Divider />
                      <Typography variant="subtitle2">
                        <b>PAYMENT ID #{paymentData?.id}</b>
                      </Typography>
                    </>
                  )}
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ))}
          </Box>
          <Box mt={2}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              style={{ color: "#fff" }}
              onClick={() => setIsOpenDialog(true)}
            >
              Kirim bukti transfer
            </Button>
          </Box>
          <Box mt={2}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              style={{ color: "#fff" }}
              onClick={() => history.push('/')}
            >
              Kembali ke Home
            </Button>
          </Box>
        </Box>
      </Container>
      <Dialog open={isOpenDialog} fullWidth maxWidth="sm">
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => {
              setIsOpenDialog(false);
              setTransferProof(null);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box textAlign="center">
            <Typography variant="subtitle2">
              Bukti transfer dapat berupa informasi transfer dari m-Banking,
              e-Banking, struk atm, dan lainnya yang bersifat informatif
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" my={2}>
            <div
              style={{
                border: `2px dashed #29C0C7`,
                borderRadius: 5,
                height: 200,
                width: "100%",
                cursor: "pointer",
                position: "relative",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundImage: `url(${
                  transferProof !== null && URL.createObjectURL(transferProof)
                })`,
              }}
            >
              {transferProof === null && (
                <React.Fragment>
                  <Typography
                    style={{
                      height: "84%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: 100,
                      color: "#29C0C7",
                    }}
                  >
                    +
                  </Typography>
                </React.Fragment>
              )}
              <input
                type="file"
                style={{
                  opacity: 0.0,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  width: "100%",
                  height: "100%",
                  cursor: "pointer",
                }}
                onChange={(event) => handleChangeImage(event)}
              />
            </div>
          </Box>
          <Box>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              style={{ color: "#fff" }}
              onClick={handleUpload}
              disabled={transferProof === null || isLoading}
            >
              {isLoading ? "Loading..." : "Kirim"}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default VendorBankCheckoutSummary;
