const testimoni = [
  {
    name: "Gita Savitri",
    photo:
      "https://cdnx.kincir.com/old/0juli2017/gita-savitri-vlogger-inspirator-anak-muda/1-gt.jpg",
    title: "Content Creator/influencer",
    quote:
      "Menjadi reseller di IdeJualan mempunyai banyak keuntungan dan menjadikan saya sebagai wirausahawati yang mandiri dengan proses pendaftaran yang mudah dan murah. IdeJualan benar-benar membantu.",
  },
  {
    name: "Mark Zuckerberg",
    photo: "https://scx1.b-cdn.net/csz/news/800/2019/2-facebookceom.jpg",
    title: "Pemrogram",
    quote:
      "Mark Elliot Zuckerberg adalah seorang pemrogram komputer dan pengusaha Internet. Ia dikenal karena menciptakan situs jejaring sosial Facebook bersama temannya, yang dengan itu ia menjadi pejabat eksekutif dan presiden.",
  },
];

export default testimoni;
