import React, { Fragment, useState, useEffect } from "react";
import Navbar from "../../../../components/navbar";
import {
  Grid,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import {
  getStorefrontProductCategories,
  getStorefrontProducts,
  getStorefront,
  isLoggedIn,
} from "../../../../services/axios";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Skeleton from "@material-ui/lab/Skeleton";
import swal from "@sweetalert/with-react";
import whatsappIcon from "../../../../assets/icons/white_whatsapp.svg";
import { generateDate } from "../../../../utils/generateDate";
import Pagination from "@material-ui/lab/Pagination";
import Footer from "../../../../components/footer";
import { withRouter } from "react-router-dom";
import CardProduct from "../../../../components/CardProduct";
import {
  FacebookShareButton,
  TwitterShareButton,
  LineShareButton,
  PinterestShareButton,
} from "react-share";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import placeholder from "../../../../assets/images/placeholder.jpg";
import ToolbarMobile from "../../../../components/Mobile/ToolbarMobile";
import CardStoreFront from "../components/cardStoreFront/cardStoreFront";
import NavbarProductMobile from "../../../../components/Mobile/NavbarProduct";

const Mobile = withStyles(style)((props) => {
  const { classes, history } = props;
  const AsCustomer = false;
  const [listProduct, setListProduct] = useState(null);
  const [listCategories, setListCategories] = useState(null);
  const [currentCategories, setCurrentCategories] = React.useState(null);
  const [dataStorefront, setDataStorefront] = useState(null);
  const [cart] = useState(
    JSON.parse(localStorage.getItem("__cart"))
      ? JSON.parse(localStorage.getItem("__cart")).length
      : 0
  );
  const isPremiumMember = JSON.parse(localStorage.getItem("USER_DATA"))?.activated || false;
  const [isOpenDialogPremiumMember, setisOpenDialogPremiumMember] = useState(isPremiumMember ? false : true)

  useEffect(() => {
    const fetch = async () => {
      const response = await getStorefront(props.match.params.reseller);
      const getCategories = await getStorefrontProductCategories(
        response.data.slug
      );
      const getProducts = await getStorefrontProducts(response.data.slug);
      setListProduct(getProducts);
      setDataStorefront(response.data);
      setListCategories(getCategories);
    };
    fetch();
  }, []);

  const handleChangeCategories = async (event) => {
    const getAllProductWithCategory = await getStorefrontProducts(
      dataStorefront.slug,
      `?filter[category.id]=${event.target.value.id}`
    );
    const getAllProduct = await getStorefrontProducts(dataStorefront.slug);
    if (event.target.value === "Semua Produk") {
      setListProduct(getAllProduct);
      setCurrentCategories("");
    } else {
      setListProduct(getAllProductWithCategory);
      setCurrentCategories(event.target.value);
    }
  };

  const handleChangePage = async (page) => {
    const response = await getStorefrontProducts(
      dataStorefront.slug,
      `?filter[category.id]=${currentCategories.id}&page=${page}`
    );
    setListProduct(response);
  };

  const handleImgError = (event) => {
    return (event.target.src = placeholder);
  };

  const handleCloseDialogPremiumMember = () => {
    setisOpenDialogPremiumMember(false)
    history.push('/')
  }

  return (
    <Fragment>
      <div>
        <NavbarProductMobile />
        {/* store */}
        <div>
          {
            dataStorefront &&
            <div className={classes.containerStore}>
              <div>
                  <img src={dataStorefront.avatar_url} className={classes.avatarCover} alt='avatar store' />
              </div>
              <div className={classes.containerNameStore}>
                <div>{dataStorefront.name}</div>
                <div>{dataStorefront.description}</div>
              </div>
            </div>
          }
        </div>

        {/* product */}
        <div className={classes.containerProdut} style={{minHeight: '100vh'}}>
          {listProduct !== null &&
            <CardStoreFront listProduct={listProduct.data} />
          }
        </div>
      </div>

      {/* dialog when user not premium member */}
      {
        isLoggedIn() &&
        <Dialog
          open={isOpenDialogPremiumMember}
          onClose={handleCloseDialogPremiumMember}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Upgrade Akun Sekarang</DialogTitle>
          <DialogContent>
              Anda harus menjadi Super Seller untuk menikmati fitur ini
          </DialogContent>
          <DialogActions>
              <Button onClick={handleCloseDialogPremiumMember} color="primary">
                  Nanti
              </Button>
              <Button onClick={() => {history.push('/upgrade-reseller')}} color="primary">
                  Upgrade
              </Button>
          </DialogActions>
        </Dialog>
      }

      {/* old */}
      {/* <Grid item xs={12} style={{ padding: 16 }}>
        <div className={classes.card}>
          {dataStorefront === null ? (
            <Skeleton width={75} height={75} variant="circle" />
          ) : (
            <img
              src={dataStorefront.avatar_url || placeholder}
              alt={`profile of ${dataStorefront.name}`}
              className={classes.photoProfile}
              onError={handleImgError}
            />
          )}
          <div style={{ margin: "auto 0 auto 20px", alignItems: "center" }}>
            <div>
              {dataStorefront === null ? (
                <Skeleton variant="text" width={88} height={24} />
              ) : (
                <Typography>
                  <div className={classes.nameStore}>{dataStorefront.name}</div>
                </Typography>
              )}
              
              {dataStorefront === null ? (
                <Skeleton variant="text" width={89} height={24} />
              ) : (
                <Typography variant="caption">
                  {dataStorefront.owner.location.city.name}
                </Typography>
              )}
            </div>
            <div style={{ marginTop: 10, display: "flex" }}>
              {dataStorefront === null ? (
                <Skeleton variant="rect" width={143} height={36} />
              ) : (
                <Button
                  variant="contained"
                  size="small"
                  onClick={() =>
                    window.open(
                      `https://wa.me/`+dataStorefront.owner.phone,
                      "_blank"
                    )
                  }
                  color="primary"
                  endIcon={
                    <img
                      src={whatsappIcon}
                      height={20}
                      width={20}
                      alt={"whatsapp"}
                    />
                  }
                >
                  <Typography variant="body2" style={{ color: "#fff" }}>
                    Chat via WA
                  </Typography>
                </Button>
              )}
            </div>
          </div>
        </div>
      </Grid>

      <Grid container style={{ margin: "20px 0" }}>
        <Grid
          item
          xs={6}
          style={{ textAlign: "center", borderRight: "1px solid #999999" }}
        >
          <Typography style={{ fontWeight: "bold" }} color="primary">
            {dataStorefront !== null && dataStorefront.stats.total_product}
          </Typography>
          <Typography style={{ fontWeight: "bold" }}>Produk</Typography>
        </Grid>
        <Grid item xs={6} style={{ textAlign: "center" }}>
          <Typography style={{ fontWeight: "bold" }} color="primary">
            {dataStorefront !== null &&
              generateDate(dataStorefront.owner.joined_at)}
          </Typography>
          <Typography style={{ fontWeight: "bold" }}>Bergabung</Typography>
        </Grid>
      </Grid>

      <Grid item xs={12} style={{ padding: 16 }}>
        <div className={classes.card} style={{ display: "block" }}>
          {dataStorefront === null ? (
            <Fragment>
              <Skeleton variant="text" width={100} />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </Fragment>
          ) : (
            <Fragment>
              <Typography>
                <b>Deskripsi Toko</b>
              </Typography>
              <Typography>
                <div
                  dangerouslySetInnerHTML={{
                    __html: dataStorefront.description,
                  }}
                />
              </Typography>
            </Fragment>
          )}
        </div>
      </Grid>

      <Container maxWidth="lg">
        <div className={classes.shareSection}>
          <Grid container spacing={2} style={{ alignItems: "center" }}>
            <Grid item xs={12}>
              <Typography style={{ fontWeight: "bold" }}>Bagikan :</Typography>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.salinWrapper}>
                <Typography className={classes.linkCopy}>
                  {window.location.href}
                </Typography>
                <CopyToClipboard
                  text={window.location.href}
                  onCopy={() => {
                    swal("Link disalin !");
                  }}
                >
                  <Typography className={classes.salin}>Salin</Typography>
                </CopyToClipboard>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" style={{ color: "#555555" }}>
                Atau bagikan di
              </Typography>
            </Grid>
            <Grid item>
              <div>
                <Grid container spacing={2}>
                  <Grid item>
                    <FacebookShareButton
                      url={window.location.href}
                      quote={document.title}
                      className={classes.socialIcon}
                    >
                      <img
                        src={require("../../../../assets/icons/facebook.svg")}
                        alt=""
                      />
                    </FacebookShareButton>
                  </Grid>
                  <Grid item>
                    <TwitterShareButton
                      url={window.location.href}
                      quote={document.title}
                      className={classes.socialIcon}
                    >
                      <img
                        src={require("../../../../assets/icons/twitter.svg")}
                        alt=""
                      />
                    </TwitterShareButton>
                  </Grid>
                  <Grid item>
                    <LineShareButton
                      url={window.location.href}
                      quote={document.title}
                      className={classes.socialIcon}
                    >
                      <img
                        src={require("../../../../assets/icons/line.svg")}
                        alt=""
                      />
                    </LineShareButton>
                  </Grid>
                  <Grid item>
                    <PinterestShareButton
                      url={window.location.href}
                      quote={document.title}
                      className={classes.socialIcon}
                    >
                      <img
                        src={require("../../../../assets/icons/pinterest.svg")}
                        alt=""
                      />
                    </PinterestShareButton>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>

      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Kategori</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={currentCategories}
          onChange={handleChangeCategories}
          label="Kategori"
        >
          <MenuItem key={0} value="Semua Produk">
            Semua Produk
          </MenuItem>
          {listCategories !== null &&
            listCategories.data.map((res, index) => (
              <MenuItem key={index + 1} value={res}>
                {res.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <Grid container spacing={2} style={{ padding: 16 }}>
        {listProduct !== null &&
          listProduct.data.map((res, index) => (
            <Grid item xs={6} key={index}>
              <CardProduct data={res} variant="customer" />
            </Grid>
          ))}
      </Grid>

      <div className={classes.pagination}>
        {listProduct !== null && (
          <Pagination
            count={listProduct.meta.last_page}
            siblingCount={listProduct.meta.current_page}
            variant="outlined"
            shape="rounded"
            color="primary"
            size="small"
            onChange={(event, index) => handleChangePage(index)}
          />
        )}
      </div>

      {AsCustomer && cart !== 0 && (
        <div
          className={classes.customerCart}
          onClick={() => history.push("/cart")}
        >
          <Badge badgeContent={cart} color="secondary">
            <ShoppingCartIcon />
          </Badge>
        </div>
      )}

      <Footer /> */}
    </Fragment>
  );
});

export default withRouter(Mobile);
