import React, { Fragment, useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import Navbar from "../../../components/navbar";
import Footer from "../../../components/footer";
import {
  Container,
  Typography,
  Divider,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  CircularProgress,
  Button,
} from "@material-ui/core";
import { getAllCourier, checkResi } from "../../../services/axios";
import swal from "sweetalert";
import { getDateWithDayName } from "../../../utils/generateDate";
import { CopyToClipboard } from "react-copy-to-clipboard";
import TruckIcon from "../../../assets/icons/mdi_truck-fast.svg";

const Desktop = withStyles(styles)((props) => {
  const { classes } = props;
  const [listCourier, setListCourier] = useState(null);
  const [currentCourier, setCurrentCourier] = useState(null);
  const [resi, setResi] = useState("");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      const response = await getAllCourier();
      setListCourier(response.data);
    };
    fetch();
  }, []);

  const handleChangeCourier = (event) => {
    setCurrentCourier(event.target.value);
  };

  const handleCekResi = async () => {
    setLoading(true);
    if (resi.length !== 0 && currentCourier !== null) {
      const body = {
        waybill: resi,
        courier: currentCourier.code,
      };
      const response = await checkResi(body);
      await setData(response);
      setLoading(false);
    } else {
      swal("Masukan nomor resi dan pilih kurir!");
    }
  };

  return (
    <Fragment>
      <Navbar variant="primary" />
      <Container maxWidth="md">
        <Grid container spacing={2} className={classes.container}>
          <Grid item xs={6}>
            <div className={classes.card}>
              <Typography variant="h6">
                <b>Lacak status dan posisi paket</b>
              </Typography>

              <div className={classes.formCekResi}>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  style={{ width: "100%" }}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Pilih Kurir
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={currentCourier}
                    style={{ width: "100%", display: "flex" }}
                    label="Pilih Kurir"
                    onChange={handleChangeCourier}
                  >
                    {listCourier !== null &&
                      listCourier.map((res, index) => {
                        return (
                          <MenuItem value={res} key={index}>
                            <div style={{ width: "100%", display: "flex" }}>
                              <Typography style={{ width: "80%" }}>
                                {res.name}
                              </Typography>
                              <img src={res.icon_url} alt="" />
                            </div>
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </div>

              <div className={classes.formCekResi}>
                <TextField
                  fullWidth={true}
                  placeholder="No. Resi"
                  variant="outlined"
                  label="No. Resi"
                  onChange={(event) => setResi(event.target.value)}
                />
              </div>

              <div className={classes.formCekResi}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCekResi}
                  fullWidth={true}
                >
                  {loading ? (
                    <CircularProgress color="inherit" size={24} />
                  ) : (
                    "Cek Resi"
                  )}
                </Button>
              </div>
            </div>
          </Grid>

          <Grid item xs={6}>
            {data === null ? (
              <div className={classes.cekresiplaceholder}>
                <img src={TruckIcon} style={{ width: "50%" }} alt="" />
              </div>
            ) : (
              <Fragment>
                <div className={classes.card2}>
                  <div className={classes.resiNumber}>
                    <div>
                      <Typography>{data.summary.courier_name}</Typography>
                      <Typography>
                        No. Resi: {data.summary.waybill_number}
                      </Typography>
                    </div>
                    <div>
                      <CopyToClipboard
                        text={data.summary.waybill_number}
                        onCopy={() => {
                          swal("resi disalin!");
                        }}
                      >
                        <Typography
                          color="primary"
                          style={{ cursor: "pointer" }}
                        >
                          <b>Salin</b>
                        </Typography>
                      </CopyToClipboard>
                    </div>
                  </div>
                </div>
                <Divider />
                <div className={classes.card2}>
                  <div>
                    <Typography variant="caption">Pengirim</Typography>
                    <Typography>
                      <b>
                        {data.summary.shipper_name} - {data.summary.origin}
                      </b>
                    </Typography>
                  </div>
                  <div className={classes.formCekResi}>
                    <Typography variant="caption">Penerima</Typography>
                    <Typography>
                      <b>
                        {data.summary.receiver_name} -{" "}
                        {data.summary.destination}
                      </b>
                    </Typography>
                  </div>
                </div>
                <Divider />
                <div
                  className={classes.card2}
                  style={{ paddingRight: 0, paddingBottom: 0 }}
                >
                  {[...data.manifest].reverse().map((label, index) => (
                    <div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          style={{
                            height: 20,
                            width: 20,
                            background: index === 0 ? "#fbbb14" : "#555555",
                            borderRadius: "50%",
                          }}
                        />
                        <Typography
                          variant="caption"
                          style={{ paddingLeft: 10 }}
                        >
                          {getDateWithDayName(label.manifest_date)},{" "}
                          {label.manifest_time}
                        </Typography>
                      </div>
                      <Typography
                        style={{
                          borderLeft: "2px solid #555555",
                          marginLeft: 10,
                          paddingLeft: 20,
                        }}
                      >
                        {label.manifest_description}
                        {index !== data.manifest.length - 1 && <Divider />}
                      </Typography>
                    </div>
                  ))}
                </div>{" "}
              </Fragment>
            )}
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </Fragment>
  );
});

export default Desktop;
