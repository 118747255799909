import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import Navbar from "../../../components/navbar";
import Footer from "../../../components/footer";
import { Container, Typography, Button } from "@material-ui/core";

const Desktop = withStyles(styles)((props) => {
  const { classes } = props;
  return (
    <Fragment>
      <Navbar variant="primary" />
      <Container maxWidth="md" className={classes.container}>
        <div className={classes.card}>
          <Typography variant="h6" className={classes.titleCard}>
            <b>Hore! Pesananmu Berhasil Dikonfirmasi!</b>
          </Typography>

          <div className={classes.content}>
            <Typography>Hai Annisa Rahma,</Typography>
            <Typography>
              Terimakasih telah berbelanja di Idejualan! Pesananmu dengan nomor
              pesanan <b>0192831782883</b> sudah berhasil kami terima pada{" "}
              <b>Jumat, 29 Mei 2020 13:41:32</b> dengan metode pembayaran{" "}
              <b>Transfer Bank BCA</b>. Setelah ini penjual akan segera
              memproses pesananmu. Mohon ditunggu ya!
            </Typography>
          </div>

          <div className={classes.action}>
            <Button variant="contained" color="primary" style={{ width: 343 }}>
              Cek Resi Pesanan
            </Button>
          </div>
        </div>
      </Container>
      <Footer />
    </Fragment>
  );
});

export default Desktop;
