import React, { useEffect, useState } from "react";
import { GET_USER_BANK } from "../../services/bank.service";
import ModalWithdrawComponent from "./ModalWithdraw.component";
import { isEmpty } from "lodash";
import { POST_REQUEST_WITHDRAW, POST_VERIFY_WITHDRAW } from "../../services/withdraw.service";
import { Alert } from "@material-ui/lab";
import swal from "sweetalert";

const ModalWithdrawContainers = (props) => {
  const { isOpen, handleClose, walletInfo, setIsRefetch } = props
  const [bankInfo, setBankInfo] = useState({});
  const [valueSaldo, setValueSaldo] = useState('');
  const [alert, setAlert] = useState(<div></div>)

  const [isOpenOtp, setIsOpenOtp] = useState(false)
  const [stateOtp, setStateOtp] = useState({ otp : ''})
  const [countdown, setCountdown] = useState(30000);

  const handleChangeSaldo = (e) => {
    setValueSaldo(e.target.value)

    if(e.target.value < 100000) {
      setAlert(<Alert severity="error">Minimal masukan nominal Rp 100.000</Alert>)
    } else {
      setAlert(<div></div>)
    }
  };

  const handleSubmitWithdraw = async () => {
    if(valueSaldo !== "" || valueSaldo !== 0) {
      await POST_REQUEST_WITHDRAW(valueSaldo).then(response => {
        if(response.success) {
          setIsOpenOtp(true)
        }
      }).catch( (e) => {
        setAlert(<Alert severity="error">{e.response.data.message}</Alert>)
      })
    }
  };

  const handleCloseOtp = () => {
    setIsOpenOtp(false)
  }
  const onChangeOtp = otp => {
    setStateOtp({otp})
  }

  const handleSubmitOtp = async () => {
    if(stateOtp.top !== '') {
      await POST_VERIFY_WITHDRAW(stateOtp.otp).then(response => {
        setIsRefetch(true)
        swal("Success", "Berhasil withdraw", "success")
      }).catch( e => {
        swal("Error", e.response.data.message, "error")
      })
    }
  }

  const resendCode = async () => {
    setIsOpenOtp(false)
    setCountdown(countdown + 30000)
    if(valueSaldo !== "" || valueSaldo !== 0) {
      await POST_REQUEST_WITHDRAW(valueSaldo).then(response => {
        if(response.success) {
          setIsOpenOtp(true)
        }
      }).catch( (e) => {
        setAlert(<Alert severity="error">{e.response.data.message}</Alert>)
      })
    }
    setIsOpenOtp(true)
  }

  const onClickEditRekening = () => {
    props.history.push("/info/edit-rekening")
  }

  useEffect(() => {
    const fetch = async () => {
      const getBankUser = await GET_USER_BANK();
      if (!isEmpty(getBankUser.data)) {
        setBankInfo(getBankUser.data);
      }
    };
    fetch();
  }, []);

  return (
    <div>
      <ModalWithdrawComponent
        open={isOpen}
        handleClose={handleClose}
        walletInfo={walletInfo}
        bankInfo={bankInfo}
        handleChangeSaldo={handleChangeSaldo}
        handleSubmitWithdraw={handleSubmitWithdraw}
        alert={alert}
        isOpenOtp={isOpenOtp}
        handleCloseOtp={handleCloseOtp}
        stateOtp={stateOtp}
        onChangeOtp={onChangeOtp}
        handleSubmitOtp={handleSubmitOtp}
        onClickEditRekening={onClickEditRekening}
        valueSaldo={valueSaldo}
        resendCode={resendCode}
        countdown={countdown}
      />
    </div>
  );
};

export default ModalWithdrawContainers;
