import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { GET_SUPPORTED_BANK, GET_USER_BANK, POST_BANK } from '../../services/bank.service';
import swal from 'sweetalert';
import { WrapEditRekening } from './style';
import { TitlePage } from '../../components';
import Navbar from '../../components/navbar';

const EditRekeningPage = (props) => {
  const isMobile = useMediaQuery("(max-width:500px)");
  const [dataRekening, setDataRekening] = useState({})
  const [supportedBanks, setSupportedBanks] = useState([])
  const formItem = [
    {
      id: "alias",
      name: "Alias",
      type: "text"
    },
    {
      id: "account_number",
      name: "Nomer Rekening",
      type: "number"
    },
    {
      id: "bank",
      name: "Nama Bank",
      type: "select"
    }
  ]

  const onSubmit = async () => {
    const body = {
      bank: dataRekening.bank,
      alias: dataRekening.alias,
      account_number: dataRekening.account_number
    }

    await POST_BANK(body).then(response => {
      swal("Success", "Berhasil mengubah rekening", "success")
    })
    .catch(e => {
      swal("Error", e.response.data.message, "error")
    })
  }

  const onChangeInput = (id, value) => {
    setDataRekening({
      ...dataRekening,
      [id]: value
    })
  }
  
  const _mappingForm = () => {
    const form = []
    const optionSelect = []
    if(!isEmpty(supportedBanks)) {
      supportedBanks.map((supportedBank, indexBank) => {
        optionSelect.push(
          <option key={indexBank} value={supportedBank.code}>{supportedBank.name}</option>
        )
      return''
      })
    }
    
    formItem.map((item, key) => {
      if(item.type === 'select') {
        form.push(
          <Grid container item xs={12} key={key}>
          <Grid item xs={12} style={{marginBottom: 8}}>
            <p>{item.name}</p>
          </Grid>
          <Grid item xs={12} style={{marginBottom: 8}}>
          <select name="cars" id="cars" onChange={e => onChangeInput(item.id, e.target.value)} value={dataRekening[item.id]}>
            {optionSelect}
          </select>
          </Grid>
        </Grid>
        )
      }
      else {
        form.push(
          <Grid container item xs={12} key={key}>
            <Grid item xs={12} style={{marginBottom: 8}}>
              <p>{item.name}</p>
            </Grid>
            <Grid item xs={12} style={{marginBottom: 8}}>
              <input
              type={item.type}
              name={item.id}
              id={item.id}
              onChange={e => onChangeInput(item.id, e.target.value)}
              value={dataRekening[item.id]}
              />
            </Grid>
          </Grid>
        )
      }
      return''
    })

    return form
  }

  useEffect( () => {
    const fetch = async () => {
      const supported = await GET_SUPPORTED_BANK()
      setSupportedBanks(supported.data)

      const getBankUser = await GET_USER_BANK();
      if (!isEmpty(getBankUser.data)) {
        setDataRekening(getBankUser.data);
      }

      supported.data.map(bank => {
        if(getBankUser.data.bank === bank.name) {
          setDataRekening({
            ...getBankUser.data,
            bank: bank.code
          })
        }
        return''
      })
    }
   
    fetch()
  }, [])

  return (
    <div style={!isMobile ? {maxWidth: 960, padding: 24, position: 'absolute', margin: '0 auto', left: 0, right:0} : {}}>
    <Navbar variant={"primary"} />
    <WrapEditRekening>
      <Grid item xs={12} style={{ padding: 16, background: "#FFF" }}>
      <TitlePage onBack={() => props.history.goBack()}>Edit Rekening</TitlePage>
      </Grid>
      <Grid item xs={12} style={{ padding: 16, background: "#FFF" }}>
        <div>
          {_mappingForm()}
          <Grid item xs={12}><button onClick={onSubmit}>Simpan</button></Grid>
        </div>
      </Grid>
      
    </WrapEditRekening>
    </div>
  );
};

export default EditRekeningPage;