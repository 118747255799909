import React, { Fragment, useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import style from "./style";
import { getProvinces } from "../../../../../services/axios";

const Sidebar = withStyles(style)((props) => {
  const { classes, handleFilterLokasi, lokasi, handleFilter } = props;
  const [openLokasi, setOpenLokasi] = useState(true);
  const [openMoreLokasi, setOpenMoreLokasi] = useState(false);
  const [listProvince, setListProvince] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      const responseProvince = await getProvinces();
      setListProvince(responseProvince.data);
    };
    fetch();
  }, []);

  const handleClickOpenLokasi = () => {
    setOpenLokasi(!openLokasi);
  };

  const handleClickOpenMoreLokasi = () => {
    setOpenMoreLokasi(!openMoreLokasi);
  };
  return (
    <Fragment>
      <div className={classes.sidebar}>
        <div className={classes.kategori}>
          <List>
            <ListItem button onClick={handleClickOpenLokasi}>
              <ListItemText>
                <Typography style={{ fontWeight: "bold" }}>Lokasi</Typography>
              </ListItemText>
              {openLokasi ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openLokasi} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {listProvince !== null &&
                  listProvince.slice(0, 10).map((res) => {
                    const labelId = `checkbox-list-label-${res.id}`;

                    return (
                      <ListItem
                        key={res.id}
                        dense
                        button
                        onClick={() => handleFilterLokasi(res.id)}
                      >
                        <Checkbox
                          edge="start"
                          checked={lokasi[lokasi.indexOf(res.id)] === res.id}
                          tabIndex={-1}
                          color="primary"
                          disableRipple
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                        <ListItemText id={labelId} primary={res.name} />
                      </ListItem>
                    );
                  })}
                <Collapse in={openMoreLokasi} timeout="auto" unmountOnExit>
                  {listProvince !== null &&
                    listProvince.slice(10).map((res) => {
                      const labelId = `checkbox-list-label-${res.id}`;

                      return (
                        <ListItem
                          key={res.id}
                          role={undefined}
                          dense
                          button
                          onClick={() => handleFilterLokasi(res.id)}
                        >
                          <Checkbox
                            edge="start"
                            checked={lokasi[lokasi.indexOf(res.id)] === res.id}
                            tabIndex={-1}
                            color="primary"
                            disableRipple
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                          <ListItemText id={labelId} primary={res.name} />
                        </ListItem>
                      );
                    })}
                </Collapse>
                <ListItem button onClick={handleClickOpenMoreLokasi}>
                  <ListItemText>
                    <Typography
                      style={{ fontWeight: "bold", color: "#FBBB14" }}
                    >
                      {openMoreLokasi
                        ? "Tampilkan Lebih Sedikit..."
                        : "Lihat selengkapnya..."}
                    </Typography>
                  </ListItemText>
                </ListItem>
              </List>
            </Collapse>
          </List>

          <div className={classes.buttonWrapper}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              style={{ color: "#fff" }}
              onClick={handleFilter}
            >
              Terapkan Filter
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
});

export default Sidebar;
