const style = (theme) => ({
    button: {
        color: '#bbbaba', 
        fontSize: '.6rem',
    },
    buttonClicked: {
        fontSize: '.6rem',
        color: '#fbbb14'
    },
    tokokuClicked: {
        border: '1px solid #fbbb14'
    }
});

export default style;
