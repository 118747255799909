import React from "react";
import OtpInput from "react-otp-input";
import swal from "sweetalert";

import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Container } from "@material-ui/core";

import Navbar from "../../components/navbar";

import {
  VerifyPhoneNumber,
  ResendVerifyPhoneNumber,
} from "../../services/axios";
import { getUserDetails } from "../../services/Reseller/reseller.service";

const useStyles = makeStyles({
  container: {
    padding: "32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
  },
  activeResend: {
    color: "#00aaff",
    cursor: "pointer",
    fontWeight: 500,
  },
  inActiveResend: {
    color: "#aaa",
    cursor: "not-allowed",
    fontWeight: 500,
  },
});

const VerifPhone = () => {
  const classes = useStyles();

  const [otp, setOtp] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoadingResend, setIsLoadingResend] = React.useState(false);

  const [seconds, setSeconds] = React.useState(60);

  React.useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        clearInterval(myInterval);
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  const handleVerify = async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("otp", otp);

      await VerifyPhoneNumber(formData);

      const userId = localStorage.getItem("REGISTER_USER_ID");

      const response = await getUserDetails(userId);
      localStorage.setItem("USER_DATA", JSON.stringify(response.data));
      localStorage.setItem("STORE_FRONT", response.data.storefront.slug);

      swal("Success!", "Nomor HP berhasil di verifikasi", "success").then(() =>
        window.location.replace("/")
      );
    } catch (error) {
      if (error) {
        if (error.response) {
          if (error.response.data) {
            if (error.response.data.message) {
              swal("Error!", error.response.data.message, "error");
              return;
            }
          }
        }
      }
      swal("Error!", "Terjadi kesalahan silakan coba lagi", "error");
      return;
    } finally {
      setIsLoading(false);
    }
  };

  const handleResend = async () => {
    try {
      setIsLoadingResend(true);

      await ResendVerifyPhoneNumber({});

      swal("Success!", "OTP berhasil dikirim ulang", "success").then(() =>
        setSeconds(60)
      );
    } catch (error) {
      if (error) {
        if (error.response) {
          if (error.response.data) {
            if (error.response.data.message) {
              swal("Error!", error.response.data.message, "error");
              return;
            }
          }
        }
      }
      swal("Error!", "Terjadi kesalahan silakan coba lagi", "error");
      return;
    } finally {
      setIsLoadingResend(false);
    }
  };

  return (
    <>
      <Navbar variant="primary" />
      <Container className={classes.container} maxWidth="xs">
        <h1>Masukkan OTP yang sudah dikirim ke no HP anda</h1>
        <OtpInput
          value={otp}
          onChange={(otp) => setOtp(otp)}
          numInputs={6}
          separator={<span>-</span>}
          isInputNum
          containerStyle={{
            marginBottom: 32,
          }}
          inputStyle={{
            width: 32,
            height: 32,
            marginRight: 4,
            marginLeft: 4,
          }}
        />
        <Box mb={4}>
          {isLoadingResend ? (
            "Mengirim ulang OTP...."
          ) : (
            <>
              {" "}
              <span
                className={
                  seconds === 0 ? classes.activeResend : classes.inActiveResend
                }
                onClick={() => !seconds > 0 && handleResend()}
              >
                Kirim ulang OTP
              </span>{" "}
              <b>{seconds > 0 && `${seconds} detik`}</b>
            </>
          )}
        </Box>
        <Button
          variant="contained"
          color="primary"
          disabled={isLoading}
          fullWidth
          onClick={handleVerify}
        >
          {isLoading ? "Loading...." : "Verifikasi"}
        </Button>
      </Container>
    </>
  );
};

export default VerifPhone;
