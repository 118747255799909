import React from "react";
import Slider from "react-slick";
import { CardProductWishlist } from "../../components";

const ProductSliderComponent = (props) => {
  const {
    type,
    handleAddToStore,
    handleDeleteProductStorefront,
    dataWishlist,
  } = props;

  const _mappingItem = () => {
    let item = [];

    if (type === "wishlist") {
      dataWishlist.map((product) => {

        item.push(
          <CardProductWishlist
            key={product.product.slug}
            type="card"
            product={product.product}
            buttonIcon={product.product.is_added ? `delete` : `add`}
            onClick={() =>
              product.product.is_added
                ? handleDeleteProductStorefront(product.product.slug)
                : handleAddToStore(product.product.slug)
            }
            history={props.history}
          />
        );
        return "";
      });
    }

    return item;
  };

  return (
    <div>
      <Slider
        infinite={false}
        slidesToShow={2}
        centerMode={false}
        adaptiveHeight={true}
        swipeToSlide={true}
        variableWidth={true}
        // nextArrow={!isMobile && <NextArrow />}
        // prevArrow={!isMobile && <PrevArrow />}
      >
        {_mappingItem()}
      </Slider>
    </div>
  );
};

export default ProductSliderComponent;
