import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import {
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox,
  Container,
  Grid,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    width: window.screen.width,
  },
  navbar: {
    background: "#FFFFFF",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
    display: "flex",
    justifyContent: "center",
  },
  navTitle: {
    padding: "20px 0",
    fontWeight: "bold",
  },
  closeIcon: {
    display: "flex",
    position: "absolute",
    right: 10,
    top: 20,
  },
  buttonLocation: {
    borderRadius: 20,
    margin: "0 5px",
  },
  buttonLocationActive: {
    borderRadius: 20,
    margin: "0 5px",
    border: "none",
    background: "rgba(253, 205, 15, 0.15)",
  },
  headerText: {
    padding: 20,
    paddingBottom: 5,
  },
  actionFilter: {
    background: "#ffffff",
    boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.08)",
    position: "fixed",
    bottom: 0,
    paddingTop: 20,
    paddingBottom: 20,
  },
}));

const Filter = (props) => {
  const classes = useStyles();
  const {
    listProvince,
    lokasi,
    resetFilter,
    applyFilter,
    handleFilterLokasi,
  } = props;

  return (
    <Fragment>
      <div className={classes.container}>
        <div className={classes.navbar}>
          <Typography className={classes.navTitle}>Filter</Typography>
          <CloseIcon className={classes.closeIcon} onClick={props.onClose} />
        </div>

        <div>
          <Typography className={classes.headerText}>
            <b>Lokasi</b>
          </Typography>
          <List dense className={classes.root}>
            {listProvince.map((response, index) => {
              const labelId = `checkbox-list-secondary-label-${response.name}`;
              return (
                <Fragment key={index}>
                  <ListItem button>
                    <ListItemText id={labelId.id} primary={response.name} />
                    <ListItemSecondaryAction>
                      <Checkbox
                        edge="end"
                        onChange={() => handleFilterLokasi(response.id)}
                        color="primary"
                        checked={
                          lokasi[lokasi.indexOf(response.id)] === response.id
                        }
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                  <hr />
                </Fragment>
              );
            })}
          </List>
        </div>

        <Container maxWidth="lg" className={classes.actionFilter}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                variant="outlined"
                color="primary"
                fullWidth={true}
                onClick={resetFilter}
              >
                <b>Reset</b>
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                fullWidth={true}
                style={{ color: "#ffffff" }}
                onClick={applyFilter}
              >
                <b>Terapkan</b>
              </Button>
            </Grid>
          </Grid>
        </Container>
      </div>
    </Fragment>
  );
};

export default Filter;
