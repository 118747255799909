import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import Slider from "react-slick";

import { Typography, Button, Container } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import { ReactComponent as PremiumIcon } from "../../assets/icons/icon_premium.svg";
import { ReactComponent as PremiumIconVector1 } from "../../assets/icons/icon_premium/premium_1.svg";
import { ReactComponent as PremiumIconVector2 } from "../../assets/icons/icon_premium/premium_2.svg";
import { ReactComponent as PremiumIconVector3 } from "../../assets/icons/icon_premium/premium_3.svg";
import { ReactComponent as PremiumIconVector4 } from "../../assets/icons/icon_premium/premium_4.svg";
import { ReactComponent as PremiumIconVector5 } from "../../assets/icons/icon_premium/premium_5.svg";
import { ReactComponent as PremiumIconVector6 } from "../../assets/icons/icon_premium/premium_6.svg";

import Navbar from "../../components/navbar";

import { upgradeAccount } from "../../services/axios";

import ReactMidtrans from "../../config/midtrans";
import midtransClientKey from "../../config/midtransClientKey";

import { formatRupiah } from "../../utils/currencyFormat";
import PopupDeeplink from "../../components/Mobile/DeeplinkPopup";

const overview = [
  {
    title: "Bimbingan Jualan",
    desc: "Di Platform Kampusjualan.com hingga 50++ Materi Jualan",
    icon: <PremiumIconVector6 height="auto" width="100%" />,
  },
  {
    title: "Potongan Hingga 30%",
    desc: "Dengan menjadi Super Seller, Anda akan mendapatkan potongan harga sebesar 30%",
    icon: <PremiumIconVector1 height="auto" width="100%" />,
  },
  {
    title: "Unlimited Produk Tokoku",
    desc: "Anda bisa menambahkan produk ke tokoku tanpa batas limitasi!",
    icon: <PremiumIconVector2 height="auto" width="100%" />,
  },
  {
    title: "Bonus Penjualan",
    desc: "Berbagai bonus menanti Anda untuk membuat anda menjasi lebih nyaman dalam menjalankan bisnis anda",
    icon: <PremiumIconVector3 height="auto" width="100%" />,
  },
  {
    title: "Event Khusus Bulanan",
    desc: "Berbagai event menarik dengan hadiah mulai dari Smartphone hingga Emas Batangan!",
    icon: <PremiumIconVector4 height="auto" width="100%" />,
  },
  {
    title: "Member Get Member",
    desc: "Ajak temanmu untuk bergabung di Idejualan dan dapatkan bonus Rp100,000/member",
    icon: <PremiumIconVector5 height="auto" width="100%" />,
  },
];

const useStyles = makeStyles({
  wrapper: {
    padding: 20,
    textAlign: "center",
  },
  buttonUpgrade: {
    color: "#ffffff",
    fontWeight: "bold",
    textTransform: "uppercase",
    borderRadius: 30,
    paddingLeft: 30,
    paddingRight: 30,
  },
  discPrice: {
    fontWeight: "initial",
    textDecoration: "line-through",
  },
  boldText: {
    fontWeight: "bold",
  },
  overviewTextWrapper: {
    textAlign: "center",
  },
  arrowBefore: {
    position: "absolute",
  },
  arrowNext: {
    position: "absolute",
    right: 0,
  },
  desc: {
    width: "80%",
    margin: "auto",
    height: 80,
  },
  icon: {
    height: 256,
    width: 256,
    margin: "auto",
  },
});

const UpgradeReseller = () => {
  const history = useHistory();
  const classes = useStyles();
  const [tokenUpgrade, setTokenUpgrade] = useState(null);
  const [upgradePrice, setUpgradePrice] = useState(99000);

  const customeSlider = React.createRef();

  const next = () => {
    customeSlider.current.slickNext();
  };
  const previous = () => {
    customeSlider.current.slickPrev();
  };

  useEffect(() => {
    const fetch = async () => {
      const userData = JSON.parse(localStorage.getItem("USER_DATA"));
      try {
        if (userData && userData.id) {
          const response = await upgradeAccount(userData.id);

          if (response.token) {
            setTokenUpgrade(response.token);
          }
          if (
            response.transaction &&
            response.transaction.transaction_details
          ) {
            setUpgradePrice(
              response.transaction.transaction_details.gross_amount
            );
          }
        } else {
          swal("Error!", "Silahkan login terlebih dahulu", "error").then(() =>
            history.push("/login")
          );
        }
      } catch (error) {
        console.log(error);
        if (error) {
          if (error.response) {
            if (error.response.data) {
              if (error.response.data.message) {
                swal("Error!", error.response.data.message, "error").then(() =>
                  history.push("/")
                );
                return;
              }
            }
          }
        }
        if (!userData) {
          swal("Error!", "Silahkan login terlebih dahulu", "error").then(() =>
            history.push("/login")
          );
        }
        swal("Error!", "Terjadi kesalahan silakan coba lagi", "error").then(
          () => history.push("/")
        );
        return;
      }
    };

    fetch();
  }, []);

  return (
    <div>
      <PopupDeeplink url={window.location.pathname}/>
      <Navbar variant="primary" />
      <Container maxWidth="md">
        <div className={classes.wrapper}>
          <Slider
            infinite={true}
            speed={500}
            slidesToShow={1}
            slidesToScroll={1}
            arrows={false}
            ref={customeSlider}
          >
            {overview.map((res, index) => (
              <div key={index}>
                <div className={classes.icon}>{res.icon}</div>
                <div className={classes.overviewTextWrapper}>
                  <Typography variant="h6">
                    <b>{res.title}</b>
                  </Typography>
                  <Typography variant="body2" className={classes.desc}>
                    {res.desc}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: -80,
                    marginBottom: 20,
                    position: "relative",
                  }}
                >
                  <ArrowBackIcon
                    className={classes.arrowBefore}
                    onClick={() => previous()}
                  />
                  <ArrowForwardIcon
                    className={classes.arrowNext}
                    onClick={() => next()}
                  />
                </div>
              </div>
            ))}
          </Slider>

          <Typography
            variant="body2"
            style={{
              marginBottom: 10,
              marginTop: 10,
            }}
          >
            <b>Dapatkan Akses Premium Setahun</b>
          </Typography>
          {/* {upgradePrice > 0 && ( */}
            <ReactMidtrans clienttKey={midtransClientKey} token={tokenUpgrade}>
              <Button
                variant="contained"
                color="primary"
                className={classes.buttonUpgrade}
                startIcon={
                  <PremiumIcon style={{ height: 20, fill: "#ffffff" }} />
                }
              >
                <div>
                  <Typography className={classes.boldText}>
                    Nikmati Premium
                  </Typography>
                  <Typography className={classes.boldText}>
                    Rp {formatRupiah(upgradePrice)}{" "}
                    <span className={classes.discPrice}>Rp500.000</span>
                  </Typography>
                  <Typography variant="caption" className={classes.boldText}>
                    Dapat Modal Jualan Rp30,000
                  </Typography>
                </div>
              </Button>
            </ReactMidtrans>
          {/* )} */}
        </div>
      </Container>
    </div>
  );
};

export default UpgradeReseller;
