import React, { Fragment, useEffect, useState } from "react";
import {
  Container,
  CircularProgress,
  Breadcrumbs,
  Button,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useParams, useHistory } from "react-router-dom";
import swal from "@sweetalert/with-react";
import moment from "moment";

import Navbar from "../../components/navbar";
import Footer from "../../components/footer";

import iconIdejualan from "../../assets/icons/iconIdejualan.svg";

import { numberSeparator } from "../../utils/numberSeparator";

import { CLAIM_VOUCHER } from "../../services/voucher.service";
import { getVoucherDetail } from "../../services/axios";

import styles from "./styles";
import PopupDeeplink from "../../components/Mobile/DeeplinkPopup";

const VoucherPage = withStyles(styles)((props) => {
  const { classes } = props;
  const [voucher, setVoucher] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { voucherId } = useParams();
  const history = useHistory();

  const isLogin = localStorage.getItem("TOKEN");

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const data = await getVoucherDetail(voucherId);
        setVoucher(data.data);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    getData();
  }, []);

  const handleClaim = async (id) => {
    try {
      await CLAIM_VOUCHER(id);
      swal("Success!", "Voucher berhasil di klaim", "success");
    } catch (error) {
      if (error) {
        if (error.response) {
          if (error.response.data) {
            if (error.response.data.message === "Unauthenticated.") {
              return swal(
                "Error!",
                "Silahkan login untuk claim voucher",
                "error"
              );
            }
          }
        }
      }
      swal("Error!", "Voucher gagal di klaim", "error");
    }
  };

  const handleRenderVoucher = () => {
    if (isLoading) {
      return (
        <div className={classes.loadingContainer}>
          <CircularProgress size={60} />
        </div>
      );
    } else {
      if (voucher) {
        return (
          <div className={classes.containerVoucher}>
            <div className={classes.voucherHead}>
              <h2 style={{ color: "white", marginBottom: "60px" }}>
                Detail Kupon
              </h2>
            </div>

            <div className={classes.voucherSummary}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                {voucher.supplier ? (
                  <img
                    src={voucher.supplier.avatar_url}
                    alt={voucher.name}
                    style={{
                      width: "70px",
                      height: "70px",
                      borderRadius: "10px",
                    }}
                  />
                ) : (
                  <img
                    src={iconIdejualan}
                    alt={voucher.name}
                    style={{
                      width: "70px",
                      height: "70px",
                      borderRadius: "10px",
                    }}
                  />
                )}
                <div style={{ padding: "0 10px 0 10px" }}>
                  <p>{voucher.name}</p>
                  <p style={{ color: "#52C1C7" }}>
                    {voucher.supplier ? (
                      voucher.supplier.name
                    ) : (
                      <>Admin Ide Jualan</>
                    )}
                  </p>
                </div>
                <div style={{ color: "orange" }}>{voucher.type}</div>
              </div>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => handleClaim(voucher.id)}
                disabled={voucher.is_claimed}
                size="small"
                style={{
                  backgroundColor: !voucher.is_claimed && "#FBBB14",
                  color: "#000000",
                  textTransform: "none",
                }}
              >
                Klaim
              </Button>
            </div>
            <div className={classes.voucherDetail}>
              <h5>Masa Berlaku</h5>
              <p>
                {moment(voucher.start_date).format("MMM Do YY")} -{" "}
                {moment(voucher.end_date).format("MMM Do YY")}
              </p>

              <h5>Jumlah Potongan</h5>
              <p>Rp {numberSeparator(voucher.amount)}</p>

              <h5>Minimum Pembelian</h5>
              <p>Rp {numberSeparator(voucher.minimum_payments)}</p>

              <h5>Berlaku Di</h5>
              <p>
                {voucher.dompetjualan ? (
                  <>Dompet Jualan</>
                ) : (
                  <>Semua Metode Pembayaran</>
                )}
              </p>

              <h5>Kupon Berbayar</h5>
              <p>{voucher.is_purchasable ? <>Berbayar</> : <>Tidak</>}</p>
            </div>
          </div>
        );
      } else {
        return "Voucher tidak ditemukan";
      }
    }
  };

  return (
    <Fragment>
      <PopupDeeplink url={window.location.pathname}/>
      <Navbar variant="primary" />
      <Container maxWidth="xs" className={classes.container}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          style={{ fontSize: 12 }}
        >
          <p
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              history.push(`/`);
            }}
          >
            Home
          </p>

          <p
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              history.push(`/voucher`);
            }}
          >
            Voucher
          </p>

          <p>{voucher && voucher.name}</p>
        </Breadcrumbs>
        {handleRenderVoucher()}
      </Container>
      <Footer />
    </Fragment>
  );
});

export default VoucherPage;
