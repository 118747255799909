const style = (theme) => ({
  actionFilter: {
    paddingTop: 15,
    paddingBottom: 15,
    background: "#ffffff",
    boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.08)",
    position: "fixed",
    bottom: 0,
  },
  pagination: {
    paddingTop: 25,
    display: "flex",
    justifyContent: "center",
    marginBottom: 75,
  },
});

export default style;
