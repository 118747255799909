import { AppBar, Box, Tab, Tabs } from '@material-ui/core'
import React from 'react'
import { WrapTabsPanel } from './style';

const TabsPanelComponent = (props) => {
  const { value = 0, onChange = null, tabs } = props;

  const TabPanel = ({ children, value, index}) => {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
      >
        {value === index && (
          <Box p={3}>
            {children}
          </Box>
        )}
    </div>
    )
  }

  const tabProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  return (
    <WrapTabsPanel>
     <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={onChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
          style={{marginBottom: 24}}
        >
          {
            tabs.map(tab => (
              <Tab label={tab.label} {...tabProps(tab.key)} />
            ))
          }
        </Tabs>
      </AppBar>
      <div>
      {
        tabs.map(tab => (
          <TabPanel value={value} index={tab.key}>
            {tab.content}
          </TabPanel>
        ))
      }
      </div>
    </WrapTabsPanel>
  )
}

export default TabsPanelComponent