import React from 'react'
import { useHistory } from "react-router-dom";
import swal from '@sweetalert/with-react';
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import { isLoggedIn } from '../../../services/axios';

// icon
import { ReactComponent as Home } from '../../../assets/icons/mobile/menu_home.svg';
import { ReactComponent as Bisnis } from '../../../assets/icons/mobile/menu_education.svg';
import { ReactComponent as Notif } from '../../../assets/icons/mobile/icon_notification.svg';
import { ReactComponent as Profile } from '../../../assets/icons/mobile/menu_profile.svg';
import tokoku from '../../../assets/images/logo.svg'
import NeedLoginSwal from '../../needLoginSwal';

const ToolbarMobile = withStyles(style)((props) => {
  const history = useHistory()
  const { classes } = props;
  const [storeFrontName, setstoreFrontName] = React.useState()

  React.useEffect(() => {
    const getStoreFrontName = () => {
      const nameStoreFront = localStorage.getItem("STORE_FRONT")
      if (nameStoreFront !== null) {
        setstoreFrontName(nameStoreFront)
      } else {
        const nameStoreFrontFromUserData = JSON.parse(localStorage.getItem('USER_DATA'))
        setstoreFrontName(nameStoreFrontFromUserData?.storefront?.slug)
      }
    }

    if (isLoggedIn()) {
      getStoreFrontName()
    }
  }, [])
  

  const handleToProfile = () =>{
    if (localStorage.getItem("TOKEN") === null) {
      swal(
        <NeedLoginSwal />
        , {
          buttons: false
        })
    } else {
      history.push("/user/my-profile")
    }
  }

  const menuToolbar = [
    {
      name: 'Home',
      icon: Home ,
      path: '/',
      onClick: () => history.push("/"),
    },
    {
      name: 'Bisnis+',
      icon: Bisnis,
      path: '/bisnis',
      onClick: () => history.push("/bisnis"),
    },
    {
      name: 'Tokoku',
      icon: tokoku,
      path: '/store/new-storefront',
      onClick: () =>
        history.push(
          isLoggedIn()
            ? `/store/${storeFrontName}`
            : "/login"
        ),
    },
    {
      name: 'Notifikasi',
      icon: Notif,
      path: '/notification',
      onClick: () => history.push("/notification"),
    },
    {
      name: 'Saya',
      icon: Profile,
      path: '/profile',
      onClick: handleToProfile,
    },
  ]
  
  return (
    <div style={{ position: 'fixed', bottom: 0, backgroundColor: 'white', width: '100%', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', padding: 5}}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: 'auto' }}>
        {
          menuToolbar.map((res, index) => 
            <div key={index + 'menutoolbar'} onClick={res.onClick} style={{cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: 5}} >
              <div style={res.name === 'Tokoku' ? { position: 'absolute', bottom: 25, backgroundColor: 'white', borderRadius: '50%', display: 'flex', alignItems: 'center', padding: 10, boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' } : {}} className={res.name === 'Tokoku' ? window.location.pathname === res.path ? classes.tokokuClicked : "" : ''}>
                {
                  res.name === 'Tokoku' ?
                    < img src={res.icon} style={{ width: 30, height: 30 }} alt='tokoku' />
                  :
                    <res.icon style={{ width: 20, height: 20 }} fill={window.location.pathname === res.path ? " #FFC801" : "#CBCBCB"} />
                }
              </div>
              <div style={res.name === 'Tokoku' ? { marginTop: 18 } : { }} className={window.location.pathname === res.path ? classes.buttonClicked : classes.button}>{res.name}</div>
            </div>
          )
        }
      </div>
    </div>
  )
})

export default ToolbarMobile