import React from 'react'
import { withStyles } from "@material-ui/core/styles";
import { stylesContent } from "./style";
import { useHistory } from "react-router-dom";
import SearchCustom from '../../SearchCustom';
import { MyCart } from '../../../pages/home-page/mobile-v2/style';
import { ReactComponent as IconCart } from "../../../assets/icons/iconShopSolid.svg";

const SearchbarMobile = withStyles(stylesContent)((props) => {
    const { classes } = props;
    const [isScroll, setisScroll] = React.useState(false)
    let history = useHistory()
    const [chart, setchart] = React.useState(JSON.parse(localStorage.getItem('__cart')) === null ? 0 : JSON.parse(localStorage.getItem('__cart')).length)

    const changeBackground = () => {
        if (window.scrollY >= 80) {
            setisScroll(true)
        }else{
            setisScroll(false)
        }
    }

    window.addEventListener('scroll', changeBackground)

    // chart
    React.useEffect(() => {
        setchart(JSON.parse(localStorage.getItem('__cart')) === null ? 0 : JSON.parse(localStorage.getItem('__cart')).length)
    }, [localStorage.getItem("__cart")])


    const _myCart = () => {
        return (
            <MyCart onClick={() => history.push("/cart")}>
                <div className="count">{chart}</div>
                <IconCart />
            </MyCart>
        );
    };

    return (
        <div className={classes.containerSearch} style={isScroll ? { background: '#FBBB14'} : {background: 'none'}}>
            <div className={classes.innerContainer}>
                <div className="wrapSearch" style={{width: '360px'}}>
                    <div className="left">
                        <SearchCustom withLogo={true} placeholder="Mau jualan apa ?"  />
                    </div>
                    <div className="right">{_myCart()}</div>
                </div>
            </div>
        </div>
    )
})

export default SearchbarMobile