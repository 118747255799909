import React, { useState, useEffect } from "react";
import swal from "@sweetalert/with-react";
import { add } from "cart-localstorage";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  FormControl,
  Grid,
  useMediaQuery,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import CustomerForm from "./CustomerForm";

import { getCustomers } from "../services/customer.service";
import { Autocomplete } from "@material-ui/lab";

const AddToCart = (props) => {
  const isMobile = useMediaQuery("(max-width:500px)");
  const userData = JSON.parse(localStorage.getItem("USER_DATA"));
  const {
    handleClose,
    open,
    data,
    colorVariant,
    sizeVariant,
    dataSupplier,
    item,
    currentProperties,
    selectedSkus,
  } = props;

  const [selectedCustomer, setSelectedCustomer] = useState({
    name: "",
    address: "",
    province: "",
    city: "",
    district: "",
    subdistrict: "",
    postal_code: "",
    phone: "",
  });
  const [listCustomer, setListCustomer] = useState([]);

  useEffect(() => {
    if (userData !== null) {
      handleGetCustomers();
    }
  }, []);

  const handleGetCustomers = async () => {
    const res = await getCustomers();
    setListCustomer(res.data);
  };

  const handleAdd = async (res) => {
    await setSelectedCustomer(res);

    if (selectedCustomer !== "") {
      add(
        {
          id: `${data.slug}-${colorVariant}-${sizeVariant}-${res.name}`,
          img: selectedSkus.image_url,
          name: data.name,
          slug: data.slug,
          varian: colorVariant,
          size: sizeVariant,
          weight_gram: selectedSkus.weight_gram,
          price: selectedSkus.price.reseller,
          supplier: dataSupplier.name,
          data_supplier: dataSupplier,
          selected_courier: dataSupplier.supported_couriers[0],
          customer: res,
          properties: currentProperties,
          selectedSkus: selectedSkus,
          full_data_product: data,
        },
        item.qty
      );

      const listSupplier = JSON.parse(localStorage.getItem("LIST_SUPPLIER"));
      if (Boolean(listSupplier)) {
        if (
          listSupplier[listSupplier.indexOf(dataSupplier.name)] !==
          dataSupplier.name
        ) {
          localStorage.setItem(
            "LIST_SUPPLIER",
            JSON.stringify([...listSupplier, dataSupplier.name])
          );
        }
      } else {
        localStorage.setItem(
          "LIST_SUPPLIER",
          JSON.stringify([dataSupplier.name])
        );
      }

      handleClose();
      swal("Success!", "Berhasil dimasukan keranjang", "success");
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6">Tambah Ke Keranjang</Typography>
          <CloseIcon
            style={{ height: 32, width: "auto", cursor: "pointer" }}
            onClick={handleClose}
          />
        </div>
      </DialogTitle>
      <DialogContent>
        <Typography>Daftar Customer</Typography>

        <FormControl
          variant="outlined"
          style={{ width: "100%", marginTop: 20 }}
        >
          <Autocomplete
            options={listCustomer}
            getOptionLabel={(customer) => customer.name}
            onChange={(_, value) => value && handleAdd(value)}
            fullWidth
            renderInput={(params) => <TextField {...params} label="Pilih Customer" variant="outlined" />}
          />

          {/* <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={selectedCustomer.name}
            label="Pilih Customer"
          >
            {!Boolean(listCustomer) ? (
              <MenuItem>Tidak ada data</MenuItem>
            ) : (
              listCustomer
              .filter(customer => customer.name.toLowerCase().includes(filterCustomer))
              .map((res, index) => (
                <MenuItem
                  key={index+res.name+'cusName'}
                  value={res.name}
                  onClick={() => handleAdd(res)}
                >
                  {res.name}
                </MenuItem>
              ))
            )}
          </Select> */}
        </FormControl>

        <Grid
          container
          spacing={0}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 20,
          }}
        >
          <Grid item xs={isMobile ? 3 : 4}>
            <hr style={{ border: "1px solid #999999" }} />
          </Grid>
          <Grid
            item
            xs={isMobile ? 6 : 4}
            style={{
              display: "flex",
              justifyContent: "center",
              color: "#999999",
            }}
          >
            <Typography>Tambah Baru</Typography>
          </Grid>
          <Grid item xs={isMobile ? 3 : 4}>
            <hr style={{ border: "1px solid #999999" }} />
          </Grid>
        </Grid>

        <CustomerForm submitCallback={handleGetCustomers} />
      </DialogContent>
    </Dialog>
  );
};

export default AddToCart;
