import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Typography,
  Grid,
  Drawer,
  Button,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  checkShippingCostV2
} from "../../../../services/axios";

import {
  getProvinces,
  getCitiesByProvinceId,
  getDistrictsByCityId,
  getSubdistrictByDistrictId,
} from "../../../../services/location.service";

import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { numberSeparator } from "../../../../utils/numberSeparator";

import Loading from "../../../../components/loading";
import Navbar from "../../../../components/navbar";
import DividerMobile from "../../../../components/divider-mobile";

const useStyles = makeStyles((theme) => ({
  courier: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "12px",
  },
  divCity: {
    display: "flex",
    cursor: "pointer",
  },
  city: {
    fontWeight: "bold",
  },
  regulerCourier: {
    fontWeight: "bold",
  },
  dividerCourier: {
    margin: "10px 0",
  },
  flexBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  gratisOngkir: {
    color: theme.palette.secondary.main,
  },
  minimalBuy: {
    fontWeight: "bold",
  },
  desc: {
    width: "85%",
  },
  fullWidth: {
    width: window.screen.width,
  },
  closeIcon: {
    height: 40,
    width: "100%",
  },
  toCenter: {
    display: "flex",
    alignItems: "center",
  },
  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    paddingTop: 20,
  },
  cityItem: {
    padding: "10px 0",
  },
  forwardIcon: {
    height: "100%",
    width: "100%",
  },
  wrapper: {
    width: window.screen.width,
  },
  CekOngkirButton: {
    color: "white",
    fontWeight: "bold",
  },
  price: {
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
}));

const CekOngkir = (props) => {
  const classes = useStyles();
  const AsCustomer = false;
  const {dataSupplier, supplier, weight_gram} = props;
  const [openDrawer, setOpenDrawer] = useState(false);

  const [currentCourier, setCurrentCourier] = useState(
    AsCustomer ? supplier.supported_couriers[0] : dataSupplier.supported_couriers[0]
  );

  const [listOngkir, setListOngkir] = useState(null);
  const [loading, setLoading] = useState(true);

  const [currentProvince, setCurrentProvince] = useState(null);
  const [currentCity, setCurrentCity] = useState(null);
  const [currentDistrict, setCurrentDistrict] = useState(null);
  const [currentSubdistrict, setCurrentSubdistrict] = useState(null);
  const [postalCode, setPostalCode] = useState("");

  const [listCity, setListCity] = useState(null);
  const [listDistrict, setListDistrict] = useState(null);
  const [listSubdistrict, setListSubdistrict] = useState(null);
  const [listProvince, setListProvince] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      setCurrentCity(dataSupplier.location.village.district.city);
      setCurrentDistrict(dataSupplier.location.village.district);
      setCurrentSubdistrict(dataSupplier.location.village);
      setLoading(false);
    };
    fetch();
  }, []);

  const handleChangeProvince = async (event) => {
    const res = await getCitiesByProvinceId(event.id);
    setListCity(res.data.sort((a, b) => a.name.localeCompare(b.name)));
    setCurrentProvince(event);
    setCurrentDistrict(null);
    setCurrentSubdistrict(null);
  };

  const handleChangeCity = async (event) => {
    const res = await getDistrictsByCityId(event.id);
    setListDistrict(res.data.sort((a, b) => a.name.localeCompare(b.name)));
    setCurrentCity(event);
  };

  const handleChangeDistrict = async (event) => {
    const res = await getSubdistrictByDistrictId(event.id);
    setListSubdistrict(res.data.sort((a, b) => a.name.localeCompare(b.name)));
    setCurrentDistrict(event);
  };

  const handleChangeSubdistrict = (event) => {
    setCurrentSubdistrict(event);
    setPostalCode(event.kodepos);
  };

  useEffect(() => {
    const handleCheckShippingCost = async (
      supplierId,
      destinationPostalCode,
      destinationSubDistrictName,
      couriers,
      weight
    ) => {
      const formData = new FormData();
      formData.append("supplier_id", supplierId);
      formData.append("destination_postal_code", destinationPostalCode);
      formData.append(
        "destination_sub_district_name",
        destinationSubDistrictName
      );
      formData.append("shipment_type", "DROP");
      formData.append("package_type_id", "1");
      formData.append("weight", Math.ceil(weight / 1000));
      formData.append("is_cod", "0");
      for (let i = 0; i < couriers.length; i++) {
        formData.append("couriers[]", couriers[i]);
      }
      const response = await checkShippingCostV2(formData);
      return response;
    };

    const getListHarga = async () => {
      setListOngkir(null);
      const resListOngkir = await handleCheckShippingCost(
        dataSupplier.id,
        postalCode ? postalCode : dataSupplier.location.village.kodepos,
        postalCode ? currentSubdistrict : dataSupplier.location.village.name,
        dataSupplier.supported_couriers,
        weight_gram
      );
      for (const key in resListOngkir) {
        if (Object.hasOwnProperty.call(resListOngkir, key)) {
          if (resListOngkir[key] && resListOngkir[key].length > 0) {
            setListOngkir(resListOngkir[key])
            break;
          }
        }
      }
    };
    currentSubdistrict !== null && getListHarga();
  }, [currentSubdistrict]);

  useEffect(() => {
    const fetch = async () => {
      const res = await getProvinces();
      setListProvince(res.data.sort((a, b) => a.name.localeCompare(b.name)));
    };
    fetch();
  }, []);

  return (
    <React.Fragment>
      <Navbar text="Cek Kurir dan Ongkos Kirim" onClick={props.onClose} />
      {loading ? (
        <div className={classes.wrapper}>
          <Loading />
        </div>
      ) : (
        <div className={classes.wrapper}>
          <Container maxWidth="lg">
            <Typography variant="subtitle2">Dikirim ke</Typography>
            <div
              className={classes.divCity}
              onClick={() => setOpenDrawer(true)}
            >
              <Typography className={classes.city}>
              {currentCity !== null && currentCity.name}, {currentDistrict !== null && currentDistrict.name}
              </Typography>
              <ExpandMoreIcon />
            </div>
          </Container>

          <DividerMobile />

          <Container maxWidth="lg">
            <div>

              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {!listOngkir ? (
                    <div className={classes.loading}>
                      <CircularProgress />
                    </div>
                  ) : (
                    listOngkir &&
                      listOngkir.map((res, index) => {
                      return (
                        <Fragment key={index}>
                          <Grid item xs={12}>
                            <div className={classes.courier}>
                              <Typography>
                                {res.logistic_name} ({res.rate_name})
                              </Typography>
                              <Typography  className={classes.price}>Rp {numberSeparator(res.price)}</Typography>
                            </div>
                            <div>
                              <Typography variant="caption">
                                Lama Pengiriman{" "}
                                {res.max_duration == 0
                                  ? '-'
                                  : res.min_duration + " - " + res.max_duration + " hari"}
                              </Typography>
                            </div>
                          </Grid>
                        </Fragment>
                      );
                    })
                  )}
                </Grid>
              </Grid>
            </div>
          </Container>

          <Drawer
            anchor="left"
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
          >
            <div className={classes.fullWidth}>
              <Navbar
                text="Cek Kurir dan Ongkos Kirim"
                onClick={() => setOpenDrawer(false)}
              />
           
            <FormControl variant="outlined" style={{ width: "100%", marginTop: 20 }}>
            <InputLabel id="demo-simple-select-outlined-label">Provinsi</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={
                currentProvince !== null && currentProvince.name
              }
              label="Provinsi"
            >
              {listProvince !== null &&
                listProvince.map((res) => {
                  return (
                    <MenuItem
                      key={res.id}
                      value={res.name}
                      onClick={() => handleChangeProvince(res)}
                    >
                      {res.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          <FormControl variant="outlined" style={{ width: "100%", marginTop: 20 }}>
            <InputLabel id="demo-simple-select-outlined-label">Kota / kabupaten</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={currentCity !== null && currentCity.name}
              label="Kota / Kabupaten"
            >
              {listCity !== null &&
                listCity.map((res) => {
                  return (
                    <MenuItem
                      key={res.id}
                      value={res.name}
                      onClick={() => handleChangeCity(res)}
                    >
                      {res.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          <FormControl variant="outlined" style={{ width: "100%", marginTop: 20 }}>
            <InputLabel id="demo-simple-select-outlined-label">
              Kecamatan
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={
                currentDistrict !== null && currentDistrict.name
              }
              label="Kecamatan"
            >
              {listDistrict !== null &&
              listDistrict.map((res) => {
                return (
                  <MenuItem
                    key={res.id}
                    value={res.name}
                    onClick={() => handleChangeDistrict(res)}
                  >
                    {res.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl variant="outlined" style={{ width: "100%", marginTop: 20 }}>
            <InputLabel id="demo-simple-select-outlined-label">
              Desa/Kelurahan
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={
                currentSubdistrict !== null && currentSubdistrict.name
              }
              label="Desa/Kelurahan"
            >
              {listSubdistrict !== null &&
              listSubdistrict.map((res) => {
                return (
                  <MenuItem
                    key={res.id}
                    value={res.name}
                    onClick={() => handleChangeSubdistrict(res)}
                  >
                    {res.name}
                  </MenuItem>
                );
              })}
            </Select>
            </FormControl>
            </div>
            <Button color="primary"
              variant="contained"
              style={{ marginTop: 20 }}
              className={classes.CekOngkirButton}
              onClick={() => setOpenDrawer(false)}
            >
            Cek Ongkir
          </Button>
          </Drawer>
        </div>
      )}
    </React.Fragment>
  );
};

export default CekOngkir;
