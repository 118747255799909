import React, { Fragment } from "react";
import { Typography } from "@material-ui/core";
import { newLineRevert } from "../../../../utils/newLine";
import swal from "@sweetalert/with-react";
import { CopyToClipboard } from "react-copy-to-clipboard";

const ProductDescription = (props) => {
  const { data } = props;
  const [isReadMore, setIsReadMore] = React.useState(false);

  return (
    <Fragment>
      <div style={{ margin: "20px 0" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 20,
          }}
        >
          <Typography style={{ fontWeight: 600 }}>Deskripsi Produk</Typography>
          <CopyToClipboard
            text={newLineRevert(data.description).replace(/<[^>]*>?/gm, "")}
            onCopy={() => {
              swal("Deskripsi disalin.");
            }}
          >
            <Typography
              color="primary"
              style={{ fontWeight: 600, cursor: "pointer" }}
            >
              Salin Deskripsi
            </Typography>
          </CopyToClipboard>
        </div>

        <div
          style={{
            maxHeight: isReadMore ? "100%" : 220,
            overflow: "hidden",
          }}
        >
          <Typography>
            {data.description.split('\n').map((text) => (<p>{text}</p>))}
          </Typography>
        </div>
        <Typography
          style={{ cursor: "pointer" }}
          color="primary"
          onClick={() => setIsReadMore(!isReadMore)}
        >
          {isReadMore ? "Lihat Lebih Sedikit" : "Lihat Selegkapnya"}
        </Typography>
      </div>
    </Fragment>
  );
};

export default ProductDescription;
