// Try edit message
export const generateDate = (join) => {
  console.log("join => ", join);
  const getDate = join.slice(0, 10);
  const removeStrip = getDate.split("-").reverse();
  const moonToWordType = removeStrip[1];
  const changetoWord = () => {
    switch (moonToWordType) {
      case "01":
        return "Januari";
        break;
      case "02":
        return "Februari";
        break;
      case "03":
        return "Maret";
        break;
      case "04":
        return "April";
        break;
      case "05":
        return "Mei";
        break;
      case "06":
        return "Juni";
        break;
      case "07":
        return "Juli";
        break;
      case "08":
        return "Agustus";
        break;
      case "09":
        return "September";
        break;
      case "10":
        return "Oktober";
        break;
      case "11":
        return "November";
        break;
      case "12":
        return "Desember";
        break;
      default:
        return "Bulan";
        break;
    }
  };
  const date = removeStrip[0];
  const moon = changetoWord();
  const year = removeStrip[2];
  const result = `${date} ${moon} ${year}`;

  return result;
};

export const getDateWithDayName = (date) => {
  const days = [
    "Minggu",
    "Senin",
    "Selasa",
    "Rabu",
    "Kamis",
    "Jum'at",
    "Sabtu",
  ];
  const month = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];
  const d = new Date(date);
  const currentDate = d.getDate();
  const dayName = days[d.getDay()];
  const monthName = month[d.getMonth()];
  const year = d.getFullYear();

  return `${dayName}, ${currentDate} ${monthName} ${year}`;
};
