const stylesContent = (theme) => ({
  title: {
    textAlign: (props) => (props.desktop ? "center" : "left"),
    fontWeight: (props) => (props.desktop ? 800 : 500),
    fontSize: 22,
    paddingTop: 20,
  },
  guide: {
    color: "#999999",
    paddingBottom: 15,
  },
  styledButton: {
    margin: "30px 0",
    textTransform: "none",
  },
  link: {
    color: "#F63B68",
    cursor: "pointer",
  },
  styledInput: {
    border: "1px solid #555555",
    borderRadius: 4,
    paddingLeft: 10,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
});

const stylesDesktop = (theme) => ({
  container: {
    width: 450,
    margin: "auto",
    padding: "50px 0",
  },
  wrapper: {
    padding: "20px 35px",
    border: "1px solid #FBBB14",
    boxSizing: "border-box",
    borderRadius: 16,
  },
});

const stylesMobile = (theme) => ({});

export { stylesContent, stylesDesktop, stylesMobile };
