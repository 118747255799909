import React from 'react'
import { WrapLabel } from './style';

const LabelComponent = (props) => {
  const { children, countNotif = 0 } = props;
  return (
    <WrapLabel>
      <div className="text">{children}</div>
      {
        countNotif > 0 && <div className="count">{countNotif}</div>
      }
    </WrapLabel>
  )
}

export default LabelComponent