import { useMediaQuery } from '@material-ui/core'
import React,{useState, useEffect} from 'react'
import { getPaymentsCustomer } from '../../services/order.service'
import Desktop from './desktop'
import Mobile from './mobile'

const ThanksPageCustomer = ({match}) => {
    const [dataOrder, setdataOrder] = useState()

    useEffect(() => {
        const getAllDataOrder = async() => {
            const response = await getPaymentsCustomer(match.params.reseller, match.params.id)
            setdataOrder(response.data)
        }

        getAllDataOrder()
    }, [])

    const isMobile = useMediaQuery("(max-width:500px)");
    return <React.Fragment>{isMobile ? <Mobile dataOrder={dataOrder} store={match.params.reseller} /> : <Desktop dataOrder={dataOrder} store={match.params.reseller} />}</React.Fragment>;
}

export default ThanksPageCustomer