import React, { useState } from "react";
import {
  Container,
  Typography,
  Grid,
  Divider,
  ButtonGroup,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { numberSeparator } from "../../../../utils/numberSeparator";
import swal from "@sweetalert/with-react";
import AddToCart from "../../../../components/AddToCart";
import { add } from "cart-localstorage";
import { isLoggedIn } from "../../../../services/axios";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  variantFilled: {
    border: `1px solid ${theme.palette.primary.main}`,
    color: `${theme.palette.primary.main} !important`,
    background: "rgba(253, 205, 15, 0.15)",
    boxSizing: "border-box",
    borderRadius: 5,
    cursor: "pointer",
    padding: "0 8px",
    display: "flex",
    alignItems: "center",
    marginTop: 10,
    marginLeft: 5,
  },
  variantProductText1: {
    margin: 0,
    opacity: 0.65,
    padding: "5px 5px",
    fontSize: 14,
  },
  variantProduct1: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #555555",
    boxSizing: "border-box",
    borderRadius: 5,
    padding: "0 8px",
    cursor: "pointer",
    marginLeft: 5,
    marginTop: 10,
  },
  variantNotAvailable: {
    backgroundColor: "#aba9a9",
    border: "1px solid #aba9a9",
    color: "#555555",
    boxSizing: "border-box",
    borderRadius: 5,
    padding: "0 8px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    marginTop: 10,
    marginLeft: 5,
  },
  img: {
    height: 100,
    width: 100,
    borderRadius: 8,
  },
  flexEnd: {
    display: "flex",
    alignItems: "flex-end",
  },
  range1: {
    marginTop: 20,
    marginBottom: 10,
  },
  range2: {
    marginTop: 10,
    marginBottom: 20,
  },
  range: {
    marginTop: 20,
    marginBottom: 20,
  },
  price: {
    fontWeight: "bold",
    lineHeight: 1,
  },
  buttonMin: {
    background: "#29C0C7",
    marginRight: 10,
    borderRadius: 4,
    color: "#ffffff",
    fontSize: 50,
    lineHeight: 0,
    cursor: "pointer",
    outline: "none",
    border: "none",
    transition: "0.5s",
    "&:hover": {
      background: "#555555",
      transition: "0.5s",
      opacity: 0.35,
    },
  },
  buttonPlus: {
    background: "#29C0C7",
    marginLeft: "10px !important",
    borderRadius: 4,
    border: "none",
    color: "#ffffff",
    fontSize: 50,
    lineHeight: 0,
    cursor: "pointer",
    outline: "none",
    transition: "0.5s",
    "&:hover": {
      background: "#555555",
      transition: "0.5s",
      opacity: 0.35,
    },
  },
  quantityItem: {
    border: "none",
    cursor: "default",
    borderBottom: "1px solid #adabab",
    width: 60,
    height: "100%",
    fontSize: 20,
    lineHeight: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    outline: "none",
    textAlign: "center",
    fontWeight: 800,
  },
  labelPrice: {
    color: "#555555",
    opacity: 0.65,
  },
  totalPrice: {
    color: "#555555",
    fontSize: 18,
    fontWeight: 600,
  },
  buttonSecondary: {
    padding: "5px 20px",
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    fontSize: "10px",
    height: 44,
    textTransform: "none",
  },
  buttonPrimary: {
    border: `1px solid ${theme.palette.primary.main}`,
    padding: "8px 20px",
    background: theme.palette.primary.main,
    marginLeft: (props) => props.role === "reseller" && 10,
    fontSize: "10px",
    height: 44,
    textTransform: "none",
  },
  actionSession: {
    background: "#ffffff",
    padding: "15px 0",
    boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.08)",
    width: "100%",
  },
  close: {
    height: "100%",
    width: 30,
    marginBottom: 70,
  },
  customerCart: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 15,
    backgroundColor: theme.palette.primary.main,
    position: "fixed",
    height: 60,
    width: 60,
    bottom: 0,
    right: 0,
    marginBottom: 30,
    marginRight: 30,
    cursor: "pointer",
  },
}));

const Variant = (props) => {
  const classes = useStyles();
  const { data, dataSupplier, onClose, setUpdate, update, isStoreFront } = props;
  const [currentImage, setCurrentImage] = useState(data.skus[0].image_url);
  const [currentProperties, setCurrentProperties] = useState(data.skus[0].properties);
  const [currentVariant] = useState(null);
  const [openAddToCart, setOpenAddToCart] = useState(false);
  const [item, setItem] = useState({
    qty: 1,
    price: isStoreFront ? data.consumer_price_idr : data.skus[0].price.reseller,
  });
  const [selectedSkus, setSelectedSkus] = useState(data.skus[0]);
  const urlPathName = window.location.pathname.split('/')
  const storefrontName = urlPathName[2]
  const dataLocalStorage = JSON.parse(localStorage.getItem('USER_DATA'))
  const storefrontNameFromLocal = dataLocalStorage?.storefront?.slug
  const history = useHistory()

  const handleClick = (operator) => {
    if (selectedSkus.price.reseller === "") {
      swal("Pilih varian terlebih dahulu");
    } else {
      const price = isStoreFront ? Number(selectedSkus.price.consumer) : Number(selectedSkus.price.reseller);
      if (operator === "+") {
        setItem({ qty: parseInt(item.qty) + 1, price: item.price + price });
      } else {
        item.qty !== 0 &&
          setItem({ qty: parseInt(item.qty) - 1, price: item.price - price });
      }
    }
  };

  const handleAddToCart = () => {
    if (!isLoggedIn()) return window.location = '/login';
    if (!currentProperties) {
      return swal("Oops!", "Pilih varian barang", "info");
     }
    if (
      (selectedSkus.properties.size === currentProperties.size) !==
      (selectedSkus.properties.color === currentProperties.color)
    ) {
      swal("Pilih varian yang tersedia");
    } else if (item.qty === 0) {
      swal("Masukan Jumlah barang");
    } else {
      setOpenAddToCart(true);
    }
  };

  const handleTambahKeranjangCustomer = () => {
    if (
      selectedSkus.properties.color === "" ||
      selectedSkus.properties.size === ""
    ) {
      swal("Oops!", "Pilih varian barang", "info");
    } else if (item.qty === 0) {
      swal("Oops!", "Masukan jumlah barang", "info");
    } else {
      add(
        {
          id: `${data.slug}-${selectedSkus.properties.color}-${selectedSkus.properties.size}`,
          img: selectedSkus.image_url,
          name: data.name,
          slug: data.slug,
          varian: selectedSkus.properties.color,
          size: selectedSkus.properties.size,
          weight_gram: selectedSkus.weight_gram,
          price: selectedSkus.price.consumer,
          supplier: dataSupplier.name,
          data_supplier: dataSupplier,
          selected_courier: dataSupplier.supported_couriers[0],
          properties: currentProperties,
          selectedSkus: selectedSkus,
          full_data_product: data,
        },
        item.qty
      );
      // sessionStorage.setItem("phone_reseller", dataSupplier.owner.phone);
      swal("Success", "Berhasil dimasukan keranjang!", "success").then(() => {
        onClose();
        setUpdate(!update);
      });
    }
  };

  return (
    <React.Fragment>
      <Container maxWidth="lg" className={classes.range1}>
        <Grid container spacing={1} style={{display: 'flex', alignItems: 'start'}}>
          <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', alignItems: 'end', width: '90%'}}>
            <Grid item xs={4}>
              <img className={classes.img} src={currentImage} alt="" />
            </Grid>
            <Grid item xs={7} style={{minWidth: 200}}>
              {props.role === "reseller" && (
                <Typography>Harga Reseller</Typography>
              )}
              <Typography
                variant={props.role === "reseller" ? "body2" : "body1"}
                color="primary"
                className={classes.price}
              >
                Rp. {isStoreFront ? numberSeparator(Number(selectedSkus.price.consumer)) : numberSeparator(Number(selectedSkus.price.reseller))}
              </Typography>
              <Typography variant="caption">Stok {selectedSkus.stock}</Typography>
            </Grid>
          </div>
          <Grid item xs={1}>
            <HighlightOffIcon className={classes.close} onClick={onClose} />
          </Grid>
        </Grid>
      </Container>

      <Divider />

      <Container maxWidth="lg" className={classes.range}>
        <Typography>Pilih Varian</Typography>
        <Grid container>
          {data.skus.map((res, index) => {
            return (
              <Grid item key={index+'variant'}>
                <div
                  className={
                    selectedSkus.code === res.code
                      ? classes.variantFilled
                      : classes.variantProduct1
                  }
                  onClick={() => {
                    setSelectedSkus(res);
                    setCurrentImage(res.image_url);
                    setCurrentProperties(res.properties);
                    setItem({
                      qty: 1,
                      price: isStoreFront? res.price.consumer : res.price.reseller,
                    });
                  }}
                >
                  <Typography className={classes.variantProductText1}>
                    {res.properties.color + ` ( ${res.properties.size} ) `}
                  </Typography>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </Container>

      <Divider />

      <Container maxWidth="lg">
        <div className={classes.range1}>
          <Grid container>
            <Grid item xs={6}>
              <ButtonGroup style={{ height: 44 }}>
                <button
                  className={classes.buttonMin}
                  onClick={() => handleClick("-")}
                >
                  -
                </button>
                <div>
                  <input
                    type="number"
                    className={classes.quantityItem}
                    onChange={(e) =>
                      setItem({
                        qty: e.target.value,
                        price: e.target.value * 117000,
                      })
                    }
                    value={item.qty}
                  />
                </div>
                <button
                  className={classes.buttonPlus}
                  onClick={() => handleClick("+")}
                >
                  +
                </button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </div>
      </Container>

      <Divider />
      <div className={classes.actionSession}>
        <Container style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <div>
            <Typography className={classes.labelPrice}>Total</Typography>
            <Typography className={classes.totalPrice}>
              Rp {numberSeparator(item.price)}
            </Typography>
          </div>
          <Button
            color="primary"
            variant="contained"
            className={classes.buttonPrimary}
            onClick={
              isStoreFront ? handleTambahKeranjangCustomer : handleAddToCart
            }
          >
            Tambahkan ke Keranjang
          </Button>
        </Container>
      </div>

      {dataSupplier !== null && (
        <AddToCart
          handleClose={() => setOpenAddToCart(false)}
          open={openAddToCart}
          data={data}
          colorVariant={selectedSkus.properties.color}
          sizeVariant={selectedSkus.properties.size}
          currentImage={currentImage}
          currentVariant={currentVariant}
          dataSupplier={dataSupplier}
          item={item}
          currentProperties={currentProperties}
          selectedSkus={selectedSkus}
        />
      )}
    </React.Fragment>
  );
};

export default Variant;
