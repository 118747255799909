import React, { useEffect } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Mobile from "./mobile";
import Desktop from "./desktop";

const ProductReseller = ({ match }) => {
  const isMobile = useMediaQuery("(max-width:500px)");

  useEffect(() => {
    sessionStorage.setItem("reseller", match.params.reseller);
  }, []);

  return <React.Fragment>{isMobile ? <Mobile /> : <Desktop />}</React.Fragment>;
};

export default ProductReseller;
