const styles = (theme) => ({
  container: {
    paddingTop: 40,
    paddingBottom: 60,
  },
  card: {
    background: "#F5F5F5",
    borderRadius: 4,
    padding: "30px 40px",
  },
  card2: {
    background: "#F5F5F5",
    padding: "20px 40px",
  },
  formCekResi: {
    marginTop: 20,
  },
  resiNumber: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cekresiplaceholder: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#F5F5F5",
    borderRadius: 4,
    padding: "30px 40px",
  },
});

export default styles;
