import React, { Fragment, useState, useEffect } from "react";
import Navbar from "../../../components/navbar";
import { withRouter } from "react-router-dom";
import {
  Checkbox,
  Typography,
  Divider,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { numberSeparator } from "../../../utils/numberSeparator";
import DeleteIcon from "@material-ui/icons/Delete";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import { quantity, remove, update } from "cart-localstorage";
import swal from "@sweetalert/with-react";
import { getProvinces, getCitiesByProvinceId, getDistrictsByCityId, getSubdistrictByDistrictId } from "../../../services/location.service";
import { isEmpty } from "lodash";

const Mobile = withStyles(styles)((props) => {
  const { classes, history } = props;
  const AsCustomer = false;
  const [cart, setCart] = useState(JSON.parse(localStorage.getItem("__cart")));
  const [selectedCart, setSelectedCart] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [listPrice, setListPrice] = useState([]);
  const [open, setOpen] = useState(false);
  const [listProvince, setListProvince] = useState([]);
  const [listCity, setListCity] = useState([]);
  const [listDistrict, setListDistrict] = useState([]);
  const [listSubdistrict, setListSubdistrict] = useState([]);
  const [dataCustomer, setDataCustomer] = useState({
    name: "",
    address: "",
    province: "",
    city: "",
    subdistrict: "",
    district: "",
    postal_code: "",
    phone: "",
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    if (selectedCart.length !== 0) {
      setOpen(true)
    } else {
      swal("Oops", "Cheklist barang yang akan di checkout", "warning");
    }
  };

  useEffect(() => {
    const fetch = async () => {
      const res = await getProvinces();
      setListProvince(res.data);
    };
    fetch();
  }, []);

  const handleChangeProvince = async (event) => {
    const res = await getCitiesByProvinceId(event.id);
    setListCity(res.data);
    setDataCustomer({ ...dataCustomer, province: event });
  };

  const handleChangeCity = async (event) => {
    const res = await getDistrictsByCityId(event.id);
    setListDistrict(res.data);
    setDataCustomer({ ...dataCustomer, city: event });
  };

  const handleChangeDistrict = async (event) => {
    const res = await getSubdistrictByDistrictId(event.id);
    setListSubdistrict(res.data);
    setDataCustomer({ ...dataCustomer, district: event });
  };

  const handleChangeSubdistrict = (event) => {
    setDataCustomer({
      ...dataCustomer,
      subdistrict: event,
      postal_code: event.kodepos || "",
    });
  };

  const getListPrice = async () => {
    let list = [];
    await selectedCart.forEach((res) => {
      list = [...list, res.price * res.quantity];
    });
    setListPrice(list);
  };

  useEffect(() => {
    getListPrice();
  }, [selectedCart]);

  useEffect(() => {
    const updateCart = async () => {
      const getCart = JSON.parse(localStorage.getItem("__cart"));
      await setCart(getCart);
    };
    updateCart();
    getListPrice();
  }, [isUpdated]);

  const handleRemoveItem = async (id) => {
    await remove(id);

    // fungsi untuk menghapus barang yang sudah di checklist
    if (selectedCart.length !== 0) {
      setSelectedCart(selectedCart.filter((value) => value.id !== id));
    }
    setIsUpdated(!isUpdated);
  };

  const handlePlus = async (id) => {
    await quantity(id, 1);
    if (selectedCart.length !== 0) {
      setSelectedCart([
        ...selectedCart.filter((value) => value.id !== id),
        {
          ...selectedCart.filter((value) => value.id === id)[0],
          quantity:
            selectedCart.filter((value) => value.id === id)[0].quantity + 1,
        },
      ]);
    }
    setIsUpdated(!isUpdated);
  };

  const handleMin = async (id) => {
    await quantity(id, -1);
    if (selectedCart.length !== 0) {
      setSelectedCart([
        ...selectedCart.filter((value) => value.id !== id),
        {
          ...selectedCart.filter((value) => value.id === id)[0],
          quantity:
            selectedCart.filter((value) => value.id === id)[0].quantity - 1,
        },
      ]);
    }
    setIsUpdated(!isUpdated);
  };

  const handleInput = async (id, value) => {
    await update(id, "quantity", value);
    setIsUpdated(!isUpdated);
  };

  const handlePilihSemua = () => {
    if (cart.length === selectedCart.length) {
      setSelectedCart([]);
    } else {
      setSelectedCart(cart);
    }
  };

  const handleSelectedCart = async (data) => {
    const index = selectedCart.findIndex((value) => value.id === data.id);
    const currentCart = selectedCart.filter((item) => item.id === data.id)[0];
    if (Boolean(currentCart) === Boolean(data)) {
      if (index > -1 && index !== 0) {
        setSelectedCart(selectedCart.filter((item) => item.id !== data.id));
      } else if (index === 0 && selectedCart.length === 1) {
        setSelectedCart([]);
      } else {
        setSelectedCart(selectedCart.filter((item) => item.id !== data.id));
      }
    } else {
      setSelectedCart([...selectedCart, data]);
    }
  };

  const handleCheckout = () => {
    const getDataCheckout = JSON.parse(localStorage.getItem("SELECTED_CART"));

    // check form
    if (isEmpty(dataCustomer.province)) {
      return swal("Error!", "Provinsi wajib diisi", "error");
    } else if (isEmpty(dataCustomer.city)) {
      return swal("Error!", "Kota wajib diisi", "error");
    } else if (isEmpty(dataCustomer.subdistrict)) {
      return swal("Error!", "Desa/Kelurahan wajib diisi", "error");
    } else if (isEmpty(dataCustomer.district)) {
      return swal("Error!", "Kecamatan wajib diisi", "error");
    } else if (isEmpty(dataCustomer.postal_code)) {
      return swal("Error!", "Kodepos wajib diisi", "error");
    } else if (isEmpty(dataCustomer.name)) {
      return swal("Error!", "Nama wajib diisi", "error");
    } else if (isEmpty(dataCustomer.address)) {
      return swal("Error!", "Alamat wajib diisi", "error");
    } else if (isEmpty(dataCustomer.phone)) {
      return swal("Error!", "No. Telephone wajib diisi", "error");
    }

    // add form to cart
    if (selectedCart.length !== 0) {
      for (let indexSelectedCart = 0; indexSelectedCart < selectedCart.length; indexSelectedCart++) {
        selectedCart[indexSelectedCart].id = `${selectedCart[indexSelectedCart].id}-${dataCustomer.name}`
        selectedCart[indexSelectedCart].customer = {
          name: dataCustomer.name,
          phone: dataCustomer.phone,
          postal_code: dataCustomer.postal_code,
          address: dataCustomer.address,
          village: {
            district: {
              city: {
                id: dataCustomer.city.id,
                name: dataCustomer.city.name,
                province_id: dataCustomer.province.id,
                province: {
                  id: dataCustomer.province.id,
                  name: dataCustomer.province.name,
                }
              },
              id: dataCustomer.district.id,
              name: dataCustomer.district.name
            },
            id: dataCustomer.subdistrict.id,
            kodepos: dataCustomer.subdistrict.kodepos,
            name: dataCustomer.subdistrict.name
          }
        }
      }

      // memasukkan data ke data checkout
      if (getDataCheckout !== null) {
        localStorage.setItem(
          "SELECTED_CART",
          JSON.stringify(getDataCheckout.concat(selectedCart))
        );
      } else {
        localStorage.setItem("SELECTED_CART", JSON.stringify(selectedCart));
      }
      history.push("/checkout-customer");
    } else {
      swal("Oops", "Cheklist barang yang akan di checkout", "warning");
    }
  };

  console.log(cart)

  return (
    <Fragment>
      <Navbar text="Keranjang Saya" onClick={() => history.goBack()} />
      {cart === null || cart.length === 0 ? (
        <Fragment>
          <div
            className={classes.center}
            style={{ height: window.screen.height - 156 }}
          >
            <div style={{ textAlign: "center" }}>
              <ShoppingBasketIcon className={classes.iconCart} />
              <Typography>Tidak ada produk dikeranjang.</Typography>
              {!AsCustomer && (
                <Button
                  color="primary"
                  variant="contained"
                  style={{ marginTop: 20 }}
                  onClick={() => history.push("/")}
                >
                  Belanja sekarang
                </Button>
              )}
            </div>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div style={{ marginBottom: 75 }}>
            {cart.map((res, index) => (
              <Fragment>
                <div className={classes.containerItemCart}>
                  <div>
                    <Checkbox
                      color="primary"
                      checked={Boolean(
                        selectedCart[
                          selectedCart.findIndex((value) => value.id === res.id)
                        ]
                      )}
                      onClick={() => handleSelectedCart(res)}
                    />
                  </div>
                  <div style={{width: '100%'}}>
                    <div style={{ display: "flex", justifyContent: 'space-between' }}>
                      <img
                        src={res.img}
                        alt={res.name}
                        className={classes.imageitemCart}
                        // onError={handleImgError}
                      />
                      <div style={{ textAlign: 'end' }}>
                        <Typography
                          className={classes.title}
                          onClick={() => history.push(`/product/${res.slug}`)}
                        >
                          {res.name}
                        </Typography>
                        <Typography variant="caption">
                          <b>Variasi: </b>
                        </Typography>
                        <Typography variant="caption">
                          {res.selectedSkus.properties.color}, {res.selectedSkus.properties.size}
                        </Typography>
                        <Typography color="primary">
                          {`@Rp${numberSeparator(res.price)}`}
                        </Typography>
                      </div>
                    </div>
                    <div className={classes.actionSectionCart}>
                      <div className={classes.inputGroup}>
                        <Button
                          className={classes.buttonInput}
                          onClick={() => handleMin(res.id)}
                        >
                          -
                        </Button>
                        <input
                          type="number"
                          className={classes.input}
                          value={res.quantity}
                          onChange={(e) => handleInput(res.id, e.target.value)}
                        />
                        <Button
                          className={classes.buttonInput}
                          onClick={() => handlePlus(res.id)}
                        >
                          +
                        </Button>
                      </div>
                      <div className={classes.flexCenter}>
                        <Typography>
                          <b>{`Rp${numberSeparator(
                            res.quantity * res.price
                          )}`}</b>
                        </Typography>
                        <DeleteIcon
                          style={{ marginLeft: 10 }}
                          onClick={() => handleRemoveItem(res.id)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {cart.length - 1 !== index && <Divider />}
              </Fragment>
            ))}
          </div>
        </Fragment>
      )}
      {/* Action Section */}
      {(cart !== null || cart.length !== 0) && (
        <div className={classes.containerActionSection}>
          <div>
            <Checkbox
              color="primary"
              checked={cart.length === selectedCart.length}
              onClick={handlePilihSemua}
            />
            <Typography variant="caption" style={{ color: "#9999" }}>
              Pilih Semua
            </Typography>
          </div>
          <div style={{ display: "flex" }}>
            <div>
              <Typography
                variant="caption"
                style={{
                  color: "#9999",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                Total Belanja
              </Typography>
              <Typography
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <b>
                  Rp {numberSeparator(listPrice.reduce((a, b) => a + b, 0))}
                </b>
              </Typography>
            </div>
            <Button
              color="primary"
              variant="contained"
              style={{ marginLeft: 10 }}
              onClick={handleOpen}
            >
              Checkout
            </Button>
          </div>
        </div>
      )}

      {/* dialog customer */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Form Pembeli</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Silahkan mengisi data diri untuk melanjutkan transaksi.
          </DialogContentText>
          <>
            <TextField
              id="outlined-basic"
              style={{ marginTop: 20 }}
              value={dataCustomer.name}
              label="Nama"
              variant="outlined"
              fullWidth={true}
              onChange={(e) =>
                setDataCustomer({ ...dataCustomer, name: e.target.value })
              }
            />
            <TextField
              id="outlined-basic"
              style={{ marginTop: 20 }}
              label="Alamat"
              value={dataCustomer.address}
              variant="outlined"
              fullWidth={true}
              onChange={(e) =>
                setDataCustomer({ ...dataCustomer, address: e.target.value })
              }
            />
            <TextField
              id="outlined-basic"
              style={{ marginTop: 20 }}
              type='number'
              label="No.Telpon"
              value={dataCustomer.phone}
              variant="outlined"
              fullWidth={true}
              onChange={(e) =>
                setDataCustomer({ ...dataCustomer, phone: e.target.value })
              }
            />
          </>

          <FormControl variant="outlined" style={{ width: "100%", marginTop: 20 }}>
            <InputLabel id="demo-simple-select-outlined-label">Provinsi</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={
                dataCustomer.province.length === 0 ? "" : dataCustomer.province.name
              }
              label="Provinsi"
            >
              {listProvince !== null &&
                listProvince.map((res) => {
                  return (
                    <MenuItem
                      key={res.id}
                      value={res.name}
                      onClick={() => handleChangeProvince(res)}
                    >
                      {res.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>

          <FormControl variant="outlined" style={{ width: "100%", marginTop: 20 }}>
            <InputLabel id="demo-simple-select-outlined-label">Kota</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={dataCustomer.city.length === 0 ? "" : dataCustomer.city.name}
              label="Kota"
            >
              {listCity !== null &&
                listCity.map((res) => {
                  return (
                    <MenuItem
                      key={res.id}
                      value={res.name}
                      onClick={() => handleChangeCity(res)}
                    >
                      {res.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>

          <FormControl variant="outlined" style={{ width: "100%", marginTop: 20 }}>
            <InputLabel id="demo-simple-select-outlined-label">
              Kecamatan
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={
                dataCustomer.district.length === 0 ? "" : dataCustomer.district.name
              }
              label="Kecamatan"
            >
              {listDistrict.map((res) => {
                return (
                  <MenuItem
                    key={res.id}
                    value={res.name}
                    onClick={() => handleChangeDistrict(res)}
                  >
                    {res.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl variant="outlined" style={{ width: "100%", marginTop: 20 }}>
            <InputLabel id="demo-simple-select-outlined-label">
              Desa/Kelurahan
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={
                dataCustomer.subdistrict.length === 0
                  ? ""
                  : dataCustomer.subdistrict.name
              }
              label="Desa/Kelurahan"
            >
              {listSubdistrict.map((res) => {
                return (
                  <MenuItem
                    key={res.id}
                    value={res.name}
                    onClick={() => handleChangeSubdistrict(res)}
                  >
                    {res.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl variant="outlined" style={{ width: "100%" }}>
            <TextField
              id="outlined-basic"
              style={{ marginTop: 20 }}
              value={dataCustomer.postal_code}
              label="Kode Pos"
              type="number"
              variant="outlined"
              fullWidth={true}
              onChange={(e) =>
                setDataCustomer({ ...dataCustomer, postal_code: e.target.value })
              }
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCheckout} color="primary">
            Lanjutkan 
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
});

export default withRouter(Mobile);
