import { axiosInstance } from "../../config/fetchAxios";

export const getWalletInfo = () => {
  const url = '/api/v2/wallet'
  const response = axiosInstance.get(url)
  return response
}

export const getUserDetails = (id) => {
  const url = `/api/v1/resellers/${id}`
  const response = axiosInstance.get(url)
  return response
}

export const editProfileUser = (id, data) => {
  const url = `/api/v1/resellers/${id}`
  data.append("_method", "PATCH")
  const response = axiosInstance.post(url, data)
  return response
}