import React, { Fragment, useState } from "react";
import {
  Typography,
  FormControl,
  Select,
  Grid,
  InputBase,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { withRouter } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CardProduct from "../../../../../components/CardProduct";

const useStyles = makeStyles((theme) => ({
  centerBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  filterUrutkan: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    fontWeight: 600,
    overflow: "hidden",
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 2,
    paddingTop: 10,
    height: 55,
  },
  priceDiscount: {
    fontSize: "0.9rem",
    color: "#555555",
    paddingTop: 10,
    display: "flex",
    alignItems: "center",
  },
  button2: {
    background: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 4,
    padding: "5px 0",
    width: "100%",
    textTransform: "none",
  },
  pagination: {
    paddingTop: 25,
    display: "flex",
    justifyContent: "center",
  },
  notFound: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 50,
  },
}));

const BootstrapInput = withStyles((theme) => ({
  input: {
    padding: "10px 14px",
    border: "1px solid #ced4da",
    borderRadius: 4,
  },
}))(InputBase);

const Produk = (props) => {
  const classes = useStyles();
  const { dataSearched, handleChangePage, handleFilterSort } = props;
  const keyword = decodeURI(props.match.params.keyword);
  const [selectedMenu, setSelectedMenu] = useState("");

  const Menu = ["Termurah", "Termahal", "Terbaru"];

  return (
    <Fragment>
      {dataSearched.data.length === 0 ? (
        <Fragment>
          <div className={classes.notFound}>
            <Typography>
              Pencarian untuk produk "{keyword}" tidak ditemukan.
            </Typography>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div className={classes.centerBetween} style={{ paddingTop: 15 }}>
            <Typography>
              Menampilkan {dataSearched.meta.total} produk untuk{" "}
              <b>"{keyword}"</b> (
              <b>
                {dataSearched.meta.current_page} - {dataSearched.meta.last_page}
              </b>{" "}
              dari <b>{dataSearched.meta.total}</b>)
            </Typography>
            <div className={classes.filterUrutkan}>
              <Typography style={{ paddingRight: 11 }}>
                <b>Urutkan</b>
              </Typography>
              <FormControl variant="outlined">
                <Select
                  native
                  value={selectedMenu}
                  onChange={(event) => handleFilterSort(event.target.value)}
                  input={<BootstrapInput />}
                >
                  {Menu.map((res, index) => {
                    return (
                      <option
                        key={index}
                        value={res}
                        onClick={() => setSelectedMenu(res)}
                      >
                        {res}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>

          <Grid container spacing={2} style={{ paddingTop: 30 }}>
            {dataSearched.data.map((res, index) => (
              <Grid item xs={3} key={index}>
                <CardProduct variant="reseller" data={res} />
              </Grid>
            ))}
          </Grid>

          <div className={classes.pagination}>
            <Pagination
              count={dataSearched.meta.last_page}
              siblingCount={dataSearched.meta.current_page}
              variant="outlined"
              shape="rounded"
              color="primary"
              onChange={(event, index) => handleChangePage(index)}
            />
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default withRouter(Produk);
