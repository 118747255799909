import React, { useEffect, useState } from 'react'
import { Container, Button } from '@material-ui/core'
import { withStyles } from "@material-ui/core/styles";
import { stylesContent } from "./style"
import moment from 'moment';
import Navbar from '../../components/navbar'
import Footer from '../../components/footer'
import { getAllHistoryDataDailyLogin, claimCoinDailyLogin } from '../../services/axios'
import { GET_WALLET_INFO } from '../../services/wallet.service'
import { numberSeparator } from '../../utils/numberSeparator';
import swal from "@sweetalert/with-react"

// img
import DailyLoginIcon from '../../assets/images/login2.svg'
import IconCoin from '../../assets/icons/coins.svg'
import NeedLoginSwal from '../../components/needLoginSwal';
import PopupDeeplink from '../../components/Mobile/DeeplinkPopup';

const DailyLogin = withStyles(stylesContent)((props) => {
    const { classes } = props;
    const [dataHistoryDailyLogin, setDataHistoryDailyLogin] = useState([])
    const [status, setStatus] = useState(null)
    const [coin, setCoin] = useState('')
    const day = ['Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu', 'Minggu'];
    const today = moment().format('dddd');
    const isLogin = localStorage.getItem("TOKEN");

    useEffect(() => {
        const getAllData = async () => {
            const allData = await getAllHistoryDataDailyLogin();
            const dataCoin = await GET_WALLET_INFO();
            setDataHistoryDailyLogin(allData.data);
            setStatus(allData.logged_in);
            setCoin(numberSeparator(dataCoin.data.coin))
        }

        if (isLogin === null) {
            swal(
                <NeedLoginSwal />
                , {
                    closeOnClickOutside: false,
                    buttons: false
                })
        } else {
            getAllData()
        }
    }, [])

    const handleClaimCoin = async () => {
        const body = {
            log_in: 1
        }
        const response = await claimCoinDailyLogin(body);
        if (response.status === 'success') {
            const getAllData = async () => {
                const allData = await getAllHistoryDataDailyLogin();
                const dataCoin = await GET_WALLET_INFO();
                setDataHistoryDailyLogin(allData.data);
                setStatus(allData.logged_in);
                setCoin(numberSeparator(dataCoin.data.coin))
            }

            getAllData()
        } else {
            swal({
                title: response.status,
                icon: "error",
            });
        }
    };

    return (
        <>
            <PopupDeeplink url={window.location.pathname}/>
            <Navbar variant="primary" />
            <Container
                maxWidth="md"
                style={{ paddingTop: 10, paddingBottom: 40, backgroundColor: '#fafafa' }}
            >
                <div className={classes.outerContainer}>
                    <div className={classes.innerContainer}>
                        <h1 style={{ margin: 0 }}>Daily Login</h1>

                        <div className={classes.coinContainer}>
                            <div>
                                <h5>Koin Saya</h5>
                                <h1>{coin}</h1>
                            </div>

                            <img src={DailyLoginIcon} alt='Daily Login Gambar' />
                        </div>
                    </div>

                    <div className={classes.mainContainer}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div>
                                {
                                    dataHistoryDailyLogin.map((res, index) =>
                                        res.day === today &&
                                        <h2 key='todayDailyLogin' style={{ color: 'orange' }}>{day[index]}</h2>
                                    )
                                }
                                <p style={{ color: 'black' }}>Buka Idejualan seiap hari untuk mendapatkan koin</p>
                            </div>

                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                style={{ color: 'white' }}
                                onClick={() => handleClaimCoin()}
                                disabled={status}
                            >
                                Klaim Koin
                            </Button>
                        </div>

                        <div className={classes.innerMainContainer}>
                            {
                                dataHistoryDailyLogin.map((res, index) =>
                                    <div key={'dailyLoginCoin' + index} className={classes.coinCard} style={res.claimed ? { backgroundColor: '#f9d786' } : {}}>
                                        <div className={classes.titleCard}>
                                            <p style={{ margin: 0 }}>{day[index]}</p>
                                        </div>

                                        <img className={classes.imgCard} src={IconCoin} alt='Icon Coin' />

                                        <p style={res.claimed ? { color: 'white' } : {}}>+{res.reward}</p>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </Container>

            <Footer />
        </>
    )
})

export default DailyLogin