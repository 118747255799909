import styled from "styled-components"

const GroupButton = styled.div`
  margin-top: 8px;
  display: flex;

  button {
    background-color: #FFFFFF;
    font-size: 13px;
    font-family: 'dmsans-regular';
    color: #777777;
    padding: 8px;
    border-radius: 5px;
    border: none;
    margin-right: 8px;
    letter-spacing: 0.02em;
  }
`

const Title = styled.h2`
  font-size: 16px;
  font-family: 'dmsans-bold';
  letter-spacing: 0.02em;
  color: #666;
  margin-top: 24px;
`

const StatisticSelling = styled.div`
  position: relative;
  background-color: #FFF2D0;
  border-radius: 10px;
  padding: 8px;

  .sub {
    float: left;
    width: 50%;
    padding-left: 16px;
    padding-bottom: 8px;
    margin-bottom: 8px;
    font-size: 12px;
    font-family: 'dmsans-regular';
    border-bottom: 1px solid #535353;
    color: #535353;
      span { 
        display: block
      }
      span:nth-child(2) {
        font-family: 'dmsans-bold'
      }

      ::before {
        position: absolute;
        display: block;
        width: 10px;
        height: 10px;
        content: ' ';
        border-radius: 15px; 
        margin-left: -16px;
        margin-top: 3px;
      }
  }
  .sub:nth-child(1)::before {
    background-color: #6EBB6C;
  }
  .sub:nth-child(2)::before {
    background-color: #BB756C;
  }

  .total {
    display: block;
    font-size: 12px;
    padding-left: 16px;
    span:nth-child(1) {
      color: #535353;
      font-family: 'dmsans-regular'
    }
    span:nth-child(2) {
      color: #6EBB6C;
      font-family: 'dmsans-bold';
      margin-left: 16px;
    }
  }
`

const style = (theme) => ({
  card: {
    backgroundColor: "#FFFFFF",
    padding: "15px 10px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    borderRadius: "3px"
  },
  photoProfile: {
    height: 84,
    width: 84,
    borderRadius: "50%",
    border: `2px solid ${theme.palette.yellow.primary}`
  },
  nameStore: {
    fontFamily: "dmsans-bold",
    fontSize: '20px'
  },
  statusMember: {
    color: `${theme.palette.yellow.primary}`,
    border: `1px solid ${theme.palette.yellow.primary}`,
    padding: "4px 7px",
    borderRadius: "30px",
    fontSize: "12px",
    fontFamily: "dmsans-regular",
    letterSpacing: "0.02em",
    textAlign: "center",
    marginTop: 8,
    width: 'fit-content'
  },
  countFollow: {
    fontSize: "12px",
    fontFamily: "dmsans-regular",
    letterSpacing: "0.02em"
  },
  cardYellow: {
    backgroundColor: `${theme.palette.yellow.primary}`,
    borderRadius: "10px",
    padding: "8px"
  },
  cardDompetCoin: {
    backgroundColor: `#fff`,
    borderRadius: "10px",
    padding: "16px",
    display: "flex"
  },
  totalDompetCoin: {
    fontSize: "16px",
    fontFamily: "dmsans-bold",
    letterSpacing: "0.02em",
    color: "#666666",
    lineHeight: "21px"
  },
  titleDompetCoin: {
    display: "block",
    fontSize: "13px",
    fontFamily: "dmsans-regular",
    letterSpacing: "0.02em",
    color: "#535353"
  },
  icon: {
    position: "relative",
    marginRight: "8px",
    top: "2px"
  }
});

export {style, GroupButton, Title, StatisticSelling};
