import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Mobile from "./mobile";
import Desktop from "./desktop";

const Storefront = ({ match }) => {
  const isMobile = useMediaQuery("(max-width:500px)");
  const isCartExist = localStorage.getItem('__cart')
  if (isCartExist === null) {
    localStorage.setItem('__cart', '[]')
  }

  return <React.Fragment>{isMobile ? <Mobile /> : <Desktop />}</React.Fragment>;
};

export default Storefront;
