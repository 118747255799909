import React from 'react'
import ToolbarMobile from '../../components/Mobile/ToolbarMobile'

// icon
import IconComingSoon from '../../assets/images/mobile/coming_soon.png'

const NotificationPage = () => {
  return (
    <div>
      
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100vh' }}>
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <img src={IconComingSoon} style={{width: '90%'}} alt='icon coming soon' />
        </div>
        <div>Coming Soon</div>
      </div>

      <ToolbarMobile/>
    </div>
  )
}

export default NotificationPage