const styles = (theme) => ({
  container: {
    paddingTop: 30,
    paddingBottom: 30,
    height: 400,
  },
  card: {
    background: "rgb(245, 245, 245)",
    padding: 30,
    borderRadius: 8,
  },
  titleCard: {
    textAlign: "center",
  },
  content: {
    padding: "20px 0",
    textAlign: "center",
  },
  action: {
    textAlign: "center",
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    justifyContent: "center",
  },
});

export default styles;
