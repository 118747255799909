import styled from 'styled-components'

export const WrapCPWishlist = styled.div`
  position: relative;
  width: 100px;
  margin-right: 8px;

  .imageProduct {
    width: 100px;
    height: 100px;
    img {width: 100%;}
  }

  h2 {
    font-size: 10px;
    letter-spacing: 0.03em;
    text-overflow: ellipsis;
    width: 100px;
    overflow: hidden;
    height: 40px;
    overflow-wrap: normal;
  }
  .price {
    margin: 0;padding: 0;
    color: #FBBD1C;
    font-size: 10px;
    font-family: 'DMSans-Bold';
    letter-spacing: 0.03em;
    margin-bottom: 8px;
  }
  button {
    cursor: pointer;
    padding: 9px 6px;
    border-radius: 5px;
    background-color: #FBBD1C;
    color: #FFF;
    font-size: 10px;
    font-family: 'DMSans-Bold';
    width: 100%;
    border: none;
    svg {
      float: left;
    left: 5px;
    position: relative;
    }
    p{ margin:0; padding: 0; position: relative; top: 2px;}
  }
`

export const CardFull = styled.div`
  position: relative;
  box-shadow: 4px 7px 20px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  padding: 8px;
  background: #FFF;
  margin-bottom: 8px;
  .imageProduct {
    width: 100px;
    height: 100px;
    img{width: 100%}
  }
  .flex {
    display: flex;
  }
  .info {
    margin-left: 16px;
    h2{
      font-size: 13px;
      color: #666;
    }
    .price {
      color: #020202;
      font-size: 16px;
      font-family: 'DMSans-Bold';
      margin-top: 8px;
      display: block;
    }
  }
  .delete {
    border: none;
    background-color: #F6F6F6;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    :focus {
      border: none;
      outline: none;
    }
  }
  .add {
    background-color: #FFF;
    border-radius: 5px;
    color: #FBBD1C;
    font-size: 13px;
    font-family: 'DMSans-Bold';
    cursor: pointer;
    border: 1px solid #FBBD1C;
    width: 100%;
    margin-left: 8px;
  }
`