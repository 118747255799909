/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'
import { withStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import style from "./style";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { ReactComponent as IconCart } from "../../../assets/icons/mobile/iconShopSolid.svg";
import { ReactComponent as IconMenu } from "../../../assets/icons/mobile/dots-menu.svg";

const NavbarProductMobile = withStyles(style)((props) => {
    const { classes } = props;
    const [isScroll, setisScroll] = useState(false)
    let history = useHistory()
    const [chart, setchart] = useState(JSON.parse(localStorage.getItem('__cart')) === null ? 0 : JSON.parse(localStorage.getItem('__cart')).length)
    const urlPathName = window.location.pathname.split('/')
    const isStoreFront = urlPathName[1] === 'store'

    const changeBackground = () => {
        if (window.scrollY >= 360) {
            setisScroll(true)
        } else {
            setisScroll(false)
        }
    }

    window.addEventListener('scroll', changeBackground)

    // chart
    useEffect(() => {
        setchart(JSON.parse(localStorage.getItem('__cart')) === null ? 0 : JSON.parse(localStorage.getItem('__cart')).length)
    }, [localStorage.getItem("__cart")])

    return (
        <div className={classes.container} style={isScroll ? {backgroundColor: 'white'} : {}}>
            <div className={classes.containerBackButton} onClick={() => history.goBack()}>
                <ArrowForwardIcon style={{ transform: 'rotate(180deg)' }} />
            </div>
            <div className={classes.containerMenu}>
                <div className={classes.conatinerChart} onClick={() => history.push(isStoreFront ? "/cart-customer" : "/cart")}>
                    <div className={classes.countChart}>{chart}</div>
                    <IconCart style={{ width: 25, height: 25 }} />
                </div>
                <IconMenu style={{ width: 20, height: 20, transform: 'rotate(90deg)' }} />
            </div>
        </div>
    )
})

export default NavbarProductMobile