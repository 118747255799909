const styles = (theme) => ({
  container: {
    paddingTop: 10,
    paddingBottom: 60,
  },
  containerVoucher: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "rgb(100 100 111 / 20%) 0px 7px 29px 0px",
    borderRadius: "5px",
    backgroundColor: "white",
    margin: "24px 0",
  },
  voucherHead: {
    backgroundColor: "orange",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  voucherSummary: {
    margin: "12px 32px",
    width: "100%",
    backgroundColor: "white",
    padding: "10px",
    borderRadius: "10px",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    position: "relative",
    top: "-62px",
  },
  voucherDetail: {
    position: "relative",
    top: "-49px",
    textAlign: "start",
    width: "100%",
    margin: "0px 32px",
  },
  loadingContainer: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default styles;
