const styles = (theme) => ({
  container: {
    paddingTop: 40,
    marginBottom: 100,
  },
  cardBackground: {
    background: "#F5F5F5",
    borderRadius: 8,
    padding: 10,
    width: "100%",
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  flexEnd: {
    display: "flex",
    alignItems: "flex-end",
  },
  flexCenterBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  productImage: {
    objectFit: "cover",
    borderRadius: 4,
    height: 150,
    width: 150,
  },
  contentWrapper: {
    paddingLeft: 20,
    width: "100%",
  },
  contentText2: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: 5,
  },
  actionWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: 35,
  },
  groupedButton: {
    display: "flex",
    borderRadius: 4,
    width: "fit-content",
    border: "1px solid #555555",
  },
  button: {
    background: "#555555",
    borderRadius: 2,
    color: "#fff",
    fontSize: 50,
    height: 40,
  },
  styledInput: {
    height: 40,
    width: 50,
    border: "none",
    outline: "none",
    padding: "10px",
    textAlign: "center",
    fontSize: "1rem",
    fontWeight: "bold",
  },
  removeItem: {
    color: "#FF6564",
    paddingTop: 20,
    paddingBottom: 25,
    cursor: "pointer",
    width: "fit-content",
  },
  iconCart: {
    height: 50,
    width: "auto",
  },
  productName: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
});

export default styles;
