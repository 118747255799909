import React, { Fragment, useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withStyles } from "@material-ui/core/styles";
import { stylesContent, stylesDesktop, stylesMobile } from "./style";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import {
  Typography,
  Input,
  Button,
  Container,
  Grid,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import { ResetPasswordReseller } from "../../services/axios";
import swal from "sweetalert";

const Content = withStyles(stylesContent)((props) => {
  const { classes } = props;
  const [password, setPassword] = useState({
    valuePassword: "",
    showValuePassword: false,
    confirmPassword: "",
    showConfirmPassword: false,
  });
  const [loading, setLoading] = useState(false);

  const handleChangeInput = (props) => (event) => {
    setPassword({ ...password, [props]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setPassword({
      ...password,
      showValuePassword: !password.showValuePassword,
    });
  };
  const handleClickShowConfirmPassword = () => {
    setPassword({
      ...password,
      showConfirmPassword: !password.showConfirmPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async () => {
    setLoading(true);
    const data = {
      token: window.location.pathname.slice(21),
      new_password: password.valuePassword,
    };
    if (password.valuePassword === password.confirmPassword) {
      const response = await ResetPasswordReseller(data);
      if (response.message) {
        setLoading(false);
        swal("Success!", response.message, "success").then(
          () => (window.location = "/login")
        );
      }
    } else {
      swal("Oops", "Pastikan pengulangan password sudah sama", "info");
    }
  };

  return (
    <Fragment>
      <Typography className={classes.title}>Reset Password</Typography>
      <div style={{ paddingTop: 20 }}>
        <Typography className={classes.guide}>
          Sebaiknya gunakan kata sandi yang kuat yang tidak Anda gunakan di
          tempat lain
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className={classes.styledInput}>
              <Input
                label="Password baru"
                type={password.showValuePassword ? "text" : "password"}
                value={password.valuePassword}
                fullWidth={true}
                variant="outlined"
                disableUnderline={true}
                onChange={handleChangeInput("valuePassword")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {password.showValuePassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.styledInput}>
              <Input
                label="Masukan ulang password baru"
                type={password.showConfirmPassword ? "text" : "password"}
                value={password.confirmPassword}
                fullWidth={true}
                variant="outlined"
                disableUnderline={true}
                onChange={handleChangeInput("confirmPassword")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {password.showConfirmPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </div>
          </Grid>
        </Grid>

        <Button
          variant="contained"
          color="primary"
          fullWidth={true}
          className={classes.styledButton}
          onClick={handleSubmit}
        >
          {loading ? (
            <CircularProgress style={{ color: "#fff" }} size={24} />
          ) : (
            "Simpan Perubahan"
          )}
        </Button>
      </div>
    </Fragment>
  );
});

const Mobile = withStyles(stylesMobile)((props) => {
  return (
    <Fragment>
      <Navbar variant="default" />
      <Container maxWidth="lg">
        <Content />
      </Container>
    </Fragment>
  );
});

const Desktop = withStyles(stylesDesktop)((props) => {
  const { classes } = props;
  return (
    <Fragment>
      <Navbar variant="default" />
      <div className={classes.container}>
        <div className={classes.wrapper}>
          <Content desktop />
        </div>
      </div>
      <Footer />
    </Fragment>
  );
});

const ResetPassword = () => {
  const isMobile = useMediaQuery("(max-width:500px)");
  return <React.Fragment>{isMobile ? <Mobile /> : <Desktop />}</React.Fragment>;
};

export default ResetPassword;
