import { axiosInstance } from "../config/fetchAxios";

export const GET_STOREFRONT_PRODUCT = async (slug, filter = '') => {
  const url = `/api/v1/storefronts/${slug}/products${filter}`
  const data = axiosInstance.get(url)
  return data
}

export const ADD_SINGLE_STOREFRONT_PRODUCT = async (storefront, slugProduct) => {
  const url = `/api/v1/storefronts/${storefront}/products`
  const data = axiosInstance.post(url, {
    product: slugProduct
  })
  return data
}

export const DELETE_SINGLE_STOREFRONT_PRODUCT = async (storefront, slugProduct) => {
  const url = `/api/v1/storefronts/${storefront}/products/${slugProduct}`
  const data = axiosInstance.delete(url)
  return data
}