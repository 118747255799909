import styled from "styled-components"

const WrapMenu = styled.div`
  position: relative;
  font-size: 13px;
  font-family: "dmsans-regular";
  display: flex;
  padding: 8px;
  border-bottom: 1px solid #E5E5E5;
  cursor: pointer;
  p {
    margin: 0;
    padding: 0;
    letter-spacing: 0.02em;
  }
  
  p:nth-child(1) {
    color: #666;
  }
  p:nth-child(2) {
    color: #CACACA;
    margin-top: 4px;
  }

  .right {
    position: absolute;
    right: 0;
    top: 14px;
  }
`

export {
  WrapMenu
}