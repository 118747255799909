import React from 'react';
import { WrapMenu } from './style';
import iconNext from '../../assets/icons/next.svg'
import { Switch, FormGroup, FormControlLabel } from '@material-ui/core';

const MenuMobileSwitchComponent = (props) => {
  const {title, desc, style, onChange, isCheck, name } = props
  return (
    <WrapMenu style={style} >
      <div className="left">
        <p>{title}</p>
        <p>{desc}</p>
      </div>
      <div className="right">
        <Switch 
          color="primary"
          checked={isCheck || false}
          name={name}
          onChange={onChange}
        />
      </div>
    </WrapMenu>
  );
};

export default MenuMobileSwitchComponent;