import React, { Fragment, useState, useEffect } from "react";
import { Typography, Grid, Button, Container, Drawer } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Carousel from "../../../../components/carousel";
import Pagination from "@material-ui/lab/Pagination";
import FilterIcon from "../../../../assets/icons/heroicons-outline_filter.svg";
import FilterIconLeft from "../../../../assets/icons/bi_filter-left.svg";
import Filter from "./filter";
import Sort from "./sort";
import { getCategories, getProvinces } from "../../../../services/axios";
import CardProduct from "../../../../components/CardProduct";
import FindInPageIcon from "@material-ui/icons/FindInPage";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 600,
    overflow: "hidden",
    display: "-webkit-box",
    boxOrient: "vertical",
    title: {
      fontWeight: 600,
      overflow: "hidden",
      display: "-webkit-box",
      boxOrient: "vertical",
      lineClamp: 2,
      paddingTop: 10,
      height: 55,
    },
    priceDiscount: {
      fontSize: "0.9rem",
      color: "#555555",
      paddingTop: 10,
      display: "flex",
      alignItems: "center",
    },
    button2: {
      background: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: 4,
      padding: "5px 0",
      width: "100%",
      textTransform: "none",
    },
    lineClamp: 2,
    paddingTop: 10,
    height: 55,
  },
  priceDiscount: {
    fontSize: "0.9rem",
    color: "#555555",
    paddingTop: 10,
    display: "flex",
    alignItems: "center",
  },
  button2: {
    background: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 4,
    padding: "5px 0",
    width: "100%",
    textTransform: "none",
  },
  pagination: {
    paddingTop: 25,
    display: "flex",
    justifyContent: "center",
  },
  actionFilter: {
    paddingTop: 15,
    paddingBottom: 15,
    background: "#ffffff",
    boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.08)",
    position: "fixed",
    bottom: 0,
  },
  notFound: {
    height: window.screen.height - 220,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const Produk = (props) => {
  const classes = useStyles();
  const {
    dataSearched,
    handleChangePage,
    handleFilter,
    handleResetFilter,
    setFilterCategory,
    filterCategory,
    filterLocation,
    setFilterLocation,
    handleFilterSort,
    sliderProduct,
  } = props;
  const keyword = decodeURI(props.match.params.keyword);
  const [openFilter, setOpenFilter] = useState(false);
  const [openSort, setOpenSort] = useState(false);
  const [listCategory, setListCategory] = useState(null);
  const [listProvince, setListProvince] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      const responseCategories = await getCategories();
      const responseProvince = await getProvinces();
      setListCategory(responseCategories.data);
      setListProvince(responseProvince.data);
    };
    fetch();
  }, []);

  return (
    <Fragment>
      {dataSearched.data.length === 0 ? (
        <div className={classes.notFound}>
          <div style={{ textAlign: "center" }}>
            <FindInPageIcon />
            <Typography>Data tidak ditemukan.</Typography>
          </div>
        </div>
      ) : (
        <Fragment>
          <Container maxWidth="lg">
            <Typography style={{ fontSize: 13, paddingTop: 10 }}>
              Menampilkan {dataSearched.meta.total} produk untuk{" "}
              <b>"{keyword}"</b> (
              <b>
                {dataSearched.meta.current_page} - {dataSearched.meta.last_page}
              </b>{" "}
              dari <b>{dataSearched.meta.total}</b>)
            </Typography>

            <Grid container spacing={2} style={{ paddingTop: 30 }}>
              {dataSearched.data.map((res, index) => (
                <Grid item xs={6} key={index}>
                  <CardProduct variant="reseller" data={res} />
                </Grid>
              ))}
            </Grid>

            <div className={classes.pagination}>
              <Pagination
                count={dataSearched.meta.last_page}
                siblingCount={dataSearched.meta.current_page}
                variant="outlined"
                shape="rounded"
                size="small"
                color="primary"
                onChange={(event, index) => {
                  handleChangePage(index);
                  window.scrollTo(0, 0);
                }}
              />
            </div>
          </Container>

          <div style={{ marginTop: 20, marginBottom: 80 }}>
            <Container maxWidth="lg">
              <Typography style={{ paddingBottom: 20, fontWeight: "bold" }}>
                Rekomendasi untuk kamu
              </Typography>
            </Container>
            <Carousel data={sliderProduct} />
          </div>

          <Container maxWidth="lg" className={classes.actionFilter}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth={true}
                  startIcon={<img src={FilterIcon} alt={"icon"} />}
                  onClick={() => setOpenFilter(true)}
                >
                  <b>Filter</b>
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth={true}
                  startIcon={<img src={FilterIconLeft} alt={"icon"} />}
                  onClick={() => setOpenSort(true)}
                >
                  <b>Urutkan</b>
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Fragment>
      )}

      <Drawer
        anchor="left"
        open={openFilter}
        onClose={() => setOpenFilter(false)}
      >
        <Filter
          onClose={() => setOpenFilter(false)}
          listCategory={listCategory}
          listProvince={listProvince}
          filterCategory={filterCategory}
          setFilterCategory={setFilterCategory}
          filterLocation={filterLocation}
          setFilterLocation={setFilterLocation}
          applyFilter={() => {
            handleFilter();
            setOpenFilter(false);
          }}
          resetFilter={() => {
            handleResetFilter();
            setOpenFilter(false);
          }}
        />
      </Drawer>

      <Drawer anchor="right" open={openSort} onClose={() => setOpenSort(false)}>
        <Sort
          onClose={() => setOpenSort(false)}
          handleFilterSort={handleFilterSort}
        />
      </Drawer>
    </Fragment>
  );
};

export default withRouter(Produk);
