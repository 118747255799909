import React, { useEffect } from "react";
import {
    Container,
    Box,
    Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CopyToClipboard from "react-copy-to-clipboard";
import swal from "sweetalert";
import { numberSeparator } from "../../../utils/numberSeparator";
import { getVedorBanks } from "../../../services/axios";
import { getCampaignData, getDetailCampaign } from "../../../services/campaign.services";
import ReactMidtrans from "../../../config/midtrans";
import midtransClientKey from "../../../config/midtransClientKey";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        boxShadow: "0 0 0.25em rgb(0 0 0 / 30%)",
        padding: "32px 64px",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        [theme.breakpoints.down("501")]: {
        padding: "32px 10px",
        },
    },
    title: {
        textAlign: "center",
        color: "#252525",
    },
    formContainer: {
        "& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-fullWidth": {
        marginBottom: "16px",
        },
    },
    submitButton: {
        color: "#fff",
        backgroundColor: "#3bb877",
    },
    wrapperBank: {
        width: '50%', 
        minWidth: 200, 
        border: '1px dashed #a9a9a9', 
        marginTop: '-1px', 
        marginLeft: '-1px',
        padding: 10
    }
}));

const SubmissionsCampaign = ({match}) => {
    const classes = useStyles();
    const history = useHistory()
    const [nominalTf, setnominalTf] = React.useState('0')
    const [allDataSubmissions, setallDataSubmissions] = React.useState()
    const [allDataBank, setallDataBank] = React.useState()
    const [Repeater, setRepeater] = React.useState(0)
    const [allDataCampaign, setallDataCampaign] = React.useState()

    useEffect(() => {
        const getAllDataTf = async() => {
            const response = await getCampaignData(match.params.uuid)
            if (response.data.payment_method === 'midtrans') {
                initSnap(response.data.payment.token)
            }
            setallDataSubmissions(response.data)
            setnominalTf(response.data.amount)
        }

        const getAllDataCampaign = async() => {
            const dataCampaign = JSON.parse(localStorage.getItem('data-campaign'))
            const response = await getDetailCampaign(dataCampaign.campaign_slug)
            setallDataCampaign(response.data)
        }

        getAllDataCampaign()
        getAllDataTf()
    }, [])
    
    useEffect(() => {
        const getAllDataBank = async() => {
            const response = await getVedorBanks()
            setallDataBank(response.data)
        }

        getAllDataBank()
    }, [])
    
    useEffect(() => {
        const getReapeatData = async() => {
            try {
                const response = await getCampaignData(match.params.uuid)
                if (response.data.payment.is_completed === true) {
                    const dataCampaign = JSON.parse(localStorage.getItem('data-campaign'))
                    history.push(`/payment-campaign?campaign_slug=${dataCampaign.campaign_slug}`)
                }
            } catch (error) {
                alert(error)
            }
        }

        getReapeatData()
        setTimeout(() => setRepeater(prevState=>prevState+1), 20000);
    }, [Repeater])

    const initSnap = (token) => {
        const { NODE_ENV: ENV } = process.env;
    
        // create element for script
        const snapScript = document.createElement("script");
    
        // checking environment mode
        snapScript.src =
            ENV === "production"
            ? 
                window.origin.includes('dev') ?
                "https://app.sandbox.midtrans.com/snap/snap.js"
                :
                "https://app.midtrans.com/snap/snap.js"
            : "https://app.sandbox.midtrans.com/snap/snap.js";
    
        snapScript.type = "text/javascript";
        snapScript.onload = () => {
        if ("snap" in window) {
            const { snap } = window;
            snap.pay(token, {
                onSuccess: function(result){
                    const dataCampaign = JSON.parse(localStorage.getItem('data-campaign'))
                    history.push(`/payment-campaign?campaign_slug=${dataCampaign.campaign_slug}`)
                }
            })
        }
        };
        snapScript.dataset.clientKey = midtransClientKey;
        document.head.appendChild(snapScript);
    };

    return (
        <>
            {
                allDataSubmissions?.payment_method === 'midtrans' ? 
                <ReactMidtrans
                    clientKey={midtransClientKey}
                >
                    <div></div>
                </ReactMidtrans>
                :
                <Box>
                    <Container maxWidth="sm">
                        <div className={classes.wrapper} style={{marginTop: 10, textAlign: 'center'}}>
                            <h1 className={classes.title}>Pembayaran Transfer Bank</h1>
                            <div style={{fontSize: '1.1rem', fontWeight: '400', marginBottom: 20}}>Untuk menyelesaikan proses pendaftaran, silahkan transfer sejumlah</div>

                            {/* jumlah tf */}
                            <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
                                <div style={{fontSize: '1.8rem', fontWeight: 'bold', marginBottom: 20}}>Rp. {nominalTf && numberSeparator(nominalTf)}</div>
                                
                                <div style={{backgroundColor: '#FCC84D', padding: 5, borderRadius: 8, marginBottom: 20}}>
                                    <div style={{fontWeight: 400}}><span style={{fontWeight: 'bold'}}>PENTING!</span> Mohon transfer sesuai sampai dengan 3 digit terakhir</div>
                                </div>

                                <CopyToClipboard
                                    text={nominalTf}
                                    onCopy={() => {
                                        swal("Nominal disalin.", {
                                            icon: "success",
                                            button: false,
                                        });
                                    }}
                                >
                                    <Button style={{maxWidth: 100, border: '1px solid black', marginBottom: 25}}>Salin Jumlah</Button>
                                </CopyToClipboard>

                                <div>ke salah satu bank berikut ini:</div>
                            </div>

                            <br/>

                            {/* bank default */}
                            {
                                allDataCampaign?.is_custom_bank === false &&
                                <div>
                                    <div style={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap'}}>
                                        {
                                            allDataBank?.map((res, index) => 
                                                <div key={res.account_name + index} className={classes.wrapperBank}>
                                                    <img src={res.bank.icon} alt={res.account_name} style={{width: 100, marginBottom: 20, marginTop: 10}}/>
                                                    <div>No. Rek: <span style={{fontWeight: 'bold', color:'#555555'}}>{res.account_number}</span></div>
                                                    <div>Atas Nama: <span style={{fontWeight: 'bold', color:'#555555'}}>{res.account_name}</span></div>
                                                    
                                                    <CopyToClipboard
                                                        text={res.account_number}
                                                        onCopy={() => {
                                                            swal("Rekening disalin.", {
                                                                icon: "success",
                                                                button: false,
                                                            });
                                                        }}
                                                    >
                                                        <Button style={{maxWidth: 120, border: '1px solid black', marginTop: 25, fontSize: '.8rem'}}>Salin Rekening</Button>
                                                    </CopyToClipboard>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            }

                            {/* custom bank */}
                            {
                                allDataCampaign?.is_custom_bank === true &&
                                <div>
                                    <div style={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap'}}>
                                        {
                                            allDataCampaign?.banks.map((res, index) => 
                                                <div key={res.account_name + index} className={classes.wrapperBank}>
                                                    <img src={res.bank.icon} alt={res.bank.name} style={{width: 100, marginBottom: 20, marginTop: 10}}/>
                                                    <div>No. Rek: <span style={{fontWeight: 'bold', color:'#555555'}}>{res.account_number}</span></div>
                                                    <div>Atas Nama: <span style={{fontWeight: 'bold', color:'#555555'}}>{res.account_name}</span></div>
                                                    
                                                    <CopyToClipboard
                                                        text={res.account_number}
                                                        onCopy={() => {
                                                            swal("Rekening disalin.", {
                                                                icon: "success",
                                                                button: false,
                                                            });
                                                        }}
                                                    >
                                                        <Button style={{maxWidth: 120, border: '1px solid black', marginTop: 25, fontSize: '.8rem'}}>Salin Rekening</Button>
                                                    </CopyToClipboard>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            }
                        </div>

                        <br/>
                    </Container>
                </Box>
            }
        </>
    )
}

export default SubmissionsCampaign