import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Popover,
  FormControl,
  Select,
  Grid,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  checkShippingCostV2,
} from "../../../../services/axios";

import {
  getProvinces,
  getCitiesByProvinceId,
  getDistrictsByCityId,
  getSubdistrictByDistrictId,
} from "../../../../services/location.service";
import { formatRupiah } from "../../../../utils/currencyFormat";

const useStyles = makeStyles((theme) => ({
  variant: {
    fontWeight: 600,
    color: "#555555",
    marginBottom: 10,
  },
  fromCity: {
    fontSize: 13,
    color: "#555555",
    opacity: 0.65,
  },
  toCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  from: {
    color: "#555555",
    opacity: 0.65,
    margin: 0,
    fontSize: 13,
  },
  bold: {
    fontWeight: 600,
    paddingLeft: 10,
    fontSize: 13,
  },
  containerPopover: {
    padding: 15,
    display: "flex",
  },
  subDistrict: {
    height: 56,
    display: "flex",
    alignItems: "center",
  },
  codePos: {
    height: 56,
    marginTop: 15,
    display: "flex",
    alignItems: "center",
  },
  containerFieldPopover: {
    paddingLeft: 10,
  },
  fieldSubdistrict: {
    display: "block",
    paddingTop: 15,
  },
  containerPopoverCourier: {
    width: 370,
    padding: 15,
  },
  regulerCourier: {
    fontWeight: "bold",
  },
  dividerCourier: {
    margin: "10px 0",
  },
  courier: {
    display: "flex",
    justifyContent: "space-between",
  },
  gratisOngkir: {
    color: theme.palette.secondary.main,
  },
  minimalBuy: {
    fontWeight: "bold",
  },
}));

const CekOngkir = (props) => {
  const classes = useStyles();
  const { dataSupplier, supplier, match, weight_gram } = props;
  const isStoreFront = match.path.indexOf("/store/:reseller") !== -1;

  const [currentProvince, setCurrentProvince] = useState(null);
  const [currentCity, setCurrentCity] = useState(null);
  const [currentDistrict, setCurrentDistrict] = useState(null);
  const [currentSubdistrict, setCurrentSubdistrict] = useState(null);
  const [postalCode, setPostalCode] = useState("");
  
  const [listCity, setListCity] = useState(null);
  const [listDistrict, setListDistrict] = useState(null);
  const [listSubdistrict, setListSubdistrict] = useState(null);
  const [listOngkir, setListOngkir] = useState(null);
  const [minOngkir, setMinOngkir] = useState(0);
  const [anchorElSubDistrict, setAnchorElSubDistrict] = useState(null);
  const [anchorElCourier, setAnchorElCourier] = useState(null);
  const [listProvince, setListProvince] = useState([]);


  useEffect(() => {
    const fetch = async () => {
      setCurrentCity(dataSupplier.location.village.district.city);
      setCurrentSubdistrict(dataSupplier.location.village);
      const res = await getProvinces();
      setListProvince(res.data.sort((a, b) => a.name.localeCompare(b.name)));
    };
    fetch();
  }, []);


  const handleChangeProvince = async (event) => {
    const res = await getCitiesByProvinceId(event.id);
    setListCity(res.data.sort((a, b) => a.name.localeCompare(b.name)));
    setCurrentProvince(event);
  };

  const handleChangeCity = async (event) => {
    const res = await getDistrictsByCityId(event.id);
    setListDistrict(res.data.sort((a, b) => a.name.localeCompare(b.name)));
    setCurrentCity(event);
  };

  const handleChangeDistrict = async (event) => {
    const res = await getSubdistrictByDistrictId(event.id);
    setListSubdistrict(res.data.sort((a, b) => a.name.localeCompare(b.name)));
    setCurrentDistrict(event);
  };

  const handleChangeSubdistrict = (event) => {
    setCurrentSubdistrict(event);
    setPostalCode(event.kodepos);
  };


  useEffect(() => {
    const handleCheckShippingCost = async (
      supplierId,
      destinationPostalCode,
      destinationSubDistrictName,
      couriers,
      weight
    ) => {
      const formData = new FormData();
      formData.append("supplier_id", supplierId);
      formData.append("destination_postal_code", destinationPostalCode);
      formData.append(
        "destination_sub_district_name",
        destinationSubDistrictName
      );
      formData.append("shipment_type", "DROP");
      formData.append("package_type_id", "1");
      formData.append("weight", Math.ceil(weight / 1000));
      formData.append("is_cod", "0");
      for (let i = 0; i < couriers.length; i++) {
        formData.append("couriers[]", couriers[i]);
      }
      const response = await checkShippingCostV2(formData);
      return response;
    };


    const getListHarga = async () => {
      const resListOngkir = await handleCheckShippingCost(
        dataSupplier.id,
        postalCode ? postalCode : dataSupplier.location.village.kodepos,
        postalCode ? currentSubdistrict : dataSupplier.location.village.name,
        dataSupplier.supported_couriers,
        weight_gram
      );
 
      let selectedOngkir = null;
      for (const key in resListOngkir) {
        if (Object.hasOwnProperty.call(resListOngkir, key)) {
          if (resListOngkir[key] && resListOngkir[key].length > 0) {
            selectedOngkir = resListOngkir[key][0];
            setMinOngkir(selectedOngkir.price)
            setListOngkir(resListOngkir[key])
            console.log(resListOngkir[key])
            break;
          }
        }
      }
    };
    currentSubdistrict !== null && getListHarga();
  }, [currentSubdistrict]);


  const handleClickPopoverOngkir = (event) => {
    setAnchorElSubDistrict(event.currentTarget);
  };
  const handleClickPopoverCourier = (event) => {
    setAnchorElCourier(event.currentTarget);
  };

  const openOngkirTo = Boolean(anchorElSubDistrict);
  const openCourier = Boolean(anchorElCourier);
  const id = openOngkirTo ? "simple-popover" : undefined;
  const idCourier = openCourier ? "simple-popover" : undefined;

  const handleCloseSubDistrict = () => {
    setAnchorElSubDistrict(null);
  };
  const handleCloseCourier = () => {
    setAnchorElCourier(null);
  };

  return (
    <Fragment>
      <div style={{ margin: "10px 0" }}>
        <Typography className={classes.variant}>Cek Ongkos Kirim</Typography>
        <Typography className={classes.fromCity}>
          Dari Kota{" "}
          {isStoreFront
            ? supplier.location.address
            : dataSupplier.location.address}
        </Typography>
        <div style={{ display: "flex" }}>
          <div className={classes.toCenter}>
            <p className={classes.from}>Ke&nbsp;</p>
            <div
              className={classes.toCenter}
              style={{ cursor: "pointer" }}
              onClick={handleClickPopoverOngkir}
            >
              <Typography className={classes.bold}>
                {currentCity !== null && currentCity.name}
              </Typography>
              <ExpandMoreIcon style={{ height: 20 }} />
            </div>
          </div>
          <Popover
            id={id}
            open={openOngkirTo}
            anchorEl={anchorElSubDistrict}
            onClose={handleCloseSubDistrict}
            anchorOrigin={{ vartical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
          >
            <div className={classes.containerPopover}>
              <div>
                <Typography className={classes.subDistrict}>
                  Provinsi
                </Typography>
                <Typography className={classes.subDistrict}>
                  Kota/Kab
                </Typography>
                <Typography className={classes.codePos}>Kecamatan</Typography>
                <Typography className={classes.codePos}>Desa/Kelurahan</Typography>
              </div>
              <div className={classes.containerFieldPopover}>
                <FormControl variant="outlined" style={{ width: 200 }}>
                  <Select
                    id="demo-simple-select-outlined"
                    value={currentProvince !== null && currentProvince.name}
                  >
                    {listProvince !== null &&
                      listProvince.map((response) => {
                        return (
                          <MenuItem
                            value={response.name}
                            key={response.id}
                            onClick={() => handleChangeProvince(response)}
                          >
                             {response.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
                <FormControl
                  variant="outlined"
                  className={classes.fieldSubdistrict}
                >
                  <Select
                    id="demo-simple-select-outlined"
                    value={
                      currentCity !== null && currentCity.name
                    }
                    style={{ width: 200 }}
                  >
                    {listCity !== null &&
                      listCity.map((response) => {
                        return (
                          <MenuItem
                            value={response.name}
                            key={response.id}
                            onClick={() => handleChangeCity(response)}
                          >
                            {response.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
                <FormControl
                  variant="outlined"
                  className={classes.fieldSubdistrict}
                >
                  <Select
                    id="demo-simple-select-outlined"
                    value={
                      currentDistrict !== null && currentDistrict.name
                    }
                    style={{ width: 200 }}
                  >
                    {listDistrict !== null &&
                      listDistrict.map((response) => {
                        return (
                          <MenuItem
                            value={response.name}
                            key={response.id}
                            onClick={() => handleChangeDistrict(response)}
                          >
                            {response.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
                <FormControl
                  variant="outlined"
                  className={classes.fieldSubdistrict}
                >
                  <Select
                    id="demo-simple-select-outlined"
                    value={
                      currentSubdistrict !== null && currentSubdistrict.name
                    }
                    style={{ width: 200 }}
                  >
                    {listSubdistrict !== null &&
                      listSubdistrict.map((response) => {
                        return (
                          <MenuItem
                            value={response.name}
                            key={response.id}
                            onClick={() => handleChangeSubdistrict(response)}
                          >
                            {response.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </div>
            </div>
          </Popover>
          <div className={classes.toCenter}>
            <p className={classes.from} style={{ marginLeft: 30 }}>
              Ongkos Kirim&nbsp;
            </p>
            <div
              className={classes.toCenter}
              style={{ cursor: "pointer" }}
              onClick={handleClickPopoverCourier}
            >
              <Typography className={classes.bold}>
                Mulai dari Rp{formatRupiah(minOngkir)}
              </Typography>
              <ExpandMoreIcon style={{ height: 20 }} />
            </div>
          </div>
          <Popover
            id={idCourier}
            open={openCourier}
            anchorEl={anchorElCourier}
            onClose={handleCloseCourier}
            anchorOrigin={{ vartical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
          >
            <div className={classes.containerPopoverCourier}>
              <Grid container spacing={3} style={{ marginTop: 0 }}>
                {listOngkir &&
                  listOngkir.map((res, index) => {
                    return (
                      <Fragment key={index}>
                        <Grid item xs={12}>
                          <div className={classes.name}>
                            <Typography>
                              {res.logistic_name} ({res.rate_name})
                            </Typography>
                            <Typography>Rp{res.price}</Typography>
                          </div>
                          <div>
                            <Typography variant="caption">
                              Lama Pengiriman{" "}
                              {res.max_duration == 0
                                ? '"tidak diketahui"'
                                : res.min_duration + " - " + res.max_duration + " hari"}
                            </Typography>
                          </div>
                        </Grid>
                      </Fragment>
                    );
                  })}
              </Grid>
            </div>
          </Popover>
        </div>
      </div>
    </Fragment>
  );
};

export default CekOngkir;
