import React from 'react'
import CloseIcon from "@material-ui/icons/Close";
import './style.css'
import Slider from "react-slick";
import { getPopUp } from "../../services/axios";

const ModalPopUp = () => {
    const [open, setOpen] = React.useState(true);
    const [popUp, setPopUp] =  React.useState([]);

    React.useEffect(() => {
        async function fetch() {
          const allData = await getPopUp();
          setPopUp(allData.data);
        }
        fetch();
      }, []);

    const handleClose = () => {
        setOpen(false);
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 2000,
        autoplaySpeed: 15000,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1
      };

    return (
            <React.Fragment>
                <div className={open === true ? 'modalPopUp': 'modalPopUp_close'}>
                    <div className="modalPopUp_background">
                        <div className="modalPopUp_content">
                            <div className="modalPopUp_simpleBanner">
                                <Slider {...settings}>
                                    {
                                        popUp.map((res, index) => 
                                        <div key={index}>
                                            <a href={res.link}>
                                                <div
                                                    style={{
                                                        background: `url('${res.image}')`,
                                                        width: "100%",
                                                        height: "90vh",
                                                        maxHeight: '700px',
                                                        backgroundPosition: "center",
                                                        backgroundSize: "cover",
                                                    }}
                                                ></div>
                                            </a>
                                        </div>
                                        )
                                    }
                                </Slider>
                            </div>
                            <div className="modalPopUp_closeArea" onClick={handleClose}>
                                <div className="modalPopUp_closeBtn">
                                    <CloseIcon/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
    )
}

export default ModalPopUp