import React, { Fragment, useState, useEffect } from "react";
import Navbar from "../../../components/navbar";
import Footer from "../../../components/footer";
import {
  Container,
  Grid,
  Typography,
  Checkbox,
  Divider,
  Button,
} from "@material-ui/core";
import shopIcon from "../../../assets/icons/shop.svg";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import { numberSeparator } from "../../../utils/numberSeparator";
import { quantity, remove, update } from "cart-localstorage";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import { withRouter } from "react-router-dom";
import Checkout from "./components/Checkout";

const Desktop = withStyles(styles)((props) => {
  const { classes, history } = props;
  const AsCustomer = false;
  const listSupplier = JSON.parse(localStorage.getItem("LIST_SUPPLIER"));
  const [cart, setCart] = useState(JSON.parse(localStorage.getItem("__cart")));
  const [selectedCart, setSelectedCart] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [listPrice, setListPrice] = useState([]);
  const [listCustomer, setListCustomer] = useState([]);
  const [listSelectedVendor, setListSelectedVendor] = useState([]);

  useEffect(() => {
    // Get List Customer
    let x = [];
    let y;
    let selesai = 0;
    if (cart) {
      cart.map((res) => {
        if (res.customer.name !== x[x.indexOf(res.customer.name)]) {
          selesai = selesai + 1;
          y = res.customer.name;
          x = [...x, res.customer.name];
          if (selesai === cart.length) setListCustomer(x);
        } else if (x.length !== 0) {
          selesai = selesai + 1;
          x = [...x];
          if (selesai === cart.length) setListCustomer(x);
        } else {
          selesai = selesai + 1;
          x = [res.customer.name];
          if (selesai === cart.length) setListCustomer(x);
        }
      });
    }
  }, []);

  const getListPrice = async () => {
    let list = [];
    await selectedCart.forEach((res) => {
      list = [...list, res.price * res.quantity];
      if (
        listSelectedVendor.findIndex((value) => value === res.supplier) === -1
      ) {
        setListSelectedVendor([res.supplier]);
      } else {
        setListSelectedVendor([...listSelectedVendor, res.supplier]);
      }
    });
    setListPrice(list);
  };

  useEffect(() => {
    getListPrice();
  }, [selectedCart]);

  useEffect(() => {
    const updateCart = async () => {
      const getCart = JSON.parse(localStorage.getItem("__cart"));
      await setCart(getCart);
    };
    updateCart();
    getListPrice();
  }, [isUpdated]);

  const handleRemoveItem = async (id, supplier_name, data, customer) => {
    await remove(id);
    const getCart = JSON.parse(localStorage.getItem("__cart"));
    const getCustomer = JSON.parse(localStorage.getItem("LIST_CUSTOMER"));
    let list = [...listSupplier];
    const index = listSupplier.indexOf(supplier_name);

    // fungsi untuk menghapus barang yang sudah di checklist
    if (selectedCart.length !== 0) {
      setSelectedCart(selectedCart.filter((value) => value.id !== id));
    }

    if (
      data.supplier === supplier_name &&
      getCart.filter((item) => item.supplier === supplier_name).length !== 0
    ) {
      return setIsUpdated(!isUpdated);
    } else {
      if (index > -1) {
        list.splice(index, 1);
      }
      localStorage.setItem("LIST_SUPPLIER", JSON.stringify(list));
    }
    if (getCart.length === 0)
      localStorage.setItem("LIST_SUPPLIER", JSON.stringify([]));

    if (
      getCart.filter((value) => value.customer.name === customer).length === 0
    ) {
      localStorage.setItem(
        "LIST_CUSTOMER",
        JSON.stringify(getCustomer.filter((value) => value.name !== customer))
      );
    }
    setIsUpdated(!isUpdated);
  };

  const handlePlus = async (id) => {
    await quantity(id, 1);
    if (selectedCart.length !== 0) {
      setSelectedCart([
        ...selectedCart.filter((value) => value.id !== id),
        {
          ...selectedCart.filter((value) => value.id === id)[0],
          quantity:
            selectedCart.filter((value) => value.id === id)[0].quantity + 1,
        },
      ]);
    }
    setIsUpdated(!isUpdated);
  };

  const handleMin = async (id, supplier_name, data) => {
    await quantity(id, -1);
    if (selectedCart.length !== 0) {
      setSelectedCart([
        ...selectedCart.filter((value) => value.id !== id),
        {
          ...selectedCart.filter((value) => value.id === id)[0],
          quantity:
            selectedCart.filter((value) => value.id === id)[0].quantity - 1,
        },
      ]);
    }
    const getCart = JSON.parse(localStorage.getItem("__cart"));
    let list = [...listSupplier];
    const index = list.indexOf(supplier_name);
    if (
      data.supplier === supplier_name &&
      getCart.filter((item) => item.supplier === supplier_name).length !== 0
    ) {
      return setIsUpdated(!isUpdated);
    } else {
      if (index > -1) {
        list.splice(index, 1);
      }
      localStorage.setItem("LIST_SUPPLIER", JSON.stringify(list));
    }
    if (getCart.length === 0)
      localStorage.setItem("LIST_SUPPLIER", JSON.stringify([]));
    setIsUpdated(!isUpdated);
  };

  const handleInput = async (id, value, supplier_name, data) => {
    await update(id, 'quantity', value);
    const getCart = JSON.parse(localStorage.getItem("__cart"));
    let list = [...listSupplier];
    const index = list.indexOf(supplier_name);
    if (
      data.supplier === supplier_name &&
      getCart.filter((item) => item.supplier === supplier_name).length !== 0
    ) {
      return setIsUpdated(!isUpdated);
    } else {
      if (index > -1) {
        list.splice(index, 1);
      }
      localStorage.setItem("LIST_SUPPLIER", JSON.stringify(list));
    }

    if (getCart.length === 0)
      localStorage.setItem("LIST_SUPPLIER", JSON.stringify([]));
    setIsUpdated(!isUpdated);
  };

  const handlePilihSemua = (customer) => {
    if (
      cart.filter((value) => value.customer.name === customer).length ===
      selectedCart.filter((value) => value.customer.name === customer).length
    ) {
      setSelectedCart(
        selectedCart.filter((value) => value.customer.name !== customer)
      );
    } else {
      setSelectedCart(
        selectedCart
          .filter((value) => value.customer.name !== customer)
          .concat(cart.filter((value) => value.customer.name === customer))
      );
    }
  };

  const handleSelectedCartByVendor = (supplier_name, customer) => {
    if (
      cart
        .filter((value) => value.customer.name === customer)
        .filter((item) => item.supplier === supplier_name).length ===
      selectedCart
        .filter((value) => value.customer.name === customer)
        .filter((item) => item.supplier === supplier_name).length
    ) {
      setSelectedCart(
        selectedCart
          .filter((value) => value.customer.name === customer)
          .filter((item) => item.supplier !== supplier_name)
      );
    } else {
      setSelectedCart(
        selectedCart.concat(
          cart
            .filter((value) => value.customer.name === customer)
            .filter((item) => item.supplier === supplier_name)
        )
      );
    }
  };

  const handleSelectedCart = async (data) => {
    const index = selectedCart.findIndex((value) => value.id === data.id);
    const currentCart = selectedCart.filter((item) => item.id === data.id)[0];
    if (Boolean(currentCart) === Boolean(data)) {
      if (index > -1 && index !== 0) {
        setSelectedCart(selectedCart.filter((item) => item.id !== data.id));
      } else if (index === 0 && selectedCart.length === 1) {
        setSelectedCart([]);
      } else {
        setSelectedCart(selectedCart.filter((item) => item.id !== data.id));
      }
    } else {
      setSelectedCart([...selectedCart, data]);
    }
  };

  const filteredSupplier = (data) => {
    let returnData = [];
    data.map((res) => {
      if (returnData[returnData.indexOf(res.supplier)] !== res.supplier) {
        returnData = [...returnData, res.supplier];
      }
    });
    return returnData;
  };

  return (
    <Fragment>
      <Navbar variant="primary" />
      <Container maxWidth="md" className={classes.container}>
        {listSupplier === null || listSupplier.length === 0 ? (
          <Fragment>
            <div className={classes.center}>
              <div style={{ textAlign: "center", height: "18vh" }}>
                <ShoppingBasketIcon className={classes.iconCart} />
                <Typography>Tidak ada produk dikeranjang.</Typography>
                {!AsCustomer && (
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ marginTop: 20 }}
                    onClick={() => history.push("/")}
                  >
                    Belanja sekarang
                  </Button>
                )}
              </div>
            </div>
          </Fragment>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={8}>
              {/* Nama Customer */}
              {listCustomer.map((customer, index) => {
                return (
                  <Fragment>
                    <Typography
                      color="primary"
                      style={{ marginBottom: 10, marginTop: index !== 0 && 20 }}
                    >
                      <b>Atas Nama {customer}</b>
                    </Typography>
                    <div className={classes.cardBackground}>
                      <div className={classes.flexCenter}>
                        <Checkbox
                          color="primary"
                          checked={
                            cart.filter(
                              (value) => value.customer.name === customer
                            ).length ===
                            selectedCart.filter(
                              (value) => value.customer.name === customer
                            ).length
                          }
                          onClick={() => handlePilihSemua(customer)}
                        />
                        <Typography>
                          <b>Pilih Semua</b>
                        </Typography>
                        <Typography
                          variant="caption"
                          style={{ paddingLeft: 10 }}
                        >
                          {selectedCart.filter(
                            (value) => value.customer.name === customer
                          ).length !== 0 &&
                            `(${
                              selectedCart.filter(
                                (value) => value.customer.name === customer
                              ).length
                            } barang terpilih)`}
                        </Typography>
                      </div>
                      <Divider />
                      {/* Nama Supplier */}
                      {filteredSupplier(
                        cart.filter((value) => value.customer.name === customer)
                      ).map((supplier_name, index) => (
                        <Fragment>
                          <div className={classes.flexCenter}>
                            <Checkbox
                              color="primary"
                              checked={
                                cart
                                  .filter(
                                    (value) => value.customer.name === customer
                                  )
                                  .filter(
                                    (item) => item.supplier === supplier_name
                                  ).length ===
                                selectedCart
                                  .filter(
                                    (value) => value.customer.name === customer
                                  )
                                  .filter(
                                    (item) => item.supplier === supplier_name
                                  ).length
                              }
                              onClick={() =>
                                handleSelectedCartByVendor(
                                  supplier_name,
                                  customer
                                )
                              }
                            />
                            <img src={shopIcon} alt="" />
                            <Typography style={{ paddingLeft: 10 }}>
                              {supplier_name}
                            </Typography>
                          </div>
                          {/* Nama Barang/Produk */}
                          {cart
                            .filter((value) => value.customer.name === customer)
                            .filter((item) => item.supplier === supplier_name)
                            .map((res) => (
                              <Fragment>
                                <div style={{ display: "flex" }}>
                                  <div>
                                    <Checkbox
                                      onClick={() => handleSelectedCart(res)}
                                      color="primary"
                                      checked={Boolean(
                                        selectedCart[
                                          selectedCart.findIndex(
                                            (value) => value.id === res.id
                                          )
                                        ]
                                      )}
                                    />
                                  </div>
                                  <img
                                    src={res.img}
                                    alt={res.name}
                                    className={classes.productImage}
                                  />
                                  <div className={classes.contentWrapper}>
                                    <Typography
                                      className={classes.productName}
                                      onClick={() =>
                                        history.push(`/product/${res.slug}`)
                                      }
                                    >
                                      <b>{res.name}</b>
                                    </Typography>
                                    <div className={classes.contentText2}>
                                      <div>
                                        <Typography variant="caption">
                                          <b>Variasi : </b>
                                        </Typography>
                                        <Typography variant="caption">
                                          {res.size}, {res.varian}
                                        </Typography>
                                      </div>
                                      <Typography
                                        variant="caption"
                                        color="primary"
                                      >
                                        @RP {numberSeparator(res.price)}
                                      </Typography>
                                    </div>
                                    <div className={classes.actionWrapper}>
                                      <div className={classes.groupedButton}>
                                        <Button
                                          className={classes.button}
                                          onClick={() =>
                                            handleMin(
                                              res.id,
                                              supplier_name,
                                              res
                                            )
                                          }
                                        >
                                          -
                                        </Button>
                                        <input
                                          type="number"
                                          className={classes.styledInput}
                                          value={res.quantity}
                                          onChange={(e) =>
                                            handleInput(
                                              res.id,
                                              e.target.value,
                                              supplier_name,
                                              res
                                            )
                                          }
                                          onKeyDown={(event) =>
                                            event.key === "ArrowUp" ? handlePlus(
                                              res.id,
                                              supplier_name,
                                              res
                                            ) : event.key === "ArrowDown" && handleMin(
                                              res.id,
                                              supplier_name,
                                              res
                                            )}
                                        />
                                        <Button
                                          className={classes.button}
                                          onClick={() =>
                                            handlePlus(
                                              res.id,
                                              supplier_name,
                                              res
                                            )
                                          }
                                        >
                                          +
                                        </Button>
                                      </div>

                                      <Typography>
                                        <b style={{ fontSize: 25 }}>
                                          Rp
                                          {numberSeparator(
                                            res.price * res.quantity
                                          )}
                                        </b>
                                      </Typography>
                                    </div>
                                    <Typography
                                      className={classes.removeItem}
                                      onClick={() =>
                                        handleRemoveItem(
                                          res.id,
                                          supplier_name,
                                          res,
                                          customer
                                        )
                                      }
                                    >
                                      Hapus barang
                                    </Typography>
                                  </div>
                                </div>
                              </Fragment>
                            ))}

                          {index !== listSupplier.length - 1 && <Divider />}
                          {/* <div style={{display: 'flex', paddingTop: 10}}>
                          <img src={discountIcon} alt="" />
                          <Typography style={{paddingLeft: 10}}>
                            Potongan pengiriman sampai{" "}
                            <span style={{ color: "#FF6564" }}>Rp10.000</span> dengan min.
                            pembelian <b>Rp50,000; </b>
                            <span style={{ color: "#FF6564" }}>Gratis ongkir</span> dengan
                            min. pembelian <b>Rp200.000</b>
                          </Typography>
                          </div> */}
                        </Fragment>
                      ))}
                    </div>
                  </Fragment>
                );
              })}
            </Grid>
            <Grid item xs={4} className={classes.flexEnd}>
              <Checkout
                listPrice={listPrice}
                selectedCart={selectedCart}
                setSelectedCart={setSelectedCart}
                listSelectedVendor={listSelectedVendor}
                listCustomer={listCustomer}
              />
            </Grid>
          </Grid>
        )}
      </Container>
      <Footer />
    </Fragment>
  );
});

export default withRouter(Desktop);
