import styled from 'styled-components'

export const WrapTitle = styled.div`
  position: relative;
  font-size: 16px;
  font-family: 'DMSans-Regular';
  color: #FBBA14;
  padding-left: 16px;
  margin-bottom: 16px;
  .bold {
    font-weight: bold;
  }
`