import React, { Fragment, useState, useEffect } from "react";
import Navbar from "../../../../components/navbar";
import {
  Grid,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { style, VoucherWrap } from "./style";
import {
  getSupplier,
  getProductData,
  getSupplierCategories,
  followSupplier,
  unfollowSupplier,
} from "../../../../services/axios";
import { GET_ALL_VOUCHERS } from "../../../../services/voucher.service";
import Skeleton from "@material-ui/lab/Skeleton";
import swal from "@sweetalert/with-react";
import whatsappIcon from "../../../../assets/icons/dashicons_whatsapp.svg";
import iconKupon from '../../../../assets/icons/menu/icon_kupon.svg'
import { generateDate } from "../../../../utils/generateDate";
import Pagination from "@material-ui/lab/Pagination";
import Footer from "../../../../components/footer";
import CardProduct from "../../../../components/CardProduct";
import PopupDeeplink from "../../../../components/Mobile/DeeplinkPopup";
import Carousel from "../../../../components/carousel";


const Mobile = withStyles(style)((props) => {
  const { classes } = props;
  const getProps = props.getProps;
  const [dataSupplier, setDataSupplier] = useState(null);
  const [listProduct, setListProduct] = useState(null);
  const [listCategories, setListCategories] = useState(null);
  const [isFollowed, setIsFollowed] = React.useState(null);
  const [setIsUnfollowed] = React.useState(null);
  const [currentCategories, setCurrentCategories] = React.useState(null);
  const [pengikut, setPengikut] = React.useState(0);
  const [vouchers, setVouchers] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      const response = await getSupplier(getProps.match.params.supplier);
      const getAllProduct = await getProductData(
        `?filter[supplier.slug]=${response.data.slug}`
      );
      const getCategories = await getSupplierCategories(response.data.slug);
      setDataSupplier(response.data);
      setPengikut(response.data.stats.followers_count);
      setListProduct(getAllProduct);
      setListCategories(getCategories);
      setIsFollowed(response.data.followed);
      console.log("data supplier => ", response.data);
      console.log("data product => ", getAllProduct);

      if(response.data.slug) {
        const resVoucher = await GET_ALL_VOUCHERS({
          "filter[supplier]" : response.data.slug,
          "paginate": 20,
          "include": "supplier"
        });
        if (resVoucher.data) {
          setVouchers(resVoucher.data);
        }
      }
    };
    fetch();
  }, []);

  const handleFollow = () => {
    followSupplier(dataSupplier.slug);
    setIsFollowed(true);
    setIsUnfollowed(false);
    setPengikut(pengikut + 1);
    swal("Success!", "Supplier difollow!", "success");
  };

  const handleUnfollow = () => {
    unfollowSupplier(dataSupplier.slug);
    setIsFollowed(false);
    setIsUnfollowed(true);
    setPengikut(pengikut - 1);
    swal("Success!", "Supplier diunfollow!", "success");
  };

  const handleChangeCategories = async (event) => {
    const getAllProductWithCategory = await getProductData(
      `?filter[supplier.slug]=${dataSupplier.slug}&filter[category.id]=${event.target.value.id}`
    );
    const getAllProduct = await getProductData(
      `?filter[supplier.slug]=${dataSupplier.slug}`
    );
    setCurrentCategories(event.target.value);
    if (event.target.value === "Semua Produk") {
      setListProduct(getAllProduct);
    } else {
      setListProduct(getAllProductWithCategory);
    }
  };

  const handleChangePage = async (page) => {
    const response = await getProductData(
      `?filter[supplier.slug]=${dataSupplier.slug}&page=${page}`
    );
    setListProduct(response);
  };

  return (
    <Fragment>
      <PopupDeeplink url={window.location.pathname}/>
      <Navbar variant="primary" />

      <Grid item xs={12} style={{ padding: 16 }}>
        <div className={classes.card}>
          {dataSupplier === null ? (
            <Skeleton width={75} height={75} variant="circle" />
          ) : (
            <img
              src={dataSupplier.avatar_url}
              alt={`profile of ${dataSupplier.name}`}
              className={classes.photoProfile}
            />
          )}
          <div style={{ margin: "auto 0 auto 20px", alignItems: "center" }}>
            <div>
              {dataSupplier === null ? (
                <Skeleton variant="text" width={88} height={24} />
              ) : (
                <Typography>
                  <b>{dataSupplier.name}</b>
                </Typography>
              )}
              {dataSupplier === null ? (
                <Skeleton variant="text" width={89} height={24} />
              ) : (
                <Typography variant="caption">
                  {dataSupplier.location.address} - {dataSupplier.location.postal_code}
                </Typography>
              )}
            </div>
            <div style={{ marginTop: 10, display: "flex" }}>
              {dataSupplier === null ? (
                <Skeleton variant="rect" width={64} height={36} />
              ) : isFollowed === true && isFollowed !== null ? (
                <Button
                  variant="contained"
                  style={{ color: "#a7a7a7", fontSize: 10 }}
                  onClick={handleUnfollow}
                >
                  Batal Ikuti
                </Button>
              ) : (
                <Button
                  onClick={handleFollow}
                  style={{ fontSize: 10 }}
                  variant="contained"
                  color="primary"
                >
                  Ikuti
                </Button>
              )}

              {dataSupplier === null ? (
                <Skeleton
                  variant="rect"
                  style={{ marginLeft: 8 }}
                  width={143}
                  height={36}
                />
              ) : (
                <Button
                  variant="outlined"
                  onClick={() =>
                    window.open(
                      `http://api.idejualan.com/whatsapp?text=Hai,%20ini%20idejualan`,
                      "_blank"
                    )
                  }
                  color="primary"
                  style={{ marginLeft: 8 }}
                  endIcon={
                    <img
                      src={whatsappIcon}
                      height={32}
                      width={32}
                      alt={"icon"}
                    />
                  }
                >
                  <Typography style={{ fontSize: 10 }}>Chat via WA</Typography>
                </Button>
              )}
            </div>
          </div>
        </div>
      </Grid>

      <Grid container style={{ margin: "20px 0" }}>
        <Grid
          item
          xs={4}
          style={{ textAlign: "center", borderRight: "1px solid #999999" }}
        >
          <Typography style={{ fontWeight: "bold" }} color="primary">
            {dataSupplier !== null && dataSupplier.stats.products_count}
          </Typography>
          <Typography style={{ fontWeight: "bold" }}>Produk</Typography>
        </Grid>
        <Grid
          item
          xs={4}
          style={{ textAlign: "center", borderRight: "1px solid #999999" }}
        >
          <Typography style={{ fontWeight: "bold" }} color="primary">
            {pengikut}
          </Typography>
          <Typography style={{ fontWeight: "bold" }}>Pengikut</Typography>
        </Grid>
        <Grid item xs={4} style={{ textAlign: "center" }}>
          <Typography style={{ fontWeight: "bold" }} color="primary">
            {dataSupplier !== null && generateDate(dataSupplier.joined_at)}
          </Typography>
          <Typography style={{ fontWeight: "bold" }}>Bergabung</Typography>
        </Grid>
      </Grid>

      <Grid item xs={12} style={{ padding: 16 }}>
        <div className={classes.card} style={{ display: "block" }}>
          {dataSupplier === null ? (
            <Fragment>
              <Skeleton variant="text" width={100} />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </Fragment>
          ) : (
            <Fragment>
              <Typography>
                <b>Deskripsi Toko</b>
              </Typography>
              <Typography>
                <div
                  dangerouslySetInnerHTML={{
                    __html: dataSupplier.description,
                  }}
                />
              </Typography>
            </Fragment>
          )}
        </div>
      </Grid>
      
      {vouchers && (
        <Grid item xs={12}  style={{ padding: 16 }}>
          <div className={classes.card} style={{ display: "block" }}>
            <VoucherWrap>
                <div className={classes.vouchers}>
                <div style={{ margin: "10px 0" }}>
                  <div style={{ display: 'flex', marginBottom: 5, alignItems:'center', justifyContent: 'start',}} >
                    <img style={{ width: 25, marginLeft: 3}} src={iconKupon} alt='icon kupon'/>
                    <div style={{ fontFamily: 'DMSans-Regular', color: '#FBBA14', marginLeft: 3, fontSize: 16 }}><span style={{ fontWeight: 'bold' }}>KUPON </span><span>Di Toko ini</span></div>
                  </div>
                  <Grid
                    container
                    spacing={5}
                    style={{ padding: 20 }}
                >
                <Carousel data={vouchers} variant="voucher"  />
              </Grid>
              </div>
            </div>
          </VoucherWrap>
          </div>
        </Grid>
        )}


      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Kategori</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={currentCategories}
          onChange={handleChangeCategories}
          label="Kategori"
        >
          <MenuItem key={0} value="Semua Produk">
            Semua Produk
          </MenuItem>
          {listCategories !== null &&
            listCategories.data.map((res, index) => (
              <MenuItem key={index + 1} value={res}>
                {res.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <Grid container spacing={2} style={{ padding: 16 }}>
        {listProduct !== null &&
          listProduct.data.map((res, index) => (
            <Grid item xs={6} key={index}>
              <CardProduct data={res} variant="reseller" />
            </Grid>
          ))}
      </Grid>

      <div className={classes.pagination}>
        {listProduct !== null && (
          <Pagination
            count={listProduct.meta.last_page}
            siblingCount={listProduct.meta.current_page}
            variant="outlined"
            shape="rounded"
            color="primary"
            onChange={(event, index) => handleChangePage(index)}
          />
        )}
      </div>

      <Footer />
    </Fragment>
  );
});

export default Mobile;
