import styled from 'styled-components'

export const WrapTabsPanel = styled.div`
  position: relative;
  .MuiPaper-elevation4 {
    box-shadow: none;
  }
  .MuiAppBar-colorDefault {
    background-color: #FFF;
  }
`