import { Grid, useMediaQuery } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Label, TabsPanel, TitlePage, TransactionList } from "../../components";
import Navbar from "../../components/navbar";
import { GET_WALLET_TRANSACTION } from "../../services/wallet.service";
import Loading from "../../components/loading";
import { isEmpty } from "lodash";

const HistoryPage = (props) => {
  const isMobile = useMediaQuery("(max-width:500px)");
  const [selectedTab, setSelectedTab] = useState(0);
  const [dataTransaction, setDataTransaction] = useState({
    all: [],
    in: [],
    out: [],
  });
  const [isLoading, setIsLoading] = useState(true);

  const _renderAllTransaction = (type) => {
    let item = [];

    if (type === "all") {
      if (isEmpty(dataTransaction.all)) {
        item.push(
          <div style={{ textAlign: "center", marginTop: 48 }}>
            Riwayat transaksi tidak ada
          </div>
        );
      } else {
        dataTransaction.all.map((transaction) => {
          item.push(
            <TransactionList
              title={transaction.note}
              date={transaction.created_at}
              price={transaction.amount}
              type={transaction.status}
            />
          );
          return "";
        });
      }
    } else if (type === "in") {
      if (isEmpty(dataTransaction.in)) {
        item.push(
          <div style={{ textAlign: "center", marginTop: 48 }}>
            Riwayat transaksi tidak ada
          </div>
        );
      } else {
        dataTransaction.in.map((transaction) => {
          item.push(
            <TransactionList
              title={transaction.note}
              date={transaction.created_at}
              price={transaction.amount}
              type={transaction.status}
            />
          );
          return "";
        });
      }
    } else {
      if (isEmpty(dataTransaction.out)) {
        item.push(
          <div style={{ textAlign: "center", marginTop: 48 }}>
            Riwayat transaksi tidak ada
          </div>
        );
      } else {
        dataTransaction.out.map((transaction) => {
          item.push(
            <TransactionList
              title={transaction.note}
              date={transaction.created_at}
              price={transaction.amount}
              type={transaction.status}
            />
          );
          return "";
        });
      }
    }

    return item;
  };

  const tabs = [
    {
      key: 0,
      label: <Label countNotif={0}>Semua</Label>,
      content: _renderAllTransaction("all"),
    },
    {
      key: 1,
      label: <Label countNotif={0}>Masuk</Label>,
      content: _renderAllTransaction("in"),
    },
    {
      key: 2,
      label: <Label countNotif={0}>Keluar</Label>,
      content: _renderAllTransaction("out"),
    },
  ];

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    const fetchTransaction = async () => {
      const walletTransaction = await GET_WALLET_TRANSACTION();
      const walletTransactionIn = await GET_WALLET_TRANSACTION(
        "?filter[status]=1"
      );
      const walletTransactionOut = await GET_WALLET_TRANSACTION(
        "?filter[status]=0"
      );
      setDataTransaction({
        ...dataTransaction,
        all: walletTransaction.data,
        in: walletTransactionIn.data,
        out: walletTransactionOut.data,
      });
      setIsLoading(false);
    };
    fetchTransaction();
  }, []);

  return (
    <div style={!isMobile ? {maxWidth: 960, padding: 24, position: 'absolute', margin: '0 auto', left: 0, right:0} : {}}>
      <Navbar variant={"primary"} />
      <Grid item xs={12} style={{ padding: 16, background: "#FFF" }}>
        <TitlePage onBack={() => props.history.goBack()}>
          Riwayat Transaksi
        </TitlePage>
      </Grid>
      {!isLoading ? (
        <Grid item xs={12} style={{ padding: 16, background: "#FFF" }}>
          <TabsPanel
            tabs={tabs}
            value={selectedTab}
            onChange={handleChangeTab}
          />
        </Grid>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default HistoryPage;
