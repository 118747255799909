const styles = (theme) => ({
    container: {
      paddingTop: 10,
      paddingBottom: 60,
    },
    containerMyVoucher: {
      cursor: 'pointer',
      display: 'flex', 
      flexWrap: 'wrap', 
      justifyContent: 'center',
    },
    
  });
  
  export default styles;