export const formatRupiah = (n, sep) => {
  function reverse(text) {
    return text.split("").reverse().join("");
  }
  var rx = /(\d{3}(?!.*\.|$))/g;
  if (!sep) {
    sep = ".";
  }
  return reverse(reverse(n.toString()).replace(rx, "$1" + sep));
};

export const inputRupiah = (value) => {
  return value.replace(/\./g, "")
}
