import { axiosInstance } from "../config/fetchAxios";

export const GET_USER_BANK = async () => {
  const url = `/api/v2/banks`;
  const data = axiosInstance.get(url);
  return data;
};

export const GET_SUPPORTED_BANK = async () => {
  const url = `/api/v2/supported_banks`;
  const data = axiosInstance.get(url);
  return data;
};

export const POST_BANK = async (body) => {
  const url = `/api/v2/banks`;
  const data = axiosInstance.post(url, body);
  return data;
};

export const GET_VENDOR_BANKS = async () => {
  const res = axiosInstance.get("/api/v2/vendor-banks");
  return res;
};
