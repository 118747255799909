const style = (theme) => ({
  container: {
    paddingTop: 40,
    paddingBottom: 80,
  },
  loading: {
    height: 400,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default style;
