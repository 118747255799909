import { withStyles } from "@material-ui/core";
import { onChange } from "cart-localstorage";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getCategories, getProductData } from "../../../services/axios";
import HomePageMobileComponent from "./HomePageMobile.component";

const style = (theme) => ({
  container: {
    paddingTop: 30,
  },
  cardbox: {
    background: "#ffffff",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.16)",
    borderRadius: 16,
    padding: "30px 0",
    textAlign: "center",
    margin: "0 5px",
    cursor: "pointer",
    transition: "0.5s",
    height: "100%",
    "&:hover": {
      transform: "scale(1.1)",
      transition: "0.5s",
    },
  },
  categoryTitle: {
    fontWeight: "bold",
    paddingBottom: 20,
    paddingLeft: 20,
  },
  arrowBefore: {
    position: "absolute",
  },
  arrowNext: {
    position: "absolute",
    marginRight: 16,
    right: 0,
  },
  cardimg: {
    height: "50px !important",
    width: "50px !important",
    margin: "auto",
  },
  titleItem: {
    paddingTop: 20,
    fontWeight: "bold",
    fontSize: "0.9rem",
  },
  cardcontainer: {
    padding: "10px 0",
    outline: "none",
    height: 172,
  },
  textBannerMiddle: {
    fontWeight: "bold",
    paddingBottom: 20,
  },
  imgBannerMiddle: {
    width: "100%",
  },
  // Testimoials
  testiWrapper: {
    paddingBottom: 30,
    paddingTop: 20,
  },
  headerTestimonials: {
    fontWeight: "bold",
    paddingBottom: 20,
  },
  bgTestimonial: {
    background: "#ffeac2",
    height: 150,
    width: "70%",
    zIndex: -1,
    borderRadius: "0px 8px 8px 0",
  },
  imgTestimonial: {
    backgroundSize: "cover !important",
    height: 150,
    width: "70%",
    marginTop: -120,
    marginLeft: 50,
    borderRadius: 8,
  },
  quote: {
    height: "30px !important",
    marginTop: 30,
    marginLeft: "-45%",
  },
  nameTestimonial: {
    textAlign: "center",
  },
  containerKategoriPopuler: {
    marginBottom: 50,
    padding: 0,
  },
  kategoriPopuler: {
    fontWeight: "bold",
    padding: "20px 0",
  },
  cardWrapper: {
    margin: "0 10px",
  },
  cardKategoriPopuler: {
    padding: 15,
    width: "100%",
    textAlign: "center",
    height: 140,
  },
  containerImgKategoriPopuler: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  textTitle: {
    fontWeight: "bold",
    paddingLeft: 16,
    paddingTop: 20,
    paddingBottom: 10,
  },
  imgKategoriPopuler: {
    height: "60px !important",
  },
  textItemKategoriPopuler: {
    paddingTop: 10,
    fontSize: "0.8rem",
  },
  buttonKategoriPopuler: {
    padding: 0,
    textTransform: "none",
    width: "90%",
  },
  titleCarousel: {
    display: "flex",
    alignItems: "center",
    paddingBottom: 10,
  },
  textTitleCarousel: {
    fontWeight: "bold",
    fontFamily: 'DMSans-Regular', 
    color: '#FBBA14', 
    marginLeft: 3, 
    fontSize: 16
  },
  icon: {
    width: 30,
    marginLeft: 3
  },
});

const HomePageMobileContainer = withStyles(style)((props) => {
  const history = useHistory();
  const [loading, setLoading] = React.useState(true);
  const [allCategories, setAllCategories] = React.useState([])
  const [sliderProduct, setSliderProduct] = React.useState(null);
  const [filters, setFilters] = useState({
    keyword: "",
  });
  const customeSlider = React.createRef();

  const onChangeSearch = (e) => {
    setFilters({
      ...filters,
      keyword: e.target.value,
    });
  };

  const onSearch = () => {
    history.push("/search/" + filters.keyword);
  };

  useEffect(() => {
    if (sliderProduct !== null) {
      setLoading(false);
    }
  }, [sliderProduct]);

  useEffect(() => {
    const getData = async () => {
      const getCategorie = await getCategories();
      setAllCategories(getCategorie);
      const category = getCategorie.data[0].children.slice(0, 1);
      const response1 = await getProductData(
        `?filter[category.id]=${category[0].id}`
      );

      let data = [];
      category.forEach((res, index) => {
        data = [
          ...data.filter((value) => value.id !== category[index].id),
          {
            ...category[index],
            products:
              response1.data
          },
        ];
        setSliderProduct(data);
      });
    };
    getData();
  }, []);

  return (
    <HomePageMobileComponent
      history={history}
      loading={loading}
      sliderProduct={sliderProduct}
      setSliderProduct={setSliderProduct}
      allCategories={allCategories}
      setAllCategories={setAllCategories}
      customeSlider={customeSlider}
      onChangeSearch={onChangeSearch}
      onSearch={onSearch}
      {...props}
    />
  );
});

export default HomePageMobileContainer;
