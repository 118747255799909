import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import swal from "@sweetalert/with-react";

import {
  Typography,
  Button,
  Divider,
  FormControl,
  Select,
  MenuItem,
  InputBase,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";

import { numberSeparator } from "../../../../utils/numberSeparator";

import ChangeAddress from "./ChangeAddress";

const useStyles = makeStyles((theme) => ({
  cardBackground: {
    background: "#F5F5F5",
    borderRadius: 8,
    padding: 10,
    width: "100%",
  },
  flexCenterBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flexBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  ubahAlamat: {
    borderBottomStyle: "dotted",
    borderWidth: 1,
    cursor: "pointer",
    display: "flex",
    width: "fit-content",
    paddingTop: 20,
    marginBottom: 30,
  },
}));

const BootstrapInput = withStyles((theme) => ({
  input: {
    padding: "10px 14px",
    border: "1px solid #ced4da",
    borderRadius: 4,
  },
}))(InputBase);

const Checkout = (props) => {
  const {
    listPrice,
    selectedCart,
    setSelectedCart,
    listSelectedVendor,
    history,
  } = props;
  const classes = useStyles();
  const AsCustomer = localStorage.getItem("TOKEN") === null;
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [openChangeAddress, setOpenChangeAddress] = useState(false);
  const [listCustomer, setListCustomer] = useState([]);
  const [update, setUpdate] = useState(false);

  const handleClose = () => {
    setOpenChangeAddress(false);
  };

  useEffect(() => {
    setUpdate(!update);
  }, [listPrice]);

  useEffect(() => {
    const getCart = JSON.parse(localStorage.getItem("__cart"));
    if (!getCart) return;
    let customer = [];
    const getCustomer = async () => {
      await getCart.forEach((res) => {
        if (
          customer.findIndex((value) => value.id === res.customer.id) === -1
        ) {
          customer = [...customer, res.customer];
        } else {
          customer = [...customer];
        }
      });
      setListCustomer(customer);

      if(selectedCart.length === 0){
        setCurrentCustomer(customer[0]);
      } else {
        setCurrentCustomer(selectedCart[0].customer)
      }
    };
    getCustomer();
  }, [update]);

  const filteredPrice = (data) => {
    let returnData = [];
    data.map((res) => {
      returnData = [...returnData, res.price * res.quantity];
    });
    return returnData;
  };

  const filteredSupplier = (data) => {
    let returnData = [];
    data.map((res) => {
      if (returnData[returnData.indexOf(res.supplier)] !== res.supplier) {
        returnData = [...returnData, res.supplier];
      }
    });
    return returnData;
  };

  const handleCheckout = () => {
    const getDataCheckout = JSON.parse(localStorage.getItem("SELECTED_CART"));
    if (selectedCart.length !== 0) {
      if (getDataCheckout !== null) {
        localStorage.setItem(
          "SELECTED_CART",
          JSON.stringify(getDataCheckout.concat(selectedCart))
        );
      } else {
        localStorage.setItem("SELECTED_CART", JSON.stringify(selectedCart));
      }
      history.push("/checkout");
    } else {
      swal("Oops", "Cheklist barang yang akan di checkout", "warning");
    }
  };

  return (
    <Fragment>
      {AsCustomer ? (
        <Fragment>
          <div className={classes.cardBackground}>
            <div className={classes.flexCenterBetween}>
              <Typography>
                <b>Total Belanja</b>
              </Typography>
              <Typography>
                <b>
                  Rp {numberSeparator(listPrice.reduce((a, b) => a + b, 0))}
                </b>
              </Typography>
            </div>

            <div
              className={classes.flexCenterBetween}
              style={{
                paddingTop: 20,
              }}
            >
              <Typography variant="caption">
                {selectedCart.length === 0
                  ? ""
                  : `${selectedCart.length} barang dari ${listSelectedVendor.length} Supplier`}
              </Typography>
              <Typography variant="caption">belum ongkos kirim</Typography>
            </div>

            <Button
              variant="contained"
              color="primary"
              fullWidth={true}
              style={{ marginTop: 30 }}
            >
              <b>Lanjut ke Pembayaran</b>
            </Button>
          </div>
        </Fragment>
      ) : (
        <div>
          {currentCustomer && selectedCart && selectedCart.length !== 0 && (
            <div className={classes.cardBackground}>
              <Typography style={{ padding: 10 }}>
                <b>Detail Pembeli</b>
              </Typography>
              <Divider />
              <div style={{ padding: 10 }}>
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <Select
                    value={currentCustomer.name}
                    input={<BootstrapInput />}
                  >
                    {listCustomer
                        .filter(res => selectedCart.some(item => item.customer.name === res.name))
                        .map((res, index) => (
                          <MenuItem
                            key={index}
                            value={res.name}
                            onClick={() => setCurrentCustomer(res)}
                        >
                          {res.name}
                        </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Typography
                  variant="caption"
                  style={{ color: "#555555", marginTop: 10 }}
                >
                  {`${currentCustomer?.address} 
                   ${currentCustomer?.village?.name || ""}
                 ${currentCustomer?.village?.district?.name || ""} ${
                    currentCustomer?.village?.district?.city?.name || ""
                  } ${
                    currentCustomer?.village?.district?.city?.province?.name ||
                    ""
                  }
                 ${currentCustomer?.village?.kodepos || ""} Indonesia`}
                </Typography>
                <div
                  className={classes.ubahAlamat}
                  onClick={() => setOpenChangeAddress(true)}
                >
                  <EditIcon style={{ height: 15, cursor: "pointer" }} />
                  <Typography variant="caption">
                    <b>Ubah Alamat</b>
                  </Typography>
                </div>
              </div>
            </div>
          )}

          <div className={classes.cardBackground} style={{ marginTop: 20 }}>
            <div className={classes.flexCenterBetween}>
              <Typography>
                <b>Total Belanja</b>
              </Typography>
              <Typography>
                <b>
                  Rp {numberSeparator(listPrice.reduce((a, b) => a + b, 0))}
                </b>
              </Typography>
            </div>

            {selectedCart &&
              listCustomer.map((customer, index) => (
                <Fragment>
                  {selectedCart.filter(
                    (value) => value.customer.name === customer.name
                  ).length !== 0 && (
                    <div
                      key={index}
                      className={classes.flexCenterBetween}
                      style={{
                        paddingTop: 20,
                        display: "block",
                      }}
                    >
                      <div className={classes.flexBetween}>
                        <Typography variant="caption" color="primary">
                          {`Atas Nama ${customer.name}`}
                        </Typography>
                        <Typography variant="caption" color="primary">
                          {`Rp ${numberSeparator(
                            filteredPrice(
                              selectedCart.filter(
                                (value) => value.customer.name === customer.name
                              )
                            ).reduce((a, b) => a + b, 0)
                          )}`}
                        </Typography>
                      </div>
                      <div className={classes.flexBetween}>
                        <Typography variant="caption">
                          {`${
                            selectedCart.filter(
                              (value) => value.customer.name === customer.name
                            ).length
                          } barang dari ${
                            filteredSupplier(
                              selectedCart.filter(
                                (value) => value.customer.name === customer.name
                              )
                            ).length
                          } Supplier`}
                        </Typography>
                        <Typography variant="caption">
                          Belum ongkos kirim
                        </Typography>
                      </div>
                    </div>
                  )}
                </Fragment>
              ))}

            <Button
              variant="contained"
              color="primary"
              fullWidth={true}
              style={{ marginTop: 20 }}
              onClick={handleCheckout}
            >
              <b>Lanjut ke Pembayaran</b>
            </Button>
          </div>
        </div>
      )}

      {currentCustomer && (
        <ChangeAddress
          setUpdate={setUpdate}
          update={update}
          open={openChangeAddress}
          handleClose={handleClose}
          selectedCart={selectedCart}
          setSelectedCart={setSelectedCart}
          selectedCustomer={currentCustomer}
        />
      )}
    </Fragment>
  );
};
export default withRouter(Checkout);
