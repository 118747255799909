import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { makeStyles } from "@material-ui/core/styles";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { Typography } from "@material-ui/core";
import { getAppBanner } from "../services/axios";

const useStyles = makeStyles((theme) => ({
  slidePrev: {
    height: 50,
    width: 50,
    background: "#29c0c7",
    borderRadius: 4,
    marginLeft: -20,
    zIndex: 9,
    color: "#ffffff",
    position: "absolute",
    transition: "0.5s",
    "&:hover": {
      background: "#f2f4f8",
      transition: "0.5s",
      color: "#555555",
    },
  },
  slideNext: {
    height: 50,
    width: 50,
    marginLeft: -25,
    position: "absolute",
    zIndex: 9,
    color: "#ffffff",
    background: "#29c0c7",
    borderRadius: 4,
    transition: "0.5s",
    "&:hover": {
      background: "#f2f4f8",
      transition: "0.5s",
      color: "#555555",
    },
  },
}));

function NextArrow(props) {
  const classes = useStyles();
  const { onClick } = props;
  return (
    <div
      onClick={onClick}
      style={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        height: 420,
      }}
    >
      <NavigateNextIcon className={classes.slideNext} />
    </div>
  );
}

function PrevArrow(props) {
  const classes = useStyles();
  const { onClick } = props;
  return (
    <div
      onClick={onClick}
      style={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        height: 420,
      }}
    >
      <NavigateBeforeIcon className={classes.slidePrev} />
    </div>
  );
}

const settingsDesktop = {
  dots: true,
  infinite: true,
  adaptiveHeight: true,
  centerMode: true,
  speed: 2000,
  autoplaySpeed: 15000,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

const Desktop = () => {
  const [appBanner, setAppBanner] = useState([]);

  useEffect(() => {
    async function fetch() {
      const banner = await getAppBanner();
      setAppBanner(banner.data);
    }
    fetch();
  }, []);

  return (
    <React.Fragment>
      <Slider {...settingsDesktop}>
        {appBanner.map((res, index) => (
          <div key={index}>
            <div style={{padding: '0 20px'}}>
              <div
                style={{
                  background: `url('${res.image}')`,
                  width: "100%",
                  height: "420px",
                  backgroundPosition: "0 center",
                  backgroundSize: "cover",
                }}
              ></div>
            </div>
          </div>
        ))}
      </Slider>
    </React.Fragment>
  );
};
const Mobile = () => {
  const [appBanner, setAppBanner] = useState([]);

  useEffect(() => {
    async function fetch() {
      const banner = await getAppBanner();
      setAppBanner(banner.data);
    }
    fetch();
  }, []);

  const [state, setState] = useState({
    oldSlide: 0,
    activeSlide: 0,
    activeSlide2: 0,
  });

  const settings = {
    dots: false,
    infinite: true,
    adaptiveHeight: true,
    speed: 2000,
    autoplaySpeed: 15000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) =>
      setState({ ...state, oldSlide: current, activeSlide: next }),
    afterChange: (current) => setState({ activeSlide2: current }),
  };

  return (
    <React.Fragment>
      <Slider {...settings}>
        {appBanner.map((res, index) => (
          <div key={index}>
            <div>
              <div
                style={{
                  background: `url('${res.image}')`,
                  width: "100%",
                  height: "230px",
                  backgroundPosition: "0 center",
                  backgroundSize: "cover",
                }}
              ></div>
            </div>
          </div>
        ))}
      </Slider>
    </React.Fragment>
  );
};

const Banner = () => {
  const isMobile = useMediaQuery("(max-width:500px)");
  return <React.Fragment>{isMobile ? <Mobile /> : <Desktop />}</React.Fragment>;
};

export default Banner;
