import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Mobile from "./mobile";
import Desktop from "./desktop";

const CartPageCustomer = () => {
  const isMobile = useMediaQuery("(max-width:500px)");
  return <React.Fragment>{isMobile ? <Mobile /> : <Desktop />}</React.Fragment>;
};

export default CartPageCustomer;
