import React, { Fragment } from "react";
import { Container, Grid } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { stylesContent } from "../style";
import Slider from "react-slick";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Skeleton from "@material-ui/lab/Skeleton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const useStyles = makeStyles((theme) => ({
  slidePrev: {
    height: 50,
    width: 50,
    background: "#29c0c7",
    borderRadius: 4,
    marginLeft: -20,
    zIndex: 9,
    color: "#ffffff",
    position: "absolute",
    transition: "0.5s",
    "&:hover": {
      background: "#f2f4f8",
      transition: "0.5s",
      color: "#555555",
    },
  },
  slideNext: {
    height: 50,
    width: 50,
    marginLeft: -25,
    position: "absolute",
    zIndex: 9,
    color: "#ffffff",
    background: "#29c0c7",
    borderRadius: 4,
    transition: "0.5s",
    "&:hover": {
      background: "#f2f4f8",
      transition: "0.5s",
      color: "#555555",
    },
  },
}));

function NextArrow(props) {
  const classes = useStyles();
  const { onClick } = props;
  return (
    <div
      onClick={onClick}
      style={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        height: 420,
      }}
    >
      <NavigateNextIcon className={classes.slideNext} />
    </div>
  );
}

function PrevArrow(props) {
  const classes = useStyles();
  const { onClick } = props;
  return (
    <div
      onClick={onClick}
      style={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        height: 420,
      }}
    >
      <NavigateBeforeIcon className={classes.slidePrev} />
    </div>
  );
}

const DesktopSkeleton = withStyles(stylesContent)((props) => {
  const { classes } = props;
  const [sliderSettings] = React.useState({
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  });
  const customeSlider = React.createRef();

  const next = () => {
    customeSlider.current.slickNext();
  };
  const previous = () => {
    customeSlider.current.slickPrev();
  };

  const CarouseSkeleton = () => (
    <Fragment>
      <div className={classes.titleCarousel}>
        <Skeleton variant="circle" height={40} width={40} />
        <Skeleton variant="text" width={99} style={{ marginLeft: 20 }} />
      </div>
      <Grid container spacing={2}>
        {[1, 2, 3, 4, 5, 6].map((res) => {
          const lebar = 120;
          return (
            <Grid
              key={res+'carouse1'}
              item
              xs={2}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div style={{ width: lebar }}>
                <Skeleton
                  variant="rect"
                  key={res + 'carouse2'}
                  height={120}
                  width={lebar}
                  style={{ borderRadius: "8px 8px 0 0" }}
                />
                <Skeleton variant="text" width="90%" style={{ marginTop: 5 }} />
                <Skeleton variant="text" width="70%" />
                <Skeleton
                  variant="text"
                  style={{ marginTop: 10 }}
                  width="80%"
                />
                <Skeleton variant="text" width="60%" />
                <Skeleton
                  variant="rect"
                  style={{ margin: "10px 0", borderRadius: 4 }}
                  height={31}
                  width={lebar}
                />
                <Skeleton
                  variant="rect"
                  key={res + 'carouse3'}
                  height={31}
                  width={lebar}
                  style={{ borderRadius: 4 }}
                />
              </div>
            </Grid>
          );
        })}
      </Grid>
    </Fragment>
  );

  return (
    <Fragment>
      <Container maxWidth="md" style={{ padding: "60px 0 140px 0" }}>
        <Slider
          dots={true}
          infinite={true}
          adaptiveHeight={true}
          centerMode={true}
          autoplay={true}
          slidesToShow={1}
          slidesToScroll={1}
          nextArrow={<NextArrow />}
          prevArrow={<PrevArrow />}
        >
          {[1, 2, 3].map((res, index) => (
            <div key={index + 'skeleton1'}>
              <div style={{ margin: "0 15px" }}>
                <Skeleton
                  width={787.5}
                  height={400}
                  variant="rect"
                  style={{ borderRadius: 12 }}
                />
              </div>
            </div>
          ))}
        </Slider>

        <div style={{ paddingTop: 80 }}>
          <Skeleton variant="text" width={99} />
          <Grid container spacing={5} style={{ padding: "20px 0 70px 0" }}>
            {[1, 2, 3, 4].map((res) => (
              <Grid item xs={3} key={res + 'skeleton2'}>
                <Skeleton
                  style={{ borderRadius: 16 }}
                  variant="rect"
                  key={res + 'skeleton3'}
                  height={240}
                  width={200}
                />
              </Grid>
            ))}
          </Grid>

          <Grid container spacing={10}>
            <Grid item xs={12}>
              <CarouseSkeleton />
            </Grid>

            {/* Banner Section */}
            <Grid item xs={12}>
              <Grid container spacing={5}>
                <Grid item xs={6}>
                  <Skeleton
                    variant="text"
                    width={171}
                    style={{ marginBottom: 20 }}
                  />
                  <Skeleton variant="rect" height={195} width={440} />
                </Grid>
                <Grid item xs={6}>
                  <Skeleton
                    variant="text"
                    width={171}
                    style={{ marginBottom: 20 }}
                  />
                  <Skeleton variant="rect" height={195} width={440} />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <CarouseSkeleton />
            </Grid>
            <Grid item xs={12}>
              <CarouseSkeleton />
            </Grid>
          </Grid>
        </div>
      </Container>

      <div style={{ marginBottom: 80 }}>
        <Slider {...sliderSettings} ref={customeSlider}>
          {[1, 2, 3].map((res) => (
            <div key={res + 'skeleton4'} style={{ outline: "none" }}>
              <Grid container spacing={10}>
                <Grid item>
                  <div className={classes.bgTestimonial}></div>
                  <Skeleton
                    width={500}
                    height={300}
                    style={{ marginTop: 70, marginLeft: 175 }}
                    variant="rect"
                  />
                </Grid>
                <Grid item xs={5}>
                  <Skeleton variant="text" width={178} height={41} />
                  <img
                    src={require("../../../../assets/icons/quote.svg")}
                    className={classes.quote}
                    alt=""
                  />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                  <div>
                    <Skeleton variant="text" width={78} />
                    <Skeleton variant="text" width={142} />
                  </div>
                </Grid>
              </Grid>
            </div>
          ))}
        </Slider>
        <div style={{ paddingLeft: 150 }}>
          <ArrowBackIcon className={classes.arrow} onClick={() => previous()} />
          <ArrowForwardIcon className={classes.arrow} onClick={() => next()} />
        </div>
      </div>

      {/* Kategori Populer */}
      <Container maxWidth="md" className={classes.containerKategoriPopuler}>
        <Skeleton variant="text" width={115} />
        <div style={{ paddingTop: 10 }}>
          <Grid container>
            {[1, 2, 3, 4, 5, 6].map((res) => (
              <Grid item xs={2} key={res + 'skeleton5'}>
                <Skeleton
                  height={125}
                  width={99}
                  variant="rect"
                  style={{ borderRadius: 4 }}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      </Container>
    </Fragment>
  );
});

export default DesktopSkeleton;
