import React, {useState} from 'react'
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import { useHistory } from "react-router-dom";
import { numberSeparator } from '../../../../../utils/numberSeparator';
import { Button } from '@material-ui/core';

const CardStoreFront = withStyles(style)((props) => {
  const { classes, listProduct } = props
  let history = useHistory()
  const urlPathName = window.location.pathname.split('/')
  const storefront = urlPathName[2];

  const handleClickProductDeatil = (data) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    history.push(`/store/${storefront}/${data.slug}`);
  }

  return (
    <div>
      {
        listProduct.map((res, index) => 
          <div className={classes.container} key={'cardStoreFrontMobile'+index}>
            <div className={classes.containerDescript} onClick={() => handleClickProductDeatil(res)} >
              <img src={res.image_url} alt='product img' />
              <div>
                <div style={{width: 140,fontWeight: 'bold', fontSize: '.8rem', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{res.name}</div>
                <div>Rp. {numberSeparator(res.consumer_price_idr)}</div>
              </div>
            </div>
            <div 
              style={{
                backgroundColor: "#FBBB14",
                color: "#ffffff",
                justifyContent: 'space-around',
                cursor: 'pointer',
                padding: '5px 10px 5px 10px',
                borderRadius: 5,
                '&:hover': {
                  backgroundColor: "#1b8388"
                }}}
              onClick={() => handleClickProductDeatil(res)} 
            >
              +
            </div>
          </div>
        )
      }
    </div>
  )
})

export default CardStoreFront