import React, { Fragment } from "react";
import Navbar from "../../../components/navbar";
import { Container, Paper } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { ReactComponent as ProductIconSecondary } from "../../../assets/icons/gridicons_product.svg";
import { ReactComponent as ProductIconPrimary } from "../../../assets/icons/gridicons_product2.svg";
import { ReactComponent as ShopIconPrimary } from "../../../assets/icons/shop2.svg";
import { ReactComponent as ShopIconSecondary } from "../../../assets/icons/shop.svg";
import Produk from "./components/produk";
import Vendor from "./components/vendor";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Mobile = withStyles(style)((props) => {
  const {
    classes,
    filterCategory,
    dataSearched,
    vendorSearched,
    handleChangePage,
    handleFilter,
    handleResetFilter,
    setFilterCategory,
    filterLocation,
    setFilterLocation,
    handleFilterSort,
    sliderProduct,
  } = props;
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Fragment>
      <Navbar variant="primary" />
      <Fragment>
        {/* <Container maxWidth="lg" style={{ paddingTop: 10 }}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link color="inherit" href="/">
              Home
            </Link>
            <Typography color="textPrimary">{filterCategory.name}</Typography>
          </Breadcrumbs>
        </Container> */}

        <div style={{ paddingTop: 20 }}>
          <Container maxWidth="lg">
            <Paper>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab
                  label="Produk"
                  icon={
                    value === 0 ? (
                      <ProductIconPrimary className={classes.iconTab} />
                    ) : (
                      <ProductIconSecondary className={classes.iconTab} />
                    )
                  }
                  {...a11yProps(0)}
                />
                <Tab
                  label="Supplier"
                  icon={
                    value === 1 ? (
                      <ShopIconPrimary className={classes.iconTab} />
                    ) : (
                      <ShopIconSecondary className={classes.iconTab} />
                    )
                  }
                  {...a11yProps(1)}
                />
              </Tabs>
            </Paper>
          </Container>
        </div>
        <TabPanel value={value} index={0}>
          {dataSearched !== null && (
            <Produk
              dataSearched={dataSearched}
              handleChangePage={handleChangePage}
              handleFilter={handleFilter}
              handleResetFilter={handleResetFilter}
              setFilterCategory={setFilterCategory}
              filterCategory={filterCategory}
              filterLocation={filterLocation}
              setFilterLocation={setFilterLocation}
              handleFilterSort={handleFilterSort}
              sliderProduct={sliderProduct}
            />
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Vendor vendorSearched={vendorSearched} />
        </TabPanel>
      </Fragment>
    </Fragment>
  );
});

export default Mobile;
