import React, { Fragment, useEffect, useState } from "react";
import Navbar from "../../../components/navbar";
import Footer from "../../../components/footer";
import { Container, Grid } from "@material-ui/core";
import Sidebar from "./components/sidebar";
import Content from "./components/content";
import { withRouter } from "react-router-dom";
import { getProductData } from "../../../services/axios";
import Loading from "../../../components/loading";

const Desktop = (props) => {
  const category = props.match.params.category;
  const category_id = category.slice(category.search("cat.")).slice(4);
  const [data, setData] = useState(null);
  const [lokasi, setLokasi] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const response = await getProductData(
        `?filter[category.id]=${category_id}`
      );
      setData(response);
    };
    fetch();
  }, []);

  const handleFilter = async () => {
    const response = await getProductData(
      `?filter[category.id]=${category_id}&filter[supplier.province_id]=${lokasi.join(
        ","
      )}`
    );
    setData(response);
  };

  const handleChangePage = async (index) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const response = await getProductData(
      `?filter[category.id]=${category_id}&page=${index}`
    );
    setData(response);
  };

  const handleFilterLokasi = (value) => {
    if (lokasi[lokasi.indexOf(value)] === value) {
      setLokasi(lokasi.filter((loc) => loc !== value));
    } else {
      setLokasi([...lokasi, value]);
    }
  };

  return (
    <Fragment>
      <Navbar variant="primary" />
      {!data ? (
        <Loading />
      ) : (
        <Container maxWidth="md" style={{ paddingTop: 60, paddingBottom: 80 }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Sidebar
                handleFilterLokasi={handleFilterLokasi}
                lokasi={lokasi}
                handleFilter={handleFilter}
              />
            </Grid>
            <Grid item xs={8}>
              <Content
                category={category}
                data={data}
                handleChangePage={handleChangePage}
              />
            </Grid>
          </Grid>
        </Container>
      )}
      <Footer />
    </Fragment>
  );
};

export default withRouter(Desktop);
