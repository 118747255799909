import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import Navbar from "../../../components/navbar";
import Footer from "../../../components/footer";
import { Container, Typography } from "@material-ui/core";

const Desktop = withStyles(styles)((props) => {
  const { classes } = props;
  return (
    <Fragment>
      <Navbar variant="default" />
      <Container maxWidth="md" className={classes.container}>
        <div className={classes.wrapper}>
          <div className={classes.card}>
            <Typography variant="h6" className={classes.titleCard}>
              <b>Congratulation!</b>
            </Typography>

            <div className={classes.content}>
              <Typography>
                Selamat Akun Anda telah di aktivasi, silahkan login di mobile
                apps atau di app.idejualan.com
              </Typography>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </Fragment>
  );
});

export default Desktop;
