import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Mobile from "./mobile";

const MyProfile = (props) => {
  const isMobile = useMediaQuery("(max-width:500px)");

  return <React.Fragment>
    {isMobile ? <Mobile /> : <div style={{maxWidth: 960, padding: 24, position: 'absolute', margin: '0 auto', left: 0, right:0}}><Mobile /></div>}
  </React.Fragment>;
};

export default MyProfile;
