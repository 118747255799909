import React from "react";

import {
  Grid,
  Typography,
  Card,
  Chip,
  Avatar,
  Box,
  Dialog,
  CircularProgress,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { numberSeparator } from "../../../utils/numberSeparator";

import { getTracking } from "../../../services/order.service";

const Delivery = (props) => {
  const { order } = props;
  const [isOpenDialogTracking, setIsOpenDialogTracking] = React.useState(false);
  const [isLoadingTracking, setIsLoadingTracking] = React.useState(false);
  const [manifest, setManifest] = React.useState([]);

  const handleDialogTracking = async (isOpen) => {
    setIsOpenDialogTracking(isOpen);

    if (isOpen) {
      try {
        setIsLoadingTracking(true);
        const res = await getTracking({
          waybill: order.resi,
          courier: order.courier.code,
        });
        setManifest(res.manifest);
        setIsLoadingTracking(false);
      } catch (error) {
        setIsLoadingTracking(false);
      }
    } else {
      setManifest([]);
    }
  };

  return (
    <>
      <Card key={order.id} style={{ marginBottom: 16 }} variant="outlined">
        <Box
          bgcolor="#3fbfc5"
          width="fit-content"
          color="#fff"
          fontWeight={600}
          p={1}
          px={2}
        >
          Order ID: #{order.id}
        </Box>
        <Box p={2}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center" gridGap={6}>
              <Avatar
                src={order.supplier.avatar_url}
                style={{ width: 36, height: 36 }}
              />
              <Typography>{order.supplier.name}</Typography>
            </Box>
            <Typography>
              Status: {order.histories[order.histories.length - 1].message}
            </Typography>
          </Box>

          <Box height={2} mt={1} mb={2} bgcolor="#F7F7F7" />
          {order.items.map(({ id, sku, reseller_price_idr, qty }) => {
            if (sku) {
              return (
                <Box mb={2} key={id}>
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      <Typography>{sku.product.name}</Typography>
                      {sku.properties && (
                        <Box mt={1} mb={2}>
                          <Chip
                            variant="outlined"
                            label={sku.properties.color}
                            disabled
                            size="small"
                          />{" "}
                          <Chip
                            variant="outlined"
                            label={sku.properties.material}
                            disabled
                            size="small"
                          />{" "}
                          <Chip
                            variant="outlined"
                            label={sku.properties.size}
                            disabled
                            size="small"
                          />
                        </Box>
                      )}
                      <Typography color="primary" component="span" variant="h6">
                        Rp.{numberSeparator(reseller_price_idr || 0)}
                      </Typography>
                      {"   x   "}
                      <Typography
                        component="span"
                        color="textSecondary"
                        variant="h6"
                      >
                        {qty}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Avatar
                        src={sku.image_url}
                        style={{ height: 64 }}
                        variant="square"
                      />
                    </Grid>
                  </Grid>
                </Box>
              );
            }
          })}
        </Box>
        <Box p={2}>
          <Button
            onClick={() => handleDialogTracking(true)}
            size="small"
            variant="contained"
            color="primary"
          >
            Lacak
          </Button>
        </Box>
      </Card>
      <Dialog
        open={isOpenDialogTracking}
        onClose={() => handleDialogTracking(false)}
        fullWidth
        maxWidth="sm"
      >
        <Box display="flex" justifyContent="flex-end" p={1}>
          <IconButton onClick={() => handleDialogTracking(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        {isLoadingTracking ? (
          <Box p={4} display="flex" justifyContent="center">
            <CircularProgress size={40} />
          </Box>
        ) : (
          <Box p={4}>
            {manifest.length > 0 ? (
              <Stepper orientation="vertical">
                {manifest.map((step) => (
                  <Step key={step.manifest_code} active>
                    <StepLabel>{step.city_name}</StepLabel>
                    <StepContent>
                      <Typography variant="caption">
                        {step.manifest_date} : {step.manifest_time}
                      </Typography>
                      <Typography>{step.manifest_description}</Typography>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            ) : (
              "Resi tidak valid"
            )}
          </Box>
        )}
      </Dialog>
    </>
  );
};

export default Delivery;
