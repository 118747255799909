import styled from 'styled-components'

export const WrapSeeAll = styled.div`
  position: relative;
  font-size: 13px;
  font-family: "dmsans-regular";
  display: flex;
  padding: 8px;
  p {
    margin: 0;
    padding: 0;
    letter-spacing: 0.02em;
  }
  
  p:nth-child(1) {
    color: #666;
    margin: 0;
    padding: 0;
    float: left;
    position: relative;
    bottom: 2px;
    right: 4px;
  }
  p:nth-child(2) {
    color: #CACACA;
    margin-top: 4px;
  }

  .right {
    position: absolute;
    right: 0;
    top: 36px;
    cursor: pointer;
    img {
      height: 18px;
    }
  }
`