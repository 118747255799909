import React, { useEffect, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { stylesContent } from "./style";
import { Typography, Container, Grid, Button, Card, CircularProgress } from "@material-ui/core";
import Slider from "react-slick";
import Navbar from "../../../components/navbar";
import Footer from "../../../components/footer";
import Banner from "../../../components/banner";
import Carousel from "../../../components/carousel";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useHistory } from "react-router-dom";
import {
  getProductData,
  getCategories,
  getProductRecomendations,
  getVouchers,
} from "../../../services/axios";
import Skeleton from "./components/skeleton";
import testimoni from "../testimoni";
import settings from "../settings";
import ModalPopUp from "../../../components/ModalPopUp";
import InfiniteScroll from 'react-infinite-scroll-component';
import swal from "@sweetalert/with-react";

// icon
import iconCategory from '../../../assets/icons/menu/icon_category.svg'
import iconPromo from '../../../assets/icons/menu/icon_promo.svg'
import iconKupon from '../../../assets/icons/menu/icon_kupon.svg'
import kampusJualan from '../../../assets/icons/menu/kampusjualan_logo.png'
import iconLogin from '../../../assets/icons/menu/icon_daily_login.svg'
import BrandPopuler from "../../../components/BrandPopuler";
import TerbaruTerlaris from "../../../components/TerbaruTerlaris";
import NeedLoginSwal from "../../../components/needLoginSwal";
import ChatPic from "../../../components/chatPIC";

const Desktop = withStyles(stylesContent)((props) => {
  const { classes } = props;
  const history = useHistory();
  const [loading, setLoading] = React.useState(true);
  const [recomenProduct, setRecomendProduct] = React.useState(null);
  const [sliderProduct, setSliderProduct] = React.useState(null);
  const [vouchers, setVouchers] = React.useState(null);
  const [sliderSettings] = React.useState({
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  });
  const customeSlider = React.createRef();
  const [allCategories, setAllCategories] = React.useState([])
  const [pages, setPages] = React.useState(1)
  const [distancePages, setDistancePages] = React.useState(2)
  const [numberPages, setNumberPages] = React.useState(0)
  const [noMore, setNoMore] = React.useState(true)

  const next = () => {
    customeSlider.current.slickNext();
  };
  const previous = () => {
    customeSlider.current.slickPrev();
  };

  useEffect(() => {
    const getDataRecom = async () => {
      const getProductRecomen = await getProductRecomendations();
      setRecomendProduct(getProductRecomen);
    };

    getDataRecom();
  }, []);

  useEffect(() => {
    const getData = async () => {
      const res = await getVouchers();
      if (res.data) {
        setVouchers(res.data);
      }
    };

    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      const getCategorie = await getCategories();
      setAllCategories(getCategorie);
      const category = getCategorie.data[0].children.slice(0, 1);
      const response1 = await getProductData(
        `?filter[category.id]=${category[0].id}`
      );

      let data = [];
      category.forEach((res, index) => {
        data = [
          ...data.filter((value) => value.id !== category[index].id),
          {
            ...category[index],
            products:
              response1.data
          },
        ];
        setSliderProduct(data);
      });
    };
    getData();
  }, []);



  useEffect(() => {
    if (sliderProduct !== null) {
      setLoading(false);
    }
  }, [sliderProduct]);


  // new
  const fetchData = () => {
    const getData = async (myDataPages, myPages, myDistancePages) => {
      const myData = allCategories.data[myDataPages]
      if (myData === undefined) {
        setNoMore(false)
      } else {
        const category = myData.children.slice(myPages, myDistancePages);
        let response1 = ''

        response1 = await getProductData(
          `?filter[category.id]=${category[0].id}`
        );

        let data = [];
        category.forEach((res, index) => {
          data = [
            ...data.filter((value) => value.id !== category[index].id),
            {
              ...category[index],
              products:
                response1.data
            },
          ];
          setSliderProduct([...sliderProduct, ...data]);
        });
      }
    };

    if (pages >= allCategories.data[numberPages].children.length) {
      if (allCategories.data[numberPages].children.length === 0) {
        getData(numberPages + 2, 0, 1);
        setNumberPages(numberPages + 2)

      } else if (allCategories.data[numberPages].children.length === 1) {
        getData(numberPages + 2, 0, 1);
        setNumberPages(numberPages + 2)

      } else {
        getData(numberPages + 1, 0, 1);
        setNumberPages(numberPages + 1)
        setPages(1)
        setDistancePages(2)
      }
    } else {
      getData(numberPages, pages, distancePages);
      setPages(pages + 1)
      setDistancePages(distancePages + 1)
    }
  }

  const handleVoucherPage = () => {
    if (localStorage.getItem("TOKEN") === null) {
      swal(
        <NeedLoginSwal />
        , {
          buttons: false
        })
    } else {
      history.push('/voucher')
    }
  }

  const handleDailyLoginPage = () => {
    if (localStorage.getItem("TOKEN") === null) {
      swal(
        <NeedLoginSwal />
        , {
          buttons: false
        })
    } else {
      history.push('/daily-login')
    }
  }

  return (
    <Fragment>
      <Navbar variant="primary" />
      {loading ? (
        <Skeleton />
      ) : (
        <Fragment>
          <ModalPopUp></ModalPopUp>
          <Container maxWidth="md" style={{ padding: "40px 0 140px 0" }}>
            <Banner />
            <div className={classes.mainMenu} style={{ paddingTop: 80 }}>
              {/* kategory */}
              <div className={classes.containerIcon}>
                <Button onClick={() => history.push("/category")}>
                  <img className={classes.iconMenu} src={iconCategory} alt={"icon kategory"} />
                </Button>
                <p>Kategori</p>
              </div>

              {/* promo */}
              <div className={classes.containerIcon}>
                <Button onClick={() => history.push("/promo")}>
                  <img className={classes.iconMenu} src={iconPromo} alt={"icon promo"} />
                </Button>
                <p>Promo</p>
              </div>

              {/* kupon */}
              <div className={classes.containerIcon}>
                <Button onClick={handleVoucherPage}>
                  <img className={classes.iconMenu} src={iconKupon} alt={"icon kupon"} />
                </Button>
                <p>Kupon</p>
              </div>

              {/* Kampus Jualan */}
              <div className={classes.containerIcon}>
                <Button onClick={() => window.open("https://www.kampusjualan.com/", '_blank')}>
                  <img className={classes.iconMenu} style={{ width: 78, height: 79 }} src={kampusJualan} alt={"icon kampus jualan"} />
                </Button>
                <p>Kampus Jualan</p>
              </div>

              {/* daily login */}
              <div className={classes.containerIcon}>
                <Button onClick={handleDailyLoginPage}>
                  <img className={classes.iconMenu} src={iconLogin} alt={"icon daily login"} style={{ width: '78px' }} />
                </Button>
                <p>Daily Login</p>
              </div>

            </div>
            <div style={{ paddingTop: 80 }}>
              {vouchers && (
                <div>
                  <div
                    className={classes.titleCarousel}
                    style={{ cursor: 'pointer' }}
                    onClick={handleVoucherPage}
                  >
                    <img
                      src={require("../../../assets/icons/icon_voucher.svg")}
                      alt={"slider"}
                      className={classes.icon}
                    />
                    <Typography className={classes.textTitleCarousel}>
                      Kupon untuk kamu hari ini
                    </Typography>
                  </div>
                  <Grid
                    container
                    spacing={5}
                    style={{ padding: "20px 0 70px 0" }}
                  >
                    <Carousel data={vouchers} variant="voucher" />
                  </Grid>
                </div>
              )}

              {settings.featured_product && (
                <div>
                  <div className={classes.titleCarousel}>
                    <img
                      src={require("../../../assets/icons/icon_premium.svg")}
                      alt={"slider"}
                      className={classes.icon}
                    />
                    <Typography className={classes.textTitleCarousel}>
                      Produk Pilihan
                    </Typography>
                  </div>
                  <Grid
                    container
                    spacing={5}
                    style={{ padding: "20px 0 70px 0" }}
                  >
                    <Carousel data={recomenProduct.data} />
                  </Grid>
                </div>
              )}

              {/* Brand Populer */}
              <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div className={classes.titleCarousel}>
                    <img
                      src={require("../../../assets/icons/shop.svg")}
                      alt={"slider"}
                      className={classes.icon}
                    />
                    <Typography className={classes.textTitleCarousel}>
                      Brand Populer
                    </Typography>
                  </div>
                  <Button onClick={() => history.push("/brand-page")} >Lihat Semua</Button>
                </div>
                <Grid
                  container
                  spacing={5}
                  style={{ padding: "20px 0 70px 0" }}
                >
                  <BrandPopuler />
                </Grid>
              </div>

              {/* Terbaru dan Terlaris */}
              <TerbaruTerlaris />
                <br /><br />

              <InfiniteScroll
                dataLength={sliderProduct.length} //This is important field to render the next data
                next={fetchData}
                style={{
                  overflow: 'none',
                }}
                hasMore={noMore}
                loader={
                  <CircularProgress
                    color="primary"
                    style={{ display: 'flex', margin: 'auto' }}
                  />
                }
                endMessage={
                  <p style={{ textAlign: 'center' }}>
                    <b>You have seen it all</b>
                  </p>
                }
              >
                {
                  sliderProduct.map((res, index) =>
                    <div key={index}>
                      <div
                        className={classes.titleCarousel}
                        onClick={() =>
                          history.push(
                            `/category/${res.name.split(' ').join('_').split('/').join('&')}-cat.${res.id}`
                          )
                        }
                        style={{ cursor: 'pointer' }}
                      >
                        <img
                          src={res.icon_url}
                          alt={"slider"}
                          className={classes.icon}
                        />
                        <Typography
                          className={classes.textTitleCarousel}
                        >
                          {res.name}
                        </Typography>
                      </div>
                      <Grid
                        container
                        spacing={5}
                        style={{ padding: "20px 0 70px 0" }}
                      >
                        <Carousel data={res.products} />
                      </Grid>
                    </div>
                  )
                }
              </InfiniteScroll>
            </div>
          </Container>

          {settings.testimoni && (
            <div style={{ marginBottom: 80 }}>
              <Slider {...sliderSettings} ref={customeSlider}>
                {testimoni.map((res, index) => (
                  <div key={index} style={{ outline: "none" }}>
                    <Grid container spacing={10}>
                      <Grid item>
                        <div className={classes.bgTestimonial}></div>
                        <div
                          style={{
                            background: `url(${res.photo})`,
                          }}
                          className={classes.imgTestimonial}
                        ></div>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography variant="h4" style={{ fontWeight: "bold" }}>
                          Testimonials
                        </Typography>
                        <img
                          src={require("../../../assets/icons/quote.svg")}
                          className={classes.quote}
                          alt=""
                        />
                        <Typography style={{ padding: "10px 0 40px 0" }}>
                          {res.quote}
                        </Typography>
                        <div>
                          <Typography style={{ fontWeight: "bold" }}>
                            {res.name}
                          </Typography>
                          <Typography
                            variant="caption"
                            style={{ color: "#777777" }}
                          >
                            {res.title}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                ))}
              </Slider>
              <div style={{ paddingLeft: 150 }}>
                <ArrowBackIcon
                  className={classes.arrow}
                  onClick={() => previous()}
                />
                <ArrowForwardIcon
                  className={classes.arrow}
                  onClick={() => next()}
                />
              </div>
            </div>
          )}

          {/* Kategori Populer */}
          {settings.populer_category && (
            <Container
              maxWidth="md"
              className={classes.containerKategoriPopuler}
            >
              <Typography className={classes.kategoriPopuler}>
                Kategori Populer
              </Typography>
              <div>
                <Grid container>
                  <Grid item xs={2}>
                    <Button
                      className={classes.buttonKategoriPopuler}
                      onClick={() =>
                        history.push(`/category/Blazer-Wanita-cat.4`)
                      }
                    >
                      <Card className={classes.cardKategoriPopuler}>
                        <div className={classes.containerImgKategoriPopuler}>
                          <img
                            src={require("../../../assets/icons/category/blazer2.svg")}
                            className={classes.imgKategoriPopuler}
                            alt=""
                          />
                        </div>
                        <Typography className={classes.textItemKategoriPopuler}>
                          Blazer Wanita
                        </Typography>
                      </Card>
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    className={classes.notFirstItemKategoriPopuler}
                  >
                    <Button
                      className={classes.buttonKategoriPopuler}
                      onClick={() =>
                        history.push(`/category/Makeup-Wajah-cat.5`)
                      }
                    >
                      <Card className={classes.cardKategoriPopuler}>
                        <div className={classes.containerImgKategoriPopuler}>
                          <img
                            src={require("../../../assets/icons/category/makeup.svg")}
                            className={classes.imgKategoriPopuler}
                            alt=""
                          />
                        </div>
                        <Typography className={classes.textItemKategoriPopuler}>
                          Makeup Wajah
                        </Typography>
                      </Card>
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    className={classes.notFirstItemKategoriPopuler}
                  >
                    <Button
                      className={classes.buttonKategoriPopuler}
                      onClick={() => history.push(`/category/Heels-cat.6`)}
                    >
                      <Card className={classes.cardKategoriPopuler}>
                        <div className={classes.containerImgKategoriPopuler}>
                          <img
                            src={require("../../../assets/icons/category/heels.svg")}
                            className={classes.imgKategoriPopuler}
                            alt=""
                          />
                        </div>
                        <Typography className={classes.textItemKategoriPopuler}>
                          heels
                        </Typography>
                      </Card>
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    className={classes.notFirstItemKategoriPopuler}
                  >
                    <Button
                      className={classes.buttonKategoriPopuler}
                      onClick={() =>
                        history.push(`/category/Makeup-Bibir-cat.7`)
                      }
                    >
                      <Card className={classes.cardKategoriPopuler}>
                        <div className={classes.containerImgKategoriPopuler}>
                          <img
                            src={require("../../../assets/icons/category/lipstick.svg")}
                            className={classes.imgKategoriPopuler}
                            alt=""
                          />
                        </div>
                        <Typography className={classes.textItemKategoriPopuler}>
                          Makeup Bibir
                        </Typography>
                      </Card>
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    className={classes.notFirstItemKategoriPopuler}
                  >
                    <Button
                      className={classes.buttonKategoriPopuler}
                      onClick={() => history.push(`/category/Makanan-cat.8`)}
                    >
                      <Card className={classes.cardKategoriPopuler}>
                        <div className={classes.containerImgKategoriPopuler}>
                          <img
                            src={require("../../../assets/icons/category/makanan.svg")}
                            className={classes.imgKategoriPopuler}
                            alt=""
                          />
                        </div>
                        <Typography className={classes.textItemKategoriPopuler}>
                          Makanan
                        </Typography>
                      </Card>
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    className={classes.notFirstItemKategoriPopuler}
                  >
                    <Button
                      className={classes.buttonKategoriPopuler}
                      onClick={() => history.push("/category")}
                    >
                      <Card className={classes.cardKategoriPopuler}>
                        <div className={classes.containerImgKategoriPopuler}>
                          <img
                            src={require("../../../assets/icons/category/lainnya.svg")}
                            className={classes.imgKategoriPopuler}
                            alt=""
                          />
                        </div>
                        <Typography className={classes.textItemKategoriPopuler}>
                          Lainnya
                        </Typography>
                      </Card>
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Container>
          )}
        </Fragment>
      )}
      <Footer />
      <ChatPic />
    </Fragment>
  );
});

export default Desktop;
