const handleRoute = () => {
  if (
    window.localStorage.getItem("TOKEN") &&
    window.location.pathname === "/login"
  ) {
    window.location = "/";
  } else if (
    window.localStorage.getItem("TOKEN") &&
    window.location.pathname === "/signup"
  ) {
    window.location = "/";
  } else if (
    window.localStorage.getItem("TOKEN") &&
    window.location.pathname === "/reset-password"
  ) {
    window.location = "/";
  } else if (
    window.localStorage.getItem("TOKEN") &&
    window.location.pathname === "/form-reset-password"
  ) {
    window.location = "/";
  } else if (
    window.localStorage.getItem("TOKEN") &&
    window.location.pathname === "/login/get-account"
  ) {
    window.location = "/";
  }
};

export default handleRoute;
