import React, { Fragment, useState, useEffect } from "react";
import Navbar from "../../../components/navbar";
import DividerMobile from "../../../components/divider-mobile";
import { withRouter } from "react-router-dom";
import { Checkbox, Typography, Divider, Button } from "@material-ui/core";
import storeIcon from "../../../assets/icons/shop.svg";
import { numberSeparator } from "../../../utils/numberSeparator";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import DeleteIcon from "@material-ui/icons/Delete";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import { quantity, remove, update } from "cart-localstorage";
import logoIdejualan from "../../../assets/images/placeholder.jpg";
import swal from "@sweetalert/with-react";

const Mobile = withStyles(styles)((props) => {
  const { classes, history } = props;
  const AsCustomer = false;
  const listSupplier = JSON.parse(localStorage.getItem("LIST_SUPPLIER"));
  const [cart, setCart] = useState(JSON.parse(localStorage.getItem("__cart")));
  const [selectedCart, setSelectedCart] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [listPrice, setListPrice] = useState([]);
  const [listCustomer, setListCustomer] = useState([]);
  const [listSelectedVendor, setListSelectedVendor] = useState([]);

  useEffect(() => {
    // Get List Customer
    let x = [];
    let y;
    let selesai = 0;
    if (cart) {
      cart.map((res) => {
        if (res.customer.name !== x[x.indexOf(res.customer.name)]) {
          selesai = selesai + 1;
          y = res.customer.name;
          x = [...x, res.customer.name];
          if (selesai === cart.length) setListCustomer(x);
        } else if (x.length !== 0) {
          selesai = selesai + 1;
          x = [...x];
          if (selesai === cart.length) setListCustomer(x);
        } else {
          selesai = selesai + 1;
          x = [res.customer.name];
          if (selesai === cart.length) setListCustomer(x);
        }
      });
    }
  }, []);

  const getListPrice = async () => {
    let list = [];
    await selectedCart.forEach((res) => {
      list = [...list, res.price * res.quantity];
      if (
        listSelectedVendor.findIndex((value) => value === res.supplier) === -1
      ) {
        setListSelectedVendor([res.supplier]);
      } else {
        setListSelectedVendor([...listSelectedVendor, res.supplier]);
      }
    });
    setListPrice(list);
  };

  useEffect(() => {
    getListPrice();
  }, [selectedCart]);

  useEffect(() => {
    const updateCart = async () => {
      const getCart = JSON.parse(localStorage.getItem("__cart"));
      await setCart(getCart);
    };
    updateCart();
    getListPrice();
  }, [isUpdated]);

  const handleRemoveItem = async (id, supplier_name, data, customer) => {
    await remove(id);
    const getCart = JSON.parse(localStorage.getItem("__cart"));
    const getCustomer = JSON.parse(localStorage.getItem("LIST_CUSTOMER"));
    let list = [...listSupplier];
    const index = listSupplier.indexOf(supplier_name);

    // fungsi untuk menghapus barang yang sudah di checklist
    if (selectedCart.length !== 0) {
      setSelectedCart(selectedCart.filter((value) => value.id !== id));
    }

    if (
      data.supplier === supplier_name &&
      getCart.filter((item) => item.supplier === supplier_name).length !== 0
    ) {
      return setIsUpdated(!isUpdated);
    } else {
      if (index > -1) {
        list.splice(index, 1);
      }
      localStorage.setItem("LIST_SUPPLIER", JSON.stringify(list));
    }
    if (getCart.length === 0)
      localStorage.setItem("LIST_SUPPLIER", JSON.stringify([]));

    if (
      getCart.filter((value) => value.customer.name === customer).length === 0
    ) {
      localStorage.setItem(
        "LIST_CUSTOMER",
        JSON.stringify(getCustomer.filter((value) => value.name !== customer))
      );
    }
    setIsUpdated(!isUpdated);
  };

  const handlePlus = async (id) => {
    await quantity(id, 1);
    if (selectedCart.length !== 0) {
      setSelectedCart([
        ...selectedCart.filter((value) => value.id !== id),
        {
          ...selectedCart.filter((value) => value.id === id)[0],
          quantity:
            selectedCart.filter((value) => value.id === id)[0].quantity + 1,
        },
      ]);
    }
    setIsUpdated(!isUpdated);
  };

  const handleMin = async (id, supplier_name, data) => {
    await quantity(id, -1);
    if (selectedCart.length !== 0) {
      setSelectedCart([
        ...selectedCart.filter((value) => value.id !== id),
        {
          ...selectedCart.filter((value) => value.id === id)[0],
          quantity:
            selectedCart.filter((value) => value.id === id)[0].quantity - 1,
        },
      ]);
    }
    const getCart = JSON.parse(localStorage.getItem("__cart"));
    let list = [...listSupplier];
    const index = list.indexOf(supplier_name);
    if (
      data.supplier === supplier_name &&
      getCart.filter((item) => item.supplier === supplier_name).length !== 0
    ) {
      return setIsUpdated(!isUpdated);
    } else {
      if (index > -1) {
        list.splice(index, 1);
      }
      localStorage.setItem("LIST_SUPPLIER", JSON.stringify(list));
    }
    if (getCart.length === 0)
      localStorage.setItem("LIST_SUPPLIER", JSON.stringify([]));
    setIsUpdated(!isUpdated);
  };

  const handleInput = async (id, value, supplier_name, data) => {
    await update(id, 'quantity', value);
    const getCart = JSON.parse(localStorage.getItem("__cart"));
    let list = [...listSupplier];
    const index = list.indexOf(supplier_name);
    if (
      data.supplier === supplier_name &&
      getCart.filter((item) => item.supplier === supplier_name).length !== 0
    ) {
      return setIsUpdated(!isUpdated);
    } else {
      if (index > -1) {
        list.splice(index, 1);
      }
      localStorage.setItem("LIST_SUPPLIER", JSON.stringify(list));
    }

    if (getCart.length === 0)
      localStorage.setItem("LIST_SUPPLIER", JSON.stringify([]));
    setIsUpdated(!isUpdated);
  };

  const handlePilihSemua = (customer) => {
    if (
      cart.filter((value) => value.customer.name === customer).length ===
      selectedCart.filter((value) => value.customer.name === customer).length
    ) {
      setSelectedCart(
        selectedCart.filter((value) => value.customer.name !== customer)
      );
    } else {
      setSelectedCart(
        selectedCart
          .filter((value) => value.customer.name !== customer)
          .concat(cart.filter((value) => value.customer.name === customer))
      );
    }
  };

  const handleSelectedCartByVendor = (supplier_name, customer) => {
    if (
      cart
        .filter((value) => value.customer.name === customer)
        .filter((item) => item.supplier === supplier_name).length ===
      selectedCart
        .filter((value) => value.customer.name === customer)
        .filter((item) => item.supplier === supplier_name).length
    ) {
      setSelectedCart(
        selectedCart
          .filter((value) => value.customer.name === customer)
          .filter((item) => item.supplier !== supplier_name)
      );
    } else {
      setSelectedCart(
        selectedCart.concat(
          cart
            .filter((value) => value.customer.name === customer)
            .filter((item) => item.supplier === supplier_name)
        )
      );
    }
  };

  const handleSelectedCart = async (data) => {
    const index = selectedCart.findIndex((value) => value.id === data.id);
    const currentCart = selectedCart.filter((item) => item.id === data.id)[0];
    if (Boolean(currentCart) === Boolean(data)) {
      if (index > -1 && index !== 0) {
        setSelectedCart(selectedCart.filter((item) => item.id !== data.id));
      } else if (index === 0 && selectedCart.length === 1) {
        setSelectedCart([]);
      } else {
        setSelectedCart(selectedCart.filter((item) => item.id !== data.id));
      }
    } else {
      setSelectedCart([...selectedCart, data]);
    }
  };

  const filteredSupplier = (data) => {
    let returnData = [];
    data.map((res) => {
      if (returnData[returnData.indexOf(res.supplier)] !== res.supplier) {
        returnData = [...returnData, res.supplier];
      }
    });
    return returnData;
  };

  const handleCheckout = () => {
    const getDataCheckout = JSON.parse(localStorage.getItem("SELECTED_CART"));
    if (selectedCart.length !== 0) {
      if (getDataCheckout !== null) {
        localStorage.setItem(
          "SELECTED_CART",
          JSON.stringify(getDataCheckout.concat(selectedCart))
        );
      } else {
        localStorage.setItem("SELECTED_CART", JSON.stringify(selectedCart));
      }
      history.push("/checkout");
    } else {
      swal("Oops", "Cheklist barang yang akan di checkout", "warning");
    }
  };

  return (
    <Fragment>
      <Navbar text="Keranjang Saya" onClick={() => history.goBack()} />
      {cart === null || cart.length === 0 ? (
        <Fragment>
          <div
            className={classes.center}
            style={{ height: window.screen.height - 156 }}
          >
            <div style={{ textAlign: "center" }}>
              <ShoppingBasketIcon className={classes.iconCart} />
              <Typography>Tidak ada produk dikeranjang.</Typography>
              {!AsCustomer && (
                <Button
                  color="primary"
                  variant="contained"
                  style={{ marginTop: 20 }}
                  onClick={() => history.push("/")}
                >
                  Belanja sekarang
                </Button>
              )}
            </div>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div style={{ marginBottom: 75 }}>
            {listCustomer.map((customer, index) => (
              <Fragment>
                <Typography
                  color="primary"
                  style={{
                    marginBottom: 10,
                    marginTop: index !== 0 && 20,
                    paddingLeft: 15,
                  }}
                >
                  <b>Atas Nama {customer}</b>
                </Typography>
                {filteredSupplier(
                  cart.filter((value) => value.customer.name === customer)
                ).map((supplier, index) => (
                  <Fragment>
                    <div className={classes.flexCenter}>
                      <Checkbox
                        color="primary"
                        checked={
                          cart
                            .filter((value) => value.customer.name === customer)
                            .filter((item) => item.supplier === supplier)
                            .length ===
                          selectedCart
                            .filter((value) => value.customer.name === customer)
                            .filter((item) => item.supplier === supplier).length
                        }
                        onClick={() =>
                          handleSelectedCartByVendor(supplier, customer)
                        }
                      />
                      <img src={storeIcon} alt="" />
                      <Typography style={{ paddingLeft: 10 }}>
                        {supplier}
                      </Typography>
                      <NavigateNextIcon />
                    </div>
                    <Divider />
                    {cart
                      .filter((value) => value.customer.name === customer)
                      .filter((item) => item.supplier === supplier)
                      .map((res, index) => (
                        <Fragment>
                          <div className={classes.containerItemCart}>
                            <div>
                              <Checkbox
                                color="primary"
                                checked={Boolean(
                                  selectedCart[
                                    selectedCart.findIndex(
                                      (value) => value.id === res.id
                                    )
                                  ]
                                )}
                                onClick={() => handleSelectedCart(res)}
                              />
                            </div>
                            <div style={{width: '100%'}}>
                              <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                <img
                                  src={res.img}
                                  alt={res.name}
                                  className={classes.imageitemCart}
                                />
                                <div style={{ textAlign: 'end' }}>
                                  <Typography
                                    className={classes.title}
                                    onClick={() =>
                                      history.push(`/product/${res.slug}`)
                                    }
                                  >
                                    {res.name}
                                  </Typography>
                                  <Typography variant="caption">
                                    <b>Variasi: </b>
                                  </Typography>
                                  <Typography variant="caption">
                                    {`${res.properties.size}, ${res.properties.color}, ${res.properties.material}`}
                                  </Typography>
                                  <Typography color="primary">
                                    {`@Rp${numberSeparator(res.price)}`}
                                  </Typography>
                                </div>
                              </div>
                              <div className={classes.actionSectionCart}>
                                <div className={classes.inputGroup}>
                                  <Button
                                    className={classes.buttonInput}
                                    onClick={() =>
                                      handleMin(res.id, supplier, res)
                                    }
                                  >
                                    -
                                  </Button>
                                  <input
                                    type="number"
                                    className={classes.input}
                                    value={res.quantity}
                                    onChange={(e) =>
                                      handleInput(
                                        res.id,
                                        e.target.value,
                                        supplier,
                                        res
                                      )
                                    }
                                  />
                                  <Button
                                    className={classes.buttonInput}
                                    onClick={() =>
                                      handlePlus(res.id, supplier, res)
                                    }
                                  >
                                    +
                                  </Button>
                                </div>
                                <div className={classes.flexCenter}>
                                  <Typography>
                                    <b>{`Rp${numberSeparator(
                                      res.quantity * res.price
                                    )}`}</b>
                                  </Typography>
                                  <DeleteIcon
                                    style={{ marginLeft: 10 }}
                                    onClick={() =>
                                      handleRemoveItem(
                                        res.id,
                                        supplier,
                                        res,
                                        customer
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          {cart
                            .filter((value) => value.customer.name === customer)
                            .filter((item) => item.supplier === supplier)
                            .length -
                            1 !==
                            index && <Divider />}
                        </Fragment>
                      ))}
                    {/* <div
                    style={{ display: "flex", paddingTop: 10, paddingLeft: 18 }}
                  >
                    <img src={discountIcon} alt="" />
                    <Typography variant="caption" style={{ paddingLeft: 10 }}>
                      Potongan pengiriman sampai{" "}
                      <span style={{ color: "#FF6564" }}>Rp10.000</span> dengan
                      min. pembelian <b>Rp50,000; </b>
                      <span style={{ color: "#FF6564" }}>
                        Gratis ongkir
                      </span>{" "}
                      dengan min. pembelian <b>Rp200.000</b>
                    </Typography>
                  </div> */}
                    {filteredSupplier(
                      cart.filter((value) => value.customer.name === customer)
                    ).length -
                      1 !==
                      index && <DividerMobile />}
                  </Fragment>
                ))}
              </Fragment>
            ))}
          </div>
        </Fragment>
      )}
      {/* Action Section */}
      {(cart !== null || cart.length !== 0) && (
        <div className={classes.containerActionSection}>
          <div>
            <Checkbox
              color="primary"
              checked={cart.length === selectedCart.length}
              onClick={handlePilihSemua}
            />
            <Typography variant="caption" style={{ color: "#9999" }}>
              Pilih Semua
            </Typography>
          </div>
          <div style={{ display: "flex" }}>
            <div>
              <Typography
                variant="caption"
                style={{
                  color: "#9999",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                Total Belanja
              </Typography>
              <Typography
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <b>
                  Rp {numberSeparator(listPrice.reduce((a, b) => a + b, 0))}
                </b>
              </Typography>
            </div>
            <Button
              color="primary"
              variant="contained"
              style={{ marginLeft: 10 }}
              onClick={handleCheckout}
            >
              Bayar
            </Button>
          </div>
        </div>
      )}
    </Fragment>
  );
});

export default withRouter(Mobile);
