import React, { useEffect } from "react";
import {
    Container,
    Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        boxShadow: "0 0 0.25em rgb(0 0 0 / 30%)",
        padding: "32px 64px",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        [theme.breakpoints.down("501")]: {
        padding: "32px 10px",
        },
    },
    title: {
        textAlign: "center",
        color: "#252525",
    },
    formContainer: {
        "& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-fullWidth": {
        marginBottom: "16px",
        },
    },
    submitButton: {
        color: "#fff",
        backgroundColor: "#3bb877",
    },
    wrapperBank: {
        width: '50%', 
        minWidth: 200, 
        border: '1px dashed #a9a9a9', 
        marginTop: '-1px', 
        marginLeft: '-1px',
        padding: 10
    }
}));

const PaymentSuccessCampaign = () => {
    const classes = useStyles();

    return (
        <Box>
            <Container maxWidth="sm">
                <div className={classes.wrapper} style={{marginTop: 10, textAlign: 'center'}}>
                    <h1 className={classes.title}>Pembayaran Transfer Anda Berhasil</h1>
                    <div style={{fontSize: '1.1rem', fontWeight: '400', marginBottom: 20}}>Terimakasih telah berbelanja di Idejualan! Pesananmu sudah berhasil kami terima</div>

                    <div>
                        <CheckCircleOutlineIcon style={{color: 'green', width: 200, height: 200}} />
                    </div>
                </div>

                <br/>
            </Container>
        </Box>
    )
}

export default PaymentSuccessCampaign