import styled from 'styled-components'
export const CommissionLabel = styled.div`
  position: relative;
  padding: 4px 8px;
  background-color: #FFF2D0;
  color: #FDCD0F;
  font-size:10px;
  font-family: 'DMSans-Bold';
  width: fit-content;
  border-radius: 5px;
`