import { Container, Box, Chip, Grid, CircularProgress } from '@material-ui/core'
import React, { Fragment, useEffect, useState } from 'react'
import { withStyles } from "@material-ui/core/styles"
import styles from "./styles"
import Footer from '../../components/footer'
import Navbar from '../../components/navbar'
import { getProductPromo } from '../../services/axios'
import CardProduct from '../../components/CardProduct'
import InfiniteScroll from 'react-infinite-scroll-component'

const PromoPage = withStyles(styles)((props) => {
    const { classes } = props;
    const [filter, setfilter] = useState("all")
    const [promoProducts, setPromoProducts] = useState([])
    const [page, setPage] = useState(1)

    useEffect(() => {
        const getData = async() => {
            const allData = await getProductPromo(filter,page);
            setPromoProducts(allData.data);
        }

        getData()
    }, []);

    const handleFilter = (myFilter, myPage) => {
        setPromoProducts([])
        setfilter(myFilter)
        setPage(myPage)
        const getData = async() => {
            const allData = await getProductPromo(myFilter, myPage);
            setPromoProducts(allData.data);
        }

        getData()
    }

    const fetchMoreData = () => {
        const getData = async() => {
            const allData = await getProductPromo(filter, page+1);
            console.log(allData)
            setPromoProducts([...promoProducts, ...allData.data]);
        }

        getData()
        setPage(page+1)
    }

  return (
    <Fragment>
        <Navbar variant="primary" />
            <Container maxWidth="md" className={classes.container}>
                <Box mb={2}>
                    <Grid container spacing={2}>
                        {/* Semua */}
                        <Grid item>
                            <Chip
                                label="Semua"
                                color={filter === 'all'? 'primary' : "default"}
                                onClick={() => handleFilter("all", 1)}
                            />
                        </Grid>

                        {/* Gratis Ongkir */}
                        <Grid item>
                            <Chip
                                label="Gratis Ongkir"
                                color={filter === 'ongkir'? 'primary' : "default"}
                                onClick={() => handleFilter("ongkir", 1)}
                            />
                        </Grid>

                        {/* Cashback */}
                        <Grid item>
                            <Chip
                                label="Cashback"
                                color={filter === 'cashback'? 'primary' : "default"}
                                onClick={() => handleFilter("cashback", 1)}
                            />
                        </Grid>

                        {/* Potongan Harga */}
                        <Grid item>
                            <Chip
                                label="Potongan Harga"
                                color={filter === 'discount'? 'primary' : "default"}
                                onClick={() => handleFilter("discount", 1)}
                            />
                        </Grid>

                        {/* Bundling */}
                        <Grid item>
                            <Chip
                                label="Bundling"
                                color={filter === 'bundling'? 'primary' : "default"}
                                onClick={() => handleFilter("bundling", 1)}
                            />
                        </Grid>
                    </Grid>

                    <br/>

                    <div className={classes.containerProduct}>
                        {
                        promoProducts &&
                            promoProducts.map((res, index) => (
                                <div key={index} style={{margin: '15px'}}>
                                    <CardProduct variant="reseller" data={res} />
                                </div>
                            ))
                        }

                        <InfiniteScroll
                        dataLength={promoProducts.length}
                        next={fetchMoreData}
                        style={{
                            overflow: 'none',
                        }}
                        hasMore={true}
                        >

                        </InfiniteScroll>
                    </div>
                </Box>
            </Container>
        <Footer />
    </Fragment>
    )
})

export default PromoPage