import { axiosInstance } from "../config/fetchAxios";

// Authorize
// ===========

export const isLoggedIn = () => localStorage.getItem("TOKEN") != null;

export const RegReseller = async (data) => {
  const response = axiosInstance.post(`/api/v1/auth/reseller/register`, data);
  return response;
};

export const LoginReseller = async (data) => {
  const response = axiosInstance.post(`/api/v1/auth/reseller/login`, data);
  return response;
};

export const ReqTokenResetPasswordReseller = async (data) => {
  const response = axiosInstance.post(
    `/api/v1/auth/reseller/passwordreset/request`,
    data
  );
  return response;
};

export const resendActivationEmail = async (data) => {
  const response = axiosInstance.post(
    `/api/v1/auth/reseller/resend-activation`,
    data
  );
  return response;
};

export const ResetPasswordReseller = async (data) => {
  const response = axiosInstance.post(
    `/api/v1/auth/reseller/passwordreset`,
    data
  );
  return response;
};

export const VerifyPhoneNumber = async (data) => {
  const response = axiosInstance.post(`/api/v1/auth/phone-verification`, data);
  return response;
};

export const ResendVerifyPhoneNumber = async (data) => {
  const response = axiosInstance.post(
    `/api/v1/auth/phone-verification/resend`,
    data
  );
  return response;
};

// Products
// ===========

export const getProductData = async (request) => {
  const response = axiosInstance.get(`/api/v1/products/${request}`);
  return response;
};

export const getProductRecomendations = async (request) => {
  const response = axiosInstance.get(
    `/api/v1/products?filter[featured_by_admin]=1&filter[recomendation]=1`
  );
  return response;
};

export const getProductPromo = async (filter, page) => {
  const response = axiosInstance.get(
    `/api/v1/products?filter[promo]=${filter}&page=${page}`
  );
  return response;
};

export const getNewProduct = async () => {
  const response = axiosInstance.get(
    `/api/v1/products?page=1&filter[from_newest]=1`
  );
  return response;
};

export const getBestSellerProduct = async () => {
  const response = axiosInstance.get(`/api/v1/products?page=1&best_selling=1`);
  return response;
};

// POP UP
// ===========

export const getPopUp = async () => {
  const response = axiosInstance.get(`/api/v1/options/popup`);
  return response;
};

// Suppliers
// ===========

export const getSupplier = async (filter) => {
  const response = axiosInstance.get(`/api/v1/suppliers/${filter}`);
  return response;
};

export const followSupplier = async (slug) => {
  const response = axiosInstance.post(`/api/v1/suppliers/${slug}/follow`);
  return response;
};

export const unfollowSupplier = async (slug) => {
  const response = axiosInstance.post(`/api/v1/suppliers/${slug}/unfollow`);
  return response;
};

export const getSupplierSupportedCourier = async (supplier) => {
  const response = axiosInstance.get(`/api/v1/suppliers/${supplier}/couriers`);
  return response;
};

export const getSupplierCategories = async (slug) => {
  const response = axiosInstance.get(`/api/v1/suppliers/${slug}/categories`);
  return response;
};

export const getBrandPopuler = async (page) => {
  const response = axiosInstance.get(
    `/api/v1/suppliers?sort=-orders_count&page=${page}`
  );
  return response;
};

// Storefronts
// ===========

export const getStorefront = async (slug) => {
  const response = axiosInstance.get(`/api/v1/storefronts/${slug}`);
  return response;
};

export const editStorefront = async (slug, data) => {
  data.append("_method", "PUT");
  const response = axiosInstance.post(`/api/v1/storefronts/${slug}`, data);
  return response;
};

export const getStorefrontProducts = async (slug, filter) => {
  const response = axiosInstance.get(
    `/api/v1/storefronts/${slug}/products${filter ? `${filter}&filter[is_sold]=0` : "?filter[is_sold]=0"}`
  );
  return response;
};

export const addToStorefront = async (storefront, product) => {
  const response = axiosInstance.post(
    `/api/v1/storefronts/${storefront}/products`,
    product
  );
  return response;
};

export const deleteProductStorefront = async (storefront, slug) => {
  const response = axiosInstance.delete(
    `/api/v1/storefronts/${storefront}/products/${slug}`
  );
  return response;
};

export const getStorefrontProductCategories = async (storefront) => {
  const response = axiosInstance.get(
    `/api/v1/storefronts/${storefront}/categories`
  );
  return response;
};

export const editPriceByReseller = async (storefrontName, form) => {
  const response = axiosInstance.post(
    `/api/v1/storefronts/${storefrontName}/products`,
    form
  );
  return response;
};

// Support Data & Service
// ===========

export const getProvinces = async () => {
  const response = axiosInstance.get(`/api/v1/support/location/provinces`);
  return response;
};

export const getCitiesByProvinceId = async (provinceId) => {
  const response = axiosInstance.get(`/api/v1/support/location/cities?filter[province.id]=${provinceId}`);
  return response;
};

export const getAllCity = async (city) => {
  const response = axiosInstance.get(`/api/v1/support/location/cities${city}`);
  return response;
};

export const getSubdistrict = async (city_id) => {
  const response = axiosInstance.get(
    `/api/v1/support/location/subdistricts?city_id=${city_id}`
  );
  return response;
};

export const upgradeAccount = async (id, data = {}) => {
  const response = axiosInstance.post(
    `/api/v1/resellers/${id}/upgrade_account`,
    data
  );
  return response;
};

export const getAllCourier = async () => {
  const response = axiosInstance.get(`/api/v1/support/shipping/couriers`);
  return response;
};

export const checkResi = async (body) => {
  const response = axiosInstance.post(
    `/api/v1/support/shipping/tracking`,
    body
  );
  return response;
};

export const checkShippingCost = async (data) => {
  const response = axiosInstance.post(`/api/v1/support/shipping/cost`, data);
  return response;
};

export const checkShippingCostV2 = async (data) => {
  const response = axiosInstance.post(`/api/v2/support/shipping/cost`, data);
  return response;
};

// Categories
// ===========

export const getCategories = async () => {
  const response = axiosInstance.get(`/api/v1/categories`);
  return response;
};

// Orders
// ===========

export const getCustomers = async (id) => {
  const response = axiosInstance.get(`/api/v1/resellers/${id}/customers`);
  return response;
};

export const checkout = async (data) => {
  const response = axiosInstance.post(`/api/v2/orders/checkout`, data);
  return response;
};

export const checkoutCustomer = async (data) => {
  const response = axiosInstance.post(`/api/v1/storefronts/new-storefront/checkout`, data);
  return response;
};

export const getAppBanner = async () => {
  const response = axiosInstance.get(`/api/v1/options/app-banner`);
  return response;
};

export const getVouchers = async () => {
  const response = axiosInstance.get(
    `/api/v2/vouchers?paginate=20&include=supplier`
  );
  return response;
};

export const getAllVouchers = async (type, page) => {
  const response = axiosInstance.get(
    `/api/v2/vouchers?filter[show_all]=1&page=${page}&filter[type]=${type}&include=supplier`
  );
  return response;
};

export const getAllMyVouchers = async (type, page) => {
  const response = axiosInstance.get(
    `/api/v2/vouchers?filter[show_all]=1&filter[claimed]=1&page=${page}&filter[type]=${type}&include=supplier`
  );
  return response;
};

export const getVoucherDetail = async (id) => {
  const response = axiosInstance.get(`/api/v2/vouchers/${id}`);
  return response;
};
// Daily Login
export const getAllHistoryDataDailyLogin = async () => {
  const response = axiosInstance.get(`/api/v2/login-history`);
  return response;
};

export const claimCoinDailyLogin = async (data) => {
  const response = axiosInstance.post(`/api/v2/daily-login`, data);
  return response;
};

// chat PIC
export const getPicContact = async () => {
  const response = axiosInstance.get(`/api/v2/pic/chat`);
  return response;
};

// vendor bank
export const getVedorBanks = async () => {
  const response = axiosInstance.get(`/api/v2/vendor-banks`);
  return response;
};
