import React, {useState} from "react";
import { useHistory } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  useMediaQuery,
  Card,
  Chip,
  Avatar,
  Box,
  CircularProgress,
  Button,
  Menu,
  MenuItem,
  Input,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "@material-ui/lab/Pagination";

import Navbar from "../../components/navbar";
import WaitingPayment from "./components/WaitingPayment";
import Forwarded from "./components/Forwarded";
import Delivery from "./components/Delivery";
import Cancel from "./components/Cancel";

import { numberSeparator } from "../../utils/numberSeparator";

import { getOrders } from "../../services/order.service";

import style from "./style";

const MyOrderPage = withStyles(style)((props) => {
  const { classes } = props;
  const isMobile = useMediaQuery("(max-width:500px)");
  let history = useHistory();
  const [orders, setOrders] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPage, setTotalPage] = React.useState(0);

  const query = new URLSearchParams(window.location.search);
  const status = query.get("status") || "waiting_payment";

  React.useEffect(() => {
    const statusList = [
      "waiting_payment",
      "forwarded",
      "processed",
      "delivery",
      "canceled",
      "done",
      "canceled_supplier",
    ];
    if (statusList.indexOf(status) === -1) {
      history.push("/orders?status=waiting_payment");
      return;
    }
    handleOrders();
  }, [currentPage, status]);

  const handleOrders = async () => {
    const currentFilter = filterList[currentFilterIndex].key;
    try {
      setLoading(true);
      const res = await getOrders({
        paginate: 10,
        page: currentPage,
        [`filter[${status}]`]: 1,
        [`filter[${currentFilter}]`]: `${filter.search}`
      });
      setOrders(res.data);
      setCurrentPage(res.meta.current_page);
      setTotalPage(res.meta.last_page);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleFilter = (item) => {
    history.push("/orders?status=" + item);
  };

  const renderOrders = () => {
    if (loading) {
      return (
        <Box
          height="100vh"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      );
    } else {
      if (orders.length === 0) {
        return (
          <Box
            height="50vh"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <h3>Data tidak ditemukan</h3>
          </Box>
        );
      } else {
        return orders.map((order) => {
          if (order.status === 10) {
            return <WaitingPayment order={order} refetch={handleOrders} />;
          } else if (order.status === 20) {
            return <Forwarded order={order} refetch={handleOrders}/>;
          } else if (order.status === 40 || order.status === 50) {
            return <Delivery order={order} />;
          } else if (order.status === 91 || order.status === 94 || order.status === 92) {
            return <Cancel order={order} />;
          }
          return (
            <Card
              key={order.id}
              style={{ marginBottom: 16 }}
              variant="outlined"
            >
              <Box p={2}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box display="flex" alignItems="center" gridGap={6}>
                    <Avatar
                      src={order.supplier.avatar_url}
                      style={{ width: 36, height: 36 }}
                    />
                    <Typography>{order.supplier.name}</Typography>
                  </Box>
                  <Typography>
                    Status:{" "}
                    {order.histories[order.histories.length - 1].message}
                  </Typography>
                </Box>

                <Box height={2} mt={1} mb={2} bgcolor="#F7F7F7" />
                {order.items.map(({ id, sku, reseller_price_idr, qty }) => {
                  if (sku) {
                    return (
                      <Box mb={2} key={id}>
                        <Grid container spacing={2}>
                          <Grid item xs={8}>
                            <Typography>{sku.product.name}</Typography>
                            {sku.properties && (
                              <Box mt={1} mb={2}>
                                <Chip
                                  variant="outlined"
                                  label={sku.properties.color}
                                  disabled
                                  size="small"
                                />{" "}
                                <Chip
                                  variant="outlined"
                                  label={sku.properties.material}
                                  disabled
                                  size="small"
                                />{" "}
                                <Chip
                                  variant="outlined"
                                  label={sku.properties.size}
                                  disabled
                                  size="small"
                                />
                              </Box>
                            )}
                            <Typography
                              color="primary"
                              component="span"
                              variant="h6"
                            >
                              Rp.{numberSeparator(reseller_price_idr || 0)}
                            </Typography>
                            {"   x   "}
                            <Typography
                              component="span"
                              color="textSecondary"
                              variant="h6"
                            >
                              {qty}
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Avatar
                              src={sku.image_url}
                              style={{ height: 64 }}
                              variant="square"
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    );
                  }
                })}
              </Box>
              <Box mb={2} height={2} bgcolor="#F7F7F7" />
              <Box
                display="flex"
                alignItems="flex-end"
                flexDirection="column"
                px={2}
              >
                <Typography variant="caption">Jumlah harus dibayar</Typography>
                <Typography variant="h6" style={{ color: "red" }}>
                  Rp.{numberSeparator(order.payment.amount || 0)}
                </Typography>
              </Box>
            </Card>
          );
        });
      }
    }
  };

  // manajement filter
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [currentFilterIndex, setCurrentFilterIndex] = useState(0);
  const [filter, setFilter] = useState({
    search: ''
  });
  const filterList = [
    { key: "order_id", name: "Order ID" },
    { key: "supplier_name", name: "Supplier" },
    { key: "customer_name", name: "Customer" },
  ];

  const handleCari = async () => {
    const currentFilter = filterList[currentFilterIndex].key;
    try {
      setLoading(true);
      const res = await getOrders({
        paginate: 10,
        page: 1,
        [`filter[${status}]`]: 1,
        [`filter[${currentFilter}]`]: `${filter.search}`
      });
      setOrders(res.data);
      setCurrentPage(res.meta.current_page);
      setTotalPage(res.meta.last_page);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeFilter = (event, index) => {
    setAnchorElFilter(null);
    setCurrentFilterIndex(index);
  };

  const handleSearch = (keyword) => {
    setFilter({ ...filter, search: keyword });
  };

  const enter = (event) => {
    if (event.keyCode === 13) {
      handleCari();
    }
  };

  console.log(filter)

  return (
    <div
      style={
        !isMobile
          ? {
              maxWidth: 960,
              padding: 24,
              position: "absolute",
              margin: "0 auto",
              left: 0,
              right: 0,
            }
          : {}
      }
    >
      <Navbar variant={"primary"} />
      <div>
        <Box mb={3}>
          <Grid container spacing={2}>
            <Grid item>
              <Chip
                label="Belum bayar"
                color={status === "waiting_payment" ? "primary" : "default"}
                onClick={() => handleFilter("waiting_payment")}
              />
            </Grid>
            <Grid item>
              <Chip
                label="Diproses"
                color={status === "forwarded" ? "primary" : "default"}
                onClick={() => handleFilter("forwarded")}
              />
            </Grid>
            <Grid item>
              <Chip
                label="Dikemas"
                color={status === "processed" ? "primary" : "default"}
                onClick={() => handleFilter("processed")}
              />
            </Grid>
            <Grid item>
              <Chip
                label="Dikirim"
                color={status === "delivery" ? "primary" : "default"}
                onClick={() => handleFilter("delivery")}
              />
            </Grid>
            <Grid item>
              <Chip
                label="Selesai"
                color={status === "done" ? "primary" : "default"}
                onClick={() => handleFilter("done")}
              />
            </Grid>
            <Grid item>
              <Chip
                label="Dibatalkan"
                color={status === "canceled" ? "primary" : "default"}
                onClick={() => handleFilter("canceled")}
              />
            </Grid>
            <Grid item>
              <Chip
                label="Dibatalkan Supplier"
                color={status === "canceled_supplier" ? "primary" : "default"}
                onClick={() => handleFilter("canceled_supplier")}
              />
            </Grid>
          </Grid>
        </Box>

        {/* filter */}
        <Box mb={3}>
          <Grid container spacing={2}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={(event) => setAnchorElFilter(event.currentTarget)}
              >
                {filterList[currentFilterIndex].name}
                <ArrowDropDownIcon />
              </Button>
            </Grid>
            <Grid item>
              <Menu
                id="anchorFilter"
                anchorEl={anchorElFilter}
                keepMounted
                open={Boolean(anchorElFilter)}
                onClose={() => setAnchorElFilter(null)}
              >
                {filterList.map((option, index) => (
                  <MenuItem
                    key={index}
                    onClick={(event) => handleChangeFilter(event, index)}
                  >
                    {filterList[index].name}
                  </MenuItem>
                ))}
              </Menu>
            </Grid>
            <Grid item>
              <div className={classes.searchWrapper}>
                <SearchIcon className={classes.SearchIcon} />
                <Input
                  disableUnderline={true}
                  placeholder="Pencarian..."
                  onKeyDown={(e) => enter(e)}
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </div>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleCari()}
                style={{ borderRadius: 30, marginLeft: 10 }}
              >
                Cari
              </Button>
            </Grid>
          </Grid>
        </Box>

        {renderOrders()}
        {orders.length > 0 && (
          <Box display="flex" justifyContent="center">
            <Pagination
              count={totalPage}
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color="primary"
              onChange={(event, page) => setCurrentPage(page)}
            />
          </Box>
        )}
      </div>
    </div>
  );
});

export default MyOrderPage;
