import React, { Fragment, useEffect, useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withStyles } from "@material-ui/core/styles";
import { stylesContent, stylesDesktop, stylesMobile } from "./style";
import {
  Grid,
  Typography,
  TextField,
  Checkbox,
  Backdrop,
  Container,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Brandlogo from "../../assets/images/Brand.svg";
import IlustrationDesktop from "../../assets/images/ilustrasi-daftar.svg";
import BackgroundDesktop from "../../assets/images/background-daftar.svg";
import GoogleIcon from "../../assets/icons/google.svg";
import FacebookIcon from "../../assets/icons/facebook.svg";
import MobileVectorBg from "../../assets/images/Vector1.svg";
import MobileVector from "../../assets/images/register2.svg";
import CloseIcon from "@material-ui/icons/Close";
import { RegReseller, resendActivationEmail } from "../../services/axios";
import swal from "@sweetalert/with-react";

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-input": {
      padding: 15,
    },
  },
})(TextField);

const Content = withStyles(stylesContent)((props) => {
  const { classes } = props;
  let history = useHistory();
  const [input, setInput] = useState({
    nama_lengkap: "",
    email: "",
    nama_toko: "",
    password: "",
    konfirmasi_password: "",
    ref: "",
    phone: "",
  });
  const [phone, setPhone] = useState("");
  const [checkbox, setCheckbox] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openDialogConfirmation, setOpenDialogConfirmation] =
    React.useState(false);

  const query = new URLSearchParams(window.location.search);
  const ref = query.get("ref") ?? localStorage.getItem("ref") ?? "";

  useEffect(() => {
    if (ref) {
      setInput((prevState) => ({
        ...prevState,
        ref: ref,
      }));

      localStorage.setItem("ref", ref);
    }
  }, []);

  const handleCloseDialogConfirmation = () => {
    setOpenDialogConfirmation(false);
  };

  const handleChangeInput = (prop) => (event) => {
    setInput({ ...input, [prop]: event.target.value });
    if (prop === "phone") {
      setPhone(event.target.value);
    }
  };

  const sendBody = {
    name: input.nama_lengkap,
    email: input.email,
    password: input.password,
    storefront_name: input.nama_toko,
    phone: input.phone,
  };

  const handleResend = async () => {
    history.push("/verif-phone");
  };

  const handleSignup = async () => {
    const {
      konfirmasi_password,
      email,
      nama_lengkap,
      nama_toko,
      password,
      phone,
    } = input;

    if (
      nama_lengkap === "" ||
      email === "" ||
      phone === "" ||
      password === "" ||
      nama_toko === "" ||
      konfirmasi_password === ""
    ) {
      swal("Oops", "Lengkapi data terlebih dahulu", "error");
      return;
    } else if (password.length < 6) {
      swal("Oops", "Password minimal 6 karakter", "error");
      return;
    } else if (password !== konfirmasi_password) {
      swal("Oops", "Konfirmasi password tidak sama dengan password", "error");
      return;
    } else if (checkbox === false) {
      swal("Oops", "Anda harus menyetujui syarat dan ketentuan", "error");
      return;
    }

    if (input.ref) {
      sendBody.ref = input.ref;
    }

    try {
      const response = await RegReseller(sendBody);
      if (response.code === "unactivated") {
        setOpenDialogConfirmation(true);
        setInput({
          nama_lengkap: "",
          email: "",
          nama_toko: "",
          password: "",
          konfirmasi_password: "",
          ref: "",
          phone: "",
        });

        localStorage.setItem("TOKEN", response.access_token);
        localStorage.setItem("REGISTER_USER_ID", response.user.id);
      }
    } catch (error) {
      if (error) {
        if (error.response) {
          if (error.response.data) {
            if (error.response.data.message) {
              swal("Error!", error.response.data.message, "error");
              return;
            }
          }
        }
      }
      swal("Error!", "Terjadi kesalahan silakan coba lagi", "error");
      return;
    }
  };

  return (
    <div>
      <Typography className={classes.title}>
        Daftar akun baru sekarang
      </Typography>

      <Grid container spacing={1} className={classes.inputGroup}>
        <Grid item xs={12}>
          <CssTextField
            className={classes.textField}
            label="Nama Lengkap"
            value={input.nama_lengkap}
            variant="outlined"
            onChange={handleChangeInput("nama_lengkap")}
          />
        </Grid>
        <Grid item xs={12}>
          <CssTextField
            className={classes.textField}
            label="Email"
            value={input.email}
            variant="outlined"
            onChange={handleChangeInput("email")}
          />
        </Grid>
        <Grid item xs={12}>
          <CssTextField
            className={classes.textField}
            label="Nama Toko"
            value={input.nama_toko}
            variant="outlined"
            onChange={handleChangeInput("nama_toko")}
          />
        </Grid>
        <Grid item xs={12}>
          <CssTextField
            className={classes.textField}
            label="No. Telepon"
            value={input.phone}
            variant="outlined"
            onChange={handleChangeInput("phone")}
            type="number"
          />
        </Grid>
        <Grid item xs={12}>
          <CssTextField
            className={classes.textField}
            label="Password"
            value={input.password}
            type="password"
            variant="outlined"
            onChange={handleChangeInput("password")}
          />
        </Grid>
        <Grid item xs={12}>
          <CssTextField
            className={classes.textField}
            label="Konfirmasi Password"
            type="password"
            value={input.konfirmasi_password}
            variant="outlined"
            onChange={handleChangeInput("konfirmasi_password")}
          />
        </Grid>
        <Grid item xs={12}>
          <CssTextField
            className={classes.textField}
            label="Kode referral"
            value={input.ref}
            variant="outlined"
            onChange={handleChangeInput("ref")}
          />
        </Grid>

        <Typography className={classes.textChekbox}>
          <Checkbox
            color="primary"
            onClick={(e) => setCheckbox(e.target.checked)}
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
          Saya telah membaca dan menyetujui{" "}
          <span className={classes.link}>Aturan penggunaan</span> dan{" "}
          <span className={classes.link}>Kebijakan Privasi </span>Idejualan
        </Typography>

        <button className={classes.signupButton} onClick={handleSignup}>
          Daftar
        </button>
        <Backdrop open={isLoading} className={classes.backdrop}>
          <CircularProgress color="primary" />
        </Backdrop>

        <Grid container spacing={0} className={classes.grid}>
          <Grid item xs={5}>
            <hr className={classes.hr} />
          </Grid>
          <Grid item xs={2} className={classes.or}>
            <Typography className={classes.textOr}>Atau</Typography>
          </Grid>
          <Grid item xs={5}>
            <hr className={classes.hr} />
          </Grid>
        </Grid>

        <div style={{ margin: "10px 0 15px", width: "100%" }}>
          <button
            className={classes.buttonGoogle}
            onClick={() => {
              setIsLoading(true);
              window.location.href =
                "https://api.idejualan.com/api/v1/auth/social/google";
            }}
          >
            <Grid container style={{ alignItems: "center" }}>
              <Grid item xs={1}>
                <img src={GoogleIcon} alt="" className={classes.icon} />
              </Grid>
              <Grid item xs={11}>
                <Typography>Login dengan Google</Typography>
              </Grid>
            </Grid>
          </button>

          <button className={classes.buttonFacebook}>
            <Grid
              container
              style={{ alignItems: "center" }}
              onClick={() => {
                setIsLoading(true);
                window.location.href =
                  "https://api.idejualan.com/api/v1/auth/social/facebook";
              }}
            >
              <Grid item xs={1}>
                <img src={FacebookIcon} alt="" className={classes.icon} />
              </Grid>
              <Grid item xs={11}>
                <Typography>Login dengan Facebook</Typography>
              </Grid>
            </Grid>
          </button>
        </div>

        <div style={{ width: "100%" }}>
          <Typography className={classes.haveAccount}>
            Sudah punya akun?&nbsp;
            <span
              style={{ color: "#F63B68", cursor: "pointer" }}
              onClick={() => history.push("/login")}
            >
              Silakan Login
            </span>
          </Typography>
        </div>
      </Grid>

      {/* Modal Dialog Confirmation */}
      <Dialog
        open={openDialogConfirmation}
        onClose={handleCloseDialogConfirmation}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ textAlign: "center" }}
      >
        <DialogTitle id="alert-dialog-title">
          <Typography>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div></div>
              <Typography variant="h6">
                <b>Verifikasi no HP</b>
              </Typography>
              <CloseIcon
                style={{ height: 32, width: "auto", cursor: "pointer" }}
                onClick={() => setOpenDialogConfirmation(false)}
              />
            </div>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Kamu hampir selesai! Kami telah mengirim kode OTP ke{" "}
            <b>{phone}</b>
            <br />
            <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.buttonModal}>
          <Button
            onClick={handleResend}
            color="primary"
            variant="contained"
            autoFocus
          >
            Verifikasi OTP
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

const Mobile = withStyles(stylesMobile)((props) => {
  const { classes } = props;
  return (
    <Fragment>
      <div className={classes.logo}>
        <img
          src={Brandlogo}
          alt="idejualan"
          style={{ height: 40, marginTop: 10 }}
        />
      </div>
      <div>
        <img src={MobileVectorBg} alt="" style={{ width: "100%" }} />
        <div className={classes.imageVector}>
          <img src={MobileVector} alt="" />
        </div>
      </div>
      <Container maxWidth="xs">
        <Content />
      </Container>
    </Fragment>
  );
});

const Desktop = withStyles(stylesDesktop)((props) => {
  const { classes } = props;
  return (
    <div className={classes.container}>
      <img src={Brandlogo} alt="idejualan" className={classes.logo} />
      <Grid container>
        <Grid item xs={6}>
          <div className={classes.containerImg}>
            <img src={IlustrationDesktop} alt="" className={classes.vector} />
            <img src={BackgroundDesktop} alt="" style={{ height: "100%" }} />
          </div>
        </Grid>
        <Grid item xs={6} className={classes.containerContent}>
          <Content desktop />
        </Grid>
      </Grid>
    </div>
  );
});

const Login = () => {
  const isMobile = useMediaQuery("(max-width:500px)");
  return <React.Fragment>{isMobile ? <Mobile /> : <Desktop />}</React.Fragment>;
};

export default Login;
