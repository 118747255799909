import { axiosInstance } from "../config/fetchAxios";

export const getProvinces = async () => {
  const response = axiosInstance.get(`/api/v2/support/location/provinces`);
  return response;
};

export const getCitiesByProvinceId = async (provinceId) => {
  const response = axiosInstance.get(
    `/api/v2/support/location/cities?filter[province.id]=${provinceId}`
  );
  return response;
};

export const getDistrictsByCityId = async (cityId) => {
  const response = axiosInstance.get(
    `/api/v2/support/location/districts?filter[city.id]=${cityId}`
  );
  return response;
};

export const getSubdistrictByDistrictId = async (districtId) => {
  const response = axiosInstance.get(
    `/api/v2/support/location/subdistricts?filter[district.id]=${districtId}`
  );
  return response;
};
