import React, { Fragment, useEffect } from "react";
import Navbar from "../../../components/navbar";
import Banner from "../../../components/banner";
import Container from "@material-ui/core/Container";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Carousel from "../../../components/carousel";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import { useHistory } from "react-router-dom";
import { getProductData, getCategories } from "../../../services/axios";
import Footer from "../../../components/footer";
import Loading from "../../../components/loading";
import testimoni from "../testimoni";
import settings from "../settings";
import ScrollMenu from 'react-horizontal-scrolling-menu';

const Mobile = withStyles(style)((props) => {
  const { classes } = props;
  const history = useHistory();
  const [loading, setLoading] = React.useState(true);
  const [sliderProduct, setSliderProduct] = React.useState(null);

  const customeSlider = React.createRef();

  const next = () => {
    customeSlider.current.slickNext();
  };
  const previous = () => {
    customeSlider.current.slickPrev();
  };

  useEffect(() => {
    const getData = async () => {
      const getCategorie = await getCategories();
      const category = getCategorie.data[0].children.slice(0, 3);
      const response1 = await getProductData(
        `?filter[category.id]=${category[0].id}`
      );
      const response2 = await getProductData(
        `?filter[category.id]=${category[1].id}`
      );
      const response3 = await getProductData(
        `?filter[category.id]=${category[2].id}`
      );

      let data = [];
      category.forEach((res, index) => {
        data = [
          ...data.filter((value) => value.id !== category[index].id),
          {
            ...category[index],
            products:
              index === 0
                ? response1.data
                : index === 1
                ? response2.data
                : response3.data,
          },
        ];
        index === 2 && setSliderProduct(data);
      });
      if (category) setLoading(!loading);
    };
    getData();
  }, []);

  const MenuItem = (props) => {
    const { res } = props;
    return (
      <div>
        <div className={classes.cardWrapper}>
          <Button className={classes.buttonKategoriPopuler} onClick={res.link}>
            <Card className={classes.cardKategoriPopuler}>
              <div className={classes.containerImgKategoriPopuler}>
                <img
                  src={res.icon}
                  className={classes.imgKategoriPopuler}
                  alt={"icon"}
                />
              </div>
              <Typography className={classes.textItemKategoriPopuler}>
                {res.name}
              </Typography>
            </Card>
          </Button>
        </div>
      </div>
    );
  };

  const KategoriPopuler = () => {
    const list = [
      {
        name: "Blazer Wanita",
        icon: require("../../../assets/icons/category/blazer2.svg"),
        link: () => history.push(`/category/Blazer-Wanita-cat.4`),
      },
      {
        name: "Makeup Wajah",
        icon: require("../../../assets/icons/category/makeup.svg"),
        link: () => history.push(`/category/Makeup-Wajah-cat.5`),
      },
      {
        name: "Heels",
        icon: require("../../../assets/icons/category/heels.svg"),
        link: () => history.push(`/category/Heels-cat.6`),
      },
      {
        name: "Makeup Bibir",
        icon: require("../../../assets/icons/category/lipstick.svg"),
        link: () => history.push(`/category/Makeup-Bibir-cat.7`),
      },
      {
        name: "Makanan",
        icon: require("../../../assets/icons/category/makanan.svg"),
        link: () => history.push(`/category/Makanan-cat.8`),
      },
      {
        name: "Lainnya",
        icon: require("../../../assets/icons/category/lainnya.svg"),
        link: () => history.push(`/category`),
      },
    ];
    return list.map((res, index) => {
      return <MenuItem res={res} key={index} />;
    });
  };

  return (
    <Fragment>
      <Navbar variant="primary" />
      {loading ? (
        <Loading />
      ) : (
        <Fragment>
          <Container maxWidth="ls" className={classes.container}>
            {/* Banner Top */}
            <Banner />
          </Container>

          {/* Produk Pilihan */}
          {settings.featured_product && (
            <div>
              <Typography
                className={classes.textTitle}
                style={{ paddingBottom: 0 }}
              >
                Produk pilihan
              </Typography>
              <Slider
                infinite={true}
                slidesToShow={2}
                centerMode={true}
                slidesToScroll={1}
                adaptiveHeight={false}
                swipeToSlide={true}
              >
                <div className={classes.cardcontainer}>
                  <div
                    className={classes.cardbox}
                    onClick={() => history.push(`/search/sneakers`)}
                  >
                    <img
                      className={classes.cardimg}
                      src={require("../../../assets/images/sepatu.png")}
                      alt=""
                    />
                    <Typography className={classes.titleItem}>
                      Sneakers
                    </Typography>
                  </div>
                </div>
                <div className={classes.cardcontainer}>
                  <div className={classes.cardbox}>
                    <img
                      className={classes.cardimg}
                      src="https://cf.shopee.co.id/file/03e8a6ed2314296d1bb969e333d30c29_tn"
                      alt=""
                    />
                    <Typography className={classes.titleItem}>
                      Headset
                    </Typography>
                  </div>
                </div>
                <div className={classes.cardcontainer}>
                  <div className={classes.cardbox}>
                    <img
                      className={classes.cardimg}
                      src="https://cf.shopee.co.id/file/0e4b8e67fbb226ee65c0948fd97f7be5_tn"
                      alt=""
                    />
                    <Typography className={classes.titleItem}>
                      Hand Sanitizer
                    </Typography>
                  </div>
                </div>
                <div className={classes.cardcontainer}>
                  <div className={classes.cardbox}>
                    <img
                      className={classes.cardimg}
                      src="https://cf.shopee.co.id/file/66213ac262a6df9f47da8422f77e0c93_tn"
                      alt=""
                    />
                    <Typography className={classes.titleItem}>Xbox</Typography>
                  </div>
                </div>
              </Slider>
            </div>
          )}

          {/* Product Slider 1 */}
          <div>
            <Typography className={classes.textTitle}>
              {sliderProduct[0].name}
            </Typography>
            {sliderProduct[0].products.length !== 0 ? (
              <Carousel data={sliderProduct[0].products} />
            ) : (
              <Typography style={{ textAlign: "center" }}>
                Tidak ada data.
              </Typography>
            )}
          </div>

          <Container maxWidth="ls">
            {/* Banner Section */}
            <Grid container spacing={5} style={{ marginTop: 20 }}>
              <Grid item xs={12}>
                <Typography className={classes.textBannerMiddle}>
                  Vendor/Supplier Favorit
                </Typography>
                <img
                  src={require("../../../assets/images/bannerMiddle1.png")}
                  className={classes.imgBannerMiddle}
                  alt="banner"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.textBannerMiddle}>
                  Lengkapi Persediaanmu
                </Typography>
                <img
                  src={require("../../../assets/images/bannerMiddle2.png")}
                  className={classes.imgBannerMiddle}
                  alt="banner"
                />
              </Grid>
            </Grid>
          </Container>

          {/* Product Slider 2 */}
          <div>
            <Typography className={classes.textTitle}>
              {sliderProduct[1].name}
            </Typography>
            {sliderProduct[1].products.length !== 0 ? (
              <Carousel data={sliderProduct[1].products} />
            ) : (
              <Typography style={{ textAlign: "center" }}>
                Tidak ada data.
              </Typography>
            )}
          </div>

          {/* Product Slider 3 */}
          <div style={{ paddingTop: 30 }}>
            <Typography className={classes.textTitle}>
              {sliderProduct[2].name}
            </Typography>
            {sliderProduct[2].products.length !== 0 ? (
              <Carousel data={sliderProduct[2].products} />
            ) : (
              <Typography style={{ textAlign: "center" }}>
                Tidak ada data.
              </Typography>
            )}
          </div>

          {/* Testimonials */}
          {settings.testimoni && (
            <div className={classes.testiWrapper}>
              <Container maxWidth="ls">
                <Typography className={classes.headerTestimonials}>
                  Testimonials
                </Typography>
              </Container>
              <Slider
                infinite={true}
                speed={500}
                slidesToShow={1}
                slidesToScroll={1}
                arrows={false}
                ref={customeSlider}
              >
                {testimoni.map((res, index) => (
                  <div key={index}>
                    <Grid item xs={12}>
                      <div className={classes.bgTestimonial}></div>
                      <div
                        style={{
                          background: `url(${res.photo})`,
                        }}
                        className={classes.imgTestimonial}
                      ></div>
                    </Grid>
                    <Container maxWidth="ls">
                      <img
                        src={require("../../../assets/icons/quote.svg")}
                        className={classes.quote}
                        alt=""
                      />
                      <Typography style={{ padding: "10px 0" }}>
                        {res.quote}
                      </Typography>
                      <div className={classes.nameTestimonial}>
                        <Typography style={{ fontWeight: "bold" }}>
                          {res.name}
                        </Typography>
                        <Typography
                          variant="caption"
                          style={{ color: "#777777" }}
                        >
                          {res.title}
                        </Typography>
                      </div>
                    </Container>
                  </div>
                ))}
              </Slider>
              <Container maxWidth="ls">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: -35,
                    marginBottom: 20,
                  }}
                >
                  <ArrowBackIcon
                    className={classes.arrowBefore}
                    onClick={() => previous()}
                  />
                  <ArrowForwardIcon
                    className={classes.arrowNext}
                    onClick={() => next()}
                  />
                </div>
              </Container>
            </div>
          )}

          {/* Kategori Populer */}
          {settings.populer_category && (
            <Fragment>
              <Container maxWidth="ls">
                <Typography className={classes.kategoriPopuler}>
                  Kategori Populer
                </Typography>
              </Container>

              <div>
                <ScrollMenu data={KategoriPopuler()} />
              </div>
            </Fragment>
          )}
        </Fragment>
      )}

      {/* Footer */}
      <Footer />
    </Fragment>
  );
});

export default Mobile;
