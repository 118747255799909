const styles = (theme) => ({
  container: {
    paddingTop: 60,
    paddingBottom: 80,
  },
  card: {
    paddingTop: 30,
  },
  banner: {
    width: "100%",
  },
  icon: {
    height: 40,
    width: 40,
  },
  category: {
    paddingBottom: 20,
    fontSize: "1.1rem",
  },
});

export default styles;
