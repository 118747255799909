import React, { Fragment, useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withStyles } from "@material-ui/core/styles";
import { stylesContent, stylesDesktop, stylesMobile } from "./style";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import {
  Typography,
  TextField,
  Button,
  Container,
  Backdrop,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router-dom";
import { ReqTokenResetPasswordReseller } from "../../services/axios";
import swal from "sweetalert";

const Content = withStyles(stylesContent)((props) => {
  const { classes } = props;
  let history = useHistory();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeInput = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async () => {
    const redirect = window.location.origin + "/form-reset-password";

    const data = {
      email: email,
      redirect: redirect,
    };
    // Catatan: Ubah domain yang sudah fixs
    // if (window.location.origin === "https://app.idejualan.com") {
    if (email !== "") {
      setIsLoading(true);
      const response = await ReqTokenResetPasswordReseller(data);
      swal("Success!", response.message, "success").then(() =>
        history.push("/login")
      );
    }
    // }
  };

  return (
    <Fragment>
      <Typography className={classes.title}>Reset Password</Typography>
      <div style={{ paddingTop: 20 }}>
        <Typography className={classes.guide}>
          Masukkan email atau nomor ponsel yang terdaftar. Kami akan mengirimkan
          kode verifikasi untuk mengatur ulang kata sandi.
        </Typography>
        <TextField
          label="Email / Nomor Telepon"
          value={email}
          type="text"
          fullWidth={true}
          variant="outlined"
          onChange={handleChangeInput}
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth={true}
          className={classes.styledButton}
          onClick={handleSubmit}
        >
          Kirim
        </Button>
        <Typography style={{ textAlign: "center" }}>
          Kembali ke halaman{" "}
          <span className={classes.link} onClick={() => history.push("/login")}>
            login
          </span>{" "}
          atau{" "}
          <span
            className={classes.link}
            onClick={() => history.push("/signup")}
          >
            daftar
          </span>
        </Typography>
      </div>
      <Backdrop open={isLoading} className={classes.backdrop}>
        <CircularProgress color="primary" />
      </Backdrop>
    </Fragment>
  );
});

const Mobile = withStyles(stylesMobile)((props) => {
  return (
    <Fragment>
      <Navbar variant="default" />
      <Container maxWidth="lg">
        <Content />
      </Container>
    </Fragment>
  );
});

const Desktop = withStyles(stylesDesktop)((props) => {
  const { classes } = props;
  return (
    <Fragment>
      <Navbar variant="default" />
      <div className={classes.container}>
        <div className={classes.wrapper}>
          <Content desktop />
        </div>
      </div>
      <Footer />
    </Fragment>
  );
});

const ResetPassword = () => {
  const isMobile = useMediaQuery("(max-width:500px)");
  return <React.Fragment>{isMobile ? <Mobile /> : <Desktop />}</React.Fragment>;
};

export default ResetPassword;
