import React, { Fragment, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import { ReactComponent as ProductIconSecondary } from "../../../../../assets/icons/gridicons_product.svg";
import { ReactComponent as ProductIconPrimary } from "../../../../../assets/icons/gridicons_product2.svg";
import { ReactComponent as ShopIconPrimary } from "../../../../../assets/icons/shop2.svg";
import { ReactComponent as ShopIconSecondary } from "../../../../../assets/icons/shop.svg";
import Produk from "./produk";
import Vendor from "./vendor";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Content = withStyles(style)((props) => {
  const {
    classes,
    dataSearched,
    handleChangePage,
    handleFilterSort,
    setIndexTab,
    vendorSearched,
  } = props;
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    setIndexTab(value);
  }, [value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Fragment>
      <Paper>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab
            label="Produk"
            icon={
              value === 0 ? (
                <ProductIconPrimary className={classes.iconTab} />
              ) : (
                <ProductIconSecondary className={classes.iconTab} />
              )
            }
            {...a11yProps(0)}
          />
          <Tab
            label="Supplier"
            icon={
              value === 1 ? (
                <ShopIconPrimary className={classes.iconTab} />
              ) : (
                <ShopIconSecondary className={classes.iconTab} />
              )
            }
            {...a11yProps(1)}
          />
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0}>
        <Produk
          dataSearched={dataSearched}
          handleChangePage={handleChangePage}
          handleFilterSort={handleFilterSort}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Vendor vendorSearched={vendorSearched} />
      </TabPanel>
    </Fragment>
  );
});

export default Content;
